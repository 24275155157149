import React, { Component, useEffect, useState } from "react"
import { useToasts } from 'react-toast-notifications';
import profileimg from "../../assets/images/frontend-img/userdefaultimage.jpg";
import axios from "axios";
import Commheader from "../frontend/header";
import nodata from "../../assets/images/frontend-img/nodata.png";
import Footeradmin from "../frontend/footeradmin";
import paymentsuccessfully from "../../assets/images/frontend-img/paymentsuccessfully.png";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import mianheaderimg from "../../assets/images/frontend-img/logo-new.png";
import { Col, Row, Table } from "reactstrap";
import loginmainimg from "../../assets/images/frontend-img/logo-new1.png";

const manageprofile = () => {
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [DOB, setDOB] = useState('');
  const [Gender, setGender] = useState('');
  const [ProfileFileUrl, setProfileFileUrl] = useState(profileimg);
  const { addToast } = useToasts();
  const [MyMembershipName, setMyMembershipName] = useState('');
  const [MembershipExpiryDate, setMembershipExpiryDate] = useState('');
  const [MembershipDetail, setMembershipDetail] = useState([]);
  const [ShowLoader, setShowLoader] = useState(0);
  const [UserMembershipList, setUserMembershipList] = useState([]);
  const [PayAmount, setPayAmount] = useState(0);
  const [PlanTitle, setPlanTitle] = useState('');
  const [CompanyName, setCompanyName] = useState('');
  const [CustomerDetails, setCustomerDetails] = useState([]);
  const [MembershipDescription,setMembershipDescription]=useState([]);
  const [CurrentDate,setCurrentDate]=useState("");

  useEffect(() => {
    setShowLoader(1);
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      getProfileData();
      getMembershipList();
      getCompanyDetail();
    }
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth();
    var curr_year = today.getFullYear();
    var m_names = new Array("January", "February", "March", 
                            "April", "May", "June", "July",
                            "August", "September", 
                            "October", "November", "December");

    today = m_names[curr_month] + " " + curr_date + ", " + curr_year;
    var newdat = today;
    setCurrentDate(newdat);
  }, []);

  const getCompanyDetail = async () => {
    axios.get(global.APIURL + "/CompanyDetail")
      .then(res => {
        setCompanyName(res.data.Result[0].CompanyName);
      });
  };

  const getMembershipList = async () => {
    axios.get(global.APIURL + "/MembershipGet")
      .then(res => {
      
      
        setUserMembershipList(res.data.Result);
      });
  };

  const getProfileData = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);

    axios.post(global.APIURL + '/MyProfileData', formData)
      .then(res => {
        setCustomerDetails(res.data.Result);
        setEmail(res.data.Result[0].Email);
        setName(res.data.Result[0].Name);
        setMobileNo(res.data.Result[0].MobileNo)
        setGender(res.data.Result[0].Gender)
        setDOB(res.data.Result[0].DateOfBirth)
        if (res.data.Result[0].ProfilePic != null) {
          if (res.data.Result[0].ProfilePic != '') {
            setProfileFileUrl(res.data.Result[0].ProfilePic);
          }
        }
        if(res.data.Result[0].Membership.length>0){
          var MembershipDetail = res.data.Result[0].Membership;
          setMembershipDetail(MembershipDetail);
          setMembershipDescription(res.data.Result[0].Membership[0].Description)
        }
      
      });
    setShowLoader(0);
  }

  const getProfileMembershipData = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);

    axios.post(global.APIURL + '/Dashbord', formData)
      .then(res => {
        setMyMembershipName(res.data.Result[0].MyMembershipName);
        setMembershipExpiryDate(res.data.Result[0].MembershipExpiryDate);
      });
    setShowLoader(0);
  }

  const BuyMemberShip = (e, PlanId, Price, PlanName) => {

    if(Price!=0){
    const razordata = {
      amount: Price * 100,
      currency: "INR",
      receipt: PlanId,
      payment_capture: 1,
    };
    setPayAmount(parseFloat(Price == null ? "0" : Price == "" ? "0" : Price));
    setPlanTitle(PlanName == null ? "" : PlanName);
    console.log("lblPlanName", PlanName);

    $("#lblPayAmount").html(Price);
    $("#lblPlanName").html(PlanName);

    axios.post(global.APIURL + "/RazorPay", razordata).then(res => {
      console.log("razor-res", res);
      let order_id = res.data.id;
      let status = res.data.status;
      if (status == "created") {
        let options = {
          key_id: "rzp_live_xPdYwT48IAXvEq",
          key_secret:"ScLGAiA7Zx46aiTRvDTlFOrK",
          // key_id : "rzp_test_iK4zpshfmPYkHU",
          // key_secret : "oVPkCWmGnEbNbFKWdh0eFFz2",
          amount:
            parseFloat(Price == null ? "0" : Price == "" ? "0" : Price) * 100, // 2000 paise = INR 20, amount in paisa
          name: CompanyName,
          description: PlanName,
          image: "/your_logo.png",
          order_id: order_id,
          handler: function (response) {
            console.log("response pay", response);
            let Vrazorpay_order_id = response.razorpay_order_id == null ? "" : response.razorpay_order_id;
            $("#lblOrderId").html(Vrazorpay_order_id);
            if (Vrazorpay_order_id != "") {
              let url = global.APIURL + `/RazorOrderDetails/${Vrazorpay_order_id}/payments`;
              axios.get(url).then(res => {
                console.log("payment-res", res);
                console.log("payment-res1", res.data.items[0].status);
                console.log("xxxx", res.data.items[0].acquirer_data.upi_transaction_id);
                let VStatus = res.data.items[0].status;

                // let Vtransaction_id = (res.data.items[0].acquirer_data.upi_transaction_id==undefined?res.data.items[0].acquirer_data.bank_transaction_id:res.data.items[0].acquirer_data.upi_transaction_id);            
                let VMethod = res.data.items[0].method;
                let Vtransaction_id;


                if (VMethod == "netbanking") {
                  Vtransaction_id = res.data.items[0].acquirer_data.bank_transaction_id;
                }
                else if (VMethod == "wallet") {
                  Vtransaction_id = res.data.items[0].acquirer_data.transaction_id;
                }
                else if (VMethod == "upi") {
                  Vtransaction_id = res.data.items[0].acquirer_data.upi_transaction_id;
                }
                let mode_of_payment = res.data.items[0].method;
                console.log("tid", Vtransaction_id)
                $("#lblModeOfPayment").html(mode_of_payment);
                if (VStatus == "captured") {
                  const formData = new FormData();
                  let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
                  formData.append("RegisterId", VRegisterId);
                  formData.append("PlanId", PlanId);
                  formData.append("Price", Price);
                  formData.append("TransactionId", Vtransaction_id);
                  formData.append("ModeOfPayment", mode_of_payment);
                  for (var pair of formData.entries()) {
                    console.log(pair[0] + ":" + pair[1]);
                  }
                  axios
                    .post(global.APIURL + "/MembershipByPlan", formData)
                    .then(res => {
                      const formData1 = new FormData();
                      let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
                      formData1.append("RegisterId", VRegisterId);            
                      axios.post(global.APIURL + "/MembershipPurchase", formData1)
                      .then(res => {                                  
                      });
                      setShowLoader(0);
                      $("#MPMembership").modal("hide");
                      $('#paymentsuccessfully').modal('show');
                      addToast(res.data.StatusMessage, {
                        appearance: "success",
                        autoDismiss: true,
                      });
                      getProfileData();
                    });
                }
                else {
                  addToast("Payment Fail", {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              });
            } else {
              addToast("Payment Fail", {
                appearance: "error",
                autoDismiss: true,
              });
            }
          },
          modal: { escape: false, ondismiss: function () { addToast("Payment Cancelled", { appearance: "error", autoDismiss: true, }); }, },
          prefill: {
            name: CompanyName,
            email: Email,
            mobile: MobileNo,
          },
          notes: {
            address: "Hello World",
          },
          theme: {
            color: "#F37254",
          },
        };
        let rzp = new window.Razorpay(options);
        rzp.open();
      }
      else {
        alert("order not created");
      }
    });
  }
  else{

    const formData = new FormData();
    let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
    formData.append("RegisterId", VRegisterId);
    formData.append("PlanId", PlanId);
    formData.append("Price", Price);
    formData.append("TransactionId", "");
    formData.append("ModeOfPayment", "");

    axios
      .post(global.APIURL + "/MembershipByPlan", formData)
      .then(res => {
  
        const formData1 = new FormData();
        let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
        formData1.append("RegisterId", VRegisterId);

        axios.post(global.APIURL + "/MembershipPurchase", formData1)
        .then(res => {
         
       
        });

        addToast(res.data.StatusMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      });

  }


  };

  const exportMembershipPDF = async (e) => {
  
    const unit = "pt";
    const size = "A4";
    const orientation = "mm";    
    const pdf = new jsPDF(orientation, unit ,size,true);    
    const data = document.getElementById("membership_pdfid");  
 
   // var desc= MembershipDescription.replace(/<[^>]+>/g, '');
    //pdf.text(35, 25, desc);
    pdf.html(data, {
      function(pdf) {
        pdf.save('DOC.pdf');
      }
    }).then(() => {
      pdf.save("Receipt.pdf");
    });

  }
  const exportPDF = () => {

    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.addImage(mianheaderimg, 'JPEG', 210, -8, 180, 100);
    doc.setFontSize(12);

   var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth();
    var curr_year = today.getFullYear();
    var m_names = new Array("January", "February", "March", 
                            "April", "May", "June", "July",
                            "August", "September", 
                            "October", "November", "December");

    today = m_names[curr_month] + " " + curr_date + ", " + curr_year;
    var newdat = today;
    doc.text(450, 75, newdat);
    

   const headers2 = [[{ content: 'User Details', colSpan: 6, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Name", "Email","MobileNo","Gender","DOB"]];

   const data2 = CustomerDetails.map((item, index) =>
     [index + 1, item.Name, item.Email,item.MobileNo,item.Gender,item.DateOfBirth]);

   let content2 = {

     startY:90,
     head: headers2,
     body: data2,
     styles: {
       fontSize: 14,
       overflow: 'linebreak',
      // font: 'Amiri',
     },
     headStyles: { fillColor: ['#12284C'] },
     styles: {fontSize: 11 , fontFamily:"Hebrew",fontStyle:"bold"},
     //alternateRowStyles: {fillColor : [209,225,249]}
   };
    const headers = [[{ content: 'Membership List', colSpan: 4, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }],["#", "Status", "Subscription", "Expiration"]];

    const data = MembershipDetail.map((item, index) =>
      [
        index + 1, item.Status, item.Subscription,item.Expiration]);

    let content = {
     
      head: headers,
      body: data,
      headStyles :{fillColor : ['#12284C']},
      //styles: {fontSize: 12 , font:"Helvetica",fontStyle:"bold"}
      styles: {fontSize: 11 , fontFamily:"Hebrew",fontStyle:"bold"},
     
    };

    doc.setFont("helvetica");
  //  doc.setFontType("bold");
    //doc.text(20, 50, 'This is helvetica bold.');
    doc.autoTable(content2);
    doc.autoTable(content);
  
    doc.save("MembershipList.pdf");

  }

  return (
    <React.Fragment>
      {ShowLoader == 1 ?
        <div className="showhidedivloader" >
          <div className="mainloadernew">
            <div className="spinner-border text-primary mainloderedit" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : ''}
      <Commheader />
      <div className="modal fade" id="MPMembership" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modalheight">
          <div className="modal-content newmodaledithight">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"> Membership Plan</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row miandivpricingbox mt-0">
                {UserMembershipList.map((item, idx) => (
                  <div className="col-md-3" key={idx}>
                    <div className="mainpricetablemodal">
                      <div className="boxpricing boxpricing1">
                        <p className="plantitle text-center">
                          {item.Name}
                        </p>
                        <p className="pricingtext">
                          ₹{item.Price}<span>/{item.ValidUpTo.replace(/[0-9]/g, '')}</span>
                        </p>
                        <div className="dividerpricing"></div>
                        <div className="contentpricing">
                          <div className="contentscroll" dangerouslySetInnerHTML={{ __html: item.Description }}></div>
                          <a className="btn pricingblubtnnewmodal w-100 d-block" id="btnBuy" onClick={e => BuyMemberShip(e, item.Id, item.Price, item.Name)}>Buy</a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="paymentsuccessfully" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog mainpaymentmodaldiv modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="mainpaymentmodalbox">
                <div className="text-center">
                  <img src={paymentsuccessfully} className="paymentloadingimg"></img>
                  <p className="mainaymentbluetext">Payment Successful</p>
                  <p className="mainpaymentmodaldetails">Thank you for your billing</p>
                </div>
                <div className="text-center">
                  <div className="mainpaymentamountboxnew">
                    <p className="paymentsuccessfullyamount">₹ <label id="lblPayAmount">0</label> </p>
                    <p className="paymentsuuceefullytext">Has Been Paid</p>
                    <p className="maininvoiceidname">Invoice : <span><label id="lblOrderId"></label></span></p>
                  </div>
                </div>
                <hr className="mainhrmodapayment"></hr>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <p className="mainpaymentdetailstitle">Mode of Payment</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p className="mainpaymentsuccessdetails"><label id="lblModeOfPayment"></label></p>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <p className="mainpaymentdetailstitle">Plan</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p className="mainpaymentsuccessdetails"><label id="lblPlanName"></label></p>
                  </div>
                  <div className="col-md-12 mainbtnfooterpayment">
                    <a className="btn btn-primary btnnexteditlineblue w-100 d-block m-0" data-bs-dismiss="modal" aria-label="Close">Finish</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="mainboxshadow manageserviceaddcustom">
            <div className="row"> 
              <div className="col-md-6 col-12">
                <p className="addservicetextform">Profile Details</p>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-md-end justify-content-between">
                <a href="/edit-profile" > <p className="addnewpropertytext bpdf me-3">  <i className="las la-edit"></i>Edit</p></a>
                <button type="button" className="btn btn-warning btnpaymentdownload newpropertypdf" onClick={exportPDF}><i className="las la-download"></i></button>
              </div>
            </div>
            <div className="row mt-3 mt-md-0">
              <div className="col-md-12">
                <div className="mainboxmpnew">
                  <div className="row gx-4">
                    <div className="col-md-10 order-md-1 order-2">
                      <div className="row gx-4">
                        <div className="col-md-6">
                          <div className="registerinput mt-0">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                            <input type="email" className="form-control border-0" id="exampleFormControlInput1" readOnly defaultValue={Name} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="registerinput mt-md-0">
                            <div>
                              <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                            </div>
                            <div className="input-group mb1vw">
                              <input type="text" className="form-control border-0" aria-label="Recipient's username" aria-describedby="button-addon2" readOnly defaultValue={Email} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="registerinput">
                            <div>
                              <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
                            </div>
                            <div className="input-group mb1vw">
                              <input type="text" className="form-control border-0" aria-label="Recipient's username" aria-describedby="button-addon2" readOnly defaultValue={MobileNo} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="registerinput">
                            <label htmlFor="exampleFormControlInput1" className="form-label">DOB</label>
                            <input type="text" className="form-control border-0" id="exampleFormControlInput1" readOnly defaultValue={DOB} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="registerinput">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Gender</label>
                            <input type="text" className="form-control border-0" id="exampleFormControlInput1" readOnly defaultValue={Gender} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 order-md-2 order-1 mb-md-0 mb-3">
                      <div className="registerinput mt-md-0">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Profile</label>
                        <div>
                          <img src={ProfileFileUrl} className="imgprofileedit"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mainboxmpnew">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="membershiptitaledirrq">Your Membership</p>
                    </div>
                    {MembershipDetail.length > 0 ?
                      <div className="col-md-12">
                        <div className="maintablediveditep">
                         <div className="table-responsive newmebershiptableresponshivenew">
                         <table className="table ">
                            <thead>
                              <tr>
                                <th scope="col">Status</th>
                                <th scope="col">Subscription</th>
                                <th scope="col">Expiration</th>
                                <th scope="col">Actions</th>
                                <th scope="col">Invoice</th>
                              </tr>
                            </thead>
                            <tbody>
                              {MembershipDetail.map((item, idx) => (
                                <tr key={idx}>
                                  <td>
                                    <p className="mainactivetextep">{item.Status}</p>
                                  </td>
                                  <td>
                                    <p className="subscriptiontextep">{item.Subscription}</p>
                                  </td>
                                  <td>
                                    <p className="mainactivetextep">{item.Expiration}</p>
                                  </td>
                                  <td>
                                    <p className="actionstexteditep"><a data-bs-toggle="modal" data-bs-target="#MPMembership">Upgrade Now</a></p>
                                  </td>
                                  <td>
                                 <a onClick={exportMembershipPDF}> <p className="invoicetextep"><i className="las la-download"></i>Download</p></a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                         </div>
                        </div>
                      </div>
                      :
                      <div className="col-md-12">
                        <div className="text-center">
                          <img src={nodata} className="nodataimg" />
                        </div>
                      </div>}
                  </div>
                  {MembershipDetail.length > 0 ?
                    <div className="row">
                      <div className="col-md-12">
                        <p className="membershiptitaledirrqnweewe">Membership Details</p>
                      </div>
                      <div className="col-md-12">
                        <div className="mainboxmabershipdetailss">
                          {MembershipDetail.map((item, idx) => (
                            <div className="mebershipdetailsep mebershipdetailsepnewdiv" key={idx}>
                              {idx == 0 ?
                           
                                <div  dangerouslySetInnerHTML={{ __html: item.Description }}></div>
                                : ''}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-none" >
          {MembershipDetail.map((item, idx) => (
            <div style={{ padding: 20, width: "600px", height: "750px",fontFamily:"Hebrew",fontStyle:"bold"}} id="membership_pdfid" key={idx}>
              <Row>
                <Col xs={12} md={4}>
                  <img className="loginboximg" src={loginmainimg} style={{ height: "60px", width: "200px" }}></img>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3><strong style={{ marginLeft: 175 }}>Membership Receipt</strong></h3>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <strong>CoLand Services Private Limited</strong>
                </Col>
              </Row>
              <Row style={{ marginTop: 11 }}>
                <Col xs={12} md={8}>
                  <div>1st Floor, Bhagyalakshmi Indoor Stadium,</div>
                  <div style={{ marginTop: -4 }}>Kolazhy, Thrissur. 680010,</div>
                  <div style={{ marginTop: -4 }}>Mail:care@colandservices.com,</div>
                  <div style={{ marginTop: -4 }}>www.colandservices.com</div>
                  <div style={{ marginTop: -4 }}>Phone:+91-9947422444</div>
                </Col>
                <Col xs={6} md={4} >
                  <table>
                    <tr>
                      <th>Receipt</th>
                      <td>:</td>
                      <td>{" "}1</td>
                    </tr>
                    <tr>
                      <th>Date</th>
                      <td>:</td>
                      <td>{" "}{CurrentDate}</td>
                    </tr>
                  </table>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <div style={{ marginTop: -4 }}>Bill To:</div>
                <div> <strong>{Name}</strong></div>             
                <div style={{ marginTop: -4 }}>{"Email:"}{" "}{Email}</div>
                {/* <div style={{ marginTop: -4 }}>{item.PropertyName}{","}{"Near"}{item.StreetAddress}{","}</div>
                <div style={{ marginTop: -4 }}>{item.StateName} {","} {item.CityName}{"-"}{item.PinCode}{","} </div> */}
                <div style={{ marginTop: -4 }}>{"Phone:"}{" "}{MobileNo}</div>
              </Row>

              <Row style={{ marginTop: 15 }}>
              <div id="resp-table" style={{width: "100%",display:"table"}}>
<div id="resp-table-header" style={{display:"table-header-group"}}>
<div className="table-header-cell"  style={{display:"table-cell",padding: "10px",textAlign: "justify",borderBottom: "1px outset #e7dbdb"}}>
<strong>#</strong>
</div>
<div className="table-header-cell"  style={{display:"table-cell",padding: "10px",textAlign: "center",borderBottom: "1px outset #e7dbdb"}}>
<strong>Subscription</strong>
</div>
<div className="table-header-cell" style={{display:"table-cell",padding: "10px",textAlign: "justify",borderBottom: "1px outset #e7dbdb"}}>
<strong>Status</strong>
</div>
<div className="table-header-cell"  style={{display:"table-cell",padding: "10px",textAlign: "justify",borderBottom: "1px outset #e7dbdb"}}>
<strong>Expiration</strong>
</div>
<div className="table-header-cell"  style={{display:"table-cell",padding: "10px",textAlign: "right",borderBottom: "1px outset #e7dbdb"}}>
<strong>Rate</strong>
</div>
<div className="table-header-cell"  style={{display:"table-cell",padding: "10px",textAlign: "right",borderBottom: "1px outset #e7dbdb"}}>
<strong>Amount</strong>
</div>


</div>
<div id="resp-table-body" style={{display:"table-row-group"}}>
<div className="resp-table-row" style={{display:"table-row"}}><div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "justify",borderBottom: "1px outset #e7dbdb"}}>
1</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "center",borderBottom: "1px outset #e7dbdb"}}>
{item.Subscription}
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "left",borderBottom: "1px outset #e7dbdb"}}>
{item.Status}
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "left",borderBottom: "1px outset #e7dbdb"}}>
{item.Expiration}
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "right",borderBottom: "1px outset #e7dbdb"}}>
  {item.Price}
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "right",borderBottom: "1px outset #e7dbdb"}}>
{item.Price}
</div>
</div>
<div className="resp-table-row" style={{display:"table-row"}}>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "justify",borderBottom: "1px  #e7dbdb"}}>
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "justify",borderBottom: "1px  #e7dbdb"}}>
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "justify",borderBottom: "1px  #e7dbdb"}}>
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "justify",borderBottom: "1px  #e7dbdb"}}>
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "right",borderBottom: "1px  #e7dbdb"}}>
Total
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "right",borderBottom: "1px  #e7dbdb"}}>
{item.Price}
</div>
</div>
</div>
</div>
              </Row>      
              {/* <Row style={{fontSize:"11px"}}>
              <div> */}
                {/* {MembershipDetail.map((item,idx)=>(
                
                 <div key={idx}  style={{whiteSpace: "pre-line"}}> */}
               {/* {item.Description.replaceAll(/<[^>]+>/g, '').replaceAll(/[\n\r]/g,' ').replaceAll(/&amp;/g, '&')}  */}
                  {/* <div key={idx}  dangerouslySetInnerHTML={{ __html: item.Description}}></div> */}
                  {/* </div>))} */}
                   
                {/* </div>
              </Row> */}
              <Row style={{ marginTop: 10 }}>
                <Col xs={12} md={12} >
                  <table>
                    <tr>
                      <td >
                        <p style={{marginBottom:"-4px",whiteSpace:"nowrap"}}>
                        * Make all cheques payable to CoLand Services Private Limited                        
                        </p>
                        <p>
                        * Total due in 2 days. Overdue accounts subject to a service charge of 10% per month.
                        </p>                       
                        </td>                  
                    </tr>                             
                  </table>
                </Col>            
              </Row>
               <Row style={{ marginTop: 20 }}>
                <Col xs={12} md={8} >
                  <table>
                    <tr>
                      <td colSpan={2}><strong>Payment Channel Details:</strong>{" "}</td>
                    </tr>          
                  </table>
                </Col>
                <Col xs={6} md={4} >
                  <table>
                    <tr>
                      <td colSpan={2}><strong>Thanking you</strong></td>
                    </tr>
                    <tr>   <td colSpan={2}><strong>Authorized Signature</strong></td></tr>
                 
                  </table>
                </Col>
              </Row>
          
            </div>
          ))}

          </div>
      <Footeradmin />
    </React.Fragment>
  )
}

export default manageprofile

import React, { Component } from "react"
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Input,
    img,
} from "reactstrap"


const footeradmin = () => {
 
    
    return (
        <React.Fragment>
          
          <div className="footeradminmain">
          <div className="text-center">
            <p className="footercopytextedit m-0">  COPYRIGHT © ALL RIGHTS RESERVED BY CoLand Services Private Limited
</p>
          </div>
        </div>


        </React.Fragment>
    )

}

export default footeradmin

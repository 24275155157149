import React, { useEffect, useState } from "react"
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import Commheader from "../frontend/header";
import axios from "axios";
import nodata from "../../assets/images/frontend-img/nodata.png";
import loader from "../../assets/images/frontend-img/loader.gif";
import Footeradmin from "../frontend/footeradmin";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import mianheaderimg from "../../assets/images/frontend-img/logo-new.png";
import defaulthomeimg from "../../assets/images/frontend-img/development.png";

import star1 from "../../assets/images/frontend-img/star1.png";
import star2 from "../../assets/images/frontend-img/star2.png";
import star3 from "../../assets/images/frontend-img/star3.png";
import star4 from "../../assets/images/frontend-img/star4.png";
import star5 from "../../assets/images/frontend-img/star5.png";

const manageservice = () => {
  const [ServiceList, setServiceList] = useState([]);
  const [ShowLoader, setShowLoader] = useState(0);
  const [PaginationList, setPaginationList] = useState([]);


  useEffect(() => {
    setShowLoader(1);
    getProfileData();
    getServiceList(1);
  }, []);

  const getProfileData = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);
    axios.post(global.APIURL + '/Dashbord', formData)
      .then(res => {
        localStorage.setItem("ServiceCount", res.data.Result[0].ServiceCount);
      });
  }

  const getServiceList = async (PageNumber) => {
    setShowLoader(1);
    localStorage.removeItem("ServiceCurrentPageNumber");
    localStorage.setItem("ServiceCurrentPageNumber", PageNumber);
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropListData = {
      RegisterId: VRegisterId,
      Pagination: PageNumber
    }
  
    axios.post(global.APIURL + '/SevicelistData', PropListData)
      .then(LPres => {       
        setShowLoader(0);
        let LCD = LPres.data.Result.map((lcdeatail) => {
          return lcdeatail
        })
        setServiceList(LCD);   
      });   
    let TSC = parseInt(localStorage.getItem("ServiceCount") == null ? '0' : localStorage.getItem("ServiceCount") == '' ? '0' : localStorage.getItem("ServiceCount"));
    let TSCAL = TSC > 30 ? TSC / 30 : 1;
    console.log(TSCAL);
    var PIPageArr = [];
    for (let tsl = 0; tsl < TSCAL; tsl++) {
      let PN = tsl + 1;
      const PLitem = {
        PageNumber: PN,
        className: PN == PageNumber ? 'page-item active' : 'page-item'
      };
      PIPageArr.push(PLitem);

      if (tsl == 0) {
        localStorage.removeItem("ServiceFirstPageNumber");
        localStorage.setItem("ServiceFirstPageNumber", PN);
      }
      localStorage.removeItem("ServiceLastPageNumber");
      localStorage.setItem("ServiceLastPageNumber", PN);

    }
    setPaginationList(PIPageArr);
  }
  const PageNumberClick = async (e, PageNumber) => {
    getServiceList(PageNumber);
  }

  const NextPageNumberClick = async () => {
    let CPageNo = parseInt(localStorage.getItem('ServiceCurrentPageNumber') == null ? '0' : localStorage.getItem('ServiceCurrentPageNumber') == '' ? '0' : localStorage.getItem('ServiceCurrentPageNumber'));
    let LPN = parseInt(localStorage.getItem('ServiceLastPageNumber') == null ? '0' : localStorage.getItem('ServiceLastPageNumber') == '' ? '0' : localStorage.getItem('ServiceLastPageNumber'));
    console.log(CPageNo + ":" + LPN);
    if (LPN > CPageNo) {
      let NPageNo = CPageNo + 1;
      getServiceList(NPageNo);
    }
  }

  const PreviousPageNumberClick = async () => {
    let CPageNo = parseInt(localStorage.getItem('ServiceCurrentPageNumber') == null ? '0' : localStorage.getItem('ServiceCurrentPageNumber') == '' ? '0' : localStorage.getItem('ServiceCurrentPageNumber'));
    let FPN = parseInt(localStorage.getItem('ServiceFirstPageNumber') == null ? '0' : localStorage.getItem('ServiceFirstPageNumber') == '' ? '0' : localStorage.getItem('ServiceFirstPageNumber'));
    console.log(CPageNo + ":" + FPN);
    if (CPageNo > FPN) {
      let NPageNo = CPageNo - 1;
      getServiceList(NPageNo);
    }
  }

  const EditDetail = (e, ServiceId, PropertyId) => {
    localStorage.setItem("EditServiceId", JSON.stringify(ServiceId));
    localStorage.setItem("EditPropertyId", JSON.stringify(PropertyId)); 
    window.location.href = "/edit-request-new-service";
  };

  const ServiceDetail = (e, ServiceId, PropertyId) => {
    localStorage.setItem("DetailServiceId", JSON.stringify(ServiceId));
    localStorage.setItem("DetailPropertyId", JSON.stringify(PropertyId));
    window.location.href = "/service-details";
  };

  const exportPDF = () => {

    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.addImage(mianheaderimg, 'JPEG', 210, -8, 180, 100);
   doc.setFontSize(12); 
   doc.setFontSize(15);
   var today = new Date();
   var curr_date = today.getDate();
   var curr_month = today.getMonth();
   var curr_year = today.getFullYear();
   var m_names = new Array("January", "February", "March", 
                           "April", "May", "June", "July",
                           "August", "September", 
                           "October", "November", "December");

   today = m_names[curr_month] + " " + curr_date + ", " + curr_year;
   var newdat = today;
   doc.text(420, 80, newdat); 
    const headers = [[{ content: 'Service List', colSpan: 6, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }],["#", "Name","Type","Date","Status","Review (Stars)"]];
    const data = ServiceList.map((item, index) =>
      [
        index + 1, item.ServiceName,item.ServiceTypeName, item.ServiceDate,item.RequestServiceStaus,item.ReviewStar]);
      let content = {
      startY: 90,
      head: headers,
      body: data,
      headStyles :{fillColor : ['#12284C']},
      //styles: {fontSize: 12 , font:"Helvetica",fontStyle:"bold"}
      styles: {fontSize: 11 , fontFamily:"Hebrew",fontStyle:"bold"},
     
    };

    doc.setFont("helvetica");
    doc.autoTable(content);
    doc.save("ServiceList.pdf");

  }

  return (
    <React.Fragment>
      {ShowLoader == 1 ?
        <div className="showhidedivloader">
          <div className="mainloadernew">
            <img src={loader} />
          </div>
        </div>
        : ''}
      <Commheader />    
      <section>
        <div className="container">
          <div className="mainboxshadow manageserviceaddcustom">
            <div className="row mainitalpropertylist">
              <div className="col-md-6 col-12">
                <p className="addservicetextform">Manage Services</p>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-md-end justify-content-between ">
                <a href="/request-new-service"> <p className="addnewpropertytext me-3">  <i className="las la-plus-circle"></i>Add New  Service</p></a>
                <button type="button" className="btn btn-warning btnpaymentdownload newpropertypdf" onClick={exportPDF}><i className="las la-download"></i></button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="maintabledivedit table-responsive">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th className="text-center" scope="col">#</th>
                        <th className="mainwidthtable" scope="col">Service</th>
                        <th className="mainwidthtable" scope="col">Property</th>
                        <th className="mainwidthtable2" scope="col">Request</th>
                        <th className="mainwidthtable2" scope="col">Status</th>
                        <th className="mainwidthtable2" scope="col">Review</th>
                        <th className="mainwidthtable2" scope="col">Query</th>
                        <th className="mainwidthtable2" scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ServiceList.length > 0 ?
                        ServiceList.map((item, idx) => (
                          <tr key={idx}>
                            <td>
                              <p className="servicelistdatems text-center"> {idx + 1}</p>
                            </td>                           
                            <td>
                              <div>
                                <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>
                                  <p className="mainservicename">{item.ServiceName}</p>
                                  <p className="mainservicetypetext">{item.ServiceTypeName}</p>
                                  <p className="mainserviceidtext">ID #SR{item.Id.toString().length == 1 ? '000000' + item.Id.toString() : item.Id.toString().length == 2 ? '00000' + item.Id.toString() : item.Id.toString().length == 3 ? '0000' + item.Id.toString() : item.Id.toString().length == 4 ? '000' + item.Id.toString() : item.Id.toString().length == 5 ? '00' + item.Id.toString() : item.Id.toString().length == 6 ? '0' + item.Id.toString() : item.Id.toString()}</p>
                                </a>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                            
                                 {item.PropertyfirstImage.length==0?<img src={defaulthomeimg} className="mainpropertysliderdefaultimg2"></img>
                                 
                                 :<div>
                                 {             
                                 item.PropertyfirstImage.filter(x=>x.Status=="true").map((item2,idx)=>( idx == 0 ?
                                 
                                 <img key={idx} src={item2.Url} className="mainpropertysliderimg2" />
                                 
                                 :""
                                 ))}
                                 
                                
                                 </div>}
                                <div className="w-100">
                                  <div className="d-flex justify-content-between w-100">
                                  <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>
                                    <p className="mainpropertytitlelistms">{item.PropertyName}</p>
                                    </a>

                                  </div>
                                  <div>
                                  <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>
                               {item.PropertyType!=""? <p className="propertycatageoriedms">{item.PropertyType}</p>:""}
                               {item.PropertyType==""? <p className="propertycatageoriedms">{item.OtherPropertyType}</p>:""}
                                 </a>
                                  </div>
                                  <div className="d-flex">
                                    <div>
                                      <img src={locationpropertyedit} className="locationpropertyeditms" />
                                    </div>
                                    <div>
                                    <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>
                                      <p className="propertytextlocationms mb-0">{item.Address}</p>
                                      </a>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                            <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}> 
                             <p className="servicelistdatems"> {item.ServiceDate}</p>
                              <p className="servicelisttimems"> {item.ServiceTime}</p>
                              </a>
                            </td>
                            <td>
                            <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>
                              {
                                item.RequestServiceStaus == 'Initiated' ?
                                  <span className="btn maingraybtnedit">Initiated</span>
                                  :
                                  item.RequestServiceStaus == 'Accepted' ?
                                    <span className="btn maingraybtnedit">Accepted</span>
                                    :
                                    item.RequestServiceStaus == 'ReadyForInspection' ?
                                      <span className="btn maingraybtnedit">Ready For Inspection</span>
                                      :
                                      item.RequestServiceStaus == 'QueryGenerated' ?
                                        <span className="btn maingraybtnedit">Query Generated</span>
                                        :
                                        item.RequestServiceStaus == 'InspectionCompleted' ?
                                          <span className="btn maingraybtnedit">Inspection Done</span>
                                          :
                                          item.RequestServiceStaus == 'AdvancePaid' ?
                                            <span className="btn yellowbtn">Advance Paid</span>
                                            :
                                            item.RequestServiceStaus == 'TeamAsssigned' ?
                                              <span className="btn purpulebtn">Team Asssigned</span>
                                              :
                                              item.RequestServiceStaus == 'InProgress' ?
                                                <span className="btn maingraybtnedit">In Progress</span>
                                                :
                                                item.RequestServiceStaus == 'JobCompleted' ?
                                                  <span className="btn maingraybtnedit">Job Completed</span>
                                                  :
                                                  item.RequestServiceStaus == 'ServiceCompleted' ?
                                                    <span className="btn greenbtn">Balance Payment</span> 
                                                    :
                                                    ''
                              } </a>
                            </td>
                            <td>

                            <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>    {item.ReviewStar == '' ? <button className="btn maingraybtnedit">Not Available</button> : item.ReviewStar == '1' ? <img src={star1} className="starimg" alt="" /> : item.ReviewStar == '2' ? <img src={star2} className="starimg" alt="" /> : item.ReviewStar == '3' ? <img src={star3} className="starimg" alt="" /> : item.ReviewStar == '4' ? <img src={star4} className="starimg" alt="" /> : item.ReviewStar == '5' ? <img src={star5} className="starimg" alt="" /> : <button className="btn maingraybtnedit">Not Available</button>}
                            </a>
                            </td>
                            <td>
                          
                            {item.QueryStaus=="Not yet"? 
                            
                            <button className="btn redbtn redbtnnew ">Not Yet</button>
                            :                  
                            <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>     <button className="btn  newbluebtnbg">{item.QueryStaus}</button></a>
                               
                            }
                            </td>
                            <td>
                              <a className="btn greenbtn" id="btnEdit" onClick={e => EditDetail(e, item.Id, item.PropertyId)}>Edit</a>
                            </td>
                          </tr>
                        )):<tr>
                          <td colSpan={7}>
                            <div className="row">
                              <div className="text-center">
                                <img src={nodata} className="nodataimg" />
                              </div>
                            </div>
                          </td>
                        </tr> }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {PaginationList.length > 0 ?
              <div className="row">
                <div className="col-md-12 d-flex justify-content-md-end justify-content-center mt-5">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li className="page-item">
                        <a className="page-link" onClick={(e) => PreviousPageNumberClick()} >Previous</a>
                      </li>
                      {PaginationList.map((PaginationItems, idx) => (
                        <li className={PaginationItems.className} key={idx}><a className="page-link" onClick={(e) => PageNumberClick(e, PaginationItems.PageNumber)}>{PaginationItems.PageNumber}</a></li>
                      ))}
                      <li className="page-item">
                        <a className="page-link" onClick={(e) => NextPageNumberClick()}>Next</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div> : ''}
          </div>
        </div>
      </section>
      <Footeradmin />
    </React.Fragment>)
}

export default manageservice

import React, { Component, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import anp1 from "../../assets/images/frontend-img/anp1.png";
import anp2 from "../../assets/images/frontend-img/anp2.png";
import anp3 from "../../assets/images/frontend-img/anp3.png";
import anp4 from "../../assets/images/frontend-img/anp4.png";
import anp5 from "../../assets/images/frontend-img/anp5.png";
import addanp from "../../assets/images/frontend-img/addanp.png";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";
import Commheader from "../frontend/header";
import Footeradmin from "../frontend/footeradmin";
import defaulthomeimg from "../../assets/images/frontend-img/development.png";


const editrequestnewservice = () => {
  const [ShowLoader, setShowLoader] = useState(0);
  let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
  const [PropertyList, setPropertyList] = useState([]);
  const [PropertyDetails, setPropertyDetails] = useState([]);
  const [DocumentList, setDocumentList] = useState([]);
  const [PropertyInsights, setPropertyInsights] = useState([]);
  const [Media, setMedia] = useState("");
  const [EditPropertyId, setEditPropertyId] = useState('0');
  const [PropertyFurnished, setPropertyFurnished] = useState([]);
  const [EditMedia,setEditMedia]=useState([]);
  const [MediaLengthCheck,setMediaLengthCheck]=useState(0);


  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));

    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {

      setShowLoader(1);

      let IsInsert = 1;
      let VEditServiceId = JSON.parse(localStorage.getItem('EditServiceId'));
      let VEditPropertyId = JSON.parse(localStorage.getItem('EditPropertyId'));
      let ES = VEditServiceId == null ? '0' : VEditServiceId == '' ? '0' : VEditServiceId;
      let EP = VEditPropertyId == null ? '0' : VEditPropertyId == '' ? '0' : VEditPropertyId;
      setEditPropertyId(EP);
      if (ES == "0") {
        IsInsert = 0;
      }
      if (EP == "0") {
        IsInsert = 0;
      }
      if (IsInsert > 0) {
        getPriorityList();
        let PId = JSON.parse(localStorage.getItem('EditPropertyId'));
        let VEditPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
        if (VEditPropertyId > 0) {
          const PropDetailsData = {
            "RegisterId": VRegisterId,
            "PropertyId": VEditPropertyId
          }
          axios.post(global.APIURL + '/propertyDetail', PropDetailsData)
            .then(res => {
              console.log(res);
              setPropertyDetails(res.data.Result);
              setPropertyInsights(res.data.Result[0].PropertyInsight);
              setMediaLengthCheck(res.data.Result[0].Media);
              if(res.data.Result[0].Media.length>0){
                setMedia(res.data.Result[0].Media[0]);
              }
            
              setDocumentList(res.data.Result[0].Document);
              setPropertyFurnished(res.data.Result[0].PropertyFurnished);
            });
        }
      }
      else {
        window.location.href = "/manage-service";
      }
      setShowLoader(0);
    }
  }, []);
  console.log(Media);

  const getPriorityList = async () => {
    setShowLoader(1);
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropListData = {
      RegisterId: VRegisterId,
      Pagination: 1,
      Type: 'Save'
    }
    axios.post(global.APIURL + '/PropertyList', PropListData)
      .then(res => {    
        setPropertyList(res.data.Result);    
      });
    setShowLoader(0);
  }

  const getPropertyDetails = (e, propId) => {
    setShowLoader(1);
    localStorage.setItem("EditPropertyId", JSON.stringify(propId));
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropDetailsData = {
      "RegisterId": VRegisterId,
      "PropertyId": propId
    }

    axios.post(global.APIURL + '/propertyDetail', PropDetailsData)
      .then(res => {
        console.log(res);
        setPropertyDetails(res.data.Result);
        setPropertyInsights(res.data.Result[0].PropertyInsight==null?"":res.data.Result[0].PropertyInsight);
        setMediaLengthCheck(res.data.Result[0].Media);
        if(res.data.Result[0].Media.length>0){
          setMedia(res.data.Result[0].Media[0]);
        }
        setDocumentList(res.data.Result[0].Document);
        setPropertyFurnished(res.data.Result[0].PropertyFurnished)

      });
    setShowLoader(0);
  }

  const SaveNext = () => {
    setShowLoader(1);
    let PId = JSON.parse(localStorage.getItem('EditPropertyId'));
    let VEditPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
    if (VEditPropertyId > 0) {
      window.location.href = "/edit-request-new-service2";
    }
    else {
      addToast("Please Select Property", { appearance: 'error', autoDismiss: true });
    }
    setShowLoader(0);
  }

  return (<React.Fragment>
    {ShowLoader == 1 ?
      <div className="showhidedivloader" >
        <div className="mainloadernew">
          <div className="spinner-border text-primary mainloderedit" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      : ''}
    <Commheader />
    
    <section>
      <div className="container">
        <div className="mainboxshadow">
          <div className="row">
            <div className="col-md-6">
              <p className="addservicetextform">Edit Service Request</p>
              <p className="mainsteptextform">Step 1 : Select Property</p>

            </div>
            <div className="col-md-6 text-md-end text-start mb-3 mb-md-0">
              <a href="/add-new-property"> <p className="addnewpropertytext">  <i className="las la-plus-circle"></i>Add New Property</p></a>
            </div>
            <div className="col-md-12">
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="mainleftsideserverequest newservicerequestscroll">
                <p className="maintitlereuestservicenee">Select Property <span className="spanred">*</span></p>
                <div>
                  {PropertyList.map((propItems, idx) => (
                    <div className="mainrsdivv" key={idx}>
                      <div className="form-check form-checkneww ps-0">
                        <input className="form-check-input" type="radio" name="exampleRadios" value="option1" defaultChecked={propItems.PropertyId === EditPropertyId} onChange={e => getPropertyDetails(e, propItems.PropertyId)} />
                        <div className="mainserviceselectradiobox" id="exampleRadios1">
                          <div className="d-flex">
                            <div>                                            
                            {propItems.Media.length==0?<img src={defaulthomeimg} className="serviceselectimgjjk"></img>: 
                            <>
                            {
                        propItems.Media.map((item,idx)=>
                        idx==0?
                        <img key={idx} className="serviceselectimgjjk" src={item.URL}>
                        </img>:"")                      
                        }</>                            
                            }                                    
                            </div>
                            <div className="w-100">
                              <div className="d-flex justify-content-between w-100">
                                <p className="mainpropertytitlelist">{propItems.PropertyName} </p>
                              </div>
                              <div>
                                <p className="propertycatageoried">{propItems.PropertyType}</p>
                              </div>
                              <div className="d-flex">
                                <div>
                                  <img src={locationpropertyedit} className="locationpropertyedit" />
                                </div>
                                <div>
                                  <p className="propertytextlocation mb-0">{propItems.FullAddress}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>))}
                </div>
              </div>
            </div>
            {PropertyDetails.map((propDetailItems) => (<div className="col-md-7" key={propDetailItems.PropertyId}>
              <div className="mainrightsideboxrequest">
                <p className="maintitlereuestservicenee">Property Details</p>
                <div className="d-md-flex align-items-center">
                  <div>
               {MediaLengthCheck.length==0?<img className="propertydetailsdefaultimgmainn"  src={defaulthomeimg} ></img>:
                <img src={Media.Url} className="propertydetailsimgmainn" />
               }
                  </div>
                  <div className="w-100">
                    <div className="d-flex justify-content-between w-100">
                      <p className="mainpropertytitlelist">{propDetailItems.PropertyTitle} </p>
                    </div>
                    <div>
                      <p className="propertycatageoried">{propDetailItems.PropertyTypeName} </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <img src={locationpropertyedit} className="locationpropertyedit" />
                      </div>
                      <div>
                        <p className="propertytextlocation mb-0">{propDetailItems.FullAddress}</p>
                      </div>
                    </div>
                    <div className="maindividerpd"> </div>
                    <div>
                      <p className="landareatext">Land Area :</p>
                      <div className="d-flex">
                        <div className="margineditlandarea">
                          <p className="mainlandareatextdescription"> <img src={landarea1} /> {propDetailItems.LandArea} {propDetailItems.LandAreaIn}</p>
                        </div>
                        <div>
                          <p className="mainlandareatextdescription"> <img src={landarea2} /> {propDetailItems.SuperBuiltUP} {propDetailItems.SuperBuiltUpIn}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="maintitlereuestserviceneebew2">Documents :</p>
                <div className="row">
                  {DocumentList.filter(x => x.DocumentStatus == 'true').map((docItems) => (<div className="col-md-6" key={docItems.DocumentId}>
                    <a href={docItems.DocumentMedia} target="_blank" rel="noopener noreferrer">
                      <div className="mainpdfdownloadsevice">
                        <div className="d-flex justify-content-between">
                          <div>
                            <p className="downloadpdftext"><i className="las la-file"></i>{docItems.DocumentName}</p>
                          </div>
                          <div>
                            <i className="las la-download downloadiconsrl"></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>))}
                </div>
                <p className="maintitlereuestserviceneebew2">Property Insights :</p>
                <div className="row">
                  {PropertyInsights?.filter(x => x.InsightStatus == 'true').map((insightItems) => (
                    <div className="col-md-4 mb-3" key={insightItems.Id}>
                      <div className="propertyinsideboxes">
                        <div>
                          <div id="carouselExampleIndicators4" className="carousel slide propertyimgsliderneww" data-bs-ride="carousel">                  
                            <div className="carousel-inner">
                              <div className="maintaginsightbox">{insightItems.InsightName}</div>
                              <div className="carousel-item active">
                                <img src={insightItems.InsightMedia} />
                              </div>
                            </div>
                          </div>
                          <div className="mainpiboxgap">
                            <p className="maintextdescriptiongdhs"><img className="mainroomsizeicon" src={roomsizeicon} />
                              <span className="textroomsize">{insightItems.InsightLength}</span>
                              <span className="textroomsizediver">&times;</span>
                              <span className="textroomsize">{insightItems.InsightWidth}</span>
                              <span className="textroomsizediver">&times;</span>
                              <span className="textroomsize">{insightItems.InsightHeight}</span>
                              <span className="textroomsize sqfttextedit">Sq. ft</span>
                            </p>
                            {PropertyFurnished?.filter(x => x.PropertyFurnishedIn == insightItems.InsightName && x.FurnishedStatus == 'true').length > 0 ?
                              <p className="maintextdescriptiongdhs mb-0"><img className="mainroomsizeiconcorrect" src={correct} /><span className="textroomsize">Furnish Property</span></p>
                              : ''
                            }
                            {PropertyFurnished?.filter(x => x.PropertyFurnishedIn == insightItems.InsightName && x.FurnishedStatus == 'true').length > 0 ?
                              <div className="maindvidertextdescription">
                              </div> : ''
                            }
                            <p className="mainfooterboxspinsight">
                              {PropertyFurnished?.filter(x => x.PropertyFurnishedIn == insightItems.InsightName && x.FurnishedStatus == 'true').map((PFItems, idx1) => (
                                idx1 == 0 ?
                                  <span key={idx1}>{PFItems.FurnishedDetails}</span>
                                  :
                                  <span key={idx1}>, {PFItems.FurnishedDetails}</span>
                              ))}</p>
                            {PropertyFurnished?.filter(x => x.PropertyFurnishedIn == insightItems.InsightName && x.FurnishedStatus == 'true').length == 0 ?
                              <div>
                                <p className="maintextdescriptiongdhs mb-0"><img className="mainroomsizeiconcorrect" src={cross} /><span className="textroomsize">Furnish Property</span></p>
                                <div className="maindvidertextdescription">
                                </div>
                                <p className="mainfooterboxspinsight">No Furniture Available</p>
                              </div> : ''
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>))}
            <div className="col-md-12"><a id="lnkNext" onClick={event => SaveNext()}><button className="btn btn-primary btnnextedit ">Next <i className="fas fa-long-arrow-alt-right"></i></button></a></div>
          </div>
        </div>
      </div>
    </section>
    <Footeradmin />
  </React.Fragment>);
}


export default editrequestnewservice

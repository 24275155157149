import React, { Component, useEffect, useState } from "react"
import axios from "axios";
import Commheader from "../frontend/header";
import referfriendimg from "../../assets/images/frontend-img/referfriendimg.png";
import { useToasts } from 'react-toast-notifications';
import nodata from "../../assets/images/frontend-img/nodata.png";
import Footeradmin from "../frontend/footeradmin";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import mianheaderimg from "../../assets/images/frontend-img/logo-new.png";


const referralslist = () => {
  const [ReferList, setReferList] = useState([]);
  const { addToast } = useToasts();
  const [Name, setName] = useState('');
  const [EmailAddress, setEmailAddress] = useState('');
  const [MobileNumber, setMobileNumber] = useState('');
  const [Message, setMessage] = useState('');
  const [ShowLoader, setShowLoader] = useState(0);

  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  useEffect(() => {
    setShowLoader(1);
    getMyReferralsList();
  }, []);

  const getMyReferralsList = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);
    axios.post(global.APIURL + '/ReferedHistory', formData)
      .then(LPres => {
        let LCD = LPres.data.Result.map((lcdeatail) => {
          return lcdeatail
        })
        setReferList(LCD);
      });

    setShowLoader(0);
  }

  const DeleteDetail = (e, Id) => {
    setShowLoader(1);
    const formData = new FormData();
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    formData.append('RegisterId', VRegisterId);
    formData.append('ReferId', Id);

    axios.post(global.APIURL + '/DeleteReferedFriend', formData).then(res => {
      if (res.data.Status == true) {
        addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
      }
      else {
        addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
      }
      getMyReferralsList();
    });
  };

  const SaveNewReferrals = async () => {
    let IsInsert = 1;
    if (Name == null) {
      addToast("Please Enter Name.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (Name == '') {
      addToast("Please Enter Name.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (EmailAddress == null) {
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (EmailAddress == '') {
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
       if (regex.test(EmailAddress) === false) {
        addToast("Please Enter Valid Email Address", { appearance: 'error', autoDismiss: true });
        return;

      }

    if (MobileNumber == null) {
      addToast("Please Enter Mobile Number", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (MobileNumber == '') {
      addToast("Please Enter Mobile Number", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (MobileNumber.length<=10) {
      addToast("Please Enter Valid Mobile Number along with Country Code", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (Message == null) {
      addToast("Please Enter Message", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (Message == '') {
      addToast("Please Enter Message", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (IsInsert > 0) {
      const formData = new FormData();
      let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
      formData.append('RegisterId', VRegisterId);
      formData.append('Name', Name);
      formData.append('EmailAddress', EmailAddress);
      formData.append('MobileNumber', MobileNumber);
      formData.append('Message', Message);

      axios.post(global.APIURL + '/ReferFriend', formData).then(res => {
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          $('#referfriend').modal('hide');
          document.getElementById("txtName").value="";
          document.getElementById("txtEmailAddress").value="";
          document.getElementById("txtMobileNumber").value="";
          document.getElementById("txtMessage").value="";
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
        }
        getMyReferralsList();
      });
    }
  }

  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  const exportPDF = () => {

    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.addImage(mianheaderimg, 'JPEG', 210, -8, 180, 100);
    doc.setFontSize(12);
   var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth();
    var curr_year = today.getFullYear();
    var m_names = new Array("January", "February", "March", 
                            "April", "May", "June", "July",
                            "August", "September", 
                            "October", "November", "December");

    today = m_names[curr_month] + " " + curr_date + ", " + curr_year;
    var newdat = today;
    doc.text(450, 75, newdat);
    const headers = [[{ content: 'Referral List', colSpan: 6, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }],["#", "Name", "MobileNumber", "Email Address", "Message", "Date"]];

    const data = ReferList.map((item, index) =>
      [
        index + 1, item.Name, item.MobileNumber,item.EmailAddress,item.Message,item.Date]);

    let content = {
      startY: 90,
      head: headers,
      body: data,
      headStyles :{fillColor : ['#12284C']},
      //styles: {fontSize: 12 , font:"Helvetica",fontStyle:"bold"}
      styles: {fontSize: 11 , fontFamily:"Hebrew",fontStyle:"bold"},
     
    };

    doc.setFont("helvetica");
    doc.autoTable(content);
    doc.save("ReferralList.pdf");

  }

  return (
    <React.Fragment>     
      {ShowLoader == 1 ?
        <div className="showhidedivloader">
          <div className="mainloadernew">
            <div className="spinner-border text-primary mainloderedit" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : ''}
      <Commheader />
      <div className="modal fade" id="referfriend" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mainrefermodal">
          <div className="modal-content">

            <div className="modal-body">
              <div className="maindivreferefriend">
                <div className="text-center">
                  <p className="lognmaintexteditnew">Refer a Friend</p>
                  <p className="maindescriptiontextlogin">Invite your friend, they hit the road and you make savings!</p>
                  <img className="mainreferfriengimgedit" src={referfriendimg}></img>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                      <input type="text" className="form-control" id="txtName" placeholder="Enter Name" onChange={e => setName(e.target.value)} />
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                      <input type="text" className="form-control" id="txtEmailAddress" placeholder="Enter Email Address" onChange={e => setEmailAddress(e.target.value)} />
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
                      <input type="number" className="form-control" id="txtMobileNumber" placeholder="Enter Mobile Number with Country Code" maxLength={15} onKeyPress={inputHandler} onChange={e => setMobileNumber(e.target.value)} />
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Message</label>
                      <textarea type="textarea" rows={1} className="form-control" id="txtMessage" placeholder="Enter Message" onChange={e => setMessage(e.target.value)}></textarea>
                    </div>

                  </div>
                  <div className="col-md-12 mainreferbtn text-center">
                    <button type="button" className="btn btn-primary btnloginnew" onClick={SaveNewReferrals}>Refer Now</button>
                  </div>

                </div>


              </div>

            </div>

          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="mainboxshadow historycustomtextadd">
            <div className="row mainitalpropertylist">
              <div className="col-md-6 col-12">
                <p className="addservicetextform">History of your Referrals</p>

              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-md-end justify-content-between">
                <a href="#" data-bs-toggle="modal" data-bs-target="#referfriend"> <p className="addnewpropertytext me-3"> <i className="las la-ticket-alt"></i>Refer a Friend Now</p></a>
                <button type="button" className="btn btn-warning btnpaymentdownload btnpdf mb-md-0" onClick={exportPDF}><i className="las la-download"></i></button>
              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="maintabledivedit table-responsive">
                  <table className="table rftable">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Number</th>
                        <th scope="col">Email</th>
                        <th scope="col">Message</th>
                        <th scope="col">Date & Time</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ReferList.length > 0 ?
                        ReferList.map((item, idx) => (
                          <tr key={idx}>
                            <td>  <p className="mainpropertytitlelistms">{idx + 1}</p></td>
                            <td>  <p className="mainpropertytitlelistms">{item.Name}</p></td>
                            <td>  <p className="mainpropertytitlelistms">{item.MobileNumber}</p></td>
                            <td>  <p className="mainpropertytitlelistms">{item.EmailAddress}</p></td>
                            <td>  <p className="mainpropertytitlelistms">{item.Message}</p></td>
                            <td>  <p className="mainpropertytitlelistms">{item.Date} <span>|</span> {item.Time}</p></td>
                            <td>
                              <div className="maindeletelistrefer">
                                <div>
                                  <a onClick={e => DeleteDetail(e, item.Id)}><i className="las la-trash-alt"></i></a>
                                </div>

                              </div>
                            </td>
                          </tr>
                        ))
                        :
                        <tr>
                          <td colSpan={7}>
                            <div className="row">
                              <div className="text-center">
                                <img src={nodata} className="nodataimg" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
      

        </div>
      </section>
      <Footeradmin />
    </React.Fragment>
  )

}

export default referralslist

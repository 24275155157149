import React, { Component, useEffect, useState } from "react"
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import Commheader from "./header";
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import Footeradmin from "./footeradmin";


const EditProperty3 = () => {
  const { addToast } = useToasts();

  const [PropertyDocumentList, setPropertyDocumentList] = useState([]);


  const [DocumentMedia1, setDocumentMedia1] = useState([]);
  const [DocumentMediaName, setDocumentMediaName] = useState('');
  const [DocMediaFlag,setDocMediaFlag]=useState([]);

  const [UpdatePropertyId, setUpdatePropertyId] = useState('0');

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {

      getPriorityDetail();


    }
  }, []);

  const getPriorityDetail = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let VPropertyId = localStorage.getItem('DetailPropertyId');
    const PropListData = {
      RegisterId: VRegisterId,
      PropertyId: VPropertyId
    }
    axios.post(global.APIURL + '/propertyDetail', PropListData)
      .then(res => {
        if (res.data.Result != '') {
          const ProDocumentsArr = res.data.Result[0].Document;
          const PDArr = [];
          for (let pm = 0; pm < ProDocumentsArr.length; pm++) {
            let RowIndex = pm + 1;
            let Vim = ProDocumentsArr[pm].DocumentMedia;
            let VDocumentStatus = ProDocumentsArr[pm].DocumentStatus == 'true' ? true : false;
            let VimArr = Vim.split("/");
            let IMFileName = VimArr[VimArr.length - 1];
            const PropertyDocumentArr = {
              DocumentRowId: RowIndex,
              DocumentId: ProDocumentsArr[pm].DocumentId,
              DocumentName: ProDocumentsArr[pm].DocumentName,
              DocumentMedia: [],
              DocumentNameId: 'txtDocumentName' + RowIndex,
              DocumentFileName: IMFileName,
              DocumentStatus: VDocumentStatus,
              DocumentDivId: "divPropertyDocument" + RowIndex,
              RecordType: 'O'
            }
            PDArr.push(PropertyDocumentArr);
          }
          setPropertyDocumentList(PDArr);
        }
      });
  }

  const UpdatePropertyDetail = () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let VPropertyId = localStorage.getItem('DetailPropertyId');

    var VDocumentName = document.getElementById('txtDocumentName').value;

    if (PropertyDocumentList.length == 0) {
      const formData = new FormData();
      let IsPropertyInsightsInsert = 1;
      if (VDocumentName == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (DocumentMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {
        var LRNo = PropertyDocumentList.length + 1;
        let CD = 'DocumentName' + LRNo;
        let CM = 'DocumentMedia' + LRNo;
        let DS = 'DocumentStatus' + LRNo;

        formData.append('RegisterId', VRegisterId);
        formData.append('Action', "Add");
        formData.append('PropertyId', VPropertyId);
        formData.append(DS, true);

        formData.append(CD, VDocumentName);
        formData.append(CM, DocumentMedia1);
        formData.append('TotalMediaCount', 1);

        for (var pair of formData.entries()) {
          console.log(pair[0] + ":" + pair[1]);
        }
        axios.post(global.APIURL + '/PropertyDocuments', formData)
          .then(res => {
            if (res.data.Status == true) {
              addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
              window.location.href = "/property-list";
            }
            else {
              addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
              
            }
          });
      }
      else {
        // addToast("Please Add Property Document Details", { appearance: 'error', autoDismiss: true });
        window.location.href = "/property-list";
      }
    }
    else {
      let IsPropertyInsightsInsert = 1;
      if (VDocumentName == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (DocumentMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {
        var LRNo = PropertyDocumentList.length + 1;
        const Pitem = {
          DocumentId: 0,
          DocumentName: VDocumentName,
          DocumentMedia: DocumentMedia1,
          DocumentStatus: true,
          DocumentNameId: 'txtDocumentName' + LRNo,
          RecordType: 'N'
        }
        PropertyDocumentList.push(Pitem);
      }

      for (let pi = 0; pi < PropertyDocumentList.length; pi++) {

        let RowIndex = pi + 1;
        let EditDocumentId = PropertyDocumentList[0].DocumentId;
        let VRecordType = PropertyDocumentList[pi].RecordType;
        let VDocumentStatus = PropertyDocumentList[pi].DocumentStatus;

        const formData1 = new FormData();
        formData1.append('PropertyId', VPropertyId);
        formData1.append('DocumentId', EditDocumentId);
        if (VRecordType == "N") {
          formData1.append('DocumentCount',RowIndex);
        }
        else{
          formData1.append('DocumentCount', PropertyDocumentList.length);
        }        

        let DS = 'DocumentStatus' + RowIndex;
        formData1.append(DS, VDocumentStatus);

        let INCN = 'DocumentName' + RowIndex;
        if (VDocumentStatus == true) {
          if (VRecordType == "O") {
            let INId = 'txtDocumentName' + RowIndex;
            let INValue = document.getElementById(INId).value;
            formData1.append(INCN, INValue);
          }
          else if (VRecordType == "N") {
            formData1.append(INCN, PropertyDocumentList[pi].DocumentName);
          }
        }
        else {
          formData1.append(INCN, PropertyDocumentList[pi].DocumentName);
        }

        let IMCN = 'DocumentMedia' + RowIndex;
        if (VDocumentStatus == true) {
          if (VRecordType == "N") {
            formData1.append(IMCN, PropertyDocumentList[pi].DocumentMedia);
          }
        }
        
        axios.post(global.APIURL + '/DocumentEdit', formData1)
          .then(res => {
            if ((PropertyDocumentList.length - 1) == pi) {
              addToast("Document Details Updated Successfully", { appearance: 'success', autoDismiss: true });
              window.location.href = "/property-list";
            }
          });
      }
    }
  }

  const PropertyDocumentAddRow = () => {

    var LRNo = PropertyDocumentList.length + 1;
    var VDocumentName = document.getElementById('txtDocumentName').value;
    let IsInsert = 1;
    if (VDocumentName == '') {
      IsInsert = 0;
      addToast("Please Enter Document Title", { appearance: 'error', autoDismiss: true });
    }
    else if (DocumentMediaName == '') {
      IsInsert = 0;
      addToast("Please Enter Document Media File", { appearance: 'error', autoDismiss: true });
    }

    if (IsInsert == 1) {
      let DocLastId = PropertyDocumentList[PropertyDocumentList.length - 1].DocumentId + 1;
      let DocRowId = PropertyDocumentList[PropertyDocumentList.length - 1].DocumentRowId + 1;
      const Pitem = {
        DocumentId: DocLastId,
        DocumentRowId: DocRowId,
        DocumentName: VDocumentName,
        DocumentMedia: DocumentMedia1,
        DocumentNameId: 'txtDocumentName' + DocLastId,
        DocumentFileName: DocumentMediaName,
        DocumentDivId: "divPropertyDocument" + DocRowId,
        RecordType: 'N',
        DocumentStatus: true
      }
      setPropertyDocumentList([...PropertyDocumentList, Pitem]);
      document.getElementById('txtDocumentName').value = '';
      setDocumentMedia1([]);
      setDocumentMediaName('')
    }
  }

  const PropertyDocumentRowdelete = DocumentId => {

    for (let pil = 0; pil < PropertyDocumentList.length; pil++) {
      const PDocumentId = PropertyDocumentList[pil].DocumentRowId;
      const VDocumentDivId = '#' + PropertyDocumentList[pil].DocumentDivId;
      if (PDocumentId == DocumentId) {
        PropertyDocumentList[pil]["DocumentStatus"] = false;
        $(VDocumentDivId).hide();
      }
    }
  };




  return (
    <React.Fragment>
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row mainitalpropertylist">
              <div className="col-md-12">
                <p className="addservicetextform">Property Details</p>
              </div>
            </div>
            <div className="row">


              <div className="col-md-12">
                <div className="mainpropertylistbox">
                  <div className="">

                    <div className="row">


                      <div className="col-md-12">
                        <div className=" ">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="addservicetextform mainmarginpdtitle">Documents</p>
                            </div>
                            <div className="col-md-6 text-end">
                              <a onClick={()=>{PropertyDocumentAddRow(); setDocMediaFlag(0)}}> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New</p></a>
                            </div>

                          </div>

                          {PropertyDocumentList.filter(x => x.DocumentStatus == true).map((item, idx) => (
                            <div className="row mainaddnewinsightalldiv" key={idx} id={item.DocumentDivId}>
                              <div className="col-md-10">
                                <div className="row align-items-center ">


                                  {/* <div className="col-md-1 mb-3 mb-md-0">
                                    <a onClick={e => PropertyDocumentRowdelete(item.DocumentRowId)}>
                                      <img src={deletedash} className="deletedashimg"></img>
                                    </a>
                                  </div> */}
                                  <div className="col-md-9 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                      <input type="text" className="form-control formcontrollplaceholder border-0" id={item.DocumentNameId} placeholder="Property Ownership Documents for Verification" defaultValue={item.DocumentName} />

                                    </div>
                                  </div>
                                  <div className="col-md-3 mb-3 mb-md-0">
                                    <div className="">
                                      <input type="file" name="file" id="file" className="file" />
                                      <div className="mainfileuploadimgwith">
                                        <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" defaultValue={item.DocumentFileName} />
                                        {/* <div className="maindivdeletprpertyaddnewsmall">
                                          <div className="deletprpertyaddnewsmall">
                                            <i className="las la-trash-alt"></i>
                                          </div>
                                        </div> */}
                                        <div className="filnamewithiconmain">
                                          <i className="las la-file"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>


                              </div>
                              <div className="col-md-2 mb-3 mb-md-0 text-end">
                                <button
                                  className="btn removerowbtnnew "
                                  onClick={e => PropertyDocumentRowdelete(item.DocumentRowId)}
                                >
                                  <img
                                    src={deletedash}
                                    className="deletedashimg"
                                  ></img>
                                  Remove
                                </button>
                              </div>
                              {/* <div className="col-md-2 mb-3 mb-md-0 text-end">
                                <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                              </div> */}

                            </div>))
                          }
                          <div className="row mainaddnewinsightalldiv">
                            <div className="col-md-10">
                              <div className="row align-items-center ">
                                <div className="col-md-9 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="txtDocumentName" placeholder="Property Ownership Documents for Verification"/>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                  <div className="">
                                    <input type="file" name="file" id="file" className="file" />
                                    <div className="mainfileuploadimgwith">
                                      <input type="text" name="file-name" id="file-name" className="file-name border-0" defaultValue={DocumentMediaName} readOnly="readonly" />
                                      <div className="maindivdeletprpertyaddnewsmall">
                                   { DocMediaFlag==1? <div className="deletprpertyaddnewsmall">
                                          <a onClick={(e) => { setDocumentMedia1([]); setDocumentMediaName('') }}><i className="las la-trash-alt"></i></a>
                                        </div>:""}
                                      </div>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 mb-3 mb-md-0 text-end">
                              <button type="button" id="upfile3" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                              <input type="file" id="file3" className="d-none" onChange={(e) => { setDocumentMedia1(e.target.files[0]); setDocumentMediaName(e.target.files[0].name); setDocMediaFlag(1)}} />
                            </div>

                          </div>



                        </div>

                      </div>



                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-12">
                <a className="btn btn-primary btnnextedit" onClick={UpdatePropertyDetail} >Save </a>
                <a className="btn btn-primary btnnexteditline" href="/property-list" > Cancel </a>
              </div>


            </div>
          </div>


        </div>
      </section>
      <Footeradmin />
    </React.Fragment >
  )
}

export default EditProperty3

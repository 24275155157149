import React, { Component, useEffect, useState } from "react"
import axios from "axios";
import Commheader from "../frontend/header";
import mianheaderimg from "../../assets/images/frontend-img/logo-new.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import nodata from "../../assets/images/frontend-img/nodata.png";
import Footeradmin from "../frontend/footeradmin";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { Col, Row, Table } from "reactstrap";
import loginmainimg from "../../assets/images/frontend-img/logo-new1.png";


const transactionhistory = () => {
  const [TransactionHistoryList, setTransactionHistoryList] = useState([]);
  const [ShowLoader, setShowLoader] = useState(0);


  useEffect(() => {
    setShowLoader(1);
    getMyReferralsList();
    
  }, []);

  const getMyReferralsList = () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);
    axios.post(global.APIURL + '/TransactionHistory', formData)
      .then(LPres => {       
       
    
        let LCD = LPres.data.Result.map((lcdeatail) => {
          return lcdeatail
        })
        setTransactionHistoryList(LCD);
      });
    setShowLoader(0);
  }

  
  const exportPDF = () => {

    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.addImage(mianheaderimg, 'JPEG',210, -8, 180, 100);
    //doc.setFontSize(15);
    // const title = "Transaction History Report";
    doc.setFontSize(12);
    var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth();
    var curr_year = today.getFullYear();
    var m_names = new Array("January", "February", "March",
      "April", "May", "June", "July",
      "August", "September",
      "October", "November", "December");

    today = m_names[curr_month] + " " + curr_date + ", " + curr_year;
    var newdat = today;
    doc.text(450, 75, newdat);

    const headers = [[{ content: 'Transaction List', colSpan: 7, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Date", "Time", "Payment Status", "Payment Details", "Mode Of Payment", "Amount (INR)"]];

    const data = TransactionHistoryList.map((item, index) =>
      [
        index + 1, item.Date, item.Time, (item.PaymentFor == 'AdvancePaid' ? 'Advance Payment' : item.PaymentFor == 'ServiceCompleted' ? 'Settlement / Final Payment' : ''), "Payment Against #SR" + (item.ServiceId.length == 1 ? '000000' + item.ServiceId : item.ServiceId.length == 2 ? '00000' + item.ServiceId : item.ServiceId.length == 3 ? '0000' + item.ServiceId : item.ServiceId.length == 4 ? '000' + item.ServiceId : item.ServiceId.length == 5 ? '00' + item.ServiceId : item.ServiceId.length == 6 ? '0' + item.ServiceId : item.ServiceId), item.ModeOfPayment, (item.Amount == null ? '' : item.Amount == '' ? '' : parseFloat(item.Amount == null ? '0' : item.Amount == '' ? '0' : item.Amount).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + ' ' + (item.Amount == null ? '' : item.Amount == '' ? '' : ''))]);

    let content = {
      startY: 90,
      head: headers,
      body: data,
      headStyles: { fillColor: ['#12284C'] },
      styles: { fontSize: 11, fontFamily: "Hebrew", fontStyle: "bold" },
    };

    // doc.text( marginLeft, 40);
    doc.autoTable(content);
    doc.save("TransactionHistory.pdf")
  }

  const exportPDF1 = async (pdfid) => {
  
    const unit = "pt";
    const size = "A4";
    const orientation = "mm";
    
    const pdf = new jsPDF(orientation, unit ,size,true);    
    const data = document.getElementById(pdfid);  
    pdf.html(data, {
      function(pdf) {
        pdf.save('DOC.pdf');
      }
    }).then(() => {
      pdf.save("Receipt.pdf");
    });
  }
  
  return (
    <React.Fragment>
      {ShowLoader == 1 ?
        <div className="showhidedivloader" >
          <div className="mainloadernew">
            <div className="spinner-border text-primary mainloderedit" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : ''}
      <Commheader />
    
       
      <section>
      <div id="previewImg">
    </div>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row mainitalpropertylist align-items-center">
              <div className="col-md-6 col-8">
                <p className="addservicetextform">Transaction History</p>
              </div>
         
              <div className="col-md-6 col-4 text-end">
                <div className="d-flex justify-content-end">
                  {TransactionHistoryList.length > 0 ?
                    <div>
                      <button type="button" className="btn btn-warning btnpaymentdownload" onClick={exportPDF}><i className="las la-download"></i></button>
                    </div> : ''}
                  <div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="maintabledivedit table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="mainwidthtable" scope="col">Date</th>
                        <th className="mainwidthtable" scope="col">Payment Status</th>
                        <th className="mainwidthtable" scope="col">Payment Details</th>
                        <th className="mainwidthtable2 text-center" scope="col">Payment Mode</th>
                        <th className="mainwidthtable2 text-center" scope="col">Download</th>
                        <th className="mainwidthtable2 text-end" scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TransactionHistoryList.length > 0 ?
                        TransactionHistoryList.map((item, idx) => (
                          <tr key={idx}>
                            <td>
                              <p className="mainpropertytitlelistms newdatenowrap" >{item.Date} {item.Time}</p>
                            </td>
                            <td>
                              <p className="mainpropertytitlelistms ap-text">{item.PaymentFor == 'AdvancePaid' ? <span className="advancepaymenttextcolor">Advance Payment</span> : item.PaymentFor == 'ServiceCompleted' ? <span className="servicecompletedcolor">Settlement / Final Payment</span> : ''}</p>
                            </td>
                            <td>
                              <p className="mainpropertytitlelistms">Payment Against #SR{item.ServiceId.length == 1 ? '000000' + item.ServiceId : item.ServiceId.length == 2 ? '00000' + item.ServiceId : item.ServiceId.length == 3 ? '0000' + item.ServiceId : item.ServiceId.length == 4 ? '000' + item.ServiceId : item.ServiceId.length == 5 ? '00' + item.ServiceId : item.ServiceId.length == 6 ? '0' + item.ServiceId : item.ServiceId}</p>
                            </td>
                            <td>
                              <p className="mainpropertytitlelistms text-center">{item.ModeOfPayment}</p>
                            </td>
                            <td>
                              <a className="mainpropertytitlelistms text-center" target="_blank" rel="noreferrer" type="application/octet-stream" download="service_transaction.pdf" onClick={e => { exportPDF1(item.HistoryId + "pdfid"); }}>                             <div className="downloadlinkcss"> <i className="las la-download fa-lg"></i></div>
                              </a>
                            </td>
                            <td>
                              <p className="mainpropertytitlelistms maininrtext text-end">{item.Amount == null ? '' : item.Amount == '' ? '' : parseFloat(item.Amount == null ? '0' : item.Amount == '' ? '0' : item.Amount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} {item.Amount == null ? '' : item.Amount == '' ? '' : 'INR'}</p>
                            </td>
                          </tr>
                        ))
                        :
                        <tr>
                          <td colSpan={7}>
                            <div className="row">
                              <div className="text-center">
                                <img src={nodata} className="nodataimg" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="d-none" >
          {TransactionHistoryList.map((item, idx) => (
            <div style={{ padding: 20, width: "600px", height: "750px",fontFamily:"Hebrew",fontStyle:"bold"}} id={item.HistoryId + "pdfid"} key={idx}>
              <Row>
                <Col xs={12} md={4}>
                  <img className="loginboximg" src={loginmainimg} style={{ height: "60px", width: "200px" }}></img>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3><strong style={{ marginLeft: 220 }}>Receipt</strong></h3>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <strong>CoLand Services Private Limited</strong>
                </Col>
              </Row>
              <Row style={{ marginTop: 22 }}>
                <Col xs={12} md={8}>
                  <div>1st Floor, Bhagyalakshmi Indoor Stadium,</div>
                  <div style={{ marginTop: -4 }}>Kolazhy, Thrissur. 680010,</div>
                  <div style={{ marginTop: -4 }}>Mail:care@colandservices.com,</div>
                  <div style={{ marginTop: -4 }}>www.colandservices.com</div>
                  <div style={{ marginTop: -4 }}>Phone:+91-9947422444</div>
                </Col>
                <Col xs={6} md={4} >
                  <table>
                    <tr>
                      <th>Receipt :</th>
                      <td>{item.HistoryId}</td>
                    </tr>
                    <tr>
                      <th>Date :</th>
                      <td>{item.Date}</td>
                    </tr>
                  </table>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <div style={{ marginTop: -4 }}>Bill To:</div>
                <div> <strong>{item.UserName}</strong></div>
                <div style={{ marginTop: -4 }}>{item.PropertyName}{","}{"Near"}{item.StreetAddress}{","}</div>
                <div style={{ marginTop: -4 }}>{item.StateName} {","} {item.CityName}{"-"}{item.PinCode}{","} </div>
                <div style={{ marginTop: -4 }}>{"Phone:"}{" "}{item.UserNumber}</div>
              </Row>

              <Row style={{ marginTop: 30 }}>
              <div id="resp-table" style={{width: "100%",display:"table"}}>

<div id="resp-table-header" style={{display:"table-header-group"}}>
<div className="table-header-cell"  style={{display:"table-cell",padding: "10px",textAlign: "justify",borderBottom: "1px outset #e7dbdb"}}>
<strong>#</strong>
</div>
<div className="table-header-cell"  style={{display:"table-cell",padding: "10px",textAlign: "center",borderBottom: "1px outset #e7dbdb"}}>
<strong>Description</strong>
</div>
<div className="table-header-cell" style={{display:"table-cell",padding: "10px",textAlign: "justify",borderBottom: "1px outset #e7dbdb"}}>
<strong>Quantity</strong>
</div>
<div className="table-header-cell"  style={{display:"table-cell",padding: "10px",textAlign: "justify",borderBottom: "1px outset #e7dbdb"}}>
<strong>SAC</strong>
</div>
<div className="table-header-cell"  style={{display:"table-cell",padding: "10px",textAlign: "right",borderBottom: "1px outset #e7dbdb"}}>
<strong>Rate</strong>
</div>
<div className="table-header-cell"  style={{display:"table-cell",padding: "10px",textAlign: "right",borderBottom: "1px outset #e7dbdb"}}>
<strong>Amount</strong>
</div>


</div>

<div id="resp-table-body" style={{display:"table-row-group"}}>
<div className="resp-table-row" style={{display:"table-row"}}><div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "justify",borderBottom: "1px outset #e7dbdb"}}>
1</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "center",borderBottom: "1px outset #e7dbdb"}}>
{item.ServiceName}
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "center",borderBottom: "1px outset #e7dbdb"}}>
1
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "center",borderBottom: "1px outset #e7dbdb"}}>
0
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "right",borderBottom: "1px outset #e7dbdb"}}>
{item.Amount}
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "right",borderBottom: "1px outset #e7dbdb"}}>
{item.Amount}
</div>
</div>
<div className="resp-table-row" style={{display:"table-row"}}>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "justify",borderBottom: "1px  #e7dbdb"}}>
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "justify",borderBottom: "1px  #e7dbdb"}}>
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "justify",borderBottom: "1px  #e7dbdb"}}>
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "justify",borderBottom: "1px  #e7dbdb"}}>
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "right",borderBottom: "1px  #e7dbdb"}}>
Total
</div>
<div className="table-body-cell" style={{display:"table-cell",padding: "8px",textAlign: "right",borderBottom: "1px  #e7dbdb"}}>
{item.Amount}
</div>
</div>
</div>

</div>

              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col xs={12} md={12} >
                  <table>
                    <tr>
                      <td style={{fontSize:"11px"}}>
                        <p style={{marginBottom:"-4px",whiteSpace:"nowrap"}}>
                        * Make all cheques payable to CoLand Services Private Limited                        
                        </p>
                        <p>
                        * Total due in 2 days. Overdue accounts subject to a service charge of 10% per month.
                        </p>                       
                        </td>                  
                    </tr>                             
                  </table>
                </Col>            
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col xs={12} md={8} >
                  <table>
                    <tr>
                      <td colSpan={2}><strong>Payment Channel Details:</strong>{" "}{item.ModeOfPayment}</td>
                    </tr>          
                  </table>
                </Col>
                <Col xs={6} md={4} >
                  <table>
                    <tr>
                      <td colSpan={2}><strong>Thanking you</strong></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>   <td colSpan={2}><strong>Authorized Signature</strong></td></tr>
                 
                  </table>
                </Col>
              </Row>
              {/* <Row style={{ marginTop: 28, textAlign: 'center' }}>
                Footer
              </Row> */}
            </div>
          ))}

          </div>
   
          </div>
        </div>

      </section>


      <Footeradmin />
    </React.Fragment>
  )
}

export default transactionhistory

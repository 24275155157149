import React, { Component, useEffect, useState } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
  View,
} from "reactstrap"

import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import servicedetailssliderrigt from "../../assets/images/frontend-img/servicedetailssliderrigt.png";
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import roomicon from "../../assets/images/frontend-img/roomicon.svg";
import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";
import Commheader from "../frontend/header";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import loader from "../../assets/images/frontend-img/loader.gif";
import Footeradmin from "../frontend/footeradmin";
import defaulthomeimg from "../../assets/images/frontend-img/development.png";

const addPropertyPage3 = () => {

  const { addToast } = useToasts();
  const [PropertyDocumentList, setPropertyDocumentList] = useState([]);
  const [DocumentMedia1, setDocumentMedia1] = useState([]);
  const [DocumentMediaFileName, setDocumentMediaFileName] = useState("");
  let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
  var VPropertyId = JSON.parse(localStorage.getItem('PropertyId'));
  const [PropertyImageList, setPropertyImageList] = useState([]);
  const [PropertyName, setPropertyName] = useState("");
  const [PropertyType, setPropertyType] = useState("");
  const [FullAddress, setFullAddress] = useState("");
  const [TermsOfUser, setTermsOfUser] = useState('');
  const [PrivacyPolicy, setPrivacyPolicy] = useState('');
  const [DocumentMediaFlag,setDocumentMediaFlag]=useState(0);

  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  useEffect(() => {
    getLegalPageTermsOfUserDetail();
    getLegalPagePrivacyDetail();
  }, []);

  const getLegalPageTermsOfUserDetail = async () => {
    const formData = new FormData();
    formData.append('Type', 'Terms_Of_User');
    axios.post(global.APIURL + '/LegalPageList', formData).then(res => {
      if(res.data.length>0){
        setTermsOfUser(res.data.Result[0].Html);
      }
    
    });
  };

  const getLegalPagePrivacyDetail = async () => {
    const formData = new FormData();
    formData.append('Type', 'Privacy');
    axios.post(global.APIURL + '/LegalPageList', formData).then(res => {
      if(res.data.Result.length>0){
        setPrivacyPolicy(res.data.Result[0].Html);
      }
      
    });
  };
  const addPropertyDocuments = () => {

    var VDocumentName = document.getElementById('txtDocumentName').value;
    var IsInsert = 1;
    var chkTermsandconditionsval = document.getElementById("chkTermsandconditions");
    if (PropertyDocumentList.length == 0) {
      if (VDocumentName == "") {
        IsInsert = 0;
     
      }
    if (DocumentMediaFileName == "") {
        IsInsert = 0;
      
      }
   
    }
   
      if (chkTermsandconditionsval.checked == false) {
        IsInsert = 0;
        addToast("Please Accept Terms and Conditions", { appearance: 'error', autoDismiss: true });
        return;

      }   

    if (IsInsert == 1) {
      const formData = new FormData();

      formData.append('RegisterId', VRegisterId);
      formData.append('Action', "Add");
      formData.append('PropertyId', VPropertyId);

      for (let di = 0; di < PropertyDocumentList.length; di++) {
        let RCount = di + 1;
        let VDocumentName = PropertyDocumentList[di].DocumentName;
        let VDocumentMedia = PropertyDocumentList[di].DocumentFile;
        let CD = 'DocumentName' + RCount;
        let CM = 'DocumentMedia' + RCount;
        formData.append(CD, VDocumentName);
        formData.append(CM, VDocumentMedia);
      }

      let DocumentNamec = 1;
      if (VDocumentName == "") {
        DocumentNamec = 0;
    
      }
      else if (DocumentMediaFileName == "") {
        DocumentNamec = 0;
  
      }

      if (DocumentNamec == 1) {
        let RCount = PropertyDocumentList.length;
        let CD = 'DocumentName' + RCount;
        let CM = 'DocumentMedia' + RCount;
        formData.append(CD, VDocumentName);
        formData.append(CM, DocumentMedia1);
        formData.append('TotalMediaCount', RCount);
      }
      else {
        //window.location.href = "/property-list";
        formData.append('TotalMediaCount', PropertyDocumentList.length);
      }

      for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }

      axios.post(global.APIURL + '/PropertyDocuments', formData)
        .then(res => {
          if (res.data.Status == true) {
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            localStorage.removeItem('PropertyId');
            window.location.href = "/property-list";
          }
          else {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          }
        });
    }
    else{
      window.location.href = "/property-list";
    }
  }
  const propertyDocumentsHandleAddRow = () => {

    var LRNo = PropertyDocumentList.length == 0 ? 1 : (PropertyDocumentList[PropertyDocumentList.length - 1].PId + 1);
    var VDocumentName = document.getElementById("txtDocumentName").value;
    let IsInsert = 1;
    if (VDocumentName == '') {
      IsInsert = 0;
      addToast("Please Enter Document Title", { appearance: 'error', autoDismiss: true });
    }
    else if (DocumentMediaFileName == '') {
      IsInsert = 0;
      addToast("Please Enter Document Media File", { appearance: 'error', autoDismiss: true });
    }
    if (IsInsert == 1) {
      const Ditem = {
        PId: LRNo,
        DocumentName: VDocumentName,
        DocumentNameTextBoxControlId: 'txtDocumentName' + LRNo,
        DocumentFile: DocumentMedia1,
        DocumentFileName: DocumentMedia1.name
      }
      setPropertyDocumentList([...PropertyDocumentList, Ditem]);
      document.getElementById("txtDocumentName").value = "";
      setDocumentMedia1([]);
      setDocumentMediaFileName('');
    }
  }

  const propertyDocumentsRowDelete = (DId) => {
    const RId = DId;
    setPropertyDocumentList(PropertyDocumentList.filter(item => item.PId !== RId));
  }

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    var VPropertyId = JSON.parse(localStorage.getItem('PropertyId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    }
    else if (VPropertyId == null) {
      window.location.href = "/add-new-property";
    }
    else {
      getPropertyDetail();
    }

  }, []);

  const getPropertyDetail = async () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    var PId = JSON.parse(localStorage.getItem('PropertyId'));
    let VSelectPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
    const PropertyDetailArr = {
      RegisterId: VRegisterId,
      PropertyId: VSelectPropertyId
    };

    axios.post(global.APIURL + "/propertyDetail", PropertyDetailArr)
      .then(res => {
        setPropertyName(res.data.Result[0].PropertyTitle);
        setPropertyType(res.data.Result[0].PropertyTypeName==""?res.data.Result[0].OtherPropertyType:res.data.Result[0].PropertyTypeName);
        setFullAddress(res.data.Result[0].FullAddress)
        var PropImageList = res.data.Result[0].Media;
        var PIArr = [];
        for (let pi = 0; pi < PropImageList.length; pi++) {
          const PropImageUrl = PropImageList[pi].Url;
          const PropImageitem = {
            RId: pi,
            SclassName: pi == 0 ? 'carousel-item active' : 'carousel-item',
            SliderName: 'Slider ' + (pi + 1),
            PropImageUrl: PropImageUrl
          };
          PIArr.push(PropImageitem);
        }
        setPropertyImageList(PIArr);
      })
  }

  return (
    <React.Fragment>
      <Commheader />
      <div className="modal fade newzindexrdit" id="termsandconditionmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Terms & Conditions</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row miandivpricingbox newmaindivpricingcustomadd">
              <div name="termly-embed" data-id="8f1e65bc-f9c1-4d67-9060-da2884dbcb6a" data-type="iframe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade newzindexrdit" id="privacypolicemodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">privacy policy</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row miandivpricingbox newmaindivpricingcustomadd">
              <div name="termly-embed" data-id="3ff5f924-f50d-42f1-a687-cae728b3476c" data-type="iframe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row">
              <div className="col-md-12">
                <p className="addservicetextform">Add New Properties</p>
              </div>
              <div className="col-md-12">
                <p className="mainsteptextform">Step 3 : Documents</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="propertysteptwomainbox">
                  <div className="d-flex">
                    <div>
                    {PropertyImageList.length==0?<img src={defaulthomeimg}  className="mainpropertysliderimg" ></img>: <div
                        id="carouselExampleIndicators"
                        className="carousel slide propertyimgslider  propertyimgslidernewimgedit"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-indicators">
                          {PropertyImageList.map((item, idx) =>
                            idx == 0 ? (
                              <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to={idx}
                                className="active"
                                aria-current="true"
                                aria-label={item.SliderName}
                              ></button>
                            ) : (
                              <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to={idx}
                                aria-label={item.SliderName}
                              ></button>
                            )
                          )}
                          {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                          {PropertyImageList.map((item, idx) => (
                            <div className={item.SclassName} key={idx}>
                              <img
                                src={item.PropImageUrl}
                                className="mainpropertysliderimg"
                              />
                            </div>
                          ))}
                        </div>
                      </div>}
                    </div>
                    <div className="w-100">
                      <div className="d-flex w-100">
                        <p className="mainpropertytitlelist">{PropertyName} </p>
                        <div>
                          <img className="verifiedgreenstep2" src={verifiedgreen} />
                        </div>
                      </div>
                      <div>
                        <p className="propertycatageoried">{PropertyType}</p>
                      </div>
                      <div className="d-flex">
                        <div>
                          <img src={locationpropertyedit} className="locationpropertyedit" />
                        </div>
                        <div>
                          <p className="propertytextlocation">{FullAddress}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="deletprpertyadd">
                      <i className="las la-trash-alt"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mainboxnewaddproperty ">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="maintitlereuestservicenee">Documents</p>
                    </div>
                    <div className="col-md-6 text-end mb-3 mb-md-0">
                      <a onClick={()=>{propertyDocumentsHandleAddRow();setDocumentMediaFlag(0)}} className="addnewpropertytextneww"> <i className="las la-plus-circle"></i>Add New Document</a>
                    </div>
                  </div>
                  {PropertyDocumentList.map((item, idx) => (<div className="row mainaddnewinsightalldiv" key={idx}>
                    <div className="col-md-10">
                      <div className="row align-items-center ">
                        {/* <div className="col-md-1 mb-3 mb-md-0">
                          <a onClick={e => propertyDocumentsRowDelete(item.PId)}><img src={deletedash} className="deletedashimg"></img></a>
                        </div> */}
                        <div className="col-md-8 mb-3 mb-md-0">
                          <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                            <input type="text" className="form-control formcontrollplaceholder border-0" id={item.DocumentNameTextBoxControlId} defaultValue={item.DocumentName} placeholder="Property Ownership Documents for Verification" />
                          </div>
                        </div>
                        <div className="col-md-3 mb-3 mb-md-0">
                          <div className="">
                            <input type="file" name="file" id="file" className="file" />
                            <div className="mainfileuploadimgwith">
                              <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" value={item.DocumentFileName} />
                              <div className="maindivdeletprpertyaddnewsmall">
                                {/* <div className="deletprpertyaddnewsmall">
                                  <i className="las la-trash-alt"></i>
                                </div> */}
                              </div>
                              <div className="filnamewithiconmain">
                                <i className="las la-file"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-2 mb-3 mb-md-0 text-end">
                      <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                    </div> */}
                        <div className="col-md-2 text-end">
                    <button className="btn removerowbtnnew " onClick={e => propertyDocumentsRowDelete(item.PId)}><img src={deletedash} className="deletedashimg"></img>Remove</button>
                  </div>
                  </div>))}
                  <div className="row mainaddnewinsightalldiv">
                    <div className="col-md-10">
                      <div className="row align-items-center ">
                      {/* <div className="col-md-1 mb-3 mb-md-0">
                         </div> */}

                        <div className="col-md-8 mb-3 mb-md-0">
                          <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                            <input type="text" className="form-control formcontrollplaceholder border-0" id="txtDocumentName" placeholder="Property Ownership Documents for Verification" />
                          </div>
                        </div>
                        <div className="col-md-3 mb-3 mb-md-0">
                          <div className="">
                            <input type="file" name="file" id="file" className="file" />
                            <div className="mainfileuploadimgwith">
                              <input type="text" name="file-name" id="file-name" className="file-name border-0" defaultValue={DocumentMediaFileName} readOnly="readonly" />
                              <div className="maindivdeletprpertyaddnewsmall">
                                {DocumentMediaFlag==1 ?<div className="deletprpertyaddnewsmall">
                                  <a onClick={(e) => { setDocumentMedia1([]); setDocumentMediaFileName('') }}><i className="las la-trash-alt"></i></a>
                                </div>:""}
                              </div>
                              <div className="filnamewithiconmain">
                                <i className="las la-file"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 mb-3 mb-md-0 text-end">
                      <button type="button" id="upfile1" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                      <input type="file" id="file1" className="d-none" onChange={(e) => { setDocumentMedia1(e.target.files[0]); setDocumentMediaFileName(e.target.files[0].name);setDocumentMediaFlag(1);}} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div>
                  <form>
                    <div className="form-group newwform-group maincheckproperty3">
                      <input type="checkbox" id="chkTermsandconditions" />
                      <label htmlFor="chkTermsandconditions" className="mb-0">By ticking, you are confirming that you have read, understood and agree to the <a className="mainlineatagee" data-bs-toggle="modal" data-bs-target="#termsandconditionmodal">Terms and conditions</a>  and <a className="mainlineatagee" data-bs-toggle="modal" data-bs-target="#privacypolicemodal">privacy policy</a> </label>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-12">
                <button type="button" className="btn btn-primary btnnextedit showloader" onClick={addPropertyDocuments}>Next <i className="fas fa-long-arrow-alt-right"></i></button>
                <a href="/add-new-property3" type="button" className="btn btn-primary btnnexteditline">Cancel </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footeradmin />
    </React.Fragment>
  )
}


export default addPropertyPage3

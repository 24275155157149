import React, { Component, useEffect, useState } from "react"
import defaulthomeimg from "../../assets/images/frontend-img/development.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import Commheader from "../frontend/header";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";
import nodata from "../../assets/images/frontend-img/nodata.png";
import loader from "../../assets/images/frontend-img/loader.gif";
import Footeradmin from "../frontend/footeradmin";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import mianheaderimg from "../../assets/images/frontend-img/logo-new.png";


const propertyList = () => {

  const [PropertyList, setPropertyList] = useState([]);
  const [ShowLoader, setShowLoader] = useState(0);
  const [PropertyListCount, setPropertyListCount] = useState(0);
  const [PaginationList, setPaginationList] = useState([]);

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      setShowLoader(1);
      getProfileData();
      getPriorityList(1);
    }
  }, []);

  const getProfileData = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);
    axios.post(global.APIURL + '/Dashbord', formData)
      .then(res => {
        localStorage.setItem("PropertiesCount", res.data.Result[0].PropertiesCount);
      });
  }

  const getPriorityList = async (PageNumber) => {
    localStorage.removeItem("PriorityCurrentPageNumber");
    localStorage.setItem("PriorityCurrentPageNumber", PageNumber);
    PropertyList.length = 0;
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropListData = {
      RegisterId: VRegisterId,
      Pagination: PageNumber,
      Type: ''
    }
    axios.post(global.APIURL + '/PropertyList', PropListData)
      .then(res => {
        setShowLoader(0);
        setPropertyListCount(res.data.Result.length)
        var PLArr = [];
        for (let pl = 0; pl < res.data.Result.length; pl++) {
          const VPropertyId = res.data.Result[pl].PropertyId;
          const VPropertyName = res.data.Result[pl].PropertyName;
          const VPropertyType = res.data.Result[pl].PropertyType;
          const VOtherPropertyType = res.data.Result[pl].OtherPropertyType;
          const VFullAddress = res.data.Result[pl].FullAddress;
          const VLatititude = res.data.Result[pl].Latititude;
          const VLongitude = res.data.Result[pl].Longitude;
          const VLandArea = res.data.Result[pl].LandArea;
          const VLandareaIn = res.data.Result[pl].LandareaIn;
          const VBuiltUpaArea = res.data.Result[pl].BuiltUpaArea;
          const VSuperBuiltUpIn = res.data.Result[pl].SuperBuiltUpIn;

          var PIArr = [];

          if (res.data.Result[pl].Media != undefined) {
            const ProMediaArr = res.data.Result[pl].Media.filter(x => x.Imagestatus == "true");
            for (let pm = 0; pm < ProMediaArr.length; pm++) {
              const VMediaFileUrl = ProMediaArr[pm].URL;
              const VImagestatus = ProMediaArr[pm].Imagestatus == 'true' ? true : false;
              const PropImageitem = {
                RId: pm,
                SclassName: pm == 0 ? 'carousel-item active' : 'carousel-item',
                SliderName: 'Slider ' + (pm + 1),
                PropImageUrl: VMediaFileUrl,
                Imagestatus: VImagestatus
              };
              PIArr.push(PropImageitem);
            }
          }
          const PLitem = {
            PropertyId: VPropertyId,
            PropertyName: VPropertyName,
            PropertyType: VPropertyType == '' ? VOtherPropertyType : VPropertyType,
            FullAddress: VFullAddress,
            Latititude: VLatititude,
            Longitude: VLongitude,
            LandArea: VLandArea,
            LandareaIn: VLandareaIn,
            BuiltUpaArea: VLandArea,
            SuperBuiltUpIn: VSuperBuiltUpIn,
            PropertyImageList: PIArr,
          };
          PLArr.push(PLitem);
        }
        setPropertyList(PLArr);
        let TPC = parseInt(localStorage.getItem('PropertiesCount') == null ? '0' : localStorage.getItem('PropertiesCount') == '' ? '0' : localStorage.getItem('PropertiesCount'));
        let TPCAL = TPC > 30 ? TPC / 30 : 0;
        var PIPageArr = [];
        for (let tpl = 0; tpl < TPCAL; tpl++) {
          let PN = tpl + 1;
          const PLitem = {
            PageNumber: PN,
            className: PN == PageNumber ? 'page-item active' : 'page-item'
          };
          PIPageArr.push(PLitem);
          if (tpl == 0) {
            localStorage.removeItem("PriorityFirstPageNumber");
            localStorage.setItem("PriorityFirstPageNumber", PN);
          }
          localStorage.removeItem("PriorityLastPageNumber");
          localStorage.setItem("PriorityLastPageNumber", PN);
        }
        setPaginationList(PIPageArr);
      });

  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.addImage(mianheaderimg, 'JPEG', 210, -8, 180, 100);
    doc.setFontSize(12);

    var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth();
    var curr_year = today.getFullYear();
    var m_names = new Array("January", "February", "March",
      "April", "May", "June", "July",
      "August", "September",
      "October", "November", "December");

    today = m_names[curr_month] + " " + curr_date + ", " + curr_year;
    var newdat = today;
    doc.text(450, 75, newdat);
    const headers = [[{ content: 'Property List', colSpan: 6, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Name", "Type", "Address", "LandArea", "BuiltUp"]];

    const data = PropertyList.map((item, index) =>
      [index + 1, item.PropertyName, item.PropertyType, item.FullAddress, item.LandArea + '  (' + item.LandareaIn + ') ', item.BuiltUpaArea + '  (' + item.SuperBuiltUpIn + ') ']);

    let content = {
      startY: 90,
      head: headers,
      body: data,
      headStyles: { fillColor: ['#12284C'] },
      styles: { fontSize: 11, fontFamily: "Hebrew", fontStyle: "bold" },
    };

    doc.setFont("helvetica");
    doc.autoTable(content);
    doc.save("PropertyList.pdf");

  }

  const PageNumberClick = async (e, PageNumber) => {
    getPriorityList(PageNumber);
  }

  const NextPageNumberClick = async () => {
    let CPageNo = parseInt(localStorage.getItem('PriorityCurrentPageNumber') == null ? '0' : localStorage.getItem('PriorityCurrentPageNumber') == '' ? '0' : localStorage.getItem('PriorityCurrentPageNumber'));
    let LPN = parseInt(localStorage.getItem('PriorityLastPageNumber') == null ? '0' : localStorage.getItem('PriorityLastPageNumber') == '' ? '0' : localStorage.getItem('PriorityLastPageNumber'));
    if (LPN > CPageNo) {
      let NPageNo = CPageNo + 1;
      getPriorityList(NPageNo);
    }
  }

  const PreviousPageNumberClick = async () => {
    let CPageNo = parseInt(localStorage.getItem('PriorityCurrentPageNumber') == null ? '0' : localStorage.getItem('PriorityCurrentPageNumber') == '' ? '0' : localStorage.getItem('PriorityCurrentPageNumber'));
    let FPN = parseInt(localStorage.getItem('PriorityFirstPageNumber') == null ? '0' : localStorage.getItem('PriorityFirstPageNumber') == '' ? '0' : localStorage.getItem('PriorityFirstPageNumber'));
    if (CPageNo > FPN) {
      let NPageNo = CPageNo - 1;
      getPriorityList(NPageNo);
    }
  }

  const EditPropertyDetails = async (e, PEditId) => {
    setShowLoader(1);
    localStorage.setItem("DetailPropertyId", PEditId);
    window.location.href = '/edit-property';
  }

  return (<React.Fragment>
    {ShowLoader == 1 ?
      <div className="showhidedivloader">
        <div className="mainloadernew">
          <img src={loader} />
        </div>
      </div>
      : ''}
    <Commheader />
    <section>
      <div className="container mainpropertylistdivnew">
        <div className="mainboxshadow">
          <div className="row mainitalpropertylist">
            <div className="col-md-6">
              <p className="addservicetextform">Property List</p>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <a href="/add-new-property"> <p className="addnewpropertytext me-3">  <i className="las la-plus-circle"></i>Add New Property</p></a>  <button type="button" className="btn btn-warning btnpaymentdownload" onClick={exportPDF} ><i className="las la-download"></i></button>
            </div>
          </div>
          {PropertyListCount > 0 ?
            <div className="row" >
              {PropertyList.map((propItems, idx) => (
                <div className="col-md-6" key={propItems.PropertyId}>
                  <div className="mainpropertylistbox">
                    <div className="d-md-flex ">
                      <div>
                        {propItems.PropertyImageList.length == 0 ? <img src={defaulthomeimg} className="mainpropertysliderimg propertylistdefimg "></img> :
                          <div id="carouselExampleIndicators" className="carousel slide propertyimgslider propertyimgsliderpl" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                              {propItems.PropertyImageList.filter(x => x.Imagestatus == true).map((item1, idx1) => (
                                idx1 == 0 ?
                                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx1} className="active" aria-current="true" aria-label={item1.SliderName}></button>
                                  :
                                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx1} aria-label={item1.SliderName}></button>
                              ))}
                            </div>
                            <div className="carousel-inner">
                              {propItems.PropertyImageList.filter(x => x.Imagestatus == true).map((item2, idx2) => (
                                <div className={item2.SclassName} key={idx2}>
                                  <img src={item2.PropImageUrl} className="mainpropertysliderimg propertylistimg" />
                                </div>
                              ))}
                            </div>
                          </div>}
                      </div>
                      <div className="w-100">
                        <div className="d-flex justify-content-between w-100">
                          <a onClick={(e) => EditPropertyDetails(e, propItems.PropertyId)}>   <p className="mainpropertytitlelist">{propItems.PropertyName}</p></a>
                        </div>
                        <div>
                          <p className="propertycatageoried">{propItems.PropertyType} </p>
                        </div>
                        <div className="d-flex">
                          <div>
                            <img src={locationpropertyedit} className="locationpropertyedit" />
                          </div>
                          <div>
                            <p className="propertytextlocation mb-0"> {propItems.FullAddress}</p>
                          </div>
                        </div>
                        <div className="maindividerpd"> </div>
                        <div>
                          <p className="landareatext">Land Area :</p>
                          <div className="d-flex">
                            <div className="margineditlandarea">
                              <p className="mainlandareatextdescriptionnew2"> <img src={landarea1} /> {propItems.LandArea} {propItems.LandareaIn}</p>
                            </div>
                            <div>
                              <p className="mainlandareatextdescriptionnew2"> <img src={landarea2} /> {propItems.BuiltUpaArea} {propItems.SuperBuiltUpIn}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>))}
            </div>
            : ''}
          {PropertyListCount == 0 ?
            <div className="row">
              <div className="text-center">
                <img src={nodata} className="nodataimg" />
              </div>
            </div> : ''
          }
          {PaginationList.length > 0 ?
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end mt-5">
                <nav aria-label="...">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" onClick={(e) => PreviousPageNumberClick()} >Previous</a>
                    </li>
                    {PaginationList.map((PaginationItems, idx) => (
                      <li className={PaginationItems.className} key={idx}><a className="page-link" onClick={(e) => PageNumberClick(e, PaginationItems.PageNumber)}>{PaginationItems.PageNumber}</a></li>
                    ))}
                    <li className="page-item">
                      <a className="page-link" onClick={(e) => NextPageNumberClick()}>Next</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div> : ''}
        </div>
      </div>
    </section>
  </React.Fragment>);
  <Footeradmin />
}


export default propertyList

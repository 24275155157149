import React, { Component, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap";
import mianheaderimg from "../../assets/images/frontend-img/logo.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/profileimg.png";
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import mainprofiledashboard from "../../assets/images/frontend-img/mainprofiledashboard.png";
import emailicon from "../../assets/images/frontend-img/emailicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import callicon from "../../assets/images/frontend-img/callicon.png";
import propertyimg from "../../assets/images/frontend-img/propertyimg.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import mainpropertiseimages from "../../assets/images/frontend-img/mainpropertiseimages.png";
import mainpropertiseimages2 from "../../assets/images/frontend-img/mainpropertiseimages2.png";
import mainpropertiseimages3 from "../../assets/images/frontend-img/mainpropertiseimages3.png";
import sr1 from "../../assets/images/frontend-img/sr1.png";
import sr2 from "../../assets/images/frontend-img/sr2.png";
import sr3 from "../../assets/images/frontend-img/sr3.png";
import sr4 from "../../assets/images/frontend-img/sr4.png";
import sr5 from "../../assets/images/frontend-img/sr5.png";
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import pi1 from "../../assets/images/frontend-img/pi1.png";
import pi2 from "../../assets/images/frontend-img/pi2.png";
import pi3 from "../../assets/images/frontend-img/pi3.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import servicedetailssliderrigt from "../../assets/images/frontend-img/servicedetailssliderrigt.png";
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import roomicon from "../../assets/images/frontend-img/roomicon.svg";
import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";
import Commheader from "../frontend/header";
import { useToasts } from "react-toast-notifications";
import Footeradmin from "../frontend/footeradmin";
import loader from "../../assets/images/frontend-img/loader.gif";
import defaulthomeimg from "../../assets/images/frontend-img/development.png";

const addPropertyPage2 = () => {
  const { addToast } = useToasts();
  const [ShowLoader, setShowLoader] = useState(0);
  const [LandArea, setLandArea] = useState([]);
  const [LandAreaIn, setLandAreaIn] = useState("Square feet");
  const [SuperBuiltUP, setSuperBuiltUP] = useState([]);
  const [SuperBuiltUpIn, setSuperBuiltUpIn] = useState("Square feet");
  const [PropertyInsightsList, setPropertyInsightsList] = useState([]);
  const [InsightMedia1, setInsightMedia1] = useState([]);
  const [IsPropertyFurnished, setIsPropertyFurnished] = useState("No");
  const [FurnishedMedia1, setFurnishedMedia1] = useState([]);
  const [FurnishedList, setFurnishedList] = useState([]);
  const [PropertyName, setPropertyName] = useState("");
  const [PropertyType, setPropertyType] = useState("");
  const [FullAddress, setFullAddress] = useState("");
  const [PropertyImageList, setPropertyImageList] = useState([]);

  const [InsightMediaName, setInsightMediaName] = useState("");
  const [FurnishedMediaName, setFurnishedMediaName] = useState("");
  const [PropInsightDdl,setPropInsightDdl]=useState([]);
  const [InsightMediaFlag,setInsightMediaFlag]=useState(0);
  const [FurnishedMediaFlag,setFurnishedMediaFlag]=useState(0)

  const addLandAreaAndInsights = () => {
    let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
    var VPropertyId = JSON.parse(localStorage.getItem("PropertyId"));
    var VInsightName1 = document.getElementById("txtInsightName1").value;
    var VInsightWidth1 = document.getElementById("txtInsightWidth1").value;
    var VInsightLength1 = document.getElementById("txtInsightLength1").value;
    var VInsightHeight1 = document.getElementById("txtInsightHeight1").value;
    var VFurnishedDetail = document.getElementById("txtFurnishedDetail").value;
    var VFurnishedIn = document.getElementById("txtFurnishedIn").value;
    var VIsPropertyFurnished = IsPropertyFurnished == "No" ? 0 : 1;
    let IsInsert = 1;


    // if (LandArea == "") {
    //   addToast("Please Enter Land Area", { appearance: "error", autoDismiss: true });
    //   IsInsert = 0;
    //   return;
    // }
    // if (LandAreaIn == "") {
    //   addToast("Select Land Area Unit", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    //   IsInsert = 0;
    //   return;
    // }
    // if (SuperBuiltUP == "") {
    //   addToast("Please Enter Super Built Up ", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    //   IsInsert = 0;
    //   return;
    // }
    // if (SuperBuiltUpIn == "") {
    //   addToast("Select Super Built Up Unit ", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    //   IsInsert = 0;
    //   return;
    // }

    if (PropertyInsightsList.length == 0) {
      let IsPIInsert = 1;

      // if (VInsightName1 == "") {
      //   addToast("Please Enter Property Insight Title", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
      //   IsPIInsert = 0;
      //   return;
      // }
      // if (VInsightWidth1 == "") {
      //   IsPIInsert = 0;
      //   addToast("Please Enter Property Insight Width", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
      //   return;
      // }
      // if (VInsightLength1 == "") {
      //   IsPIInsert = 0;
      //   addToast("Please Enter Property Insight Length", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
      //   return;
      // }
      // if (VInsightHeight1 == "") {
      //   IsPIInsert = 0;
      //   addToast("Please Enter Property Insight Height", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
      //   return;
      // }
      // if (InsightMediaName == "") {
      //   IsPIInsert = 0;
      //   addToast("Please Enter Property Insight Media File", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
      //   return;
      // }
  
    }
   
    if (FurnishedList.length == 0) {
      let IsPFInsert = 1;
      if (VIsPropertyFurnished == 1) {
        if (VFurnishedIn == "") {
          IsPFInsert = 0;
          addToast("Please Select Property Furnished", {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        if (VFurnishedDetail == "") {
          IsPFInsert = 0;
          addToast("Please Enter Property Furniture", {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        if (FurnishedMediaName == "") {
          IsPFInsert = 0;
          addToast("Please Enter Property Furnished Media", {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
      }
    }
   
    if (IsInsert == 1) {
      const formData = new FormData();
      formData.append("RegisterId", VRegisterId);
      formData.append("Action", "Add");
      formData.append("PropertyId", VPropertyId);
      formData.append("LandArea", LandArea);
      formData.append("LandAreaIn", LandAreaIn);
      formData.append("SuperBuiltUP", SuperBuiltUP);
      formData.append("SuperBuiltUpIn", SuperBuiltUpIn);

      for (let pi = 0; pi < PropertyInsightsList.length; pi++) {
        let RCount = pi + 1;
        let VInsightWidth = PropertyInsightsList[pi].InsightWidth;
        let VInsightLength = PropertyInsightsList[pi].InsightLength;
        let VInsightHeight = PropertyInsightsList[pi].InsightHeight;
        let VInsightName = PropertyInsightsList[pi].InsightName;
        let VInsightFile = PropertyInsightsList[pi].InsightFile;
        let CW = "InsightWidth" + RCount;
        let CH = "InsightHeight" + RCount;
        let CL = "InsightLength" + RCount;
        let CN = "InsightName" + RCount;
        let CF = "InsightMedia" + RCount;

        // let INID = "txtInsightName1" + RCount;
        // let INValue = document.getElementById(INID).value;
        // formData.append(CN, INValue);

        // let IWId = "txtInsightWidth1" + RCount;
        // let IWValue = document.getElementById(IWId).value;
        // formData.append(CW, IWValue);

        // let IHId = "txtInsightHeight1" + RCount;
        // let IHValue = document.getElementById(IHId).value;
        // formData.append(CL, IHValue);

        // let ILId = "txtInsightLength1" + RCount;
        // let ILValue = document.getElementById(ILId).value;
        // formData.append(CH, ILValue);

        formData.append(CW, VInsightWidth);
        formData.append(CH, VInsightHeight);
        formData.append(CL, VInsightLength);
        formData.append(CN, VInsightName);
        formData.append(CF, VInsightFile);
      }

      let IsPropertyInsightsInsert = 1;
      if (VInsightName1 == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VInsightWidth1 == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VInsightLength1 == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VInsightHeight1 == "") {
        IsPropertyInsightsInsert = 0;
      } else if (InsightMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {
        let RCount = PropertyInsightsList.length + 1;
        let CW = "InsightWidth" + RCount;
        let CH = "InsightHeight" + RCount;
        let CL = "InsightLength" + RCount;
        let CN = "InsightName" + RCount;
        let CF = "InsightMedia" + RCount;

        formData.append(CN, VInsightName1);
        formData.append(CW, VInsightWidth1);
        formData.append(CL, VInsightLength1);
        formData.append(CH, VInsightHeight1);
        formData.append(CF, InsightMedia1);

        formData.append("PropertyInsightCount", RCount);
      } else {
        formData.append("PropertyInsightCount", PropertyInsightsList.length);
      }

      formData.append("IsPropertyFurnished", IsPropertyFurnished);

      for (let fi = 0; fi < FurnishedList.length; fi++) {
        let RCount = fi + 1;
        let VFurnishedDetail = FurnishedList[fi].FurnishedDetail;
        let VFurnishedIn = FurnishedList[fi].FurnishedIn;
        let VFurnishedFile = FurnishedList[fi].FurnishedFile;

        let FI = "PropertyFurnishedIn" + RCount;
        let FD = "PropertyFurnishedDetail" + RCount;
        let FF = "FurnishedMedia" + RCount;

        formData.append(FI, VFurnishedIn);
        formData.append(FD, VFurnishedDetail);
        formData.append(FF, VFurnishedFile);
      }

      let IsFurnishedDetailInsert = 1;
      if (VFurnishedDetail == "") {
        IsFurnishedDetailInsert = 0;
      } else if (VFurnishedIn == "") {
        IsFurnishedDetailInsert = 0;
      } else if (FurnishedMediaName == "") {
        IsFurnishedDetailInsert = 0;
      }

      if (IsFurnishedDetailInsert == 1) {
        let RCount = FurnishedList.length + 1;
        let FD = "PropertyFurnishedDetail" + RCount;
        let FI = "PropertyFurnishedIn" + RCount;
        let FF = "FurnishedMedia" + RCount;

        formData.append(FD, VFurnishedDetail);
        formData.append(FI, VFurnishedIn);
        formData.append(FF, FurnishedMedia1);
        formData.append("FurnishedCount", FurnishedList.length + 1);
      } else {
        formData.append("FurnishedCount", FurnishedList.length);
      }

   
      axios
        .post(global.APIURL + "/addEditDeleteLandAreaAndInsights", formData)
        .then(res => {
          if (res.data.Status == true) {
            addToast(res.data.StatusMessage, {
              appearance: "success",
              autoDismiss: true,
            });
            window.location.href = "/add-new-property3";
          } else {
            addToast(res.data.StatusMessage, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    }
  };

  const getPropertyInsightsArray=()=>{


    let VInsightName1=document.getElementById("txtInsightName1").value;
    
  if(VInsightName1!=""){
 
   var PE=PropInsightDdl.filter(x=> x.InsightName==VInsightName1);
    if(PE.length==0)
    {
      let PArray={
        Id:0,
        InsightName:VInsightName1
      }
      PropInsightDdl.push(PArray);
    }

  }
    
  }

 
 
  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
    var VPropertyId = JSON.parse(localStorage.getItem("PropertyId"));
    if (VRegisterId == null) {
      window.location.href = "/home";
    } else if (VRegisterId == "") {
      window.location.href = "/home";
    } else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else if (VPropertyId == null) {
      window.location.href = "/add-new-property";
    } else {
      getPropertyDetail();
    }

    window.onload = function () {
      $("#autoUpdate").hide();
      $("#addnewbutton").hide();
    };
  }, []);

  const getInsightMedia=(e)=>{
    let VInsightName1=document.getElementById("txtInsightName1").value;
    if(VInsightName1==""){
    }
    else{

      setInsightMedia1(e.target.files[0]);
      setInsightMediaName(e.target.files[0].name);                   
      setInsightMediaFlag(1);
  
    }
 
  }

  const getPropertyDetail = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
    var PId = JSON.parse(localStorage.getItem("PropertyId"));
    let VSelectPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
    const PropertyDetailArr = {
      RegisterId: VRegisterId,
      PropertyId: VSelectPropertyId,
    };

    axios
      .post(global.APIURL + "/propertyDetail", PropertyDetailArr)
      .then(res => {     
        setPropertyName(res.data.Result[0].PropertyTitle);
        setPropertyType(
          res.data.Result[0].PropertyTypeName == ""
            ? res.data.Result[0].OtherPropertyType
            : res.data.Result[0].PropertyTypeName
        );
        setFullAddress(res.data.Result[0].FullAddress);
        if(res.data.Result[0].Media.length>0){

          var PropImageList = res.data.Result[0].Media;
          var PIArr = [];
          
          for (let pi = 0; pi < PropImageList.length; pi++) {
            const PropImageUrl = PropImageList[pi].Url;
            const PropImageitem = {
              RId: pi,
              SclassName: pi == 0 ? "carousel-item active" : "carousel-item",
              SliderName: "Slider " + (pi + 1),
              PropImageUrl: PropImageUrl,
            };
            PIArr.push(PropImageitem);
          }
          setPropertyImageList(PIArr);

        }
      
      });
  };

  const handleInsightDd=()=>{

    setInsightMedia1([]);
    setInsightMediaName("");                   
    setInsightMediaFlag(0);


  }

  const insightsHandleAddRow = () => {
    var LRNo =
      PropertyInsightsList.length == 0
        ? 1
        : PropertyInsightsList[PropertyInsightsList.length - 1].PId + 1;
    var VInsightName1 = document.getElementById("txtInsightName1").value;
    var VInsightWidth1 = document.getElementById("txtInsightWidth1").value;
    var VInsightLength1 = document.getElementById("txtInsightLength1").value;
    var VInsightHeight1 = document.getElementById("txtInsightHeight1").value;
    let IsInsert = 1;
    // if (VInsightName1 == "") {
    //   IsInsert = 0;
    //   addToast("Please Enter Property Insight Title", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    // } else if (VInsightWidth1 == "") {
    //   IsInsert = 0;
    //   addToast("Please Enter Property Insight Width", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    // } else if (VInsightLength1 == "") {
    //   IsInsert = 0;
    //   addToast("Please Enter Property Insight Length", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    // } else if (VInsightHeight1 == "") {
    //   IsInsert = 0;
    //   addToast("Please Enter Property Insight Height", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    // } else if (InsightMediaName == "") {
    //   IsInsert = 0;
    //   addToast("Please Enter Property Insight Media File", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    // }
    if (IsInsert == 1) {
      const Pitem = {
        PId: LRNo,
        InsightName: VInsightName1,
        InsightWidth: VInsightWidth1,
        InsightLength: VInsightLength1,
        InsightHeight: VInsightHeight1,
        InsightName1TextBoxControlId: "txtInsightName1" + LRNo,
        InsightWidth1TextBoxControlId: "txtInsightWidth1" + LRNo,
        InsightLength1TextBoxControlId: "txtInsightLength1" + LRNo,
        InsightHeight1TextBoxControlId: "txtInsightHeight1" + LRNo,
        InsightFileName: InsightMedia1.name,
        InsightFile: InsightMedia1,
      };
      setPropertyInsightsList([...PropertyInsightsList, Pitem]);
      document.getElementById("txtInsightName1").value = "";
      document.getElementById("txtInsightWidth1").value = "";
      document.getElementById("txtInsightLength1").value = "";
      document.getElementById("txtInsightHeight1").value = "";
      setInsightMedia1([]);
      setInsightMediaName("");
    }
  };



  const insightsHandleAddRowdelete = (PId,VName) => {
    const RId = PId;

    setPropertyInsightsList(
      PropertyInsightsList.filter(item => item.PId !== RId)
    );

    setPropInsightDdl(
      PropInsightDdl.filter(item => item.InsightName !== VName)
    );


    setFurnishedList(
      FurnishedList.filter(item => item.FurnishedIn !== VName)
    );


  };

  const handleFurnishedCheckbox = e => {
    var checked = e.target.checked;
    if (checked) {  
        setIsPropertyFurnished("Yes");   
    } else if (!checked) {
      setIsPropertyFurnished("No");
      document.getElementById("txtFurnishedDetail").value = "";
      document.getElementById("txtFurnishedIn").value = "";
      document.getElementById("file-name").value = "";
      setFurnishedList([]);
      setFurnishedMedia1([]);
      setFurnishedMediaName("");
    }
  };
  
  const insightImageHandle=()=>{
    if(InsightMedia1.length==0){
      addToast("Please Add Property Insight Image", {
        appearance: "error",
        autoDismiss: true,
      });
      return;

    }

  }

  const furnishedHandleAddRow = () => {
    var LRNo =
      FurnishedList.length == 0
        ? 1
        : FurnishedList[FurnishedList.length - 1].PId + 1;
    var VFurnishedDetail = document.getElementById("txtFurnishedDetail").value;
    var VFurnishedIn = document.getElementById("txtFurnishedIn").value;
    let IsInsert = 1;
    if (VFurnishedDetail == "") {
      IsInsert = 0;
      addToast("Please Enter Property Furniture", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (VFurnishedIn == "") {
      IsInsert = 0;
      addToast("Please Enter Property Furnished", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (FurnishedMediaName == "") {
      IsInsert = 0;
      addToast("Please Enter Property Furnished Media", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (IsInsert == 1) {
      const Fitem = {
        PId: LRNo,
        FurnishedDetail: VFurnishedDetail,
        FurnishedIn: VFurnishedIn,
        FurnishedFile: FurnishedMedia1,
        FurnishedDetailTextBoxControlId: "txtFurnishedDetail" + LRNo,
        FurnishedInTextBoxControlId: "txtFurnishedIn" + LRNo,
        FurnishedFileName: FurnishedMedia1.name,
      };
      setFurnishedList([...FurnishedList, Fitem]);
      document.getElementById("txtFurnishedDetail").value = "";
      document.getElementById("txtFurnishedIn").value = "";
      setFurnishedMedia1([]);
      setFurnishedMediaName("");
    }
  };

  const furnishedHandleRowDelete = FId => {
    const RId = FId;
    setFurnishedList(FurnishedList.filter(item => item.PId !== RId));
  };

  return (
    <React.Fragment>
      {ShowLoader == 1 ? (
        <div className="showhidedivloader">
          <div className="mainloadernew">
            <img src={loader} />
          </div>
        </div>
      ) : (
        ""
      )}
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row">
              <div className="col-md-12">
                <p className="addservicetextform">Add New Properties</p>
              </div>

              <div className="col-md-12">
                <p className="mainsteptextform">
                  Step 2 : Land Area & Insights
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="propertysteptwomainbox">
                  <div className="d-flex">
                    <div>
                      {PropertyImageList.length==0?<img src={defaulthomeimg}  className="mainpropertysliderimg" ></img>: <div
                        id="carouselExampleIndicators"
                        className="carousel slide propertyimgslider  propertyimgslidernewimgedit"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-indicators">
                          {PropertyImageList.map((item, idx) =>
                            idx == 0 ? (
                              <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to={idx}
                                className="active"
                                aria-current="true"
                                aria-label={item.SliderName}
                              ></button>
                            ) : (
                              <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to={idx}
                                aria-label={item.SliderName}
                              ></button>
                            )
                          )}
                          {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                          {PropertyImageList.map((item, idx) => (
                            <div className={item.SclassName} key={idx}>
                              <img
                                src={item.PropImageUrl}
                                className="mainpropertysliderimg"
                              />
                            </div>
                          ))}
                        </div>
                      </div>}
                     
                    </div>

                    <div className="w-100">
                      <div className="d-flex w-100">
                        <p className="mainpropertytitlelist">{PropertyName} </p>
                        <div>
                          <img
                            className="verifiedgreenstep2"
                            src={verifiedgreen}
                          />
                        </div>
                      </div>
                      <div>
                        <p className="propertycatageoried">{PropertyType}</p>
                      </div>
                      <div className="d-flex">
                        <div>
                          <img
                            src={locationpropertyedit}
                            className="locationpropertyedit"
                          />
                        </div>
                        <div>
                          <p className="propertytextlocation">{FullAddress}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="deletprpertyadd">
                      <a href="/request-new-service">
                        <i className="las la-trash-alt selectservicebackbutton"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row ">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="maintitlereuestservicenee">Land Area <span className="spanred"></span></p>
                      </div>
                      <div className="col-md-6 mb-3 mb-md-0">
                        <div className="registerinputnewedirr form-controlsustomeditnew">
                          <input
                            type="number"
                            className="form-control formcontrollplaceholder"
                            id="exampleFormControlInput1"
                            placeholder="Enter Land Area"
                            onChange={e => setLandArea(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <select
                          className="form-select form-selectnewwr form-select-lg mb-0"
                          aria-label=".form-select-lg example"
                          onChange={e => setLandAreaIn(e.target.value)}
                        >
                          <option value="Square feet" selected>
                            Square feet
                          </option>
                          <option value="CENTS">CENTS</option>
                          <option value="Square meter">Square meter</option>
                          <option value="Hectar">Hectare</option>
                          
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-12 ">
                        <p className="maintitlereuestservicenee">
                          Super Built Up <span className="spanred"></span>
                        </p>
                      </div>
                      <div className="col-md-6 mb-0 mb-3">
                        <div className="registerinputnewedirr form-controlsustomeditnew">
                          <input
                            type="number"
                            className="form-control formcontrollplaceholder"
                            id="exampleFormControlInput1"
                            placeholder="Enter"
                            onChange={e => setSuperBuiltUP(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <select
                          className="form-select form-selectnewwr form-select-lg mb-0"
                          aria-label=".form-select-lg example"
                          onChange={e => setSuperBuiltUpIn(e.target.value)}
                        >
                          <option value="Square feet" selected>
                            Square feet
                          </option>
                          <option value="CENTS">CENTS</option>
                          <option value="Square meter">Square meter</option>
                          <option value="Hectar">Hectare</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mainboxnewaddproperty mb-0">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="maintitlereuestservicenee">
                        Property Insight <span className="spanred"></span>
                      </p>
                    </div>
                    <div className="col-md-6 text-end mb-3 mb-md-0">
                      <a
                      onClick={()=>{insightsHandleAddRow(); setInsightMediaFlag(0)}}

                  
                        className="addnewpropertytextneww"
                      >
                        {" "}
                        <i className="las la-plus-circle"></i>Add New Property
                      </a>
                    </div>
                  </div>
                  {PropertyInsightsList.map((item, idx) => (
                    <div className="row mainaddnewinsightalldiv" key={idx}>
                      <div className="col-md-10">
                        <div className="row align-items-center ">
                          <div className="col-md-3 mb-3 mb-md-0">
                            <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                              <input
                                type="text"
                                className="form-control formcontrollplaceholder border-0"
                                id={item.InsightName1TextBoxControlId}
                                defaultValue={item.InsightName}
                                placeholder="write here"
                                readOnly="readonly"
                              />
                            </div>
                          </div>
                          <div className="col-md-2 mb-3 mb-md-0">
                            <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                              <input
                                type="number"
                                min={0}
                                className="form-control formcontrollplaceholder border-0"
                                id={item.InsightWidth1TextBoxControlId}
                                defaultValue={item.InsightWidth}
                                readOnly="readonly"
                              />
                              <div className="maininputdefulttext">
                                <p>W</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 mb-3 mb-md-0">
                            <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                              <input
                                type="number"
                                min={0}
                                className="form-control formcontrollplaceholder border-0"
                                id={item.InsightLength1TextBoxControlId}
                                defaultValue={item.InsightLength}
                                readOnly="readonly"
                              />
                              <div className="maininputdefulttext">
                                <p>L</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 mb-3 mb-md-0">
                            <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                              <input
                                type="number"
                                min={0}
                                className="form-control formcontrollplaceholder border-0"
                                id={item.InsightHeight1TextBoxControlId}
                                defaultValue={item.InsightHeight}
                                readOnly="readonly"
                              />
                              <div className="maininputdefulttext">
                                <p>H</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 mb-3 mb-md-0">
                            <div className="">
                              <input
                                type="file"
                                name="file"
                                id="file"
                                className="file"
                              />
                              <div className="mainfileuploadimgwith">
                                <input
                                  type="text"
                                  name="file-name"
                                  id="file-name"
                                  className="file-name border-0"
                                  readOnly="readonly"
                                  value={item.InsightFileName}
                                />
                                <div className="maindivdeletprpertyaddnewsmall">
                                  {/* <div className="deletprpertyaddnewsmall">
                                <i className="las la-trash-alt"></i>
                              </div> */}
                                </div>
                                <div className="filnamewithiconmain">
                                  <i className="las la-file"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 text-end">
                        <button
                          className="btn removerowbtnnew "
                          onClick={e => insightsHandleAddRowdelete(item.PId,item.InsightName)}
                        >
                          <img src={deletedash} className="deletedashimg"></img>
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="row mainaddnewinsightalldiv">
                    <div className="col-md-10">
                      <div className="row align-items-center ">
                        <div className="col-md-3 mb-3 mb-md-0">
                          <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                            <input
                              type="text"
                              className="form-control formcontrollplaceholder border-0"
                              id="txtInsightName1"
                              placeholder="write here"
                              onChange={handleInsightDd}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 mb-3 mb-md-0">
                          <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                            <input
                              type="number"
                              min={0}
                              className="form-control formcontrollplaceholder border-0"
                              id="txtInsightWidth1"
                            />
                            <div className="maininputdefulttext">
                              <p>W</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 mb-3 mb-md-0">
                          <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                            <input
                              type="number"
                              min={0}
                              className="form-control formcontrollplaceholder border-0"
                              id="txtInsightLength1"
                            />
                            <div className="maininputdefulttext">
                              <p>L</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 mb-3 mb-md-0">
                          <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                            <input
                              type="number"
                              min={0}
                              className="form-control formcontrollplaceholder border-0"
                              id="txtInsightHeight1"
                            />
                            <div className="maininputdefulttext">
                              <p>H</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3 mb-md-0">
                          <div className="">
                            <input
                              type="file"
                              name="file"
                              id="file"
                              className="file"
                            />
                            <div className="mainfileuploadimgwith">
                              <input
                                type="text"
                                name="file-name"
                                id="file-name"
                                className="file-name border-0"
                                defaultValue={InsightMediaName}
                                readOnly="readonly"
                              />
                              <a
                                onClick={e => {
                                  setInsightMedia1([]);
                                  setInsightMediaName("");
                              
                                }}
                              >
                                <div className="maindivdeletprpertyaddnewsmall">
                          {InsightMediaFlag==1?<div className="deletprpertyaddnewsmall">
                                    <i className="las la-trash-alt"></i>
                                  </div>:""}
                                </div>
                              </a>
                              <div className="filnamewithiconmain">
                                <i className="las la-file"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 text-end">
                      <button
                        type="button"
                        className="btn uploadbtnmainaddproperty"
                        id="upfile1"
                        onClick={getPropertyInsightsArray}
                      >
                        <i className="las la-cloud-upload-alt"></i>Upload Files{" "}
                      </button>
                      <input
                        type="file"
                        id="file1"
                        onChange={e => {
                          getInsightMedia(e);
                      
                        }}
                        className="d-none"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="maindivederaddpropertyinsight"></div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div>
                          <form>
                            <div className="form-group newwform-group">
                              <input
                                type="checkbox"
                                id="checkbox1"
                                onChange={e => {handleFurnishedCheckbox(e);insightImageHandle(e)}}
                              />
                              <label htmlFor="checkbox1">
                                is Property Furnished ?
                              </label>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div
                        className="col-md-6 text-end mb-3 mb-md-0"
                        id="addnewbutton"
                      >
                        <a
                          onClick={()=>{furnishedHandleAddRow();setFurnishedMediaFlag(0)}}
                          className="addnewpropertytextneww"
                        >
                          {" "}
                          <i className="las la-plus-circle"></i>Add New{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* {IsPropertyFurnished == "Yes" ?
                  <div> */}
                  {FurnishedList.map((item, idx) => (
                    <div
                      className="row mainaddnewinsightalldiv align-items-center"
                      key={idx}
                    >
                      <div className="col-md-12">
                        <div className="row align-items-center ">
                          <div className="col-md-3 mb-3 mb-md-0">
                            {/* <select className="form-select form-selectnewwr form-select-lg mb-0 border-0" aria-label=".form-select-lg example" id={item.FurnishedInTextBoxControlId} selected={item.FurnishedIn}>
                          <option selected>select</option>
                          {PropertyInsightsList.map((ditem, idx) => (
                            <option value={ditem.InsightName} key={idx} selected={ditem.InsightName === item.FurnishedIn}>{ditem.InsightName}</option>
                          ))}
                        </select> */}
                            <input
                              type="text"
                              className="form-control formcontrollplaceholder border-0"
                              id={item.FurnishedInTextBoxControlId}
                              defaultValue={item.FurnishedIn}
                              placeholder="write here property insight."
                            />
                          </div>
                          <div className="col-md-4 mb-3 mb-md-0">
                            <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                              <input
                                type="text"
                                className="form-control formcontrollplaceholder border-0"
                                id={item.FurnishedDetailTextBoxControlId}
                                defaultValue={item.FurnishedDetail}
                                placeholder="write here furnished title"
                              />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3 mb-md-0">
                            <div className="">
                              <input
                                type="file"
                                name="file"
                                id="file"
                                className="file"
                              />
                              <div className="mainfileuploadimgwith">
                                <input
                                  type="text"
                                  name="file-name"
                                  id="file-name"
                                  className="file-name border-0"
                                  readOnly="readonly"
                                  value={item.FurnishedFileName}
                                />
                                <div className="maindivdeletprpertyaddnewsmall">
                                  {/* <div className="deletprpertyaddnewsmall">
                                <i className="las la-trash-alt"></i>
                              </div> */}
                                </div>
                                <div className="filnamewithiconmain">
                                  <i className="las la-file"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 text-end">
                            <button
                              className="btn removerowbtnnew "
                              onClick={e => furnishedHandleRowDelete(item.PId)}
                            >
                              <img
                                src={deletedash}
                                className="deletedashimg"
                              ></img>
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div
                    className="row mainaddnewinsightalldiv align-items-center"
                    id="autoUpdate"
                  >
                    <div className="col-md-12">
                      <div className="row align-items-center ">
                        <div className="col-md-3 mb-3 mb-md-0">
                          <select
                            className="form-select form-selectnewwr form-select-lg mb-0 border-0"
                            aria-label=".form-select-lg example"
                            id="txtFurnishedIn"
                          >
                            <option label="Select" selected></option>
                            {PropInsightDdl.map((item, idx) => (
                              <option value={item.InsightName} key={idx}>
                                {item.InsightName}
                              </option>
                            ))}
                          </select>
                          {/* <input type="text" className="form-control formcontrollplaceholder border-0" id="txtFurnishedIn" placeholder="write here property insight." /> */}
                        </div>
                        <div className="col-md-4 mb-3 mb-md-0">
                          <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                            <input
                              type="text"
                              className="form-control formcontrollplaceholder border-0"
                              id="txtFurnishedDetail"
                              placeholder="write here furnished title"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 mb-3 mb-md-0">
                          <div className="">
                            <input
                              type="file"
                              name="file"
                              id="file"
                              className="file"
                            />
                            <div className="mainfileuploadimgwith">
                              <input
                                type="text"
                                name="file-name"
                                id="file-name"
                                className="file-name border-0"
                                defaultValue={FurnishedMediaName}
                                readOnly="readonly"
                              />
                              <div className="maindivdeletprpertyaddnewsmall">
                               { FurnishedMediaFlag==1?<div className="deletprpertyaddnewsmall">
                                  <a
                                    onClick={e => {
                                      setFurnishedMedia1([]);
                                      setFurnishedMediaName("");
                                    }}
                                  >
                                    <i className="las la-trash-alt"></i>
                                  </a>
                                </div>:""}
                              </div>
                              <div className="filnamewithiconmain">
                                <i className="las la-file"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 mb-3 mb-md-0 text-end">
                          <button
                            type="button"
                            className="btn uploadbtnmainaddproperty"
                            id="upfile2"
                          >
                            <i className="las la-cloud-upload-alt"></i>Upload
                            Files{" "}
                          </button>
                          <input
                            type="file"
                            id="file2"
                            onChange={e => {
                              setFurnishedMedia1(e.target.files[0]);
                              setFurnishedMediaName(e.target.files[0].name);
                              setFurnishedMediaFlag(1);
                            }}
                            className="d-none"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-2 text-end">
                    <a onClick={furnishedHandleAddRow} className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New </a>
                  </div> */}
                  </div>
                  {/* </div> : ''} */}
                </div>
              </div>
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-primary btnnextedit "
                  onClick={addLandAreaAndInsights}
                >
                  Next <i className="fas fa-long-arrow-alt-right"></i>
                </button>{" "}
                {/*</a> */}
                <a
                  href="/add-new-property2"
                  type="button"
                  className="btn btn-primary btnnexteditline"
                >
                  Cancel{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footeradmin />
    </React.Fragment>
  );
};

export default addPropertyPage2;

import React, { Component, useEffect, useState } from "react"
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import addanp from "../../assets/images/frontend-img/addanp.png";
import Commheader from "./header";
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import { set } from "lodash";
import Footeradmin from "./footeradmin";
import ReactDOM from "react-dom";


const propertydetail = () => {
  const { addToast } = useToasts();
  const hiddenFileInput = React.useRef(null);
  const [OtherPropertyType, setOtherPropertyType] = useState('');
  const [PropertyTitle, setPropertyTitle] = useState('');
  const [OtherPropertyTypeTitle, setOtherPropertyTypeTitle] = useState('');
  const [PropertyTypeId, setPropertyTypeId] = useState('0');
  const [PropertyType, setPropertyType] = useState('0');
  const [PropertyTypeList, setPropertyTypeList] = useState([]);
  const [PropertyMediaList, setPropertyMediaList] = useState([]);
  const [PropertyNumber, setPropertyNumber] = useState('');
  const [NearByPlace, setNearByPlace] = useState('');
  const [Street, setStreet] = useState('');
  const [Pincode, setPincode] = useState('');
  const [CountryList, setCountryList] = useState([]);
  const [StateFilterList, setStateFilterList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityFilterList, setCityFilterList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [CountryId, setCountryId] = useState(0);
  const [StateId, setStateId] = useState(0);
  const [CityId, setCityId] = useState(0);
  const [FullAddress, setFullAddress] = useState([]);

  const [LandArea, setLandArea] = useState('');
  const [LandAreaIn, setLandAreaIn] = useState('');
  const [SuperBuiltUP, setSuperBuiltUP] = useState('');
  const [SuperBuiltUpIn, setSuperBuiltUpIn] = useState('');

  const [PropertyInsightList, setPropertyInsightList] = useState([]);
  const [PropertyFurnishedList, setPropertyFurnishedList] = useState([]);
  const [PropertyDocumentList, setPropertyDocumentList] = useState([]);

  const [IsPropertyFurnished, setIsPropertyFurnished] = useState("No");
  const [InsightMedia1, setInsightMedia1] = useState([]);
  const [InsightMediaName, setInsightMediaName] = useState('');
  const [FurnishedMedia1, setFurnishedMedia1] = useState([]);
  const [FurnishedMediaName, setFurnishedMediaName] = useState('');
  const [DocumentMedia1, setDocumentMedia1] = useState([]);
  const [DocumentMediaName, setDocumentMediaName] = useState('');

  const [UpdatePropertyId, setUpdatePropertyId] = useState('0');




  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      getCountryList();
      getPriorityDetail();


    }
  }, []);

  const handleFurnishedCheckbox = (e) => {
    var checked = e.target.checked;
    if (checked) {
      setIsPropertyFurnished("Yes")
    }
    else if (!checked) {
      setIsPropertyFurnished("No")
    }
  }

  const getPropertyType = async () => {
    axios.get(global.APIURL + '/PropertyType')
      .then(res => {
        setPropertyTypeList(res.data.Result);
      });
  }

  function handleChange(e) {
    let lastMedId = PropertyMediaList.length == 0 ? 0 : PropertyMediaList[PropertyMediaList.length - 1].PId;
    const PropertyMediaArr = {
      PId: lastMedId + 1,
      MediaFile: e.target.files[0],
      MediaFileUrl: URL.createObjectURL(e.target.files[0])
    }
    setPropertyMediaList([...PropertyMediaList, PropertyMediaArr]);
  }

  const getPriorityDetail = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let VPropertyId = localStorage.getItem('DetailPropertyId');
    const PropListData = {
      RegisterId: VRegisterId,
      PropertyId: VPropertyId
    }
    axios.post(global.APIURL + '/propertyDetail', PropListData)
      .then(res => {
        if (res.data.Result != '') {
          setPropertyTitle(res.data.Result[0].PropertyTitle);
          setPropertyTypeId(res.data.Result[0].PropertyTypeID);
          setPropertyType(res.data.Result[0].PropertyTypeName);
          getPropertyType();
          setOtherPropertyType(res.data.Result[0].OtherPropertyType == null ? '' : res.data.Result[0].OtherPropertyType == '' ? '' : 'Other');
          setOtherPropertyTypeTitle(res.data.Result[0].OtherPropertyType == null ? '' : res.data.Result[0].OtherPropertyType);
          setPropertyNumber(res.data.Result[0].PropertyNumber == null ? '' : res.data.Result[0].PropertyNumber);
          setNearByPlace(res.data.Result[0].NearByPlace == null ? '' : res.data.Result[0].NearByPlace);
          setStreet(res.data.Result[0].Street == null ? '' : res.data.Result[0].Street);
          setPincode(res.data.Result[0].Pincode == null ? '' : res.data.Result[0].Pincode);
          setFullAddress(res.data.Result[0].FullAddress == null ? '' : res.data.Result[0].FullAddress);
          setCountryId(res.data.Result[0].Country == null ? 0 : res.data.Result[0].Country == '' ? 0 : res.data.Result[0].Country);
          var CountryId = res.data.Result[0].Country == null ? 0 : res.data.Result[0].Country == '' ? 0 : res.data.Result[0].Country;
          var VStateName = res.data.Result[0].StateName == null ? '' : res.data.Result[0].StateName;
          var SF = StateFilterList.filter(x => x.StateName == VStateName);
          var SId = SF.length == 0 ? 0 : SF[0].StateId == null ? 0 : SF[0].StateId == '' ? 0 : SF[0].StateId;
          setStateId(SId);
          setCityId(res.data.Result[0].City == null ? 0 : res.data.Result[0].City == '' ? 0 : res.data.Result[0].City);
          StateList.length = 0;
          for (let sd = 0; sd < StateFilterList.length; sd++) {
            if (StateFilterList[sd].CountryId == CountryId) {
              const Stateitem = {
                CountryId: StateFilterList[sd].CountryId,
                StateId: StateFilterList[sd].StateId,
                StateName: StateFilterList[sd].StateName
              };
              StateList.push(Stateitem);
            }
          }

          for (let sd = 0; sd < CityFilterList.length; sd++) {
            if (CityFilterList[sd].StateId == SId) {
              const Cityitem = {
                StateId: CityFilterList[sd].StateId,
                CityId: CityFilterList[sd].CityId,
                CityName: CityFilterList[sd].CityName
              };
              CityList.push(Cityitem);
            }
          }

          setLandArea(res.data.Result[0].LandArea);
          setLandAreaIn(res.data.Result[0].LandAreaIn);
          setSuperBuiltUP(res.data.Result[0].SuperBuiltUP);
          setSuperBuiltUpIn(res.data.Result[0].SuperBuiltUpIn);
          setUpdatePropertyId(res.data.Result[0].UpdateId);
          const ProMediaArr = res.data.Result[0].Media;
          const pra = [];

          for (let pm = 0; pm < ProMediaArr.length; pm++) {
            const VMediaFileUrl = ProMediaArr[pm].Url;
            const PropertyMediaArr = {
              PId: pm + 1,
              MediaFile: '',
              MediaFileUrl: VMediaFileUrl
            }
            pra.push(PropertyMediaArr);
            //setPropertyMediaList([...PropertyMediaList, PropertyMediaArr]);
          }
          setPropertyMediaList(pra);
          const ProInsightArr = res.data.Result[0].PropertyInsight;
          const PIArr = [];
          for (let pm = 0; pm < ProInsightArr.length; pm++) {
            let Vim = ProInsightArr[pm].InsightMedia;
            let VimArr = Vim.split("/");
            let IMFileName = VimArr[VimArr.length - 1];
            const PropertyMediaArr = {
              Id: ProInsightArr[pm].Id,
              InsightName: ProInsightArr[pm].InsightName,
              InsightWidth: ProInsightArr[pm].InsightWidth,
              InsightLength: ProInsightArr[pm].InsightLength,
              InsightHeight: ProInsightArr[pm].InsightHeight,
              InsightMediaName: IMFileName,
              InsightMedia: ProInsightArr[pm].InsightMedia,

              InsightNameId: 'txtInsightName' + ProInsightArr[pm].Id,
              InsightWidthId: 'txtInsightWidth' + ProInsightArr[pm].Id,
              InsightLengthId: 'txtInsightLength' + ProInsightArr[pm].Id,
              InsightHeightId: 'txtInsightHeight' + ProInsightArr[pm].Id,
              InsightMediaNameId: 'txtInsightMediaName' + ProInsightArr[pm].Id,

              RecordType: 'O'
            }
            PIArr.push(PropertyMediaArr);
          }
          setPropertyInsightList(PIArr);

          const ProFurnishedArr = res.data.Result[0].PropertyFurnished;
          const PFArr = [];
          for (let pm = 0; pm < ProFurnishedArr.length; pm++) {
            let Vim = ProFurnishedArr[pm].FurnishedMedia;
            let VimArr = Vim.split("/");
            let IMFileName = VimArr[VimArr.length - 1];
            const PropertyFurnishedArr = {
              FurnishedId: ProFurnishedArr[pm].FurnishedId,
              FurnishedDetails: ProFurnishedArr[pm].FurnishedDetails,
              PropertyFurnishedIn: ProFurnishedArr[pm].PropertyFurnishedIn,
              FurnishedMedia: IMFileName,
              FurnishedDetailsId: 'txtFurnishedDetails' + (pm + 1),
              FurnishedInId: 'txtFurnishedIn' + (pm + 1),
              RecordType: 'O'
            }
            PFArr.push(PropertyFurnishedArr);
          }
          setPropertyFurnishedList(PFArr);

          const ProDocumentsArr = res.data.Result[0].Document;
          const PDArr = [];
          for (let pm = 0; pm < ProDocumentsArr.length; pm++) {
            let RowIndex = pm + 1;
            let Vim = ProDocumentsArr[pm].DocumentMedia;
            let VimArr = Vim.split("/");
            let IMFileName = VimArr[VimArr.length - 1];
            const PropertyDocumentArr = {
              DocumentId: ProDocumentsArr[pm].DocumentId,
              DocumentName: ProDocumentsArr[pm].DocumentName,
              DocumentMedia: [],
              DocumentNameId: 'txtDocumentName' + RowIndex,
              DocumentFileName: IMFileName,
              RecordType: 'O'
            }
            PDArr.push(PropertyDocumentArr);
          }
          setPropertyDocumentList(PDArr);
        }
      });
  }

  const getCountryList = () => {
    CountryList.length = 0;
    StateFilterList.length = 0;
    CityFilterList.length = 0;
    var AUrl = global.APIURL + '/CountryStateCity';
    axios.get(AUrl)
      .then(res => {
        const cl = res.data.Result[0].Country;
        var CLA = [];
        var SLA = [];
        var CTA = [];
        for (let cd = 0; cd < cl.length; cd++) {
          const Countyitem = {
            CountryId: cl[cd].Id,
            CountryName: cl[cd].CountryName
          };
          CountryList.push(Countyitem);
          const Sl = cl[cd].State;

          for (let sd = 0; sd < Sl.length; sd++) {
            const StateId = Sl[sd].Id;

            const Stateitem = {
              CountryId: cl[cd].Id,
              StateId: Sl[sd].Id,
              StateName: Sl[sd].StateName
            };
            StateFilterList.push(Stateitem);

            const CityList = Sl[sd].City;
            for (let ctd = 0; ctd < CityList.length; ctd++) {
              const Cityitem = {
                StateId: StateId,
                CityId: CityList[ctd].Id,
                CityName: CityList[ctd].CityName
              };
              CityFilterList.push(Cityitem);
            }
          }
        }
      });
  }

  const getCountryChange = (CountryId) => {
    var SF = StateFilterList.filter(x => x.CountryId == CountryId);
    setStateList(SF);
  }

  const getStateChange = (StateId) => {
    var CF = CityFilterList.filter(x => x.StateId == StateId);
    setCityList(CF);
  }

  const UpdatePropertyDetail = () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let VPropertyId = localStorage.getItem('DetailPropertyId');
    const formData = new FormData();

    formData.append('RegisterId', VRegisterId);
    formData.append('Action', "Edit");
    formData.append('PropertyId', VPropertyId);

    formData.append('PropertyTypeID', PropertyTypeId);
    formData.append('OtherPropertyType', OtherPropertyType);
    formData.append('PropertyTitle', PropertyTitle);
    formData.append('PropertyNumber', PropertyNumber);
    formData.append('NearByPlace', NearByPlace);
    formData.append('Street', Street);
    formData.append('Pincode', Pincode);
    formData.append('City', CityId);
    formData.append('State', StateId);
    formData.append('Country', CountryId);
    formData.append('FullAddress', FullAddress);
    formData.append('Latitude', "");
    formData.append('Longitude', "");
    let TMCount = 0
    for (let di = 0; di < PropertyMediaList.length; di++) {

      let PMF = PropertyMediaList[di].MediaFile;
      if (PMF != null) {
        if (PMF != '') {
          let RCount = TMCount + 1;
          let VMedia = PropertyMediaList[di].MediaFile;
          let CM = 'Media' + RCount;
          formData.append(CM, VMedia);
          TMCount++;
        }
      }
    }

    if (TMCount > 0) {
      formData.append('TotalMediaCount', PropertyMediaList.length);
    }




    axios.post(global.APIURL + '/PropertyAndLocationAdd', formData)
      .then(res => {

      });

    var VInsightName = document.getElementById('txtInsightName').value;
    var VInsightWidth = document.getElementById('txtInsightWidth').value;
    var VInsightLength = document.getElementById("txtInsightLength").value;
    var VInsightHeight = document.getElementById("txtInsightHeight").value;

    if (PropertyInsightList.length == 0) {
      const formData = new FormData();
      let IsPropertyInsightsInsert = 1;
      if (VInsightName == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (VInsightWidth == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (VInsightLength == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (VInsightHeight == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (InsightMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {
        var LRNo = PropertyInsightList.length + 1;
        formData.append('PropertyId', VPropertyId);
        formData.append('PropertyLandareaInsightsId', UpdatePropertyId);
        formData.append('PropertyInsightCount', LRNo);
        let INCN = 'InsightName' + LRNo;
        formData.append(INCN, VInsightName);

        let IWCN = 'InsightWidth' + LRNo;
        formData.append(IWCN, VInsightWidth);

        let ILCN = 'InsightLength' + LRNo;
        formData.append(ILCN, VInsightLength);

        let IHCN = 'InsightHeight' + LRNo;
        formData.append(IHCN, VInsightHeight);

        let IMCN = 'InsightMedia' + LRNo;
        formData.append(IMCN, InsightMedia1);

        axios.post(global.APIURL + '/EditPropertyDetail', formData).then(res => { });
      }
      else {
        addToast("Please add property insights detail.", { appearance: 'error', autoDismiss: true });
      }
    }
    else {

      let IsPropertyInsightsInsert = 1;
      if (VInsightName == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (VInsightWidth == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (VInsightLength == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (VInsightHeight == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (InsightMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {

        var LRNo = PropertyInsightList.length + 1;
        var piarr = [];
        const Pitem = {
          Id: LRNo,
          InsightName: VInsightName,
          InsightWidth: VInsightWidth,
          InsightLength: VInsightLength,
          InsightHeight: VInsightHeight,
          InsightMediaName: InsightMediaName,
          InsightMedia: InsightMedia1,
          InsightNameId: 'txtInsightName' + LRNo,
          InsightWidthId: 'txtInsightWidth' + LRNo,
          InsightLengthId: 'txtInsightLength' + LRNo,
          InsightHeightId: 'txtInsightHeight' + LRNo,
          InsightMediaNameId: 'txtInsightMediaName' + LRNo,
          RecordType: 'N'
        }
        PropertyInsightList.push(Pitem);

        if (PropertyInsightList.length > 0) {
          let ProInsCount = PropertyInsightList.length - 1;
          const formData = new FormData();
          for (let pi = 0; pi < PropertyInsightList.length; pi++) {
            let PICount = pi + 1;
            let VRecordType = PropertyInsightList[pi].RecordType;
            formData.append('PropertyId', VPropertyId);
            formData.append('PropertyLandareaInsightsId', UpdatePropertyId);
            formData.append('PropertyInsightCount', PICount);

            let INCN = 'InsightName' + PICount;
            let INId = 'txtInsightName' + PICount;

            if (VRecordType == 'O') {
              let INValue = document.getElementById(INId).value;
              formData.append(INCN, INValue);
            }
            else {
              formData.append(INCN, PropertyInsightList[pi].InsightName);
            }

            let IWCN = 'InsightWidth' + PICount;
            let IWId = 'txtInsightWidth' + PICount;

            if (VRecordType == 'O') {
              let IWValue = document.getElementById(IWId).value;
              formData.append(IWCN, IWValue);
            }
            else {
              formData.append(IWCN, PropertyInsightList[pi].InsightWidth);
            }

            let ILCN = 'InsightLength' + PICount;
            let ILId = 'txtInsightLength' + PICount;

            if (VRecordType == 'O') {
              let ILValue = document.getElementById(ILId).value;
              formData.append(ILCN, ILValue);
            }
            else {
              formData.append(ILCN, PropertyInsightList[pi].InsightLength);
            }

            let IHCN = 'InsightHeight' + PICount;
            let IHId = 'txtInsightHeight' + PICount;
            if (VRecordType == 'O') {
              let IHValue = document.getElementById(IHId).value;
              formData.append(IHCN, IHValue);
            }
            else {
              formData.append(IHCN, PropertyInsightList[pi].InsightHeight);
            }

            let IMCN = 'InsightMedia' + PICount;
            if (VRecordType == "O") {
              let src = PropertyInsightList[pi].InsightMedia;
              const file = blobCreationFromURL(src);
              formData.append(IMCN, file);
            }
            else if (VRecordType == "N") {

              formData.append(IMCN, PropertyInsightList[pi].InsightMedia);
            }
            else {
              //formData.append(IMCN, []);
            }

            axios.post(global.APIURL + '/EditPropertyDetail', formData).then(res => { });
          }
        }
      }
    }

    var VFurnishedDetails = document.getElementById('txtFurnishedDetails').value;
    var VFurnishedIn = document.getElementById('txtFurnishedIn').value;

    if (PropertyFurnishedList.length == 0) {
      const formData = new FormData();
      let IsPropertyInsightsInsert = 1;
      if (VFurnishedDetails == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (VFurnishedIn == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (FurnishedMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {
        var LRNo = PropertyFurnishedList.length + 1;

        formData.append('PropertyId', VPropertyId);
        formData.append('PropertyLandareaInsightsId', UpdatePropertyId);
        formData.append('FurnishedCount', LRNo);

        let INCN = 'PropertyFurnishedDetail' + LRNo;
        let INValue = document.getElementById('txtFurnishedDetails').value;
        formData.append(INCN, INValue);

        let IWCN = 'PropertyFurnishedIn' + LRNo;
        let IWValue = document.getElementById('txtFurnishedIn').value;
        formData.append(IWCN, IWValue);

        let IMCN = 'FurnishedMedia' + PICount;
        formData.append(IMCN, FurnishedMedia1);


        axios.post(global.APIURL + '/FurnishedEditData', formData)
          .then(res => {
            if (res.data.Status == true) {
              addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            }
            else {
              addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
            }
          });
      }
      else {
        addToast("Please add property furnished detail.", { appearance: 'error', autoDismiss: true });
      }
    }
    else {

      let IsPropertyInsightsInsert = 1;
      if (VFurnishedDetails == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (VFurnishedIn == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (FurnishedMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {
        var LRNo = PropertyFurnishedList.length + 1;
        const Pitem = {
          FurnishedId: LRNo,
          FurnishedDetails: VFurnishedDetails,
          PropertyFurnishedIn: VFurnishedIn,
          FurnishedMedia: FurnishedMedia1,
          FurnishedDetailsId: 'txtFurnishedDetails' + LRNo,
          FurnishedInId: 'txtFurnishedIn' + LRNo,
          RecordType: 'N'
        }
        PropertyFurnishedList.push(Pitem);
      }

      for (let pi = 0; pi < PropertyFurnishedList.length; pi++) {

        let PICount = pi + 1;
        let VRecordType = PropertyFurnishedList[pi].RecordType;
        const formData = new FormData();
        formData.append('PropertyId', VPropertyId);
        formData.append('PropertyLandareaInsightsId', UpdatePropertyId);
        formData.append('FurnishedCount', PropertyFurnishedList.length);

        let INCN = 'PropertyFurnishedDetail' + PICount;
        if (VRecordType == "O") {
          let INId = 'txtFurnishedDetails' + PICount;
          let INValue = document.getElementById(INId).value;
          formData.append(INCN, INValue);
        }
        else
          if (VRecordType == "N") {
            formData.append(INCN, PropertyFurnishedList[pi].FurnishedDetails);
          }

        let IWCN = 'PropertyFurnishedIn' + PICount;
        if (VRecordType == "O") {
          let IWId = 'txtFurnishedIn' + PICount;
          let IWValue = document.getElementById(IWId).value;
          formData.append(IWCN, IWValue);
        }
        else if (VRecordType == "N") {
          formData.append(IWCN, PropertyFurnishedList[pi].PropertyFurnishedIn);
        }


        let IMCN = 'FurnishedMedia' + PICount;
        // if (VRecordType == "O") {
        //   formData.append(IMCN, []);
        // }
        // else 
        if (VRecordType == "N") {
          formData.append(IMCN, PropertyFurnishedList[pi].FurnishedMedia);
        }

        axios.post(global.APIURL + '/FurnishedEditData', formData)
          .then(res => {
            // if (res.data.Status == true) {
            //   addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            // }
            // else {
            //   addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
            // }
          });
      }

    }

    var VDocumentName = document.getElementById('txtDocumentName').value;

    if (PropertyDocumentList.length == 0) {
      const formData = new FormData();
      let IsPropertyInsightsInsert = 1;
      if (VDocumentName == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (DocumentMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {
        var LRNo = PropertyDocumentList.length + 1;
        let CD = 'DocumentName' + LRNo;
        let CM = 'DocumentMedia' + LRNo;

        formData.append('RegisterId', VRegisterId);
        formData.append('Action', "Add");
        formData.append('PropertyId', VPropertyId);

        formData.append(CD, VDocumentName);
        formData.append(CM, DocumentMedia1);
        formData.append('TotalMediaCount', 1);

        axios.post(global.APIURL + '/PropertyDocuments', formData)
          .then(res => {
            if (res.data.Status == true) {
              addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            }
            else {
              addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
            }
          });
      }
      else {
        addToast("Please add property document detail.", { appearance: 'error', autoDismiss: true });
      }
    }
    else {
      let IsPropertyInsightsInsert = 1;
      if (VDocumentName == "") {
        IsPropertyInsightsInsert = 0;
      }
      else if (DocumentMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {
        var LRNo = PropertyDocumentList.length + 1;
        const Pitem = {
          DocumentId: 0,
          DocumentName: VDocumentName,
          DocumentMedia: DocumentMedia1,
          DocumentNameId: 'txtDocumentName' + LRNo,
          RecordType: 'N'
        }
        PropertyDocumentList.push(Pitem);
      }

      for (let pi = 0; pi < PropertyDocumentList.length; pi++) {

        let RowIndex = pi + 1;
        let EditDocumentId = PropertyDocumentList[0].DocumentId;
        let VRecordType = PropertyDocumentList[pi].RecordType;
        const formData = new FormData();
        formData.append('PropertyId', VPropertyId);
        formData.append('DocumentId', EditDocumentId);
        formData.append('DocumentCount', PropertyDocumentList.length);

        let INCN = 'DocumentName' + RowIndex;
        if (VRecordType == "O") {
          let INId = 'txtDocumentName' + RowIndex;
          let INValue = document.getElementById(INId).value;
          formData.append(INCN, INValue);
        }
        else if (VRecordType == "N") {
          formData.append(INCN, PropertyDocumentList[pi].DocumentName);
        }

        let IMCN = 'DocumentMedia' + RowIndex;
        // if (VRecordType == "O") {
        //   formData.append(IMCN, []);
        // }
        // else 
        if (VRecordType == "N") {
          formData.append(IMCN, PropertyDocumentList[pi].DocumentMedia);
        }

        axios.post(global.APIURL + '/DocumentEdit', formData)
          .then(res => {
            // if (res.data.Status == true) {
            //   addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            // }
            // else {
            //   addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
            // }
          });
      }
    }

    addToast("Property detail update successfully.", { appearance: 'success', autoDismiss: true });
    window.location.href = "/property-list";
  }

  function blobCreationFromURL(inputURI) {

    var binaryVal;

    // mime extension extraction
    var inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0];

    // Extract remaining part of URL and convert it to binary value
    if (inputURI.split(',')[0].indexOf('base64') >= 0)
      binaryVal = atob(inputURI.split(',')[1]);

    // Decoding of base64 encoded string
    else
      binaryVal = unescape(inputURI.split(',')[1]);

    // Computation of new string in which hexadecimal
    // escape sequences are replaced by the character 
    // it represents

    // Store the bytes of the string to a typed array
    var blobArray = [];
    for (var index = 0; index < binaryVal.length; index++) {
      blobArray.push(binaryVal.charCodeAt(index));
    }

    return new Blob([blobArray], {
      type: inputMIME
    });
  }


  // const UpdatePropertyInsight = () => {

  //   var VPropertyId = localStorage.getItem('DetailPropertyId');


  // }

  // const UpdatePropertyFurnished = () => {

  //   var VPropertyId = localStorage.getItem('DetailPropertyId');


  // }

  // const UpdatePropertyDocument = () => {

  //   var VPropertyId = localStorage.getItem('DetailPropertyId');
  //   let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));

  // }

  const PropertyInsightAddRow = () => {

    var LRNo = parseInt(PropertyInsightList.length == 0 ? 1 : (PropertyInsightList[PropertyInsightList.length - 1].Id) + 1);
    var VInsightName1 = document.getElementById('txtInsightName').value;
    var VInsightWidth1 = document.getElementById('txtInsightWidth').value;
    var VInsightLength1 = document.getElementById("txtInsightLength").value;
    var VInsightHeight1 = document.getElementById("txtInsightHeight").value;
    let IsInsert = 1;
    if (VInsightName1 == '') {
      IsInsert = 0;
      addToast("Please input insight name.", { appearance: 'error', autoDismiss: true });
    }
    else if (VInsightWidth1 == '') {
      IsInsert = 0;
      addToast("Please input insight width.", { appearance: 'error', autoDismiss: true });
    }
    else if (VInsightLength1 == '') {
      IsInsert = 0;
      addToast("Please input insight length.", { appearance: 'error', autoDismiss: true });
    }
    else if (VInsightHeight1 == '') {
      IsInsert = 0;
      addToast("Please input insight height.", { appearance: 'error', autoDismiss: true });
    }
    else if (InsightMediaName == '') {
      IsInsert = 0;
      addToast("Please upload insight media file.", { appearance: 'error', autoDismiss: true });
    }
    if (IsInsert == 1) {
      const Pitem = {
        Id: LRNo,
        InsightName: VInsightName1,
        InsightWidth: VInsightWidth1,
        InsightLength: VInsightLength1,
        InsightHeight: VInsightHeight1,
        InsightMediaName: InsightMedia1.name,
        InsightMedia: InsightMedia1,
        InsightNameId: 'txtInsightName' + LRNo,
        InsightWidthId: 'txtInsightWidth' + LRNo,
        InsightLengthId: 'txtInsightLength' + LRNo,
        InsightHeightId: 'txtInsightHeight' + LRNo,
        InsightMediaNameId: 'txtInsightMediaName' + LRNo,
        RecordType: 'N'
      }
      setPropertyInsightList([...PropertyInsightList, Pitem]);
      document.getElementById('txtInsightName').value = '';
      document.getElementById('txtInsightWidth').value = '';
      document.getElementById('txtInsightLength').value = '';
      document.getElementById('txtInsightHeight').value = '';
      setInsightMedia1([]);
      setInsightMediaName('')
    }

  }

  const PropertyInsightRowdelete = (Id) => {
    const RId = Id;
    const formData = new FormData();
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let VPropertyId = localStorage.getItem('DetailPropertyId');

    formData.append('RegisterId', VRegisterId);
    formData.append('Action', "Delete");
    formData.append('PropertyId', VPropertyId);
    formData.append('LandAreaInsightsId', Id);

    // axios.post(global.APIURL + '/addEditDeleteLandAreaAndInsights', formData)
    //     .then(res => {
    //       if (res.data.Status == true) {
    //         alert(res.data.StatusMessage);
    //         setPropertyInsightList(PropertyInsightList.filter(item => item.Id !== RId));
    //       }
    //       else {
    //         alert(res.data.StatusMessage)
    //       }
    //     });

    setPropertyInsightList(PropertyInsightList.filter(item => item.Id !== RId));

  }

  const PropertyFurnishedAddRow = () => {

    var LRNo = PropertyFurnishedList.length == 0 ? 1 : (PropertyFurnishedList[PropertyFurnishedList.length - 1].FurnishedId + 1);
    var VFurnishedDetails = document.getElementById('txtFurnishedDetails').value;
    var VFurnishedIn = document.getElementById('txtFurnishedIn').value;
    let IsInsert = 1;
    if (VFurnishedDetails == '') {
      IsInsert = 0;
      addToast("Please input furnished details.", { appearance: 'error', autoDismiss: true });
    }
    else if (VFurnishedIn == '') {
      IsInsert = 0;
      addToast("Please input furnished in.", { appearance: 'error', autoDismiss: true });
    }
    else if (FurnishedMediaName == '') {
      IsInsert = 0;
      addToast("Please upload insight media file.", { appearance: 'error', autoDismiss: true });
    }
    if (IsInsert == 1) {
      const Pitem = {
        FurnishedId: LRNo,
        FurnishedDetails: VFurnishedDetails,
        PropertyFurnishedIn: VFurnishedIn,
        FurnishedMedia: FurnishedMedia1,
        FurnishedDetailsId: 'txtFurnishedDetails' + ProInsightArr[pm].Id,
        FurnishedInId: 'txtFurnishedIn' + ProInsightArr[pm].Id,
        RecordType: 'N'
      }
      setPropertyFurnishedList([...PropertyFurnishedList, Pitem]);
      document.getElementById('txtFurnishedDetails').value = '';
      document.getElementById('txtFurnishedIn').value = '';
      setFurnishedMedia1([]);
      setFurnishedMediaName('')
    }

  }

  const PropertyDocumentAddRow = () => {

    var LRNo = PropertyDocumentList.length + 1;
    var VDocumentName = document.getElementById('txtDocumentName').value;
    let IsInsert = 1;
    if (VDocumentName == '') {
      IsInsert = 0;
      addToast("Please input document name.", { appearance: 'error', autoDismiss: true });
    }
    else if (DocumentMediaName == '') {
      IsInsert = 0;
      addToast("Please upload document file.", { appearance: 'error', autoDismiss: true });
    }
    if (IsInsert == 1) {
      let DocLastId = PropertyDocumentList[PropertyDocumentList.length - 1].DocumentId + 1;
      const Pitem = {
        DocumentId: DocLastId,
        DocumentName: VDocumentName,
        DocumentMedia: DocumentMedia1,
        DocumentNameId: 'txtDocumentName' + DocLastId,
        DocumentFileName: DocumentMediaName,
        RecordType: 'N'
      }
      setPropertyDocumentList([...PropertyDocumentList, Pitem]);
      document.getElementById('txtDocumentName').value = '';
      setDocumentMedia1([]);
      setDocumentMediaName('')
    }

  }

  const PropertyFurnishedRowdelete = (FurnishedId) => {
    const RId = FurnishedId;
    const formData = new FormData();
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let VPropertyId = localStorage.getItem('DetailPropertyId');

    formData.append('RegisterId', VRegisterId);
    formData.append('Action', "Delete");
    formData.append('PropertyId', VPropertyId);
    formData.append('LandAreaInsightsId', RId);

    // axios.post(global.APIURL + '/addEditDeleteLandAreaAndInsights', formData)
    //     .then(res => {
    //       if (res.data.Status == true) {
    //         alert(res.data.StatusMessage);
    //         setPropertyFurnishedList(PropertyFurnishedList.filter(item => item.FurnishedId !== RId));
    //       }
    //       else {
    //         alert(res.data.StatusMessage)
    //       }
    //     });

    setPropertyFurnishedList(PropertyFurnishedList.filter(item => item.FurnishedId !== RId));

  }

  const PropertUpdateDelete = (PId) => {
    const RId = PId;
    setPropertyMediaList(PropertyMediaList.filter(item => item.PId !== RId));
  }

  const fileUploadhandleChange = (event, Id) => {
    console.log(Id);
    var RId = parseInt(Id) - 1;
    PropertyInsightList[RId]['InsightMediaName'] = event.target.files[0].name;
    PropertyInsightList[RId]['InsightMedia'] = event.target.files[0];
    PropertyInsightList[RId]['RecordType'] = 'U';
    let InsightMediaNameId = "txtInsightMediaName" + Id;
    document.getElementById(InsightMediaNameId).value = event.target.files[0].name;
    console.log(PropertyInsightList);
  };

  const fileUploadhandleRemove = (Id) => {
    console.log(Id);
    var RId = parseInt(Id) + 1;
    PropertyInsightList[Id]['InsightMediaName'] = '';
    PropertyInsightList[Id]['InsightMedia'] = [];
    PropertyInsightList[Id]['RecordType'] = 'D';
    let InsightMediaNameId = "txtInsightMediaName" + RId;
    document.getElementById(InsightMediaNameId).value = '';
  };

  const fileUploadhandleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <React.Fragment>
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row mainitalpropertylist">
              <div className="col-md-12">
                <p className="addservicetextform">Property Details</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mainpropertylistbox">
                  <div className="">

                    <div className="row">
                      <div className="col-md-12">
                        <div className="">
                          <div className="row align-items-center">
                            <div className="col-md-7">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Property Title </p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Property Title" defaultValue={PropertyTitle} onChange={(e) => setPropertyTitle(e.target.value)} />
                                  </div>

                                </div>

                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="mainredioboxeditap">
                                    <p className="maintitlereuestservicenee">Property Type</p>
                                    <div className="d-flex align-items-center">
                                      <div>
                                        {PropertyTypeList.map((propItems) =>
                                          <div className="form-check form-check-inline" key={propItems.Id}>
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id={propItems.Id} defaultChecked={propItems.PropertyType === PropertyType} value={propItems.Id} onChange={(e) => { setPropertyTypeId(e.target.value); setOtherPropertyType(e.target.value) }} />
                                            <label className="form-check-label commonblackcolor" htmlFor={propItems.Id}>{propItems.PropertyType}</label>
                                          </div>
                                        )}
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4" onChange={(e) => setOtherPropertyType('Other')} />
                                          <label className="form-check-label commonblackcolor" htmlFor="inlineRadio4">Other</label>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="registerinputnewedirr form-controlsustomeditnew">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {OtherPropertyType == 'Other' ?
                                <div className="row mt-2">
                                  <div className="col-md-6">
                                    <div className="registerinputnewedirr form-controlsustomeditnew">
                                      <p className="maintitlereuestservicenee">Other Property Type </p>
                                      <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setOtherPropertyTypeTitle(e.target.value)} placeholder="Write Here" defaultValue={OtherPropertyTypeTitle} />
                                    </div>
                                  </div>
                                </div>
                                : ''}
                            </div>
                            <div className="col-md-5">
                              <div className="row mainsideanpimgdiv">

                                {PropertyMediaList.map((item, idx) => (
                                  <div className="col-6 col-md-4" key={idx}>
                                    <div className="mainanpimg">
                                      <img src={item.MediaFileUrl}></img>
                                      <div className="maindivdeletprpertyaddnewsmall2pd">
                                        <div className="deletprpertyaddnewsmall2pd">
                                          <a onClick={e => PropertUpdateDelete(item.PId)}><i className="las la-trash-alt"></i></a>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                ))}

                                {PropertyMediaList.length < 6 ?
                                  <div className="col-6 col-md-4">
                                    <div className="mainanpimg">
                                      <a id="btnUploadPropertyDetailImage">  <img src={addanp}></img></a>
                                      <input type="file" accept="image/*" id="fuUploadPropertyDetailImage" onChange={handleChange} className="d-none" />
                                    </div>

                                  </div>
                                  : ''}

                              </div>

                            </div>

                          </div>

                        </div>

                      </div>
                      <div className="col-md-12">
                        <div className="maindivederaddpropertyinsight"></div>

                      </div>
                      <div className="col-md-12">
                        <div className=" mb-0">
                          <div className="row align-items-center">
                            <div className="col-md-12">
                              <p className="addservicetextform mainmarginpdtitle">Location Information</p>
                            </div>
                            <div className="col-md-7">

                              <div className="row mainlocationinfodivv">

                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Property Number </p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter House / Property Number" onChange={(e) => setPropertyNumber} defaultValue={PropertyNumber} />
                                  </div>

                                </div>
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Near By Address</p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Near by Address" defaultValue={NearByPlace} onChange={(e) => setNearByPlace(e.target.value)} />
                                  </div>

                                </div>
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Street</p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Street Name" defaultValue={Street} onChange={(e) => setStreet(e.target.value)} />
                                  </div>

                                </div>
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Pin Code</p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Pin Code" defaultValue={Pincode} onChange={(e) => setPincode(e.target.value)} />
                                  </div>

                                </div>
                                <div className="col-md-4">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                    <p className="maintitlereuestservicenee">Country</p>
                                    <select id="ddlCountry" className="form-select form-selectnewwr border-0 form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => { getCountryChange(e.target.value); setCountryId(e.target.value) }}>
                                      <option selected>Select Country</option>
                                      {CountryList.map((CountryListItems) => (
                                        <option key={CountryListItems.CountryId} value={CountryListItems.CountryId} selected={CountryListItems.CountryId === CountryId}> {CountryListItems.CountryName} </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                    <p className="maintitlereuestservicenee">State</p>
                                    <select id="ddlState" className="form-select border-0 form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => { getStateChange(e.target.value); setStateId(e.target.value) }}>
                                      <option selected>Select State</option>
                                      {StateList.map((StateItems) => (
                                        <option key={StateItems.StateId} value={StateItems.StateId} selected={StateItems.StateId === StateId}> {StateItems.StateName} </option>
                                      ))}
                                    </select>
                                  </div>

                                </div>
                                <div className="col-md-4">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                    <p className="maintitlereuestservicenee">City</p>
                                    <select id="ddlCity" className="form-select border-0 form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => setCityId(e.target.value)}>
                                      <option selected>Select City</option>
                                      {CityList.map((CityItems) => (
                                        <option key={CityItems.CityId} value={CityItems.CityId} selected={CityItems.CityId === CityId}> {CityItems.CityName} </option>
                                      ))}
                                    </select>
                                  </div>

                                </div>


                              </div>


                            </div>
                            <div className="col-md-5">
                              <p className="maintitlereuestservicenee">Location  </p>
                              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4079037565034!2d72.50534231495733!3d23.04550252125371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9bb51d412b01%3A0x4c5338c9649c6eab!2sZion%20Z1!5e0!3m2!1sen!2sin!4v1661162103882!5m2!1sen!2sin" width="100%" height="191" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="mainlocationpreview"></iframe>
                              <div className="registerinputnewedirr form-controlsustomeditnew">

                                <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" defaultValue={FullAddress} placeholder="Enter Your Address" onChange={(e) => setFullAddress(e.target.value)} />
                              </div>

                            </div>

                          </div>

                        </div>

                      </div>


                    </div>






                  </div>
                </div>

              </div>
              <div className="col-md-12">
                <div className="mainpropertylistbox">
                  <div className="">

                    <div className="row">

                      <div className="col-md-12">
                        <div className="row ">
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12">
                                <p className="maintitlereuestservicenee">Land Area</p>
                              </div>
                              <div className="col-md-8 mb-3 mb-md-0">
                                <div className="registerinputnewedirr form-controlsustomeditnew">

                                  <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Land Area" defaultValue={LandArea} />
                                </div>
                              </div>
                              <div className="col-md-4 mb-3 mb-md-0">

                                <select className="form-select form-selectnewwr form-select-lg mb-0 border-0" aria-label=".form-select-lg example">
                                  <option selected>Sq. ft</option>
                                  <option value="One" selected={"One" === LandAreaIn}>One</option>
                                  <option value="Two" selected={"Two" === LandAreaIn}>Two</option>
                                  <option value="Three" selected={"Three" === LandAreaIn}>Three</option>
                                </select>

                              </div>


                            </div>

                          </div>
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12">
                                <p className="maintitlereuestservicenee">Super Built Up</p>
                              </div>
                              <div className="col-md-8 mb-3 mb-md-0">
                                <div className="registerinputnewedirr form-controlsustomeditnew">

                                  <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter" defaultValue={SuperBuiltUP} />
                                </div>
                              </div>
                              <div className="col-md-4 mb-3 mb-md-0">

                                <select className="form-select form-selectnewwr form-select-lg mb-0 border-0" aria-label=".form-select-lg example">
                                  <option selected>Sq. ft</option>
                                  <option value="One" selected={"One" === SuperBuiltUpIn}>One</option>
                                  <option value="Two" selected={"Two" === SuperBuiltUpIn}>Two</option>
                                  <option value="Three" selected={"Three" === SuperBuiltUpIn}>Three</option>
                                </select>

                              </div>


                            </div>

                          </div>

                        </div>

                      </div>
                      <div className="col-md-12">
                        <div className="maindivederaddpropertyinsight"></div>
                      </div>
                      <div className="col-md-12">
                        <div className=" mb-0">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="addservicetextform mainmarginpdtitle">Property Insight (Optional)</p>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0 text-end">
                              <a onClick={PropertyInsightAddRow}> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New</p></a>
                            </div>

                          </div>
                          {PropertyInsightList.map((item, idx) => (
                            <div className="row mainaddnewinsightalldiv" key={idx}>
                              <div className="col-md-10">
                                <div className="row align-items-center ">
                                  <div className="col-md-1 mb-3 mb-md-0">

                                    <a onClick={e => PropertyInsightRowdelete(item.Id)}><img src={deletedash} className="deletedashimg"></img></a>

                                  </div>
                                  <div className="col-md-2 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                      <input type="text" className="form-control formcontrollplaceholder border-0" id={item.InsightNameId} placeholder="write here" defaultValue={item.InsightName} />

                                    </div>

                                  </div>
                                  <div className="col-md-2 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                      <input type="text" className="form-control formcontrollplaceholder border-0" id={item.InsightWidthId} defaultValue={item.InsightWidth} />
                                      <div className="maininputdefulttext">
                                        <p>W</p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="col-md-2 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                      <input type="text" className="form-control formcontrollplaceholder border-0" id={item.InsightLengthId} defaultValue={item.InsightLength} />
                                      <div className="maininputdefulttext">
                                        <p>L</p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="col-md-2 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                      <input type="text" className="form-control formcontrollplaceholder border-0" id={item.InsightHeightId} defaultValue={item.InsightHeight} />
                                      <div className="maininputdefulttext">
                                        <p>H</p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="col-md-2 mb-3 mb-md-0">
                                    <div className="">
                                      <input type="file" name="file" id="file" className="file" />
                                      <div className="mainfileuploadimgwith">
                                        <input type="text" name="file-name" className="file-name border-0" readOnly="readonly" id={item.InsightMediaNameId} defaultValue={item.InsightMediaName} />
                                        <a onClick={(e) => fileUploadhandleRemove(idx)}>
                                          <div className="maindivdeletprpertyaddnewsmall">
                                            <div className="deletprpertyaddnewsmall">
                                              <i className="las la-trash-alt"></i>
                                            </div>
                                          </div>
                                        </a>
                                        <div className="filnamewithiconmain">
                                          <i className="las la-file"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-1 mb-3 mb-md-0 text-end">
                                    <input type="file" ref={hiddenFileInput} onChange={(e) => fileUploadhandleChange(e, idx)} className="d-none" />
                                    <button type="button" className="btn uploadbtnmainaddproperty" onClick={fileUploadhandleClick} ><i className="las la-cloud-upload-alt" ></i></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div className="row mainaddnewinsightalldiv">
                            <div className="col-md-10">
                              <div className="row align-items-center ">
                                <div className="col-md-1">

                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="txtInsightName" placeholder="write here" />
                                  </div>
                                </div>

                                <div className="col-md-2 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                    <input type="number" min={0} className="form-control formcontrollplaceholder border-0" id="txtInsightWidth" />
                                    <div className="maininputdefulttext">
                                      <p>W</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="number" min={0} className="form-control formcontrollplaceholder border-0" id="txtInsightLength" />
                                    <div className="maininputdefulttext">
                                      <p>L</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="number" min={0} className="form-control formcontrollplaceholder border-0" id="txtInsightHeight" />
                                    <div className="maininputdefulttext">
                                      <p>H</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                  <div className="">
                                    <input type="file" name="file" id="file" className="file" />
                                    <div className="mainfileuploadimgwith">
                                      <input type="text" name="file-name" id="file-name" className="file-name border-0" defaultValue={InsightMediaName} readOnly="readonly" />
                                      <a onClick={(e) => { setInsightMedia1([]); setInsightMediaName('') }}>
                                        <div className="maindivdeletprpertyaddnewsmall">
                                          <div className="deletprpertyaddnewsmall">
                                            <i className="las la-trash-alt"></i>
                                          </div>
                                        </div>
                                      </a>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 mb-3 mb-md-0 text-end">
                              <button type="button" className="btn uploadbtnmainaddproperty" id="upfile1"><i className="las la-cloud-upload-alt" ></i>Upload Files </button>
                              <input type="file" id="file1" onChange={(e) => { setInsightMedia1(e.target.files[0]); setInsightMediaName(e.target.files[0].name) }} className="d-none" />
                            </div>
                          </div>

                          <div className="row">

                            <div className="col-md-10">
                              <div>
                                <form>
                                  <div className="form-group newwform-group">
                                    <input type="checkbox" id="html" onChange={(e) => handleFurnishedCheckbox(e)} />
                                    <label htmlFor="html">is Property Furnished ?</label>
                                  </div>

                                </form>
                              </div>

                            </div>
                            <div className="col-md-2 text-end">
                              <a onClick={PropertyFurnishedAddRow}> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New </p></a>
                            </div>
                            <div className="col-md-12">
                              <div className="maindivederaddpropertyinsight"></div>
                            </div>
                          </div>
                          {PropertyFurnishedList.map((item, idx) => (
                            <div className="row mainaddnewinsightalldiv align-items-center " key={idx}>
                              <div className="col-md-11">
                                <div className="row align-items-center ">
                                  <div className="col-md-1 mb-3 mb-md-0">

                                    <a onClick={e => PropertyFurnishedRowdelete(item.FurnishedId)}><img src={deletedash} className="deletedashimg"></img></a>

                                  </div>
                                  <div className="col-md-3 mb-3 mb-md-0">

                                    <input type="text" className="form-control formcontrollplaceholder border-0" id={item.FurnishedInId} placeholder="write here property insight" defaultValue={item.PropertyFurnishedIn} />

                                  </div>
                                  <div className="col-md-3 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                      <input type="text" className="form-control formcontrollplaceholder border-0" id={item.FurnishedDetailsId} placeholder="write here furnished detail" defaultValue={item.FurnishedDetails} />

                                    </div>

                                  </div>


                                  <div className="col-md-3 mb-3 mb-md-0">
                                    <div className="">
                                      <input type="file" name="file" id="file" className="file" />
                                      <div className="mainfileuploadimgwith">
                                        <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" defaultValue={item.FurnishedMedia} />
                                        {/* <div className="maindivdeletprpertyaddnewsmall">
                                          <div className="deletprpertyaddnewsmall">
                                            <i className="las la-trash-alt"></i>
                                          </div>
                                        </div> */}
                                        <div className="filnamewithiconmain">
                                          <i className="las la-file"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-md-3 ">
                                    <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                                  </div> */}

                                </div>


                              </div>

                            </div>
                          ))}
                          <div className="row mainaddnewinsightalldiv align-items-center ">

                            <div className="col-md-11">
                              <div className="row align-items-center ">
                                <div className="col-md-1">
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                  <input type="text" className="form-control formcontrollplaceholder border-0" id="txtFurnishedIn" placeholder="write here property insight" />
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="txtFurnishedDetails" placeholder="write here furnished detail" />
                                  </div>
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                  <div className="">
                                    <input type="file" name="file" id="file" className="file" />
                                    <div className="mainfileuploadimgwith">
                                      <input type="text" name="file-name" id="file-name" className="file-name border-0" defaultValue={FurnishedMediaName} readOnly="readonly" />
                                      <div className="maindivdeletprpertyaddnewsmall">
                                        <div className="deletprpertyaddnewsmall">
                                          <a onClick={(e) => { setFurnishedMedia1([]); setFurnishedMediaName('') }}><i className="las la-trash-alt"></i></a>
                                        </div>
                                      </div>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <button type="button" className="btn uploadbtnmainaddproperty" id="upfile2"><i className="las la-cloud-upload-alt" ></i>Upload Files </button>
                                  <input type="file" id="file2" onChange={(e) => { setFurnishedMedia1(e.target.files[0]); setFurnishedMediaName(e.target.files[0].name) }} className="d-none" />
                                </div>

                              </div>


                            </div>

                          </div>

                        </div>

                      </div>



                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-12">
                <div className="mainpropertylistbox">
                  <div className="">

                    <div className="row">


                      <div className="col-md-12">
                        <div className=" ">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="addservicetextform mainmarginpdtitle">Documents</p>
                            </div>
                            <div className="col-md-6 text-end">
                              <a onClick={PropertyDocumentAddRow}> <p className="addnewpropertytextneww">  <i className="las la-plus-circle"></i>Add New</p></a>
                            </div>

                          </div>

                          {PropertyDocumentList.map((item, idx) => (
                            <div className="row mainaddnewinsightalldiv" key={idx}>
                              <div className="col-md-10">
                                <div className="row align-items-center ">
                                  <div className="col-md-1 mb-3 mb-md-0">

                                    <img src={deletedash} className="deletedashimg"></img>

                                  </div>
                                  <div className="col-md-8 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                      <input type="text" className="form-control formcontrollplaceholder border-0" id={item.DocumentNameId} placeholder="write here" defaultValue={item.DocumentName} />

                                    </div>

                                  </div>



                                  <div className="col-md-3 mb-3 mb-md-0">
                                    <div className="">
                                      <input type="file" name="file" id="file" className="file" />
                                      <div className="mainfileuploadimgwith">
                                        <input type="text" name="file-name" id="file-name" className="file-name border-0" readOnly="readonly" defaultValue={item.DocumentFileName} />
                                        {/* <div className="maindivdeletprpertyaddnewsmall">
                                          <div className="deletprpertyaddnewsmall">
                                            <i className="las la-trash-alt"></i>
                                          </div>
                                        </div> */}
                                        <div className="filnamewithiconmain">
                                          <i className="las la-file"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                              </div>
                              {/* <div className="col-md-2 mb-3 mb-md-0 text-end">
                                <button type="button" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                              </div> */}

                            </div>))
                          }
                          <div className="row mainaddnewinsightalldiv">
                            <div className="col-md-10">
                              <div className="row align-items-center ">
                                <div className="col-md-1 mb-3 mb-md-0">
                                </div>
                                <div className="col-md-8 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">

                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="txtDocumentName" placeholder="write here" />

                                  </div>

                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                  <div className="">
                                    <input type="file" name="file" id="file" className="file" />
                                    <div className="mainfileuploadimgwith">
                                      <input type="text" name="file-name" id="file-name" className="file-name border-0" defaultValue={DocumentMediaName} readOnly="readonly" />
                                      <div className="maindivdeletprpertyaddnewsmall">
                                        <div className="deletprpertyaddnewsmall">
                                          <a onClick={(e) => { setDocumentMedia1([]); setDocumentMediaName('') }}><i className="las la-trash-alt"></i></a>
                                        </div>
                                      </div>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                            <div className="col-md-2 mb-3 mb-md-0 text-end">
                              <button type="button" id="upfile3" className="btn uploadbtnmainaddproperty" ><i className="las la-cloud-upload-alt"></i>Upload Files </button>
                              <input type="file" id="file3" className="d-none" onChange={(e) => { setDocumentMedia1(e.target.files[0]); setDocumentMediaName(e.target.files[0].name) }} />
                            </div>

                          </div>



                        </div>

                      </div>



                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-12">
                <a className="btn btn-primary btnnextedit" onClick={UpdatePropertyDetail} >Save </a>
                <a className="btn btn-primary btnnexteditline" href="/property-list" > Cancel </a>
              </div>


            </div>
          </div>


        </div>
      </section>
      <Footeradmin />
    </React.Fragment >
  )
}

export default propertydetail

import React, { useEffect, useState } from "react"
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";
import Commheader from "./header";
import { useToasts } from 'react-toast-notifications';
import Footeradmin from "../frontend/footeradmin";
import loader from "../../assets/images/frontend-img/loader.gif";
import defaulthomeimg from "../../assets/images/frontend-img/development.png";


const requestService2 = () => {
  const [ShowLoader, setShowLoader] = useState(0);
  const [ServiceType, setServiceType] = useState([]);
  const [ServiceFilter, setServiceFilter] = useState([]);
  const [ServiceList, setServiceList] = useState([]);
  const [ServiceDescriptionList, setServiceDescriptionList] = useState([]);
  const [ServiceMediaList, setServiceMediaList] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const [CityFilterList, setCityFilterList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [PropertyImageList, setPropertyImageList] = useState([]);
  const [ServiceTypeId, setServiceTypeId] = useState(0);
  const [ServiceId, setServiceId] = useState('0');
  const [ServiceReqDate, setServiceReqDate] = useState('');
  const [Time, setTime] = useState('');
  const [KeyStatus, setKeyStatus] = useState("");
  const [PartyNumber, setPartyNumber] = useState('');
  const [CountryId, setCountryId] = useState(0);
  const [CityId, setCityId] = useState(0);
  const [IsSpecializedSecurity, setIsSpecializedSecurity] = useState(0);
  const [SecurityDetail, setSecurityDetail] = useState('');
  const [IsPets, setIsPets] = useState(0);
  const [PetsInstruction, setPetsInstruction] = useState('');
  const [OtherInstructions, setOtherInstructions] = useState('');
  const [PetsId, setPetsId] = useState(0);
  const [PropertyName, setPropertyName] = useState("");
  const [PropertyType, setPropertyType] = useState("");
  const [FullAddress, setFullAddress] = useState("");
  const { addToast } = useToasts();
  const [PetsList, setPetsList] = useState([]);
  const [DescList, setDescList] = useState([]);
  const [ShowMessageBox,setShowMessageBox]=useState(0);


  useEffect(() => {
    // getServiceType();
    getCountryList();
    getPropertyDetail();
    getServiceDetail();
    getPetList();
  }, []);

  const getPetList = () => {
    setShowLoader(1);
    var AUrl = global.APIURL + '/PaidListdata';
    axios.get(AUrl)
      .then(res => {
        setPetsList(res.data.Result);
      });
    setShowLoader(0);
  }


  const getServiceTypeChange = (STypeId) => {
    setShowLoader(1);
    var SF = ServiceFilter.filter(x => x.ServiceTypeId == STypeId);
    ServiceList.length == 0;
    setServiceList(SF);
    setServiceTypeId(STypeId)
    setShowLoader(0);
  }


  const getServiceDetail = () => {
    setShowLoader(1);
    var VLoginUserId = JSON.parse(localStorage.getItem("RegisterId") == null ? "0" : localStorage.getItem("RegisterId") == "" ? "0" : localStorage.getItem("RegisterId"));
    var VServiceId = JSON.parse(localStorage.getItem("EditServiceId") == null ? "0" : localStorage.getItem("EditServiceId") == "" ? "0" : localStorage.getItem("EditServiceId"));

    const SDetailArr = { "ServiceId": VServiceId }
    axios.post(global.APIURL + '/serviceEditList', SDetailArr)
      .then(res => {
      
        setServiceTypeId(res.data.Result[0].ServiceTypeId);
        setServiceId(res.data.Result[0].ServiceId);
        const STypeId = res.data.Result[0].ServiceTypeId;
        const SId = res.data.Result[0].ServiceId;
        var AUrl = global.APIURL + '/Servicetype';
        axios.get(AUrl)
          .then(res => {

            setServiceType(res.data.Result);
            for (let sl = 0; sl < res.data.Result.length; sl++) {
              const VServiceList = res.data.Result[sl].ServiceList;

              for (let sd = 0; sd < VServiceList.length; sd++) {
                const Ditem = {
                  ServiceTypeId: res.data.Result[sl].ServiceTypeId,
                  ServiceId: VServiceList[sd].ServiceId,
                  ServiceName: VServiceList[sd].ServiceName,
                  Description: VServiceList[sd].Description,
                  Media: VServiceList[sd].Media,
                };
                ServiceFilter.push(Ditem);
                var SF = ServiceFilter.filter(x => x.ServiceTypeId === STypeId);

                ServiceList.length == 0;
                setServiceList(SF);

                var DL = ServiceFilter.filter(x => x.ServiceId == SId);            
                const BArray = [];
                for (let i = 0; i < DL.length; i++) {
                  let VData = DL[i];
                  let VDesc = DL[i].Description.split('.');
                  setServiceMediaList(DL[0].Media);
                  const DItem = {
                    VServiceName: VData.ServiceName,
                    VServiceTypeId: VData.ServiceTypeId,
                    VDescription: VDesc,
                    VMedia: VData.Media
                  }
                  BArray.push(DItem);
                  setDescList(BArray);
                }
              }
            }
          });
        document.getElementById("ddlService").value = res.data.Result[0].ServiceId;
        setServiceReqDate(res.data.Result[0].Date);
        setTime(res.data.Result[0].Time);
        setKeyStatus(res.data.Result[0].KeyStatus);
        if(res.data.Result[0].KeyStatus=="with_third_party"){
          setShowMessageBox(1);
        }
        setPartyNumber(res.data.Result[0].PartyNumber);
        setCountryId(res.data.Result[0].CountryId==""?0:res.data.Result[0].CountryId);
        setCityId(res.data.Result[0].CityId==""?0:res.data.Result[0].CityId);
        var CF = CityFilterList.filter(x => x.CountryId == res.data.Result[0].CountryId);
        setCityList(CF);
        setIsSpecializedSecurity(res.data.Result[0].IsSpecializedSecurity);
        if (res.data.Result[0].IsSpecializedSecurity == 1) {
          document.getElementById("ckSpecialisedSecurity").checked = true;
        }
        else {
          document.getElementById("ckSpecialisedSecurity").checked = false;
        }
        setSecurityDetail(res.data.Result[0].SecurityDetail);

        setIsPets(res.data.Result[0].IsPets);
        if (res.data.Result[0].IsPets == 1) {
          setPetsId(res.data.Result[0].PetsId);
          document.getElementById("ckAnyPets").checked = true;
        }
        else {
          document.getElementById("ckAnyPets").checked = false;
        }
        setPetsInstruction(res.data.Result[0].PetsInstruction);
        setOtherInstructions(res.data.Result[0].OtherInstructions);
        if (res.data.Result[0].OtherInstructions != null) {
          if (res.data.Result[0].OtherInstructions != '') {
            document.getElementById("ckOthers").checked = true;
          }
          else {
            document.getElementById("ckOthers").checked = false;
          }
        }
        else {
          document.getElementById("ckOthers").checked = false;
        }
        if (res.data.Result[0].PetsId != null) {
          setPetsId(res.data.Result[0].PetsId);
        }
      });
    setShowLoader(0);
  }

  const getServiceChange = (SId) => {
    setShowLoader(1);
    var SF = ServiceFilter.filter(x => x.ServiceId == SId);
    if (SF.length > 0) {
      setServiceId(SId)
      setServiceDescriptionList(SF[0].Description);
      setServiceMediaList(SF[0].Media);
      const BArray = [];
      for (let i = 0; i < SF.length; i++) {
        let VData = SF[i];
        let VDesc = SF[i].Description.split('.');

        const DItem = {
          VServiceName: VData.ServiceName,
          VServiceTypeId: VData.ServiceTypeId,
          VDescription: VDesc
        }
        BArray.push(DItem);
        setDescList(BArray);
      }
    }
    setShowLoader(0);
  }

  const getCountryList = () => {
    setShowLoader(1);
    CountryList.length = 0;
    CityFilterList.length = 0;
    var AUrl = global.APIURL + '/CountryStateCity';
    axios.get(AUrl)
      .then(res => {
        const cl = res.data.Result[0].Country;
        for (let cd = 0; cd < cl.length; cd++) {
          const Countyitem = {
            CountryId: cl[cd].Id,
            CountryName: cl[cd].CountryName
          };
          CountryList.push(Countyitem);
          const Sl = cl[cd].State;

          for (let sd = 0; sd < Sl.length; sd++) {
            const StateId = Sl[sd].Id;
            const CityList = Sl[sd].City;
            for (let ctd = 0; ctd < CityList.length; ctd++) {
              const Cityitem = {
                CountryId: cl[cd].Id,
                CityId: CityList[ctd].Id,
                CityName: CityList[ctd].CityName
              };
              CityFilterList.push(Cityitem);
            }
          }
        }
      });
    setShowLoader(0);
  }

  const getCountryChange = (CountryId) => {
    setShowLoader(1);
    var CF = CityFilterList.filter(x => x.CountryId == CountryId);
    setCityList(CF);
    setShowLoader(0);
  }

  const getPropertyDetail = async () => {
    setShowLoader(1);
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let PId = JSON.parse(localStorage.getItem('EditPropertyId'));
    let VEditPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
    const PropertyDetailArr = {
      RegisterId: VRegisterId,
      PropertyId: VEditPropertyId
    };
    axios.post(global.APIURL + "/propertyDetail", PropertyDetailArr)
      .then(res => {
        setPropertyName(res.data.Result[0].PropertyTitle);
        setPropertyType(res.data.Result[0].PropertyTypeName);
        setFullAddress(res.data.Result[0].FullAddress)
        var PropImageList = res.data.Result[0].Media;
        var PIArr = [];
        for (let pi = 0; pi < PropImageList.length; pi++) {
          const PropImageUrl = PropImageList[pi].Url;
          if (PropImageUrl != null) {
            if (PropImageUrl != '') {
              const PropImageitem = {
                RId: pi,
                SclassName: pi == 0 ? 'carousel-item active' : 'carousel-item',
                SliderName: 'Slider ' + (pi + 1),
                PropImageUrl: PropImageUrl
              };
              PIArr.push(PropImageitem);
            }
          }
        }
        setPropertyImageList(PIArr);
      })
    setShowLoader(0);
  }

  const SaveRequestNewService = async () => {
    setShowLoader(1);
    var LoginUserId = JSON.parse(localStorage.getItem("RegisterId") == null ? "0" : localStorage.getItem("RegisterId") == "" ? "0" : localStorage.getItem("RegisterId"));
    var VServiceId = parseInt(JSON.parse(localStorage.getItem("EditServiceId") == null ? "0" : localStorage.getItem("EditServiceId") == "" ? "0" : localStorage.getItem("EditServiceId")));
    let PId = JSON.parse(localStorage.getItem('EditPropertyId'));
    let VEditPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
    let IsInsert = 1;

    var chkTermsandconditionsval = document.getElementById("chkTermsandconditions");
    var ckSpecialisedSecurityval = document.getElementById("ckSpecialisedSecurity");
    var ckAnyPetsval = document.getElementById("ckAnyPets");
    var ckOthersval = document.getElementById("ckOthers");
    var petId = document.getElementById("ddlAnyPets").value;

    if (VEditPropertyId == 0) {
      addToast("Select Property", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (ServiceTypeId == 0) {
      addToast("Select Service Type", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (ServiceId == 0) {
      addToast("Select Service", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (ServiceReqDate == null) {
      setServiceReqDate(0);

    }
    else {
      if (ServiceReqDate == '') {
        setServiceReqDate(0);

      }
    }

    if (Time == null) {
      setTime(0);

    }
    else {
      if (Time == '') {
        setTime(0);
      
      }
    }

    // if (KeyStatus == null) {
    //   addToast("Select Key Status", { appearance: 'error', autoDismiss: true });
    //   IsInsert = 0;
    //   return;
    // }
    // else {
    //   if (KeyStatus == '') {
    //     addToast("Select Key Status", { appearance: 'error', autoDismiss: true });
    //     IsInsert = 0;
    //     return;
    //   }
    // }
    // if (PartyNumber == "") {
    //   addToast('Add Mobile Number', { appearance: 'error', autoDismiss: true });
    //   IsInsert = 0;
    //   return;
    // }
    // if (PartyNumber.length < 10) {
    //   addToast('Add Valid Mobile Number', { appearance: 'error', autoDismiss: true });
    //   IsInsert = 0;
    //   return;
    // }
    // if (CountryId == 0) {
    //   addToast("Select Country", { appearance: 'error', autoDismiss: true });
    //   IsInsert = 0;
    //   return;
    // }

    // if (CityId == 0) {
    //   addToast("Select City", { appearance: 'error', autoDismiss: true });
    //   IsInsert = 0;
    //   return;
    // }

    if (ckSpecialisedSecurityval.checked == true) {
      if (SecurityDetail == "") {
        addToast("Please Enter Specialised Security.", { appearance: 'error', autoDismiss: true });
        IsInsert = 0;
        return;
      }
    }
    if (ckAnyPetsval.checked == true) {
      if (PetsInstruction == "") {
        addToast("Please Enter Pets Instruction", { appearance: 'error', autoDismiss: true });
        IsInsert = 0;
        return;
      }

    }
    if (ckOthersval.checked == true) {
      if (OtherInstructions == "") {
        addToast("Please Enter Other Instruction", { appearance: 'error', autoDismiss: true });
        IsInsert = 0;
        return;
      }

    }
    if (chkTermsandconditionsval.checked == false) {

      addToast("Please Accept Terms and Conditions.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      setShowLoader(0);
      return;

    }


    if (IsInsert > 0) {
      const formData = new FormData();
      formData.append('RegisterId', LoginUserId);
      formData.append('ServiceReqestId', VServiceId);
      formData.append('Action', 'Edit');
      formData.append('PropertyId', VEditPropertyId);
      formData.append('ServiceTypeId', ServiceTypeId);
      formData.append('ServiceId', ServiceId);
      formData.append('Date', ServiceReqDate);
      formData.append('Time', Time);
      formData.append('KeyStatus', KeyStatus);
      formData.append('PartyNumber', PartyNumber);
      formData.append('CountryId', CountryId);
      formData.append('CityId', CityId);
      formData.append('IsSpecializedSecurity', IsSpecializedSecurity);
      formData.append('SecurityDetail', SecurityDetail);
      formData.append('IsPets', IsPets);
      formData.append('PetsInstruction', PetsInstruction);
      formData.append('OtherInstructions', OtherInstructions);
      formData.append('PetsId', petId);
      for (var pair of formData.entries()) {
        console.log(pair[0] + ':' + pair[1]);
      }
      axios.post(global.APIURL + "/addEditDeleteService", formData)
        .then(res => {
          if (res.data.Status == true) {
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            localStorage.removeItem("EditServiceId");
            localStorage.removeItem("EditPropertyId");
            window.location.href = "/manage-service";
          }
          else {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          }
        });
    }

    setShowLoader(1);
  };

  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  return (<React.Fragment>

    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="verificationeditext">
              Verification
            </p>
            <button type="button" className="skipbtn" data-bs-dismiss="modal" aria-label="Close">Skip</button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <img src={otpicon} ></img>
            </div>
            <div>
              <form action="" className="formotp">
                <div>
                  <p className="otpverificationtitlenew">Mobile Number Verification</p>
                </div>
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(1)' maxLength='1' />
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(2)' maxLength='1' />
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(3)' maxLength='1' />
                <input className="otp" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(4)' maxLength='1' />
              </form>
              <form action="" className="formotp">
                <div>
                  <p className="otpverificationtitlenew">Email Address Verification</p>
                </div>
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(1)' maxLength='1' />
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(2)' maxLength='1' />
                <input className="otp mr1vw" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(3)' maxLength='1' />
                <input className="otp" type="text" onInput='digitValidate(this)' onKeyUp='tabChange(4)' maxLength='1' />
              </form>
              <div className="text-center">
                <button type="button" className="btn btn-primary btnnextedit " >Continue <i className="fas fa-long-arrow-alt-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {ShowLoader == 1 ?
      <div className="showhidedivloader">
        <div className="mainloadernew">
          <img src={loader} />
        </div>
      </div>
      : ''}
    <Commheader />
    <div className="modal fade newzindexrdit" id="termsandconditionmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Terms & Conditions</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row miandivpricingbox newmaindivpricingcustomadd">
              <div name="termly-embed" data-id="8f1e65bc-f9c1-4d67-9060-da2884dbcb6a" data-type="iframe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade newzindexrdit" id="privacypolicemodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">privacy policy</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row miandivpricingbox newmaindivpricingcustomadd">
              <div name="termly-embed" data-id="3ff5f924-f50d-42f1-a687-cae728b3476c" data-type="iframe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <section>
      <div className="container">
        <div className="mainboxshadow">
          <div className="row">
            <div className="col-md-12">
              <p className="addservicetextform">Edit Service Request</p>
            </div>
            <div className="col-md-12">
              <p className="mainsteptextform">Step 2 : Select Service</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="propertysteptwomainbox">
                <div className="d-flex">
                  {PropertyImageList.length==0?<img className="mainpropertysliderdefaultimg" src={defaulthomeimg}></img>:                 
                 <div>
                  <div id="carouselExampleIndicators" className="carousel slide propertyimgslider  propertyimgslidernewimgedit" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                      {PropertyImageList.map((item, idx) => (

                        item.PropImageUrl == '' ? '' :
                          idx == 0 ?
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} className="active" aria-current="true" aria-label={item.SliderName}></button>
                            :
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} aria-label={item.SliderName}></button>

                      ))}
                      {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                    </div>
                    <div className="carousel-inner">
                      {PropertyImageList.map((item, idx) => (
                        item.PropImageUrl == null ? '' : item.PropImageUrl == '' ? '' :
                          <div className={item.SclassName} key={idx}>
                            <img src={item.PropImageUrl} className="mainpropertysliderimg" />
                          </div>
                      ))}
                    </div>
                  </div>
                </div>
                  }                 
                  <div className="w-100">
                    <div className="d-flex w-100">
                      <p className="mainpropertytitlelist">{PropertyName} </p>
                      <div>
                        <img className="verifiedgreenstep2" src={verifiedgreen} />
                      </div>
                    </div>
                    <div>
                      <p className="propertycatageoried">{PropertyType}</p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <img src={locationpropertyedit} className="locationpropertyedit" />
                      </div>
                      <div>
                        <p className="propertytextlocation">{FullAddress}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="deletprpertyadd">
                    <a href="/edit-request-new-service"><i className="las la-trash-alt selectservicebackbutton"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row ">
                <div className="col-md-4">
                  <p className="maintitlereuestservicenee">Service Type <span className="spanred">*</span></p>
                  <select id="ddlServiceType" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={e => { getServiceTypeChange(e.target.value); }}>
                    <option selected>Select Service Type</option>
                    {ServiceType.map((ServiceTypeItems) => (
                      <option key={ServiceTypeItems.ServiceTypeId} value={ServiceTypeItems.ServiceTypeId} selected={ServiceTypeItems.ServiceTypeId === ServiceTypeId}> {ServiceTypeItems.ServiceTypeName} </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <p className="maintitlereuestservicenee">Service <span className="spanred">*</span></p>
                  <select id="ddlService" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={e => getServiceChange(e.target.value)}>
                    <option selected>Select Service</option>
                    {ServiceList.map((ServiceItems) => (
                      <option key={ServiceItems.ServiceId} value={ServiceItems.ServiceId} selected={ServiceId == ServiceItems.ServiceId}> {ServiceItems.ServiceName} </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12 mianservicedetailseditboxenewq reqsercss">
              <div className="row  align-items-end">
                <div className="col-md-2">
                  <div>
                    <div className="registerinputnewedirr form-controlsustomeditnew">
                      <label htmlFor="txtDate" className="form-label">Select Date <span className="spanred"></span> </label>
                      <input type="date" className="form-control " id="txtDate" min={new Date().toISOString().split('T')[0]} onChange={(e) => setServiceReqDate(e.target.value)} defaultValue={ServiceReqDate} />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div>
                    <div className="registerinputnewedirr form-controlsustomeditnew">
                      <label htmlFor="txtTime" className="form-label">Select Time <span className="spanred"></span></label>
                      <input type="time" className="form-control " id="txtTime" onChange={(e) => setTime(e.target.value)} defaultValue={Time} />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div>
                    <div className="mainrediocheckcustome">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Key Status <span className="spanred"></span></label>
                      <div className="form-check d-flex align-items-center">
                        <input className="form-check-input" type="radio" name="rbKeyStatus" id="rbKeyStatusWithCompany" checked={KeyStatus === 'with_company'} onChange={(e) => {setKeyStatus("with_company");setShowMessageBox(0)}} />
                        <label className="form-check-label" htmlFor="rbKeyStatusWithCompany">
                          With Company
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center">
                        <input className="form-check-input" type="radio" name="rbKeyStatus" id="rbKeyStatusWith3rdParty" checked={KeyStatus === 'with_third_party'} onChange={(e) => {setKeyStatus("with_third_party");setShowMessageBox(1)}} />
                        <label className="form-check-label" htmlFor="rbKeyStatusWith3rdParty">
                          With 3<sup>rd</sup> Party
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row ">
                    {ShowMessageBox==1?<>
                    <div className="col-md-12 ">
                      <p className="maintitlereuestservicenee">Please message to user & Share their number <span className="spanred"></span></p>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                      <div className="registerinputnewedirr form-controlsustomeditnew">
                        <input type="number" className="form-control formcontrollplaceholder" maxLength={10} onKeyPress={inputHandler} id="txtShareNumber" defaultValue={PartyNumber} placeholder="Enter Number" onChange={(e) => setPartyNumber(e.target.value)} />
                      </div>
                    </div></>:""}
                    <div className="col-md-4 mb-3 mb-md-0">
                      <select id="ddlCountry" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => { getCountryChange(e.target.value); setCountryId(e.target.value) }}>
                        <option selected>Select Country</option>
                        {CountryList.map((CountryListItems) => (
                          <option key={CountryListItems.CountryId} value={CountryListItems.CountryId} selected={CountryListItems.CountryId === CountryId}> {CountryListItems.CountryName} </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4">

                      <select id="ddlCity" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => setCityId(e.target.value)}>
                        <option selected>Select City</option>
                        {CityList.map((CityItems) => (
                          <option key={CityItems.CityId} value={CityItems.CityId} selected={CityItems.CityId === CityId}> {CityItems.CityName} </option>
                        ))}
                      </select>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mianservicedetailseditboxenewq1">
                <div className="row">
                  <div className="col-md-12">
                    <p className="maintitlereuestservicenee">Service Detail</p>
                  </div>
                  <div className="col-md-3 mb-3 mb-md-0">
                    <div>
                      <form>
                        <div className="form-group newwform-group">
                          <input type="checkbox" id="ckSpecialisedSecurity" onChange={(e) => setIsSpecializedSecurity(e.target.checked == true ? 1 : 0)} />
                          <label htmlFor="ckSpecialisedSecurity">Specialised Security</label>
                        </div>
                      </form>
                    </div>
                    <div className="registerinputnewedirr form-controlsustomeditnew">
                      <textarea type="text" className="form-control formcontrollplaceholder" id="txtSpecialisedSecurity" defaultValue={SecurityDetail} placeholder="Write Here" onChange={(e) => setSecurityDetail(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group newwform-group">
                          <input type="checkbox" id="ckAnyPets" onChange={(e) => setIsPets(e.target.checked == true ? 1 : 0)} />
                          <label htmlFor="ckAnyPets">Any Pets </label>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3 mb-md-0">
                        <select id="ddlAnyPets" className="form-select form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" >
                          <option value={0}selected>Select Type</option>
                          {PetsList.map((item, idx) => (
                            <option key={idx} value={item.Id} selected={item.Id == PetsId}>{item.Name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-8 mb-3 mb-md-0">
                        <div className="registerinputnewedirr form-controlsustomeditnew">
                          <textarea type="text" className="form-control formcontrollplaceholder" id="txtAnyPets" defaultValue={PetsInstruction} placeholder="Write Your Instruction Here" onChange={(e) => setPetsInstruction(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group newwform-group">
                      <input type="checkbox" id="ckOthers" />
                      <label htmlFor="ckOthers">Others</label>
                    </div>
                    <div className="registerinputnewedirr form-controlsustomeditnew">
                      <textarea type="text" className="form-control formcontrollplaceholder" id="txtOthers" placeholder="Write Here" defaultValue={OtherInstructions} onChange={(e) => setOtherInstructions(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group newwform-group2">
                <input type="checkbox" id="chkTermsandconditions" />
                <label htmlFor="chkTermsandconditions" className="mb-0">By ticking, you are confirming that you have read, understood and agree to the <a className="mainlineatagee" data-bs-toggle="modal" data-bs-target="#termsandconditionmodal">Terms and conditions</a>  and <a className="mainlineatagee" data-bs-toggle="modal" data-bs-target="#privacypolicemodal">privacy policy</a> </label>             
              </div>
            </div>
            <div className="col-md-12">
              <button type="button" onClick={() => SaveRequestNewService()} className="btn btn-primary btnnextedit">Finish <i className="fas fa-long-arrow-alt-right"></i></button>
              <a href="/request-new-service">  <button type="button" className="btn btn-primary btnnexteditline">Back </button></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footeradmin />
  </React.Fragment>);
}



export default requestService2

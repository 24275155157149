import React, { useEffect, useState } from "react"
import profileimg from "../../assets/images/frontend-img/userdefaultimage.jpg";
import axios from "axios";
import Commheader from "../frontend/header";
import { useToasts } from 'react-toast-notifications';
import Footeradmin from "../frontend/footeradmin";
import moment from 'moment';

const EditProfile = () => {
  const { addToast } = useToasts();
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [DOB, setDOB] = useState('');
  const [Gender, setGender] = useState('');

  const [ProfileFileArr, setProfileFileArr] = useState([]);
  const [IsSelectProfileFile, setIsSelectProfileFile] = useState(0);
  const [ProfileFileUrl, setProfileFileUrl] = useState(profileimg);
  const [ShowLoader, setShowLoader] = useState(0);

  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const getProfileData = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);

    axios.post(global.APIURL + '/MyProfileData', formData)
      .then(res => {
        setEmail(res.data.Result[0].Email);
        setName(res.data.Result[0].Name);
        setMobileNo(res.data.Result[0].MobileNo)
        setGender(res.data.Result[0].Gender)
        setDOB(res.data.Result[0].EditDateOfBirth)
        setProfileFileUrl(res.data.Result[0].ProfilePic);
      });

    setShowLoader(0);
  }

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      setShowLoader(1);
      getProfileData();
    }
  }, []);


  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  const UpdateProfile = async () => {

    let IsEdit = 1;
    if (Name == "") {
      addToast("Please Enter Name", { appearance: 'error', autoDismiss: true });
      IsEdit = 0;
      return;
    }
    if (Email == "") {
      addToast("Please Enter Email", { appearance: 'error', autoDismiss: true });
      IsEdit = 0;
      return;
    }

    if (Email != "") {
      if (regex.test(Email) === false) {
        addToast("Please Enter Valid Email Address", { appearance: 'error', autoDismiss: true });
        IsEdit = 0;
        return;
      }
    }
    if (MobileNo == "") {
      addToast("Please Enter Mobile Number", { appearance: 'error', autoDismiss: true });
      IsEdit = 0;
      return;
    }
    if (MobileNo.length < 10) {
      addToast("Please Enter Valid Mobile Number", { appearance: 'error', autoDismiss: true });
      IsEdit = 0;
      return;

    }
    if (Gender == 0) {
      addToast("Select Gender", { appearance: 'error', autoDismiss: true });
      IsEdit = 0;
      return;
    }
    if (IsEdit == 1) {
      
      const formData = new FormData();
      let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
      formData.append('RegisterId', VRegisterId);
      formData.append('Name', Name);
      formData.append('MobileNumber', MobileNo);
      formData.append('Email', Email);
      formData.append('OTPType', '');
      formData.append('DeviceInfo', '');
      formData.append('Gender', Gender);
      formData.append('ProfilePhoto', IsSelectProfileFile == 1 ? ProfileFileArr : '');
      formData.append('EditDateOfBirth', DOB);
      
      for (var pair of formData.entries()) {
        console.log(pair[0] + ':' + pair[1]);
      }
      axios.post(global.APIURL + '/EditProfileData', formData).then(res => {
        setShowLoader(0);
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          setIsSelectProfileFile(0);
          window.location.href = "/manage-profile";
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
        }
      });
    }
    setShowLoader(0);
  }

  function handleChange(e) {
    setShowLoader(1);
    setProfileFileArr(e.target.files[0]);
    setProfileFileUrl(URL.createObjectURL(e.target.files[0]));
    setIsSelectProfileFile(1);
    setShowLoader(0);
  }

  return (
    <React.Fragment>
      {ShowLoader == 1 ?
        <div className="showhidedivloader" >
          <div className="mainloadernew">
            <div className="spinner-border text-primary mainloderedit" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : ''}
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row">
              <div className="col-md-12">
                <p className="addservicetextform">Edit Profile</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mainboxmpnew">
                  <div className="row gx-4">
                    <div className="col-md-10">
                      <div className="row gx-4">
                      <div className="col-md-2 d-md-none d-block mb-2 mb-md-0">
                      <div className="registerinput mt-md-0">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Profile</label>
                        <div>
                          <img src={ProfileFileUrl} className="imgprofileedit"></img>
                        </div>

                      </div>
                      <div>
                        <button type="button" className="btn btn-primary btnuploadnew" id="btnProfileImageEdit" >Upload </button>
                        <input type="file" accept="image/*" id="fuProfileImageEdit" onChange={handleChange} className="d-none" />
                      </div>

                    </div>
                        <div className="col-md-6">
                          <div className="registerinput mt-0">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Name <span className="spanred">*</span></label>
                            <input type="email" className="form-control " id="exampleFormControlInput1" defaultValue={Name} onChange={e => setName(e.target.value)} />
                          </div>

                        </div>

                        <div className="col-md-6">
                          <div className="registerinput mt-md-0">
                            <div>
                              <label htmlFor="exampleFormControlInput1" className="form-label">Email Address <span className="spanred">*</span></label>
                            </div>
                            <div className="input-group mb1vw">
                              <input type="text" className="form-control " aria-label="Recipient's username" aria-describedby="button-addon2" defaultValue={Email} onChange={e => setEmail(e.target.value)} />

                            </div>



                          </div>

                        </div>
                        <div className="col-md-6">
                          <div className="registerinput">
                            <div>
                              <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number <span className="spanred">*</span></label>
                            </div>
                            <div className="input-group mb1vw">
                              <input type="number" className="form-control " aria-label="Recipient's username" aria-describedby="button-addon2" defaultValue={MobileNo} onChange={e => setMobileNo(e.target.value)} maxLength={10} onKeyPress={inputHandler} />

                            </div>

                          </div>


                        </div>

                        <div className="col-md-6">
                          <div className="registerinput">
                            <label htmlFor="exampleFormControlInput1" className="form-label">DOB <span className="spanred">*</span></label>
                            <input type="date" className="form-control" id="exampleFormControlInput1" max={moment().subtract(18,'years').format('YYYY-MM-DD')} onChange={e => setDOB(e.target.value)} defaultValue={DOB} />
                          </div>

                        </div>
                        <div className="col-md-6">
                          <div className="registerinput newselectgenderadd">

                            <label htmlFor="exampleFormControlInput1" className="form-label">Gender <span className="spanred">*</span></label>
                            <select className="form-select" aria-label="Default select example" onChange={e => setGender(e.target.value)}>
                              <option selected>Select Gender</option>
                              <option value="Male" selected={Gender === 'Male'}>Male</option>
                              <option value="Female" selected={Gender === 'Female'}>Female</option>
                              <option value="Transgender" selected={Gender === 'Transgender'}>Transgender</option>
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 d-md-block d-none">
                      <div className="registerinput mt-md-0">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Profile</label>
                        <div>
                          {ProfileFileUrl==""?   <img src={profileimg} className="imgprofileedit"></img>:  
                           <img src={ProfileFileUrl} className="imgprofileedit"></img>}                       
                        </div>
                      </div>
                      <div className="text-md-center">
                        <button type="button" className="btn btn-primary btnuploadnew" id="btnProfileImage" >Upload </button>
                        <input type="file" accept="image/*" id="fuProfileImage" onChange={handleChange} className="d-none" />
                      </div>

                    </div>
                    <div className="col-md-12">
                      <button type="button" className="btn btn-primary btnnextedit " onClick={UpdateProfile}>Save </button>

                    </div>

                  </div>
                </div>

              </div>

            </div>



          </div>

        </div>
      </section>
      <Footeradmin />
    </React.Fragment>
  )


}

export default EditProfile

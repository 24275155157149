import React, { Component, useEffect, useState } from "react"
import landarea1 from "../../assets/images/frontend-img/landarea1.png";
import landarea2 from "../../assets/images/frontend-img/landarea2.png";
import roomsizeicon from "../../assets/images/frontend-img/roomsizeicon.png";
import correct from "../../assets/images/frontend-img/correct.png";
import cross from "../../assets/images/frontend-img/cross.png";
import defaulthomeimg from "../../assets/images/frontend-img/development.png";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import axios from "axios";
import Commheader from "../frontend/header";
import { useToasts } from 'react-toast-notifications';
import loader from "../../assets/images/frontend-img/loader.gif";
import Footeradmin from "../frontend/footeradmin";


const requestService = () => {
  const [ShowLoader, setShowLoader] = useState(0);
  const { addToast } = useToasts();
  const [PropertyList, setPropertyList] = useState([]);
  const [PropertyDetails, setPropertyDetails] = useState([]);
  const [DocumentList, setDocumentList] = useState([]);
  const [PropertyInsights, setPropertyInsights] = useState([]);
  const [PropertyFurnished, setPropertyFurnished] = useState([]);
  const [Media, setMedia] = useState("");
  const [PropertyType1, setPropertyType1] = useState([]);
  const [PropertyType, setPropertyType] = useState([]);
  const [CheckServiceId, setCheckServiceId] = useState(0);
  const [MediaLengthCheck,setMediaLengthCheck]=useState(0);
  const [PaginationList, setPaginationList] = useState([]);
  const URL = "https://admin.colandservices.com";

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    var VPropertyId = JSON.parse(localStorage.getItem('ModalPropertyId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    }
    else {
      getProfileData();
      getPriorityList(1);
      getPropertyType();
   
    }
  }, []);

  const getProfileData = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);
    axios.post(global.APIURL + '/Dashbord', formData)
      .then(res => {
        localStorage.setItem("RequestPropertiesCount", res.data.Result[0].PropertiesCount);
      });
  }

  const getPriorityList = async (PageNumber) => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropListData = {
      RegisterId: VRegisterId,
      Pagination: PageNumber,
      Type: 'Save'
    }
    axios.post(global.APIURL + '/PropertyList', PropListData)
      .then(res => {

        setShowLoader(0);
        var PLArr = [];
        for (let pl = 0; pl < res.data.Result.length; pl++) {
          const VPropertyId = res.data.Result[pl].PropertyId;
          const VPropertyName = res.data.Result[pl].PropertyName;
          const VPropertyType = (res.data.Result[pl].PropertyType == "" ? res.data.Result[pl].OtherPropertyType : res.data.Result[pl].PropertyType);
          const VFullAddress = res.data.Result[pl].FullAddress;
          const VLatititude = res.data.Result[pl].Latititude;
          const VLongitude = res.data.Result[pl].Longitude;
          const VLandArea = res.data.Result[pl].LandArea;
          const VBuiltUpaArea = res.data.Result[pl].BuiltUpaArea;

          var PIArr = [];

          if (res.data.Result[pl].Media != undefined) {

            const ProMediaArr = res.data.Result[pl].Media.filter(x => x.Imagestatus == "true");

            for (let pm = 0; pm < ProMediaArr.length; pm++) {
              const VMediaFileUrl = ProMediaArr[pm].URL;
              const VImagestatus = ProMediaArr[pm].Imagestatus == 'true' ? true : false;
              const PropImageitem = {
                RId: pm,
                SclassName: pm == 0 ? 'carousel-item active' : 'carousel-item',
                SliderName: 'Slider ' + (pm + 1),
                PropImageUrl: VMediaFileUrl,
                Imagestatus: VImagestatus
              };

              PIArr.push(PropImageitem);
            }
          }


          const PLitem = {
            PropertyId: VPropertyId,
            PropertyName: VPropertyName,
            PropertyType: VPropertyType,
            FullAddress: VFullAddress,
            Latititude: VLatititude,
            Longitude: VLongitude,
            LandArea: VLandArea,
            BuiltUpaArea: VBuiltUpaArea,
            PropertyImageList: PIArr,
          };
          PLArr.push(PLitem);

        }
        setPropertyList(PLArr);

        let TPC = parseInt(localStorage.getItem('RequestPropertiesCount') == null ? '0' : localStorage.getItem('RequestPropertiesCount') == '' ? '0' : localStorage.getItem('RequestPropertiesCount'));
        let TPCAL = TPC > 30 ? TPC / 30 : 0;
        var PIPageArr = [];
        for (let tpl = 0; tpl < TPCAL; tpl++) {
          let PN = tpl + 1;
          const PGLitem = {
            PageNumber: PN,
            className: PN == PageNumber ? 'page-item active' : 'page-item'
          };
          PIPageArr.push(PGLitem);
          if (tpl == 0) {
            localStorage.removeItem("PriorityFirstPageNumber");
            localStorage.setItem("PriorityFirstPageNumber", PN);
          }
          localStorage.removeItem("PriorityLastPageNumber");
          localStorage.setItem("PriorityLastPageNumber", PN);
        }
        setPaginationList(PIPageArr);
      });
  }


  const getPropertyType = async () => {
    axios.get(global.APIURL + '/PropertyType')
      .then(res => {
        setPropertyType(res.data.Result);
      });
  }

  const getSelectedPropertyDetails = (e, propId) => {
    setShowLoader(1);
    localStorage.setItem("ServicePropertyId", JSON.stringify(propId));
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let SId = parseInt(propId == null ? '0' : propId == '' ? '0' : propId);
    setCheckServiceId(SId);
    const PropDetailsData = {
      "RegisterId": VRegisterId,
      "PropertyId": propId
    }

    axios.post(global.APIURL + '/propertyDetail', PropDetailsData)
      .then(res => {
     

        setShowLoader(0);
        setPropertyDetails(res.data.Result);
        setPropertyType1(res.data.Result[0].PropertyTypeName == "" ? res.data.Result[0].OtherPropertyType : res.data.Result[0].PropertyTypeName);
        if (res.data.Result) {
          setDocumentList(res.data.Result[0].Document);
          setPropertyInsights(res.data.Result[0].PropertyInsight);
          setPropertyFurnished(res.data.Result[0].PropertyFurnished)
          setMediaLengthCheck(res.data.Result[0].Media);     
            if(res.data.Result[0].Media.length>0){
              const MArray = res.data.Result[0].Media;
              const Media = MArray.filter(x => x.Imagestatus == "true");
              setMedia(Media[0]);            
            }            
        }
      });
  }

  const SaveNext = async () => {
    setShowLoader(1);
    if (CheckServiceId > 0) {
      let PId = JSON.parse(localStorage.getItem('ServicePropertyId') == null ? '0' : localStorage.getItem('ServicePropertyId') == undefined ? '0' : localStorage.getItem('ServicePropertyId') == '' ? '0' : localStorage.getItem('ServicePropertyId'));
      let VSelectPropertyId = parseInt(PId == null ? "0" : PId == "" ? "0" : PId);
      if (VSelectPropertyId > 0) {
        setShowLoader(0);
        window.location.href = "/request-new-service2";
      }
      else {
        setShowLoader(0);
        addToast("Please Select Property", { appearance: 'error', autoDismiss: true });
      }
    }
    else {
      addToast("Please Select Property", { appearance: 'error', autoDismiss: true });
    }
    setShowLoader(0);
  }

  const PageNumberClick = async (e, PageNumber) => {
    getPriorityList(PageNumber);
  }

  const NextPageNumberClick = async () => {
    let CPageNo = parseInt(localStorage.getItem('PriorityCurrentPageNumber') == null ? '0' : localStorage.getItem('PriorityCurrentPageNumber') == '' ? '0' : localStorage.getItem('PriorityCurrentPageNumber'));
    let LPN = parseInt(localStorage.getItem('PriorityLastPageNumber') == null ? '0' : localStorage.getItem('PriorityLastPageNumber') == '' ? '0' : localStorage.getItem('PriorityLastPageNumber'));
   
    if (LPN > CPageNo) {
      let NPageNo = CPageNo + 1;
      getPriorityList(NPageNo);
    }
  }
  const PreviousPageNumberClick = async () => {
    let CPageNo = parseInt(localStorage.getItem('PriorityCurrentPageNumber') == null ? '0' : localStorage.getItem('PriorityCurrentPageNumber') == '' ? '0' : localStorage.getItem('PriorityCurrentPageNumber'));
    let FPN = parseInt(localStorage.getItem('PriorityFirstPageNumber') == null ? '0' : localStorage.getItem('PriorityFirstPageNumber') == '' ? '0' : localStorage.getItem('PriorityFirstPageNumber'));
    if (CPageNo > FPN) {
      let NPageNo = CPageNo - 1;
      getPriorityList(NPageNo);
    }
  }

  return (<React.Fragment>
    {ShowLoader == 1 ?
      <div className="showhidedivloader">
        <div className="mainloadernew">
          <img src={loader} />
        </div>
      </div>
      : ''}

    <Commheader />
    <section>
      <div className="container">
        <div className="mainboxshadow">
          <div className="row">
            <div className="col-md-6">
              <p className="addservicetextform">Add New Service Request </p>
              <p className="mainsteptextform">Step 1 : Select Property</p>
            </div>
            <div className="col-md-6 mb-3 mb-md-0 text-md-end text-start">
              {/* <a href="#" data-bs-toggle="modal" data-bs-target="#addnewpropertymodal" className="addnewpropertytext">  <i className="las la-plus-circle"></i>Add New Property</a> */}
              <a href="/add-new-property" className="addnewpropertytext">  <i className="las la-plus-circle"></i>Add New Property</a>
            </div>


          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="mainleftsideserverequest newservicerequestscroll">
                <div className="d-flex justify-content-between">
                <p className="maintitlereuestservicenee">Select Property  <span className="spanred">*</span></p>
                <div className=""><a id="lnkNext" onClick={() => SaveNext()}><button className="btn btn-smnew mt-0 mb-0 btn-primary btnnextedit ">Next <i className="fas fa-long-arrow-alt-right"></i></button></a></div>

                </div>
                <div>

                  {PropertyList.map((propItems, idx) => (
                    <div className="mainrsdivv" key={idx}>
                      <div className="form-check form-checkneww ps-0">

                        <input className="form-check-input" type="radio" name="exampleRadios" value="option1" onChange={e => getSelectedPropertyDetails(e, propItems.PropertyId)} />

                        <div className="mainserviceselectradiobox" id="exampleRadios1">

                          <div className="d-flex">
                    {propItems.PropertyImageList.length==0?
                    
                    <img className="reqserdefaultimg" src={defaulthomeimg}></img>:
                    <div>  {propItems.PropertyImageList.filter(x => x.Imagestatus == true).map((item2, idx2) => (
                              idx2 == 0 ?
                                <div key={idx2}>
                                  <img src={item2.PropImageUrl} className="serviceselectimgjjk" />
                                </div>
                                : ''
                            ))}</div>}
                          

                            <div className="w-100">
                              <div className="d-flex justify-content-between w-100">
                                <p className="mainpropertytitlelist">{propItems.PropertyName} </p>

                              </div>
                              <div>
                                <p className="propertycatageoried">{propItems.PropertyType}</p>
                              </div>
                              <div className="d-flex">
                                <div>
                                  <img src={locationpropertyedit} className="locationpropertyedit" />
                                </div>
                                <div>
                                  <p className="propertytextlocation mb-0">{propItems.FullAddress}</p>
                                </div>

                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>

                    </div>))}

                  {PaginationList.length > 0 ?
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-end mt-5">
                        <nav aria-label="...">
                          <ul className="pagination">
                            <li className="page-item">
                              <a className="page-link" onClick={(e) => PreviousPageNumberClick()} >Previous</a>
                            </li>
                            {PaginationList.map((PaginationItems, idx) => (
                              <li className={PaginationItems.className} key={idx}><a className="page-link" onClick={(e) => PageNumberClick(e, PaginationItems.PageNumber)}>{PaginationItems.PageNumber}</a></li>
                            ))}
                            <li className="page-item">
                              <a className="page-link" onClick={(e) => NextPageNumberClick()}>Next</a>
                            </li>
                          </ul>
                        </nav>

                      </div>

                    </div> : ''}

                </div>
              </div>
            </div>

            {PropertyDetails?.map((propDetailItems) => (<div className="col-md-7" key={propDetailItems.PropertyId}>
              <div className="mainrightsideboxrequest">
                <p className="maintitlereuestservicenee">Property Details</p>
                <div className="d-md-flex align-items-center">
                  <div>
                   {MediaLengthCheck.length>0? 
                   
                   <img src={Media.Url} className="propertydetailsimgmainn" /> : <img className="propertydetailsdefaultimgmainn" src={defaulthomeimg}></img>}
                                     
                  </div>
                  <div className="w-100">
                    <div className="d-flex justify-content-between w-100">
                      <p className="mainpropertytitlelist">{propDetailItems.PropertyTitle} </p>

                    </div>
                    <div>
                      <p className="propertycatageoried">{PropertyType1} </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <img src={locationpropertyedit} className="locationpropertyedit" />
                      </div>
                      <div>
                        <p className="propertytextlocation mb-0">{propDetailItems.FullAddress}</p>
                      </div>

                    </div>
                    <div className="maindividerpd"> </div>
                    <div>
                      <p className="landareatext">Land Area :</p>
                      <div className="d-flex">
                        <div className="margineditlandarea">
                          <p className="mainlandareatextdescription"> <img src={landarea1} /> {propDetailItems.LandArea} {propDetailItems.LandAreaIn}</p>
                        </div>
                        <div>
                          <p className="mainlandareatextdescription"> <img src={landarea2} /> {propDetailItems.SuperBuiltUP} {propDetailItems.SuperBuiltUpIn}</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <p className="maintitlereuestserviceneebew2">Documents :</p>
                <div className="row">

                  {DocumentList?.filter(x => x.DocumentStatus == 'true').map((docItems) => (<div className="col-md-6" key={docItems.DocumentId}>
                    <a href={URL + docItems.DocumentMedia} target="_blank" rel="noopener noreferrer">
                      <div className="mainpdfdownloadsevice">
                        <div className="d-flex justify-content-between">
                          <div>
                            <p className="downloadpdftext"><i className="las la-file"></i>{docItems.DocumentName}</p>
                          </div>
                          <div>
                            <i className="las la-download downloadiconsrl"></i>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>))}

                </div>
                <p className="maintitlereuestserviceneebew2">Property Insights :</p>

                <div className="row">

                  {PropertyInsights?.filter(x => x.InsightStatus == 'true').map((insightItems) => (
                    <div className="col-md-4 mb-3" key={insightItems.Id}>
                      <div className="propertyinsideboxes">
                        <div>
                          <div id="carouselExampleIndicators4" className="carousel slide propertyimgsliderneww" data-bs-ride="carousel">
                            {/* <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators4" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                          </div> */}
                            <div className="carousel-inner">
                              <div className="maintaginsightbox">{insightItems.InsightName}</div>
                              <div className="carousel-item active">
                                <img src={insightItems.InsightMedia} />
                              </div>

                            </div>
                          </div>
                          <div className="mainpiboxgap">
                            <p className="maintextdescriptiongdhs"><img className="mainroomsizeicon" src={roomsizeicon} />
                              <span className="textroomsize">{insightItems.InsightLength}</span>
                              <span className="textroomsizediver">&times;</span>
                              <span className="textroomsize">{insightItems.InsightWidth}</span>
                              <span className="textroomsizediver">&times;</span>
                              <span className="textroomsize">{insightItems.InsightHeight}</span>
                              {/* <span className="textroomsize sqfttextedit">Sq. ft</span> */}
                            </p>
                            {PropertyFurnished?.filter(x => x.PropertyFurnishedIn == insightItems.InsightName && x.FurnishedStatus == 'true').length > 0 ?

                              <p className="maintextdescriptiongdhs mb-0"><img className="mainroomsizeiconcorrect" src={correct} /><span className="textroomsize">Furnish Property</span></p>
                              : ''
                            }
                            {PropertyFurnished?.filter(x => x.PropertyFurnishedIn == insightItems.InsightName && x.FurnishedStatus == 'true').length > 0 ?
                              <div className="maindvidertextdescription">
                              </div> : ''
                            }
                            <p className="mainfooterboxspinsight">
                              {PropertyFurnished?.filter(x => x.PropertyFurnishedIn == insightItems.InsightName && x.FurnishedStatus == 'true').map((PFItems, idx1) => (
                                idx1 == 0 ?
                                  <span key={idx1}>{PFItems.FurnishedDetails}</span>
                                  :
                                  <span key={idx1}>, {PFItems.FurnishedDetails}</span>
                              ))}</p>

                            {PropertyFurnished?.filter(x => x.PropertyFurnishedIn == insightItems.InsightName && x.FurnishedStatus == 'true').length == 0 ?
                              <div>
                                <p className="maintextdescriptiongdhs mb-0"><img className="mainroomsizeiconcorrect" src={cross} /><span className="textroomsize">Furnish Property</span></p>
                                <div className="maindvidertextdescription">
                                </div>
                                <p className="mainfooterboxspinsight">No Furniture Available</p>
                              </div> : ''
                            }
                          </div>


                        </div>

                      </div>

                    </div>
                  ))}

                </div>

              </div>

            </div>))}

            
          </div>
        </div>


      </div>
    </section>
    <Footeradmin />
  </React.Fragment>);
}


export default requestService

import React, { useEffect, useState } from "react"
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import verified from "../../assets/images/frontend-img/verified.png";
import shaprightrequest from "../../assets/images/frontend-img/shaprightrequest.png";
import shapleftrequest from "../../assets/images/frontend-img/shapleftrequest.png";
import propertyiconedit from "../../assets/images/frontend-img/propertyiconedit.png";
import serviceiconedit from "../../assets/images/frontend-img/serviceiconedit.png";
import spenticonedit from "../../assets/images/frontend-img/spenticonedit.png";
import loader from "../../assets/images/frontend-img/loader.gif";
import paymentsuccessfully from "../../assets/images/frontend-img/paymentsuccessfully.png";
import cancel from "../../assets/images/frontend-img/cancel.png";
import failed from "../../assets/images/frontend-img/failed.png";
import verifiedgreen from "../../assets/images/frontend-img/verifiedgreen.svg";
import locationpropertyedit from "../../assets/images/frontend-img/locationpropertyedit.svg";
import mainpaymentloadingimg from "../../assets/images/frontend-img/mainpaymentloadingimg.png";
import referfriendimg from "../../assets/images/frontend-img/referfriendimg.png";
import axios from "axios";
import Commheader from "./header";
import Footeradmin from "./footeradmin";
import { useToasts } from 'react-toast-notifications';
import nodata from "../../assets/images/frontend-img/nodata.png";
import { useRef } from "react";
import defaulthomeimg from "../../assets/images/frontend-img/development.png";

const DashboardNew = () => {
  const [TopPropertiesList, setTopPropertiesList] = useState([]);
  const [RecentServicesList, setRecentServicesList] = useState([]);
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [CompanyName, setCompanyName] = useState('');
  const [EmailVerified, setEmailVerified] = useState('No');
  const [MobileVerified, setMobileVerified] = useState('No');
  const [PropertiesCount, setPropertiesCount] = useState('');
  const [ServiceCount, setServiceCount] = useState('');
  const [TotalSpentAmount, setTotalSpentAmount] = useState('');
  const [ShowLoader, setShowLoader] = useState(0);
  const { addToast } = useToasts();
  const [MembershipDetail, setMembershipDetail] = useState([]);
  const [UserMembershipList, setUserMembershipList] = useState([]);
  const [PayAmount, setPayAmount] = useState(0);
  const [PlanTitle, setPlanTitle] = useState('');
  const [RName, setRName] = useState('');
  const [REmailAddress, setREmailAddress] = useState('');
  const [RMobileNumber, setRMobileNumber] = useState('');
  const [RMessage, setRMessage] = useState('');


  const [ServiceId, setServiceId] = useState(0);
  const [ServiceCode, setServiceCode] = useState('');
  const [ServiceHistoryType, setServiceHistoryType] = useState('');
  const [ServicePayAmount, setServicePayAmount] = useState(0);
  const [ServiceTitle, setServiceTitle] = useState('');
  const [PropertyName, setPropertyName] = useState('');

  const [OTP1, setOTP1] = useState([]);
  const [OTP2, setOTP2] = useState([]);
  const [OTP3, setOTP3] = useState([]);
  const [OTP4, setOTP4] = useState([]);

  const [OTP5, setOTP5] = useState([]);
  const [OTP6, setOTP6] = useState([]);
  const [OTP7, setOTP7] = useState([]);
  const [OTP8, setOTP8] = useState([]);

  const num1 = useRef(null);
  const num2 = useRef();
  const num3 = useRef();
  const num4 = useRef();

  const num5 = useRef(null);
  const num6 = useRef();
  const num7 = useRef();
  const num8 = useRef();

  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
    if (VRegisterId == null) {
      window.location.href = "/home";
    } else if (VRegisterId == "") {
      window.location.href = "/home";
    } else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      getTransactionHistoryTotal();
      getCompanyDetail();
      getProfileData();
      getMembershipList();
      getMembershipDetail();
    }

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const getTransactionHistoryTotal = () => {
    let TotalTransactionAmount = 0;
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);
    axios.post(global.APIURL + '/TransactionHistory', formData)
      .then(LPres => {
        for (let th = 0; th < LPres.data.Result.length; th++) {
          const TA = parseFloat(LPres.data.Result[th].Amount == null ? '0' : LPres.data.Result[th].Amount == '' ? '0' : LPres.data.Result[th].Amount);
          TotalTransactionAmount = TotalTransactionAmount + TA;
          setTotalSpentAmount(TotalTransactionAmount);
        }
      });
  }

  const getCompanyDetail = async () => {
    axios.get(global.APIURL + "/CompanyDetail")
      .then(res => {
        if(res.data.Result.length>0){
          setCompanyName(res.data.Result[0].CompanyName);
        }
       
      });
  };


  const getMembershipDetail = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);

    axios.post(global.APIURL + '/MyProfileData', formData)
      .then(res => {
        var MembershipDetail = res.data.Result[0].Membership;
        setMembershipDetail(MembershipDetail);
      });
    setShowLoader(0);
  }

  const getProfileData = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);
    axios.post(global.APIURL + '/Dashbord', formData)
      .then(res => {
        console.log("dashboard", res);
        setEmail(res.data.Result[0].UserEmail);
        setName(res.data.Result[0].UserName);
        setMobileNo(res.data.Result[0].UserNumber)
        setEmailVerified(res.data.Result[0].IsEmailVerified)
        setMobileVerified(res.data.Result[0].IsNumberVerified)
        setPropertiesCount(res.data.Result[0].PropertiesCount)
        setServiceCount(res.data.Result[0].ServiceCount)
        setRecentServicesList(res.data.Result[0].RecentService)
        var RecentProperty = res.data.Result[0].RecentProperty;
        let TopPropertiesArr = [];
        for (let rp = 0; rp < RecentProperty.length; rp++) {
          const ProImgArr = RecentProperty[rp].Images.filter(x => x.Imagestatus == "true");
          var PropertyImagesArr = [];
          for (let pi = 0; pi < ProImgArr.length; pi++) {
            const Url = ProImgArr[pi].Url;
            const VImagestatus = ProImgArr[pi].Imagestatus == 'true' ? true : false;
            const PropImageitem = {
              RId: pi,
              SclassName: pi == 0 ? 'carousel-item active' : 'carousel-item',
              SliderName: 'Slider ' + (pi + 1),
              PropImageUrl: Url,
              Imagestatus: VImagestatus
            };
            PropertyImagesArr.push(PropImageitem);
          }
          const RecentPropertyitem = {
            PropertyId: RecentProperty[rp].PropertyId,
            PropertyName: RecentProperty[rp].PropertyName,
            PropertyType: RecentProperty[rp].PropertyType == "" ? RecentProperty[rp].OtherPropertyType : RecentProperty[rp].PropertyType,
            FullAddress: RecentProperty[rp].FullAddress,
            Latititude: RecentProperty[rp].Latititude,
            Longitude: RecentProperty[rp].Longitude,
            LandArea: RecentProperty[rp].LandArea,
            BuiltUpaArea: RecentProperty[rp].BuiltUpaArea,
            PropertyImages: PropertyImagesArr,
          };
          TopPropertiesArr.push(RecentPropertyitem);
        }
        setTopPropertiesList(TopPropertiesArr);
     
      });
  }

  const getMembershipList = async () => {
    axios.get(global.APIURL + "/MembershipGet")
      .then(res => {
     
        setUserMembershipList(res.data.Result);
      });
  };

  const sendMobileOTP = async () => {

    setShowLoader(1);
    const formData = new FormData();

    formData.append('MobileNumber', MobileNo);
    formData.append('Email', "");
    formData.append('OTPType', "MobileNumber");
    formData.append('DeviceInfo', "");

    axios.post(global.APIURL + '/SendOTP', formData)
      .then(res => {
        console.log("send-mobile-api", res.data.OTP);
      });
    setShowLoader(0);
  }

  const sendEmailOTP = async () => {
    setShowLoader(1);
    const formData = new FormData();

    formData.append('MobileNumber', "");
    formData.append('Email', Email);
    formData.append('OTPType', "Email");
    formData.append('DeviceInfo', "");

    axios.post(global.APIURL + '/SendOTP', formData)
      .then(res => {
        console.log("Send-email-resapi", res.data.OTP);
      });
    setShowLoader(0);
  }


  const handleOtp5Change = (value) => {
    if (event.target.value != "") {
      num6.current.focus();
    }

    setOTP5(value);

  }
  const handleOtp6Change = (value) => {

    num7.current.focus();
    setOTP6(value);
    if (event.target.value == "") {
      num5.current.focus();
    }

  }
  const handleOtp7Change = (value) => {

    num8.current.focus();
    setOTP7(value);
    if (event.target.value == "") {
      num6.current.focus();
    }

  }
  const handleOtp8Change = (value) => {

    setOTP8(value);
    if (event.target.value == "") {
      num7.current.focus();
    }

  }

  const verifyEmailOTP = async () => {

    setShowLoader(1);
    const OTP = OTP5 + OTP6 + OTP7 + OTP8;
    const formData = new FormData();

    formData.append('MobileNumber', "");
    formData.append('Email', Email);
    formData.append('OTPType', "Email");
    formData.append('OTP', OTP);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ':' + pair[1]);
    }

    axios.post(global.APIURL + '/OtpVerify', formData)
      .then(res => {
        console.log("verifyemailotp", res);
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          $('#MPEmailAddressVerification').modal('hide');
          setEmailVerified('Yes');
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          setEmailVerified('No');
        }
      });
    setShowLoader(0);
  }

  const handleOtp1Change = (value) => {

    if (event.target.value != "") {
      num2.current.focus();
    }
    setOTP1(value);
  }
  const handleOtp2Change = (value) => {

    num3.current.focus();
    setOTP2(value);
    if (event.target.value == "") {
      num1.current.focus();
    }

  }
  const handleOtp3Change = (value) => {

    num4.current.focus();
    setOTP3(value);
    if (event.target.value == "") {
      num2.current.focus();
    }

  }
  const handleOtp4Change = (value) => {

    setOTP4(value);
    if (event.target.value == "") {
      num3.current.focus();
    }

  }

  const verifyMobileOTP = async () => {
    setShowLoader(1);

    const OTP = OTP1 + OTP2 + OTP3 + OTP4;

    const formData = new FormData();
    formData.append('MobileNumber', MobileNo);
    formData.append('Email', "");
    formData.append('OTPType', "MobileNumber");
    formData.append('OTP', OTP);

    axios.post(global.APIURL + '/OtpVerify', formData)
      .then(res => {
        console.log("verifymobile", res);
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          $('#MPMobileVerification').modal('hide');
          setMobileVerified('Yes');
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          setMobileVerified('Yes');
        }
      });
    setShowLoader(1);
  }
  // const BuyMemberShip = (e, PlanId, Price) => {
  //   const formData = new FormData();
  //   let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
  //   formData.append('RegisterId', VRegisterId);
  //   formData.append('PlanId', PlanId);
  //   formData.append('Price', Price);
  //   formData.append('TransactionId', PlanId);
  //   for (var pair of formData.entries()) {
  //     console.log(pair[0] + ':' + pair[1]);
  //   }
  //   axios.post(global.APIURL + '/MembershipByPlan', formData).then(res => {
  //     setShowLoader(0);
  //     $('#MPMembership').modal('hide');
  //     addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
  //     getProfileData();
  //   });
  // };
  const BuyMemberShip = (e, PlanId, Price, PlanName) => {
    console.log(PlanId, Price, PlanName);
    if(Price!=0){
      const razordata = {
        amount: Price * 100,
        currency: "INR",
        receipt: PlanId,
        payment_capture: 1,
      };
      setPayAmount(parseFloat(Price == null ? "0" : Price == "" ? "0" : Price));
      setPlanTitle(PlanName == null ? "" : PlanName);
      console.log("lblPlanName", PlanName); 
      $("#lblPayAmount").html(Price);
      $("#lblPlanName").html(PlanName);  
      axios.post(global.APIURL + "/RazorPay", razordata).then(res => {
        console.log("razor-res", res);
        let order_id = res.data.id;
        let status = res.data.status;
        if (status == "created") {
          let options = { 
            key_id: "rzp_live_xPdYwT48IAXvEq",
            key_secret: "ScLGAiA7Zx46aiTRvDTlFOrK",
            // key_id : "rzp_test_iK4zpshfmPYkHU",
            // key_secret : "oVPkCWmGnEbNbFKWdh0eFFz2",
            amount:parseFloat(Price == null ? "0" : Price == "" ? "0" : Price) * 100, // 2000 paise = INR 20, amount in paisa
            name: CompanyName,
            description: PlanName,
            image: "/your_logo.png",
            order_id: order_id,
            handler: function (response) {
              console.log("response pay", response);
              let Vrazorpay_order_id = response.razorpay_order_id == null ? "" : response.razorpay_order_id;
              $("#lblOrderId").html(Vrazorpay_order_id);
              if (Vrazorpay_order_id != "") {
                let url = global.APIURL + `/RazorOrderDetails/${Vrazorpay_order_id}/payments`;
                axios.get(url).then(res => {
                  console.log("payment-res", res);
                  console.log("payment-res1", res.data.items[0].status);
                  console.log("xxxx", res.data.items[0].acquirer_data.upi_transaction_id);
                  let VStatus = res.data.items[0].status;  
                  // let Vtransaction_id = (res.data.items[0].acquirer_data.upi_transaction_id==undefined?res.data.items[0].acquirer_data.bank_transaction_id:res.data.items[0].acquirer_data.upi_transaction_id);            
                  let VMethod = res.data.items[0].method;
                  let Vtransaction_id;  
                  if (VMethod == "netbanking") {
                    Vtransaction_id = res.data.items[0].acquirer_data.bank_transaction_id;
                  }
                  else if (VMethod == "wallet") {
                    Vtransaction_id = res.data.items[0].acquirer_data.transaction_id;
                  }
                  else if (VMethod == "upi") {
                    Vtransaction_id = res.data.items[0].acquirer_data.upi_transaction_id;
                  }                  
                  let mode_of_payment = res.data.items[0].method;
                  console.log("tid", Vtransaction_id)
                  $("#lblModeOfPayment").html(mode_of_payment);
                  if (VStatus == "captured") {
                    const formData = new FormData();
                    let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
                    formData.append("RegisterId", VRegisterId);
                    formData.append("PlanId", PlanId);
                    formData.append("Price", Price);
                    formData.append("TransactionId", Vtransaction_id);
                    formData.append("ModeOfPayment", mode_of_payment);  
                    for (var pair of formData.entries()) {
                      console.log(pair[0] + ":" + pair[1]);
                    }
                    axios
                      .post(global.APIURL + "/MembershipByPlan", formData)
                      .then(res => {
                        const formData1 = new FormData();
                        let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
                        formData1.append("RegisterId", VRegisterId);
                        axios.post(global.APIURL + "/MembershipPurchase", formData1)
                        .then(res => {                        
                          addToast("MembershipPurchase", {
                            appearance: "success",
                            autoDismiss: true,
                          });                        
                        });                     
                        setShowLoader(0);
                        $("#MPMembership").modal("hide");
                        $('#paymentsuccessfully').modal('show');
                        addToast(res.data.StatusMessage, {
                          appearance: "success",
                          autoDismiss: true,
                        });
               getProfileData();
                      });
                  }
                  else {
                    addToast("Payment Fail", {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  }
                });
              } else {
                addToast("Payment Fail", {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            },
            modal: { escape: false, ondismiss: function () { addToast("Payment Cancelled", { appearance: "error", autoDismiss: true, }); }, },
            prefill: {
              name: CompanyName,
              email: Email,
              mobile: MobileNo,
            },
            notes: {
              address: "Hello World",
            },
            theme: {
              color: "#F37254",
            },
          };
          let rzp = new window.Razorpay(options);
          rzp.open();
        }
        else {        
          addToast("order not created", {
            appearance: "error",
            autoDismiss: true,
          });
          getMembershipDetail();
        }
      });
    }
    else{
      const formData = new FormData();
      let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
      formData.append("RegisterId", VRegisterId);
      formData.append("PlanId", PlanId);
      formData.append("Price", Price);
      formData.append("TransactionId", "");
      formData.append("ModeOfPayment", "");
      for (var pair of formData.entries()) {
        console.log(pair[0] + ":" + pair[1]);
      }
      axios
        .post(global.APIURL + "/MembershipByPlan", formData)
        .then(res => {
          console.log("res",res);
          const formData1 = new FormData();
          let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
          formData1.append("RegisterId", VRegisterId);
          axios.post(global.APIURL + "/MembershipPurchase", formData1)
          .then(res => {          
            console.log("res--0",res);
          });
          setShowLoader(0);
          $("#MPMembership").modal("hide");
          $('#paymentsuccessfully').modal('show');      
          addToast(res.data.StatusMessage, {
            appearance: "error",
            autoDismiss: true,
          });
          getMembershipDetail();
        });
    }
  };

  const CheckProperty = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const PropListData = {
      RegisterId: VRegisterId,
      Pagination: 1
    }
    axios.post(global.APIURL + '/PropertyList', PropListData)
      .then(res => {
        setShowLoader(0);
        var PropertyListCount = res.data.Result.length;
        if (PropertyListCount == 0) {
          addToast("Oops, Please Complete The Add Property Section First.", { appearance: 'error', autoDismiss: true });
        }
        else {
          window.location.href = "/request-new-service";
        }
      });
  }

  const ServicePayment = (e, ServiceId, HistoryType, PayAmount, ServiceName, PropertyName) => {
    setShowLoader(1);
    let VSelectId = parseInt(ServiceId == null ? "0" : ServiceId == "" ? "0" : ServiceId);
    setServiceId(VSelectId);
    let SL = VSelectId.toString().length;
    let VServiceCode = '';
    if (SL == 1) {
      VServiceCode = '#SR0000000' + VSelectId.toString();
    }
    else if (SL == 2) {
      VServiceCode = '#SR000000' + VSelectId.toString();
    }
    else if (SL == 3) {
      VServiceCode = '#SR00000' + VSelectId.toString();
    }
    else if (SL == 4) {
      VServiceCode = '#SR0000' + VSelectId.toString();
    }
    else if (SL == 5) {
      VServiceCode = '#SR000' + VSelectId.toString();
    }
    else if (SL == 6) {
      VServiceCode = '#SR00' + VSelectId.toString();
    }
    else if (SL == 7) {
      VServiceCode = '#SR0' + VSelectId.toString();
    }
    else if (SL == 8) {
      VServiceCode = '#SR' + VSelectId.toString();
    }
    setServiceCode(VServiceCode);
    setServiceHistoryType(HistoryType);
    setServicePayAmount(parseFloat(PayAmount == null ? '0' : PayAmount == '' ? '0' : PayAmount));
    setServiceTitle(ServiceName);
    setPropertyName(PropertyName);
    let lessAmount = parseFloat(PayAmount == null ? '0' : PayAmount == '' ? '0' : PayAmount)*10/100; 
    let SAPaid = parseFloat(PayAmount == null ? '0' : PayAmount == '' ? '0' : PayAmount) - lessAmount;
    let SLPaid = parseFloat(PayAmount == null ? '0' : PayAmount == '' ? '0' : PayAmount);
    let PaymentAmount = HistoryType == 'AdvancePaid' ? SAPaid : SLPaid;
    $("#lblServicePaymentAmount").html(PaymentAmount == null ? '0' : PaymentAmount == '' ? '0' : PaymentAmount);
    $('#servicepaymentmodal').modal('show');
    setShowLoader(0);
  };

  const ServiceStatusChange = (e) => {
    setShowLoader(1);
    const formData = new FormData();
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let lessAmount = parseFloat(ServicePayAmount == null ? '0' : ServicePayAmount == '' ? '0' : ServicePayAmount)*10/100; 
    let SAPaid = parseFloat(ServicePayAmount) - lessAmount;
    let SLPaid = parseFloat(ServicePayAmount);
    let PaymentAmount = ServiceHistoryType == 'AdvancePaid' ? SAPaid : SLPaid;//parseFloat($("#lblPaymentAmount").html() == null ? '0' : $("#lblPaymentAmount").html() == '' ? '0' : $("#lblPaymentAmount").html());
    $("#lblServiceTotalPayAmount").html(PaymentAmount == null ? '0' : PaymentAmount == '' ? '0' : PaymentAmount);
    const razordata = { amount: PaymentAmount * 100, currency: "INR", receipt: ServiceCode, payment_capture: 1 };
    console.log(razordata);
    axios.post(global.APIURL + "/RazorPay", razordata).then(res => {
      console.log("razor-res", res);
      let order_id = res.data.id;
      let status = res.data.status;
      if (status == "created") {
        let options = {
          key_id: "rzp_live_xPdYwT48IAXvEq",
          key_secret: "ScLGAiA7Zx46aiTRvDTlFOrK",
          // key_id : "rzp_test_iK4zpshfmPYkHU",
          // key_secret : "oVPkCWmGnEbNbFKWdh0eFFz2",
          amount: PaymentAmount * 100, // 2000 paise = INR 20, amount in paisa
          name: CompanyName,
          description: ServiceCode,
          image: "/your_logo.png",
          order_id: order_id,
          handler: function (response) {
            let Vrazorpay_order_id = response.razorpay_order_id == null ? "" : response.razorpay_order_id;
            $("#lblServiceOrderId").html(Vrazorpay_order_id);
            if (Vrazorpay_order_id != "") {
              let url = global.APIURL + `/RazorOrderDetails/${Vrazorpay_order_id}/payments`;
              axios.get(url).then(res => {
                let VStatus = res.data.items[0].status;
                let Vtransaction_id = res.data.items[0].acquirer_data.upi_transaction_id;
                let mode_of_payment = res.data.items[0].method;
                $("#lblServiceModeOfPayment").html(mode_of_payment);
                if (VStatus == "captured") {
                  formData.append('RegisterId', VRegisterId);
                  formData.append('ServiceId', ServiceId);
                  formData.append('HistoryType', ServiceHistoryType);
                  formData.append('AmountPaid', PaymentAmount);
                  formData.append('TransactionId', Vtransaction_id);
                  axios.post(global.APIURL + '/StatusChange', formData).then(res => {
                    if (res.data.Status == true) {
                      console.log(res.data);
                      setShowLoader(0);
                      getProfileData();
                      addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
                      $('#servicepaymentmodal').modal('hide');
                      $('#servicepaymentsuccessfully').modal('show');
                    }
                    else {
                      addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
                      $('#servicepaymentmodal').modal('show');
                    }
                  });
                }
                else {
                  addToast("Payment Fail", {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              });
            } else {
              addToast("Payment Fail", {
                appearance: "error",
                autoDismiss: true,
              });
            }
          },
          modal: { escape: false, ondismiss: function () { addToast("Payment Cancelled", { appearance: "error", autoDismiss: true, }); }, },
          prefill: {
            name: CompanyName,
            email: Email,
            mobile: MobileNo,
          },
          notes: {
            address: "Hello World",
          },
          theme: {
            color: "#F37254",
          },
        };
        let rzp = new window.Razorpay(options);
        rzp.open();
      }
      else {
        addToast("order not created", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const SaveNewReferrals = async () => {
    let IsInsert = 1;
    if (RName == null) {
      addToast("Please Enter Name.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (RName == '') {
      addToast("Please Enter Name.", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (REmailAddress == null) {
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (RMobileNumber == '') {
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (regex.test(RMobileNumber) === false) {
      addToast("Please Enter Valid Email Address", { appearance: 'error', autoDismiss: true });
      return;

    }

    if (RMobileNumber == null) {
      addToast("Please Enter Mobile Number", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (RMobileNumber == '') {
      addToast("Please Enter Mobile Number", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (RMobileNumber.length < 10) {
      addToast("Please Enter Valid Mobile Number", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (RMessage == null) {
      addToast("Please Enter Message", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }
    if (RMessage == '') {
      addToast("Please Enter Message", { appearance: 'error', autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (IsInsert > 0) {
      const formData = new FormData();
      let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
      formData.append('RegisterId', VRegisterId);
      formData.append('Name', RName);
      formData.append('EmailAddress', REmailAddress);
      formData.append('MobileNumber', RMobileNumber);
      formData.append('Message', RMessage);

      axios.post(global.APIURL + '/ReferFriend', formData).then(res => {
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          $('#referfriend').modal('hide');
          document.getElementById("txtRName").value = "";
          document.getElementById("txtREmailAddress").value = "";
          document.getElementById("txtRMobileNumber").value = "";
          document.getElementById("txtRMessage").value = "";
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
        }
      });
    }
  }
  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  const ServiceDetail = (e, ServiceId, PropertyId) => {
    localStorage.setItem("DetailServiceId", JSON.stringify(ServiceId));
    localStorage.setItem("DetailPropertyId", JSON.stringify(PropertyId));
    window.location.href = "/service-details";
  };


  return (<React.Fragment>
    {ShowLoader == 1 ?
      <div className="showhidedivloader">
        <div className="mainloadernew">
          <img src={loader} />
        </div>
      </div>
      : ''}
    <div className="modal fade" id="referfriend" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mainrefermodal">
        <div className="modal-content">

          <div className="modal-body">
            <div className="maindivreferefriend">
              <div className="text-center">
                <p className="lognmaintexteditnew">Refer a Friend</p>
                <p className="maindescriptiontextlogin">Invite your friend, they hit the road and you make savings!</p>
                <img className="mainreferfriengimgedit" src={referfriendimg}></img>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mainformlogininput">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                    <input type="text" className="form-control" id="txtName" placeholder="Enter Name" onChange={e => setRName(e.target.value)} />
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="mainformlogininput">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                    <input type="text" className="form-control" id="txtEmailAddress" placeholder="Enter Email Address" onChange={e => setREmailAddress(e.target.value)} />
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="mainformlogininput">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number</label>
                    <input type="number" className="form-control" id="txtMobileNumber" placeholder="Enter Mobile Number" maxLength={10} onKeyPress={inputHandler} onChange={e => setRMobileNumber(e.target.value)} />
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="mainformlogininput">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Message</label>
                    <textarea type="textarea" rows={1} className="form-control" id="txtMessage" placeholder="Enter Message" onChange={e => setRMessage(e.target.value)}></textarea>
                  </div>

                </div>
                <div className="col-md-12 mainreferbtn text-center">
                  <button type="button" className="btn btn-primary btnloginnew" onClick={SaveNewReferrals}>Refer Now</button>
                </div>

              </div>


            </div>

          </div>

        </div>
      </div>
    </div>
    <div className="modal fade" id="paymentsuccessfully" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog mainpaymentmodaldiv modal-dialog-centered">
        <div className="modal-content">

          <div className="modal-body">
            <div className="mainpaymentmodalbox">
              <div className="text-center">
                <img src={paymentsuccessfully} className="paymentloadingimg"></img>
                <p className="mainaymentbluetext">Payment Successful</p>
                <p className="mainpaymentmodaldetails">Thank you for your billing</p>
              </div>
              <div className="text-center">
                <div className="mainpaymentamountboxnew">
                  <p className="paymentsuccessfullyamount">₹ <label id="lblPayAmount">0</label> </p>
                  <p className="paymentsuuceefullytext">Has Been Paid</p>
                  <p className="maininvoiceidname">Invoice : <span><label id="lblOrderId"></label></span></p>
                </div>
              </div>
              <hr className="mainhrmodapayment"></hr>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <p className="mainpaymentdetailstitle">Mode of Payment</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="mainpaymentsuccessdetails"><label id="lblModeOfPayment"></label></p>
                </div>
                <div className="col-md-6">
                  <div>
                    <p className="mainpaymentdetailstitle">Plan</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="mainpaymentsuccessdetails"><label id="lblPlanName"></label></p>
                </div>
                <div className="col-md-12 mainbtnfooterpayment">
                  <a className="btn btn-primary btnnexteditlineblue w-100 d-block m-0" data-bs-dismiss="modal" aria-label="Close">Finish</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="cancelapayment" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog mainpaymentmodaldiv modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="mainpaymentmodalbox">
              <div className="text-center">
                <img src={cancel} className="paymentloadingimg"></img>
                <p className="mainaymentbluetext">Cancel Payment</p>
                <p className="mainpaymentmodaldetails">Please confirm that you wish to cancel this transaction and will be charged. </p>
              </div>
              <div className="row">
                <div className="col-md-6 mainbtnfooterpayment">
                  <button type="button" className="btn btn-primary btnnexteditpm w-100 d-block m-0" >Pay Now</button>
                </div>
                <div className="col-md-6 mainbtnfooterpayment">
                  <button type="button" className="btn btn-primary btnnexteditlinepm w-100 d-block m-0" data-bs-dismiss="modal" aria-label="Close">Cancel Payment </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="paymentfailed" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog mainpaymentmodaldiv modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="mainpaymentmodalbox">
              <div className="text-center">
                <img src={failed} className="paymentloadingimg"></img>
                <p className="mainaymentbluetext">Oh No, Your Payment Failed</p>
                <p className="mainpaymentmodaldetails">Sorry, We are not able to process your payment at the moment </p>
              </div>
              <div className="row">
                <div className="col-md-6 mainbtnfooterpayment">
                  <button type="button" className="btn btn-primary btnnexteditpm w-100 d-block m-0" >Try Again</button>
                </div>
                <div className="col-md-6 mainbtnfooterpayment">
                  <button type="button" className="btn btn-primary btnnexteditlineblue w-100 d-block m-0" data-bs-dismiss="modal" aria-label="Close" >Close </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="MPMembership" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modalheight ">
        <div className="modal-content newmodaledithight">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"> Membership Plan</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="row miandivpricingbox mt-0">
              {UserMembershipList.map((item, idx) => (
                <div className="col-md-3" key={idx}>
                  <div className="mainpricetablemodal">
                    <div className="boxpricing boxpricing1">
                      <p className="plantitle text-center">
                        {item.Name}
                      </p>
                      <p className="pricingtext">
                        ₹{item.Price}<span>/{item.ValidUpTo.replace(/[0-9]/g, '')}</span>
                      </p>
                      <div className="dividerpricing"></div>
                      <div className="contentpricing">
                        <div className="contentscroll" dangerouslySetInnerHTML={{ __html: item.Description }}></div>
                        <a className="btn pricingblubtnnewmodal w-100 d-block" id="btnBuy" onClick={e => BuyMemberShip(e, item.Id, item.Price, item.Name)}>Buy</a>
                      </div>
                    </div>
                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="MPMobileVerification" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" autoFocus={false} >
      <div className="modal-dialog modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header">
            <p className="verificationeditext">
              Verification
            </p>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <img src={otpicon} ></img>
            </div>
            <div>
              <form action="" className="formotp">
                <div>
                  <p className="otpverificationtitlenew">Mobile Number Verification</p>
                </div>
                <input autoFocus="autoFocus" className="otp mr1vw" type="text" maxLength='1' id="OTP1" ref={num1} onChange={(e) => handleOtp1Change(e.target.value)} />
                <input className="otp mr1vw" type="text" on maxLength='1' id="OTP2" ref={num2} onChange={(e) => handleOtp2Change(e.target.value)} />
                <input className="otp mr1vw" type="text" maxLength='1' id="OTP3" ref={num3} onChange={(e) => handleOtp3Change(e.target.value)} />
                <input className="otp" type="text" maxLength='1' id="OTP4" ref={num4} onChange={(e) => handleOtp4Change(e.target.value)} />
              </form>

              <div className="text-center">
                <button type="button" className="btn btn-primary btnnextedit " onClick={verifyMobileOTP} >Continue <i className="fas fa-long-arrow-alt-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="MPEmailAddressVerification" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="verificationeditext">
              Verification
            </p>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <img src={otpicon} ></img>
            </div>
            <div>
              <form action="" className="formotp">
                <div>
                  <p className="otpverificationtitlenew">Email Address Verification</p>
                </div>
                <input className="otp mr1vw" type="text" maxLength='1' id="OTP5" ref={num5} onChange={(e) => handleOtp5Change(e.target.value)} />
                <input className="otp mr1vw" type="text" on maxLength='1' id="OTP6" ref={num6} onChange={(e) => handleOtp6Change(e.target.value)} />
                <input className="otp mr1vw" type="text" maxLength='1' id="OTP7" ref={num7} onChange={(e) => handleOtp7Change(e.target.value)} />
                <input className="otp" type="text" maxLength='1' id="OTP8" ref={num8} onChange={(e) => handleOtp8Change(e.target.value)} />
              </form>
              <div className="text-center">
                <button type="button" className="btn btn-primary btnnextedit " onClick={verifyEmailOTP} >Continue <i className="fas fa-long-arrow-alt-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="servicepaymentmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog mainpaymentmodaldiv modal-dialog-centered">
        <div className="modal-content">

          <div className="modal-body">
            <div className="mainpaymentmodalbox">
              <div className="text-center">
                <img src={mainpaymentloadingimg} className="paymentloadingimg"></img>
                <p className="mainaymentbluetext">Hang Tight!</p>
                <p className="mainpaymentmodaldetails">You are bring redirected to another page, it may takes upto 10 seconds</p>

              </div>
              <div className="text-center">
                <div className="mainpaymentamountbox">
                  <p className="mainpaymentamounttext">Payment Of <span>₹<label id="lblServicePaymentAmount">0</label> </span> </p>
                  <p className="paymentiserviceid">Service Id {ServiceCode}</p>
                </div>
                <div className="col-md-12 mainbtnfooterpayment">
                  <button type="button" className="btn btn-primary btnnexteditpm w-100 d-block m-0" onClick={e => ServiceStatusChange(e)}>Next</button>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
    <div className="modal fade" id="servicepaymentsuccessfully" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog mainpaymentmodaldiv modal-dialog-centered">
        <div className="modal-content">

          <div className="modal-body">
            <div className="mainpaymentmodalbox">
              <div className="text-center">
                <img src={paymentsuccessfully} className="paymentloadingimg"></img>
                <p className="mainaymentbluetext">Payment Successful</p>
                <p className="mainpaymentmodaldetails">Thank you for your billing</p>

              </div>
              <div className="text-center">
                <div className="mainpaymentamountboxnew">
                  <p className="paymentsuccessfullyamount">₹ <label id="lblServiceTotalPayAmount">0</label> </p>
                  <p className="paymentsuuceefullytext">Has Been Paid</p>
                  <p className="maininvoiceidname">Invoice : <span><label id="lblServiceOrderId"></label></span></p>


                </div>
              </div>
              <hr className="mainhrmodapayment"></hr>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <p className="mainpaymentdetailstitle">Mode of Payment</p>

                  </div>

                </div>
                <div className="col-md-6">
                  <p className="mainpaymentsuccessdetails"><label id="lblServiceModeOfPayment"></label></p>
                </div>
                <div className="col-md-6">
                  <div>
                    <p className="mainpaymentdetailstitle">Service ID</p>

                  </div>

                </div>
                <div className="col-md-6">
                  <p className="mainpaymentsuccessdetails">{ServiceCode}</p>
                </div>
                <div className="col-md-6">
                  <div>
                    <p className="mainpaymentdetailstitle">Property</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="mainpaymentsuccessdetails">{PropertyName}</p>
                </div>

                <div className="col-md-12 mainbtnfooterpayment">
                  <a className="btn btn-primary btnnexteditlineblue w-100 d-block m-0" data-bs-dismiss="modal" aria-label="Close">Finish</a>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
    <Commheader />
    <section>
      <div className="container">
        <div className="mainboxshadow">
          <div className="row">
            <div className="col-md-12">
              <p className="maindashboardtextedit">
                Dashboard
              </p>
            </div>
            <div className="col-md-3">
              <div className="mainbluboxdleft">
                <p className="hellomartinetext">Hello {Name}</p>
                <div className="dividerdashleft"></div>
                <div className="mainverifiedbox">
                  <div className="d-flex">
                    <p className="mainverifiedline"> <i className="las la-envelope"></i> </p>

                    <p className="mainverifiedline mainverifiedlinenewedit">{Email}</p>
                  </div>
                  {EmailVerified != 'No' ?
                    <div>
                      <img src={verified} className="verifiedimgedit" />
                    </div> : <a className="text-success newmainverifynew" data-bs-toggle="modal" data-bs-target="#MPEmailAddressVerification" onClick={sendEmailOTP} >Verify<i className="las la-angle-right"></i></a>
                  }

                </div>
                {/* <div className="mainverifiedbox mb-0">
                  <div>
                    <p className="mainverifiedline mb-0"><i className="las la-phone"></i>{MobileNo}</p>
                  </div>
                  {MobileVerified != 'No' ?
                    <div>
                      <img src={verified} className="verifiedimgedit" />
                    </div> : <a className="text-success" data-bs-toggle="modal" data-bs-target="#MPMobileVerification" onClick={sendMobileOTP} >Verify<i className="las la-angle-right"></i></a>

                  }


                </div> */}
              </div>
              <div className="maingrayboxedit mainmabershipdashborddiv">
                <p className="membershiptext">Membership Details</p>
                {MembershipDetail.map((item, idx) => (
                  idx == MembershipDetail.length - 1 ?
                    <div key={idx}>

                      <div className="upcomingboxedit"><span className="mainupcomingtext">Plan : </span><span className="mainupcomingdate"> {item.Subscription} </span><span className="mainupcomingdivider"> </span><span className="mainupcomingdate"> </span></div>

                      <div dangerouslySetInnerHTML={{ __html: item.Description }} ></div>
                    </div> : ''
                ))}
                <a data-bs-toggle="modal" data-bs-target="#MPMembership" className="btn btn-danger loginbtnfooter mt-0">Upgrade</a>
                <div>
                  {/* <a  className="btn btn-danger loginbtnfooter mt-0" onClick={paymentGateway}>Pay</a> */}
                </div>
              </div>
              {RecentServicesList.length > 0 ?
                <div className="mainbluboxdleft2">
                  <p className="hellomartinetext2">Service Board</p>
                  <div className="dividerdashleft2"></div>
                  <div id="carouselExampleIndicators" className="carousel carouselnewmajinserviceleft slide" data-bs-ride="carousel">
                    <div className="carousel-indicators ">
                      {RecentServicesList.map((item, idx) => (
                        idx == 0 ?
                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} className="active" aria-current="true" aria-label={"Slide " + (idx + 1)}></button>
                          :
                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} aria-label={"Slide " + (idx + 1)}></button>
                      ))}
                      {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                    </div>
                    <div className="carousel-inner">
                      {RecentServicesList.map((item, idx) => (
                        idx == 0 ?
                          <div className="carousel-item active" key={idx}>
                            <div><div className="upcomingboxedit">
                              <span className="mainupcomingtext">Upcoming : </span>
                              <span className="mainupcomingdate"> {item.ServiceDate} </span>
                              <span className="mainupcomingdivider"> | </span>
                              <span className="mainupcomingdate"> {item.ServiceTime} </span>
                            </div>
                              <div className="d-flex">
                               { item.PropertyfirstImage.length==0?
                               <img src={defaulthomeimg} className="propertimgedit"></img>:  <>
                               {item.PropertyfirstImage.filter(x => x.Status == "true").map((item2, idx) => (idx == 0 ? 
                               <img key={idx} src={item2.Url} className="propertimgedit" /> : ""))}

                              {item.PropertyfirstImage.filter(x => x.Status == "false").map((item2, idx) => (idx == 0 ? 
                               <img src={defaulthomeimg} className="propertimgedit"></img> : ""))}
                               </>
                             
                                
                               }
                           
                               
                                <div>
                                  <p className="propertynamedit">{item.PropertyName}</p>
                                  <div className="d-flex">
                                    <div><p className="mainpropertyaddress"><i className="fas fa-map-marker-alt"></i>
                                    </p></div><div><p className="mainpropertyaddress propertytextlocation">{item.Address}</p>
                                    </div></div></div></div></div>
                          </div>
                          :
                          <div className="carousel-item" key={idx}>
                            <div><div className="upcomingboxedit"><span className="mainupcomingtext">Upcoming : </span><span className="mainupcomingdate"> {item.ServiceDate} </span><span className="mainupcomingdivider"> | </span><span className="mainupcomingdate"> {item.ServiceTime} </span></div><div className="d-flex">
                           {item.PropertyfirstImage.length==0? <img src={defaulthomeimg} className="propertimgedit"></img>: 
                           <>  
                           
                           {item.PropertyfirstImage.filter(x => x.Status == "true").map((item2, idx) => (idx == 0 ? 
                           <img key={idx} src={item2.Url} className="propertimgedit" /> 
                              : ""))
                              
                              }

                     {item.PropertyfirstImage.filter(x => x.Status == "false").map((item2, idx) => (idx == 0 ? 
                           <img src={defaulthomeimg} className="propertimgedit"></img>
                              : ""))
                              
                              }
                            
                              </>
                              
                              }
                           

                           
                              <div><p className="propertynamedit">{item.PropertyName}</p><div className="d-flex"><div><p className="mainpropertyaddress"><i className="fas fa-map-marker-alt"></i></p></div><div><p className="mainpropertyaddress propertytextlocation">{item.Address}</p></div></div></div></div></div>
                          </div>
                      ))}

                    </div>
                  </div>
                </div> : ''}
              <div className="mainrequestbox">
                <img src={shapleftrequest} className="shapleftrequest" />
                <img src={shaprightrequest} className="shaprightrequest" />

                <a onClick={CheckProperty}>
                  
                  <div className="d-flex reqcss">
                  <div>
                    <i className="las la-plus-circle mainrequesttitleicon"></i>
                  </div>
                  <div>
                    <p className="mainrequesttitle">Request New Service</p>
                    <p className="mainrequestdescription"></p>
                  </div>
                </div>
                </a>

              </div>

            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-4 col-6">
                  <div className="mainboxshadowthreebox">
                    <div className="threeboxcontent">
                      <div className="text-center">
                        <p className="mainthrreboxcount">{PropertiesCount}</p>
                        <p className="maintitlethrrebox">Properties</p>
                      </div>
                      <div>
                        <img className="mainthreeboximg" src={propertyiconedit} />
                      </div>

                    </div>
                    <div className="threeboxboottomcontent d-md-block d-none">
                      <a href="/add-new-property">  <p className="textboxthreecontent"> <i className="las la-plus-circle bootomplusicon"></i>Add New Properties</p></a>
                    </div>
                    <div className="threeboxboottomcontent d-md-none d-block">
                      <a href="/add-new-property">  <p className="textboxthreecontent"> <i className="las la-plus-circle bootomplusicon"></i>Add New </p></a>
                    </div>

                  </div>

                </div>
                <div className="col-md-4 col-6">
                  <div className="mainboxshadowthreebox">
                    <div className="threeboxcontent">
                      <div className="text-center">
                        <p className="mainthrreboxcount">{ServiceCount}</p>
                        <p className="maintitlethrrebox">Services</p>
                      </div>
                      <div>
                        <img className="mainthreeboximg" src={serviceiconedit} />
                      </div>

                    </div>
                    <div className="threeboxboottomcontent d-md-block d-none">
                      <a onClick={CheckProperty}>  <p className="textboxthreecontent"> <i className="las la-plus-circle bootomplusicon"></i>Add New Services Request</p></a>
                    </div>
                    <div className="threeboxboottomcontent d-md-none d-block">
                      <a onClick={CheckProperty}>  <p className="textboxthreecontent"> <i className="las la-plus-circle bootomplusicon"></i>Add New </p></a>
                    </div>

                  </div>

                </div>
                <div className="col-md-4 col-6">
                  <div className="mainboxshadowthreebox newthreeboxcustomservice">
                    <div className="threeboxcontent">
                      <div className="text-center">
                        <p className="mainthrreboxcount">₹ {parseFloat(TotalSpentAmount == null ? '0' : TotalSpentAmount == '' ? '0' : TotalSpentAmount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
                        <p className="maintitlethrrebox">Spent</p>
                      </div>
                      <div>
                        <img className="mainthreeboximg" src={spenticonedit} />
                      </div>

                    </div>
                    <div className="threeboxboottomcontent">
                      {/* <a href="#">  <p className="textboxthreecontent"> <i className="las la-eye bootomplusicon"></i>View</p></a> */}
                      <a href="/transaction-history"><p className="textboxthreecontent"> <i className="las la-eye bootomplusicon"></i>View</p></a>
                    </div>

                  </div>

                </div>

              </div>

              <div className="mainboxxshadoweditnew">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pointfiveedit">
                      <p className="maindashboardboxtitlecommon"> Top Properties</p>
                    </div>
                  </div>
                </div>
                <div className="mainboxespropertyedit">
                  {TopPropertiesList.length > 0 ?
                    <div className="row">
                      {TopPropertiesList.map((item, idx) => (
                        idx < 3 ?
                          <div className="col-md-4" key={idx}>
                            <div className="propertyboxeditindi">
                              <div>
                          
                                {item.PropertyImages.length==0?<img className="mainpropertysliderdefaultimg" src={defaulthomeimg}></img>:   
                                   <div id="carouselExampleIndicators" className="carousel slide propertyimgslider newpropertydesigncontent" data-bs-ride="carousel">
                                  <div className="carousel-indicators carbtn">
                                    {item.PropertyImages.filter(x => x.Imagestatus == true).map((item, idx) => (
                                      idx == 0 ?
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} className="active" aria-current="true" aria-label={item.SliderName}></button>
                                        :
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} aria-label={item.SliderName}></button>
                                    ))}
                                  </div>
                                  <div className="carousel-inner">
                                    {item.PropertyImages.filter(x => x.Imagestatus == true).map((item, idx) => (
                                      <div className={item.SclassName} key={idx}>
                                        <img src={item.PropImageUrl} className="mainpropertysliderimg" />
                                      </div>
                                    ))}
                                  </div>
                                </div>}
                                {/* {item.Images.length==0?<img></img>:""} */}
                              </div>
                              <div className="w-100">
                                <div className="d-flex justify-content-between w-100">
                                  <p className="mainpropertytitlelist">{item.PropertyName}</p>
                                  <div>

                                    <img className="verifiedgreen" src={verifiedgreen} />
                                  </div>
                                </div>
                                <div>
                                  <p className="propertycatageoried">{item.PropertyType}</p>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <img src={locationpropertyedit} className="locationpropertyedit" />
                                  </div>
                                  <div>
                                    <p className="propertytextlocation">{item.FullAddress}</p>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                          : ''
                      ))}
                    </div>
                    :
                    <div className="row">
                      <div className="text-center">
                        <img src={nodata} className="nodataimg" />
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="mainboxxshadoweditnew">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pointfiveedit">
                      <p className="maindashboardboxtitlecommon"> Recent Services</p>
                    </div>
                  </div>
                </div>
                <div className="mainboxespropertyedit">
                  {RecentServicesList.length > 0 ?
                    <div className="row">
                      {RecentServicesList.map((item, idx) => (
                        idx == 0 ?
                          <div className="row maindetaillistalldiv" key={idx}>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4">
                                <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}><p className="servicelistdate">{item.ServiceDate}</p>
                                  <p className="servicelisttime"> {item.ServiceTime}</p></a>
                                </div>
                                <div className="col-md-4">
                                <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>   <p className="servicetitle">  {item.ServiceTypeName}</p></a>
                                  {/* <p className="descriptionservicelist">{item.ServiceTypeName} </p> */}
                                </div>
                                <div className="col-md-3">
                                  <div className="servicetagdiv" data-bs-toggle="tooltip" data-bs-placement="top" title={item.ServiceName}>
                                  <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>  <p> {item.ServiceName}</p></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="d-flex">

                          {item.PropertyfirstImage.length==0?
                           <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>  
                          <img src={defaulthomeimg} className="mainpropertysliderimg2 recentpropertyimgedit" ></img>
                          
                          </a>
                          : 
                           <div>                                                                       
                            <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>  
                                 
                                      {item.PropertyfirstImage.filter(x => x.Status == "true").map((item2, idx) => (                                       
                                        idx == 0 ?                                  
                                      <img key={idx} src={item2.Url} className="mainpropertysliderimg2 recentpropertyimgedit" /> :  
                                ""
                                                                    
                                      ))}

                               {item.PropertyfirstImage.filter(x => x.Status == "false").map((item2, idx) => (                                       
                                        idx == 0 ?                                  
                                        <img src={defaulthomeimg} className="mainpropertysliderimg2 recentpropertyimgedit" ></img>:  
                                ""
                                                                    
                                      ))}
                                 
                                  </a>
                                    </div>
                                    
                                    }
                                  

                                    <div className="w-100">
                                      <div className="d-flex justify-content-between w-100">
                                    <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}><p className="mainpropertytitlelist">{item.PropertyName} ({item.PropertyType}) </p></a> 
                                      </div>
                                      {/* <div>
                                        <p className="propertycatageoried"></p>
                                      </div> */}
                                      <div className="d-flex">
                                        <div>
                                          <img src={locationpropertyedit} className="locationpropertyeditdb" />
                                        </div>
                                        <div>
                                        <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}> <p className="propertytextlocation mb-0">{item.Address}</p></a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  {item.RequestServiceStaus == 'InspectionCompleted' ?
                                    <div className="mainpaidboxdashboard">
                                      <div>
                                        <p className="paidamount">₹{parseFloat(item.TotalAmount) - parseFloat(item.TotalPaidAmount)}</p>
                                      </div>
                                      <div className="maindivmaintextpaidnow">
                                        <a onClick={e => ServicePayment(e, item.Id, 'AdvancePaid', parseFloat(item.PayAmount), item.ServiceName, item.PropertyName)}><p className="maintextpaidnow">Pay Now</p></a>
                                      </div>
                                    </div>
                                    :
                                    item.RequestServiceStaus == 'ServiceCompleted' ?
                                      <div className="mainpaidboxdashboard2">
                                        <div>
                                          <p className="paidamount2">₹{item.TotalPaidAmount}</p>
                                        </div>
                                        <div className="maindivmaintextpaidnow2">
                                          <p className="maintextpaidnow2">Paid</p>
                                        </div>
                                      </div>
                                      :
                                      item.RequestServiceStaus == 'JobCompleted' ?
                                        <div className="mainpaidboxdashboard">
                                          <div>
                                            <p className="paidamount">₹{parseFloat(item.TotalAmount) - parseFloat(item.TotalPaidAmount)}</p>
                                          </div>
                                          <div className="maindivmaintextpaidnow">
                                            <a onClick={e => ServicePayment(e, item.Id, 'ServiceCompleted', parseFloat(item.PayAmount), item.ServiceName, item.PropertyName)}><p className="maintextpaidnow">Pay Now</p></a>
                                          </div>
                                        </div>
                                        :
                                        <div className="servicetagdiv" data-bs-toggle="tooltip" data-bs-placement="top" title={item.RequestServiceStaus}>
                                          <p> {item.RequestServiceStaus}</p>
                                        </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          idx == 1 ?
                            <div className="row maindetaillistalldiv" key={idx}>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                  <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>  <p className="servicelistdate">  {item.ServiceDate}</p></a>
                                  <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>     <p className="servicelisttime"> {item.ServiceTime}</p></a>
                                  </div>
                                  <div className="col-md-4">
                                  <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>  <p className="servicetitle">  {item.ServiceTypeName}</p></a>
                                    {/* <p className="descriptionservicelist">{item.ServiceTypeName} </p> */}
                                  </div>
                                  <div className="col-md-3">
                                    <div className="servicetagdiv" data-bs-toggle="tooltip" data-bs-placement="top" title={item.ServiceName}>
                                    <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>    <p> {item.ServiceName}</p></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="d-flex">

                                    {item.PropertyfirstImage.length==0?

                           <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>  
                          <img src={defaulthomeimg} className="mainpropertysliderimg2 recentpropertyimgedit" ></img>                         
                          </a>:
                          
                          <div> <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}>  
                              {item.PropertyfirstImage.filter(x => x.Status == "true").map((item2, idx) => (idx == 0 ? 
                              <img key={idx} src={item2.Url} className="mainpropertysliderimg2 recentpropertyimgedit" /> : ""))}  
                                {item.PropertyfirstImage.filter(x => x.Status == "false").map((item2, idx) => (idx == 0 ? 
                              <img src={defaulthomeimg} className="mainpropertysliderimg2 recentpropertyimgedit" ></img>   : ""))}                                  
                           </a></div>
                              
                              
                              }

                                    
                                      <div className="w-100">
                                        <div className="d-flex justify-content-between w-100">
                                        <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}><p className="mainpropertytitlelist">{item.PropertyName} ({item.PropertyType})</p></a>
                                        </div>
                                        <div className="d-flex">
                                          <div>
                                          <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}><img src={locationpropertyedit} className="locationpropertyeditdb" /></a>
                                          </div>
                                          <div>
                                          <a onClick={e => ServiceDetail(e, item.Id, item.PropertyId)}><p className="propertytextlocation mb-0">{item.Address}</p></a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    {item.RequestServiceStaus == 'InspectionCompleted' ?
                                      <div className="mainpaidboxdashboard">
                                        <div>
                                          <p className="paidamount">₹{parseFloat(item.TotalAmount) - parseFloat(item.TotalPaidAmount)}</p>
                                        </div>
                                        <div className="maindivmaintextpaidnow">
                                          <a onClick={e => ServicePayment(e, item.Id, 'AdvancePaid', parseFloat(item.PayAmount), item.ServiceName, item.PropertyName)}><p className="maintextpaidnow">Pay Now</p></a>
                                        </div>
                                      </div>
                                      :
                                      item.RequestServiceStaus == 'ServiceCompleted' ?
                                        <div className="mainpaidboxdashboard2">
                                          <div>
                                            <p className="paidamount2">₹{item.TotalPaidAmount}</p>
                                          </div>
                                          <div className="maindivmaintextpaidnow2">
                                            <p className="maintextpaidnow2">Paid</p>
                                          </div>
                                        </div>
                                        :
                                        item.RequestServiceStaus == 'JobCompleted' ?
                                          <div className="mainpaidboxdashboard">
                                            <div>
                                              <p className="paidamount">₹{parseFloat(item.PayAmount)}</p>
                                            </div>
                                            <div className="maindivmaintextpaidnow">
                                              <a onClick={e => ServicePayment(e, item.Id, 'ServiceCompleted', parseFloat(item.PayAmount), item.ServiceName, item.PropertyName)}><p className="maintextpaidnow">Pay Now</p></a>
                                            </div>
                                          </div>
                                          :
                                          <div className="servicetagdiv" data-bs-toggle="tooltip" data-bs-placement="top" title={item.RequestServiceStaus}>
                                            <p> {item.RequestServiceStaus}</p>
                                          </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            : ''
                      ))}
                    </div>
                    :
                    <div className="row">
                      <div className="text-center">
                        <img src={nodata} className="nodataimg" />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <Footeradmin />
  </React.Fragment>
  )
}


export default DashboardNew

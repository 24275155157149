import React, { Component, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  img,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import mianheaderimg from "../../assets/images/frontend-img/logo-new1.png";
import mainsliderheroimg from "../../assets/images/frontend-img/mainsliderheroimg.png";
import mainsliderelement from "../../assets/images/frontend-img/mainsliderelement.png";
import dropdownicon from "../../assets/images/frontend-img/dropdownicon.png";
import serchicon from "../../assets/images/frontend-img/serchicon.png";
import builtforimpact from "../../assets/images/frontend-img/builtforimpact.png";
import goodtofindonline from "../../assets/images/frontend-img/goodtofindonline.png";
import whoweareimgmain from "../../assets/images/frontend-img/whoweareimgmain.png";
import playicon from "../../assets/images/frontend-img/playicon.png";
import whowearetop from "../../assets/images/frontend-img/whowearetop.png";
import whoweareline from "../../assets/images/frontend-img/whoweareline.png";
import hdtw1 from "../../assets/images/frontend-img/hdtw1.png";
import hdtw2 from "../../assets/images/frontend-img/hdtw2.png";
import hdtw3 from "../../assets/images/frontend-img/hdtw3.png";
import hdtw4 from "../../assets/images/frontend-img/hdtw4.png";
import shaphw1 from "../../assets/images/frontend-img/shaphw1.png";
import shaphw2 from "../../assets/images/frontend-img/shaphw2.png";
import shaphw3 from "../../assets/images/frontend-img/shaphw3.png";
import testimonialcheck from "../../assets/images/frontend-img/testimonialcheck.png";
import testimonialuser from "../../assets/images/frontend-img/testimonialuser.png";
import star1 from "../../assets/images/frontend-img/star1.png";
import star2 from "../../assets/images/frontend-img/star2.png";
import star3 from "../../assets/images/frontend-img/star3.png";
import star4 from "../../assets/images/frontend-img/star4.png";
import star5 from "../../assets/images/frontend-img/star5.png";
import tp1 from "../../assets/images/frontend-img/tp1.png";
import tp2 from "../../assets/images/frontend-img/tp2.png";
import tp3 from "../../assets/images/frontend-img/tp3.png";
import featureinclude from "../../assets/images/frontend-img/featureinclude.png";
import featureincludenot from "../../assets/images/frontend-img/featureincludenot.png";
import civil from "../../assets/images/frontend-img/civil.png";
import interior from "../../assets/images/frontend-img/interior.png";
import property from "../../assets/images/frontend-img/property.png";
import development from "../../assets/images/frontend-img/development.png";
import footershap from "../../assets/images/frontend-img/footershap.png";
import workflowsape from "../../assets/images/frontend-img/workflowsape.png";
import testimonialshap1 from "../../assets/images/frontend-img/testimonialshap1.png";
import testimonialshap from "../../assets/images/frontend-img/testimonialshap.png";
import axios from "axios";
import { useToasts } from 'react-toast-notifications';



const Footer = () => {
  const { addToast } = useToasts();
  const [UserMembershipList, setUserMembershipList] = useState([]);

  const [QuickInquiryName, setQuickInquiryName] = useState('');
  const [QuickInquiryEmail, setQuickInquiryEmail] = useState('');
  const [QuickInquiryMobile, setQuickInquiryMobile] = useState('');
  const [QuickInquiryMessage, setQuickInquiryMessage] = useState('');

  const [NewsletterEmail, setNewsletterEmail] = useState('');

  const [CompanyEmail, setCompanyEmail] = useState('');
  const [CompanyEmailMailto, setCompanyEmailMailto] = useState('');
  const [CompanyContact, setCompanyContact] = useState('');
  const [CompanyContacttel, setCompanyContacttel] = useState('');
  const [facebookLink, setfacebookLink] = useState('');
  const [InstagramLink, setInstagramLink] = useState('');
  const [LinkedinLink, setLinkedinLink] = useState('');
  const [TwitterLink, setTwitterLink] = useState('');
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


  useEffect(() => {
    getMembershipList();
    getCompanyDetail();
  }, []);

  const getMembershipList = async () => {
    axios.get(global.APIURL + "/MembershipGet")
      .then(res => {
        setUserMembershipList(res.data.Result);
      });
  };

  const getCompanyDetail = async () => {
    axios.get(global.APIURL + "/CompanyDetail")
      .then(res => {
        setCompanyEmail(res.data.Result[0].CompanyEmail);
        setCompanyEmailMailto('mailto:'+res.data.Result[0].CompanyEmail);
        setCompanyContact(res.data.Result[0].CompanyContact);
        setCompanyContacttel('tel:'+res.data.Result[0].CompanyContact);
        setfacebookLink(res.data.Result[0].facebookLink);
        setInstagramLink(res.data.Result[0].InstagramLink);
        setLinkedinLink(res.data.Result[0].LinkedinLink);
        setTwitterLink(res.data.Result[0].TwitterLink);
      });
  };


  const QuickInquirySave = (e) => {
    let IsRegister = 1;

    if (QuickInquiryName == '') {
      IsRegister = 0;
      addToast("Please Enter Name", { appearance: 'error', autoDismiss: true });
      return;

    }
    if (QuickInquiryEmail == '') {
      IsRegister = 0;
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      return;
    }
    if (regex.test(QuickInquiryEmail) === false) {
      IsRegister = 0;
      addToast("Please Enter Valid Email Address", { appearance: 'error', autoDismiss: true });
      return;

    }
    if (QuickInquiryMobile == '') {
      IsRegister = 0;
      addToast("Please Enter Mobile Number", { appearance: 'error', autoDismiss: true });
      return;

    }
    if (QuickInquiryMobile.length < 10) {
      IsRegister = 0;
      addToast("Please Enter Valid Mobile Number", { appearance: 'error', autoDismiss: true });
      return;

    }
    if (QuickInquiryMessage == '') {
      IsRegister = 0;
      addToast("Please Enter Message", { appearance: 'error', autoDismiss: true });
      return;

    }
    if (IsRegister > 0) {
      const formData = new FormData();
      formData.append('RegisterId', 1);
      formData.append('Name', QuickInquiryName);
      formData.append('Email', QuickInquiryEmail);
      formData.append('MobileNumber', QuickInquiryMobile);
      formData.append('Message', QuickInquiryMessage);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ':' + pair[1]);
      }
      axios.post(global.APIURL + '/QuickInquiry', formData).then(res => {
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          document.getElementById('txtQuickInquiryName').value = '';
          document.getElementById('txtQuickInquiryEmail').value = '';
          document.getElementById('txtQuickInquiryMobile').value = '';
          document.getElementById('txtQuickInquiryMessage').value = '';
          setQuickInquiryName('');
          setQuickInquiryEmail('');
          setQuickInquiryMobile('');
          setQuickInquiryMessage('');
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
        }
      });
    }
  };

  const NewsletterSave = (e) => {
    let IsRegister = 1;
    if (NewsletterEmail == '') {
      IsRegister = 0;
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      return;
    }
    if (NewsletterEmail != "") {
      if (regex.test(NewsletterEmail) === false) {
        addToast("Please Enter Valid Email Address", { appearance: 'error', autoDismiss: true });
        return;
      }
    }
    if (IsRegister > 0) {
      const formData = new FormData();
      formData.append('RegisterId', 1);
      formData.append('Email', NewsletterEmail);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ':' + pair[1]);
      }

      axios.post(global.APIURL + '/Newsletter', formData).then(res => {
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          document.getElementById('txtNewsletterEmail').value = '';
          setNewsletterEmail('');
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
        }
      });
    }
  };


  const LoginRedirect = (e) => {
    window.location.href = "/Login";
  };

  const inputHandler = e => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  return (
    <React.Fragment>
      <section id="contactusid">
        <div className="footermaindiv">
          <img src={footershap} className="footershapimg"></img>
          <img src={footershap} className="footershapimg"></img>
          <div className="container">
            <div className="row maincontentdivfooter">
              <div className="col-md-4">
                <div className="paddingfooterdiv">
                  <p className="footerdescription mt-1">   </p>
                  <p className="footerdescription">
                   <h5 className="footertitle"> CoLand Services Private Limited</h5>                    
                    <p className="footerdescription"> 1st Floor,</p>
                    Bhagyalakshmi Indoor Stadium,
                  </p>
                  <p className="footerdescription">
                    Thrissur -Shornur road,
                  </p>
                  <p className="footerdescription">
                    Kolazhy, Thrissur,
                  </p>
                  <p className="footerdescription">
                    Kerala, India-680010
                  </p><br/>
                  <p className="footertitle addrescss"> Contact Us On:</p>
                  <p className="footerdescription mt-1 "><i className="fa-solid fa-phone me-2"></i>+91-9947422444 </p>
                  <p className="footerdescription mt-1"><i className="fa-solid fa-phone me-2"></i>+91-9847982444 </p>
                  <br/>
                  <p className="footerdescription mt-1"><p className="footertitle addrescss">Our Branches : </p> Ernakulam | Kottayam | Thiruvalla | Trivandrum | Palakkad | Malappuram | Calicut | Chennai | Bangalore</p>            
                  <a onClick={e => LoginRedirect(e)} className="btn btn-danger loginbtnfooter">Login</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="paddingfooterdiv2">
                  <p className="footertitle"> Quick Inquiry</p>
                  <div className="footerform">
                    <input type="email" className="form-control" id="txtQuickInquiryName" placeholder="Name" onChange={(e) => setQuickInquiryName(e.target.value)} />
                  </div>
                  <div className="footerform">
                    <input type="email" className="form-control" id="txtQuickInquiryEmail" placeholder="Email Address" onChange={(e) => setQuickInquiryEmail(e.target.value)} />
                  </div>
                  <div className="footerform">
                    <input type="number" className="form-control" maxLength={10} id="txtQuickInquiryMobile" placeholder="Mobile Number" onChange={(e) => setQuickInquiryMobile(e.target.value)} onKeyPress={inputHandler} />
                  </div>
                  <div className="footerform">
                    <input type="email" className="form-control" id="txtQuickInquiryMessage" placeholder="Message" onChange={(e) => setQuickInquiryMessage(e.target.value)} />
                  </div>
                  <div>
                    <a onClick={e => QuickInquirySave(e)} className="submittextbtn">Submit<i className="fas fa-long-arrow-alt-right"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <p className="footertitle">Newsletter</p>
                  {/* <p className="footerdescription">Omnis illo ipsum pariatur magni unde necessitatibus ut illo. In autem qui ut accusamus sint perferendis veritatis voluptatem. Eos impedit ea. </p>*/}
                  <div className="input-group input-groupfooter">
                    <input type="text" id="txtNewsletterEmail" className="form-control" placeholder="Email Address" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setNewsletterEmail(e.target.value)} />
                    <button className="btn btn-danger" type="button" id="button-addon2" onClick={e => NewsletterSave(e)}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="footersocialmedia">
              <div className="row">
                <div className="col-md-8">
                  <div className="mainfooterpagetext d-md-flex d-block">
                    <p className="footerpagetext d-md-block d-none"> <a href="/terms-of-user">Terms & Conditions </a> </p>
                    <p className="footerpagetext2 d-flex d-md-none" > <a href="/terms-of-user">Terms & Conditions </a> </p>
                    <p className="footerpagetext2"> <a href="/privacy">Privacy Policy</a></p>
                    <p className="footerpagetext2"> <a href="/refund">Refund Policy</a></p>
                    <p className="footerpagetext2"> <a href="/cookie-policy">Cookie Policy</a></p>
                    <p className="footerpagetext2"> <a href="/EULA">EULA</a></p>
                    <p className="footerpagetext2"> <a href="/disclaimer">Disclaimer</a></p>
                    <p className="footerpagetext2"> <a href="/accept-use-policy">Acceptable Use Policy</a></p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footersocialmediaicon">
                    <a href={facebookLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f ms-0"></i></a>
                    <a href={InstagramLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                    <a href={LinkedinLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                    <a href={TwitterLink} target="_blank" rel="noopener noreferrer"> <i className="fab fa-youtube"></i></a>                   
                  </div>
                </div>
              </div>
            </div>
            <div className="disclamerlinefooter">
              <p>
                COPYRIGHT © ALL RIGHTS RESERVED BY CoLand Services Private Limited
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )

}

export default Footer

import React, { Component, useState } from "react"
import loginmainimg from "../../assets/images/frontend-img/logo-new1.png";
import axios from "axios";
import { useToasts } from 'react-toast-notifications';

const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
 
const ForgotPassword = () => {

  const { addToast } = useToasts();
  const [EmailVerify, setEmailVerify] = useState(0);
  const [EmailAddress, setEmailAddress] = useState('');
  const [EmailOTP, setEmailOTP] = useState('');
  const [PasswordAllow,setPasswordAllow]=useState(0);
  const [Password,setPassword]=useState("");
  const [CPassword,setCPassword]=useState("");


const sendEmailOTP=()=>{
  if(EmailAddress==""){
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      return;
   }
   if (regex.test(EmailAddress) === false) {
    addToast("Please Enter Valid Email Address", { appearance: 'error', autoDismiss: true });
    return;
  }
        const formData = new FormData();
        formData.append('Email', EmailAddress);      
        axios.post(global.APIURL + '/PaaswordOtpSend', formData)
          .then(res => {         
            if (res.data.Status == true) {
              addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
              setEmailVerify(1);         
            }
            else {
              addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });            
              setEmailVerify(0);
            } });
  }

  const verifyEmailOTP = async () => {
    const formData = new FormData();
    formData.append('Email', EmailAddress);  
    formData.append('OTP', EmailOTP);
    axios.post(global.APIURL + '/PasswordOtpVerify', formData)
      .then(res => {
        console.log("res",res);
        if (res.data.Status == true) {
          setEmailVerify(0);  
          setPasswordAllow(1);     
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });       
        }
        else {
          setEmailVerify(1);
          setPasswordAllow(0);   
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });      
        }
      });
  }

  const passwordReset=async()=>{
    let isChange=0; 
    if(Password==""){
      addToast("Please Enter Password", { appearance: 'error', autoDismiss: true });
      isChange=0;   
      return; 
   }
   if(CPassword==""){
    addToast("Please Confirm Password", { appearance: 'error', autoDismiss: true });
    isChange=0;   
    return; 
   }
   if(Password!="" && CPassword!=""){
    if(Password!=CPassword){
      addToast("Password and Confirm Password Does not Match", { appearance: 'error', autoDismiss: true });
      isChange=0;   
      return;
    }
   }
    

  const formData = new FormData();
  formData.append('Email', EmailAddress);  
  formData.append('NewPaasword', Password);
  axios.post(global.APIURL + '/PasswordChange', formData)
  .then(res => { 
    if(res.data.UserId){
      localStorage.setItem("RegisterId", JSON.stringify(res.data.UserId));
    }
    if (res.data.Status == true) {        
      addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });   
      localStorage.setItem("RegisterId", JSON.stringify(res.data.UserId));      
      window.location.href = "/dashboard";
    } 
  }); 
  }

  return (
    <React.Fragment>
      <section>
        <div className="container">
          <div className="loginboxnew loginnewboxresponsive pt-0 pb-0">
            <div className="row justify-content-center align-items-center mainnewloginrow">
              <div className="col-md-4">
                <div className="mainloginboxcontent forgetpasscss">
                  <div className="text-center">
                    <a href="/home"> <img className="loginboximg" src={loginmainimg}></img></a>
                    <p className="lognmaintexteditnew">Forgot Password ?</p>
                    <p className="maindescriptiontextlogin"></p>
                  </div>
                  <div className="maininputboxlogin">
                    <div className="mainformlogininput">
                    <div className="registerinput">
                      <div>
                        <label htmlFor="exampleFormControlInput1" className="form-label">Email Address  <span className="spanred">*</span></label>
                      </div>
                      <div className="input-group mb1vw">
                        <input type="text" className="form-control" placeholder="Enter Your Email Address" id="txtEmail" onChange={(e) => setEmailAddress(e.target.value)} aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <button className="btn btn-outline-secondary btnsendedit" type="button" id="btnESend" onClick={sendEmailOTP}>Send</button>
                      </div>                   
                     {EmailVerify==1  ? <div className="input-group">
                          <input type="text" className="form-control" placeholder="Enter otp" aria-label="Recipient's username" onChange={(e) => setEmailOTP(e.target.value)} id="txtEmailOTP" aria-describedby="button-addon2" />
                          <button className="btn btn-outline-secondary btnsendedit" type="button" id="btnEVerify" onClick={verifyEmailOTP}>Verify</button>
                        </div>:""}  
                    </div>                      
                    </div>
              {PasswordAllow==1?    <div>
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">New Password</label>
                      <input type="password" className="form-control" id="txtPassword" placeholder="Enter Your Password"  onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="mainformlogininput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Confirm Password</label>
                      <input type="password" className="form-control" id="txtCPassword" placeholder="Confirm Your Password"  onChange={(e) => setCPassword(e.target.value)} />
                    </div>
                    <div>
                      <button type="button" className="btn btn-primary btnloginnew" onClick={passwordReset} >Continue</button>
                    </div>
                    </div>:""}                   
                    <div>
                  </div>
                  </div>
                </div>
                <div className="d-md-flex justify-content-between">
                  <div>
                    <p className="footercopytextedit footerCRcss">COPYRIGHT © ALL RIGHTS RESERVED BY CoLand Services Private Limited</p>
                    <a href='/terms-of-user' target="_blank" className="footercopytextedit privacycss">T&C</a>
                    <a href='/privacy' target="_blank" className="footercopytextedit2 ">Privacy</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ForgotPassword

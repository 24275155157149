import React, { Component, useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import mianheaderimg from "../../assets/images/frontend-img/logo-new1.png";
import mainsliderheroimg from "../../assets/images/frontend-img/mainsliderheroimg.png";
import mainsliderelement from "../../assets/images/frontend-img/mainsliderelement.png";
import dropdownicon from "../../assets/images/frontend-img/dropdownicon.png";
import serchicon from "../../assets/images/frontend-img/serchicon.png";
import builtforimpact from "../../assets/images/frontend-img/builtforimpact.png";
import goodtofindonline from "../../assets/images/frontend-img/goodtofindonline.png";
import whoweareimgmain from "../../assets/images/frontend-img/whoweareimgmain.png";
import playicon from "../../assets/images/frontend-img/playicon.png";
import whowearetop from "../../assets/images/frontend-img/whowearetop.png";
import whoweareline from "../../assets/images/frontend-img/whoweareline.png";
import hdtw1 from "../../assets/images/frontend-img/hdtw1.png";
import hdtw2 from "../../assets/images/frontend-img/hdtw2.png";
import hdtw3 from "../../assets/images/frontend-img/hdtw3.png";
import hdtw4 from "../../assets/images/frontend-img/hdtw4.png";
import shaphw1 from "../../assets/images/frontend-img/shaphw1.png";
import shaphw2 from "../../assets/images/frontend-img/shaphw2.png";
import shaphw3 from "../../assets/images/frontend-img/shaphw3.png";
import testimonialcheck from "../../assets/images/frontend-img/testimonialcheck.png";
import testimonialuser from "../../assets/images/frontend-img/testimonialuser.png";
import star1 from "../../assets/images/frontend-img/star1.png";
import star2 from "../../assets/images/frontend-img/star2.png";
import star3 from "../../assets/images/frontend-img/star3.png";
import star4 from "../../assets/images/frontend-img/star4.png";
import star5 from "../../assets/images/frontend-img/star5.png";
import tp1 from "../../assets/images/frontend-img/tp1.png";
import tp2 from "../../assets/images/frontend-img/tp2.png";
import tp3 from "../../assets/images/frontend-img/tp3.png";
import featureinclude from "../../assets/images/frontend-img/featureinclude.png";
import featureincludenot from "../../assets/images/frontend-img/featureincludenot.png";
import civil from "../../assets/images/frontend-img/civil.png";
import interior from "../../assets/images/frontend-img/interior.png";
import property from "../../assets/images/frontend-img/property.png";
import development from "../../assets/images/frontend-img/development.png";
import footershap from "../../assets/images/frontend-img/footershap.png";
import workflowsape from "../../assets/images/frontend-img/workflowsape.png";
import testimonialshap1 from "../../assets/images/frontend-img/testimonialshap1.png";
import testimonialshap from "../../assets/images/frontend-img/testimonialshap.png";
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import Commfooter from "../frontend/footer";

const aboutus = () => {
  
    const [CompanyEmail, setCompanyEmail] = useState('');
    const [CompanyEmailMailto, setCompanyEmailMailto] = useState('');
    const [CompanyContact, setCompanyContact] = useState('');
    const [CompanyContacttel, setCompanyContacttel] = useState('');
    const [facebookLink, setfacebookLink] = useState('');
    const [InstagramLink, setInstagramLink] = useState('');
    const [LinkedinLink, setLinkedinLink] = useState('');
    const [TwitterLink, setTwitterLink] = useState('');

    useEffect(() => {
        getCompanyDetail();
    }, []);



    const getCompanyDetail = async () => {
        axios.get(global.APIURL + "/CompanyDetail")
            .then(res => {
                setCompanyEmail(res.data.Result[0].CompanyEmail);
                setCompanyEmailMailto('mailto:' + res.data.Result[0].CompanyEmail);
                setCompanyContact(res.data.Result[0].CompanyContact);
                setCompanyContacttel('tel:' + res.data.Result[0].CompanyContact);
                setfacebookLink(res.data.Result[0].facebookLink);
                setInstagramLink(res.data.Result[0].InstagramLink);
                setLinkedinLink(res.data.Result[0].LinkedinLink);
                setTwitterLink(res.data.Result[0].TwitterLink);
            });
    };


    return (
        <React.Fragment>
            <div>
                <section className="maintopbar">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="text-white topbarsocialicon">
                                <a href={facebookLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                <a href={InstagramLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                <a href={LinkedinLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                <a href={TwitterLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                            </div>
                            <div className="text-white topbarcontact">
                                <a className="mt-0" href={CompanyContacttel}><i className="fas fa-phone me-md-3 me-2"></i>Call us : {CompanyContact}</a>
                                <a href={CompanyEmailMailto} className="me-0"><i className="fas fa-envelope me-md-3 me-2"></i>Mail us : {CompanyEmail}</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="">
                        <div className="">
                            <nav
                                className="navbar navbar-expand-lg navbar-light hedermain nav-container p-0  subpageheader"
                                aria-label="Eighth navbar example">
                                <div className="container">
                                    <a className="navbar-brand" href="/home">
                                        <img src={mianheaderimg} className="headerlogo" alt="" />
                                    </a>
                                    <div>
                                        <button
                                            className="navbar-toggler"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#navbarsExample07"
                                            aria-controls="navbarsExample07"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                    </div>
                                    <div
                                        className="collapse navbar-collapse navbar-collapseedit"
                                        id="navbarsExample07">
                                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                                            <li className="nav-item navbar-linksedit">
                                                <a
                                                    className="nav-link "
                                                    aria-current="page"
                                                    href="/home"
                                                >Home
                                                </a>
                                            </li>
                                            <li className="nav-item navbar-linksedit">
                                                <a className="nav-link" href="/aboutus">About us</a>
                                            </li>
                                            <li className="nav-item navbar-linksedit">
                                                <a className="nav-link" aria-current="page" href="/how-it-works"
                                                >How it Works?</a>
                                            </li>
                                            <li className="nav-item navbar-linksedit">
                                                <a className="nav-link" href="/pricing">Pricing</a>
                                            </li>
                                            <li className="nav-item navbar-linksedit">
                                                <a
                                                    className="nav-link"
                                                    aria-current="page"
                                                    href="/login"
                                                >Login</a>
                                            </li>
                                            <li className="nav-item navbar-linksedit">
                                                <a className="nav-link me-0 btneditgetstarted"
                                                    aria-current="page"
                                                    href="/register-your-profile">
                                                    <button type="button" className="btn btn-outline-light btngetstarted">Get Started</button></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="aboutusid">
        <div className="whowearemaindiv">
          <div className="container">
            <div className="row align-items-center aboutusdivcss">
              <div className="col-md-7">
                <p className="maincommonsmalltext2">
                  Who We Are
                </p>
                <p className="commonmainbigtitle">
                  ABOUT COLAND SERVICES - SERVICE MALL
                </p>
                <p className="blacktextedit">
                  Coland Services Private Limited is LLC, professional property management company, introducing our new start-up ServiceMall is an ultimate service providing solution web app to our customers who are all away from the property, Non-Residential States, NRI’s who need to professional attention to the property and related services well.</p>
                <p className="blacktextedit">
                  We heading from Kerala and expanding the service operations all across India . Right now we provide our services across Kerala and some parts of Tamilnadu – Chennai & Coimbatore and Karnataka-Bangalore. Our aim is to provide the quality services to our valuable customers to meet their property management requirements by assuring quality, price and on time delivery.
                </p>
                <p className="blacktextedit">
                  We are here to act and work as a third eye of you. We always committed to deliver the best from our end, which we gained from the last 15 years from the industry experience and professional excellence.
                </p>
                <p className="blacktextedit">
                  We are your Property Protector, Inspector, Doctor, Nurse, Assistant, Engineer, Partner and Manger, etc and all other what do you expect from our side.
                </p>
         
              </div>
              <div className="col-md-5">
                <div className="row align-items-center mainsidebarwwr">
                  <div className="col-md-3">
                  </div>
                  <div className="col-md-9">
                    <div className="mainwhowearebox">
                      <p className="wwrboxcount">01</p>
                      <p className="wwrboxtitle">VISION</p>
                      <p className="wwrboxsubtitle">To be the first choice of the customers  and ultimate property management solution in Property Management Industry.</p>
                    </div>
                    <div className="mainwhowearebox mainwhoweareboxnewedit">
                      <p className="wwrboxcount">02</p>
                      <p className="wwrboxtitle">MISSION</p>
                      <p className="wwrboxsubtitle">To be the Third Eye of our customers.
                        To become the quick provision and response of the customers to provide the quality delivery on time.
                        To provide satisfaction to our customers by the excellence of services.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={whowearetop} className="whowearetopimg" alt="" />
          <img src={whoweareline} className="whowearelineimg" alt="" />
        </div>
      </section>
<Commfooter/>           
</div>
</React.Fragment>
    )
}

export default aboutus

import React, { Component, useEffect, useState } from "react";
import deletedash from "../../assets/images/frontend-img/deletedash.svg";
import Commheader from "./header";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import Footeradmin from "./footeradmin";

const EditProperty2 = () => {
  const { addToast } = useToasts();

  const [LandArea, setLandArea] = useState("");
  const [LandAreaIn, setLandAreaIn] = useState("Square feet");
  const [SuperBuiltUP, setSuperBuiltUP] = useState("");
  const [SuperBuiltUpIn, setSuperBuiltUpIn] = useState("Square feet");
  const [PropertyInsightList, setPropertyInsightList] = useState([]);
  const [PropertyFurnishedList, setPropertyFurnishedList] = useState([]);
  const [IsPropertyFurnished, setIsPropertyFurnished] = useState("");
  const [InsightMedia1, setInsightMedia1] = useState([]);
  const [InsightMediaName, setInsightMediaName] = useState("");
  const [FurnishedMedia1, setFurnishedMedia1] = useState([]);
  const [FurnishedMediaName, setFurnishedMediaName] = useState("");
  const [FurnishedIn, setFurnishedIn] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const [PropInsightDdl,setPropInsightDdl]=useState([]);
  const [InsightMediaFlag,setInsightMediaFlag]=useState(0);
  const [FurnishedMediaFlag,setFurnishedMediaFlag]=useState(0);
  const [UpdatePropertyId, setUpdatePropertyId] = useState("0");

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
    if (VRegisterId == null) {
      window.location.href = "/home";
    } else if (VRegisterId == "") {
      window.location.href = "/home";
    } else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      getPriorityDetail();
    }
  }, []);

  const handleFurnishedCheckbox = e => {
    var checked = e.target.checked;
    if (checked) {
      setIsPropertyFurnished("Yes");   
    } else if (!checked) {
      setIsPropertyFurnished("No");   
    }
  };

  const getPriorityDetail = async () => {
    let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
    let VPropertyId = localStorage.getItem("DetailPropertyId");
    const PropListData = {
      RegisterId: VRegisterId,
      PropertyId: VPropertyId,
    };
    axios.post(global.APIURL + "/propertyDetail", PropListData).then(res => {

      if (res.data.Result != "") {
        let isFurnished = res.data.Result[0].IsPropertyFurnished;        
        isFurnished == "Yes"? setIsPropertyFurnished(true):setIsPropertyFurnished(false);
        if(isFurnished=="Yes"){          
          window.onload = function () {
            $("#autoUpdate").show();
            $("#addnewbutton").show();
          };
        }else if("No"){
          window.onload = function () {
            $("#autoUpdate").hide();
            $("#addnewbutton").hide();
          };
        }
        setLandArea(res.data.Result[0].LandArea);
        setLandAreaIn(res.data.Result[0].LandAreaIn);
        setSuperBuiltUP(res.data.Result[0].SuperBuiltUP);
        setSuperBuiltUpIn(res.data.Result[0].SuperBuiltUpIn);
        setUpdatePropertyId(res.data.Result[0].UpdateId);    
     
        const ProInsightArr = res.data.Result[0].PropertyInsight;
        const PIArr = [];
        const PIArr1 = [];
        for (let pm = 0; pm < ProInsightArr.length; pm++) {
          let Vim = ProInsightArr[pm].InsightMedia;
          let VimArr = Vim.split("/");
          let IMFileName = VimArr[VimArr.length - 1];
          const PropertyMediaArr = {
            Id: ProInsightArr[pm].Id,
            InsightName: ProInsightArr[pm].InsightName,
            InsightWidth: ProInsightArr[pm].InsightWidth,
            InsightLength: ProInsightArr[pm].InsightLength,
            InsightHeight: ProInsightArr[pm].InsightHeight,
            InsightMediaName: IMFileName,
            InsightMedia: ProInsightArr[pm].InsightMedia,
            InsightStatus:
              ProInsightArr[pm].InsightStatus == "true" ? true : false,
            InsightNameId: "txtInsightName" + ProInsightArr[pm].Id,
            InsightWidthId: "txtInsightWidth" + ProInsightArr[pm].Id,
            InsightLengthId: "txtInsightLength" + ProInsightArr[pm].Id,
            InsightHeightId: "txtInsightHeight" + ProInsightArr[pm].Id,
            InsightMediaNameId: "txtInsightMediaName" + ProInsightArr[pm].Id,
            InsightDivId: "divPropertyInsight" + ProInsightArr[pm].Id,

            RecordType: "O",
          };
          PIArr.push(PropertyMediaArr);
        }
        for (let pm = 0; pm < ProInsightArr.length; pm++) {
      
          const PropertyMediaArr1 = {
            Id: ProInsightArr[pm].Id,
            InsightName: ProInsightArr[pm].InsightName,
          };
          PIArr1.push(PropertyMediaArr1);
        }

        setPropertyInsightList(PIArr);
        setPropInsightDdl(PIArr1);

        const ProFurnishedArr = res.data.Result[0].PropertyFurnished;
        if (ProFurnishedArr != undefined) {
          const PFArr = [];
          for (let pm = 0; pm < ProFurnishedArr.length; pm++) {
            let Vim = ProFurnishedArr[pm].FurnishedMedia;
            let VimArr = Vim.split("/");
            let IMFileName = VimArr[VimArr.length - 1];
            const PropertyFurnishedArr = {
              FurnishedId: ProFurnishedArr[pm].FurnishedId,
              FurnishedDetails: ProFurnishedArr[pm].FurnishedDetails,
              PropertyFurnishedIn: ProFurnishedArr[pm].PropertyFurnishedIn,
              FurnishedMedia: [],
              FurnishedDetailsId: "txtFurnishedDetails" + (pm + 1),
              FurnishedInId: "ddlFurnishedIn" + (pm + 1),
              FurnishedMediaName: IMFileName,
              FurnishedStatus:
                ProFurnishedArr[pm].FurnishedStatus == "true" ? true : false,
              FurnishedDivId:
                "divPropertyFurnished" + ProFurnishedArr[pm].FurnishedId,
              RecordType: "O",
            };
            PFArr.push(PropertyFurnishedArr);
          }
          setPropertyFurnishedList(PFArr);
        }
      }
    });
  };


  const getInsightMedia=(e)=>{
    let VInsightName1=document.getElementById("txtInsightName").value;
    if(VInsightName1==""){
      addToast("Please Enter Property Insight Title", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    else{

      setInsightMedia1(e.target.files[0]);
      setInsightMediaName(e.target.files[0].name);                   
      setInsightMediaFlag(1);
  
    }
 
  }

 

  const UpdatePropertyDetail = () => {
    let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
    let VPropertyId = localStorage.getItem("DetailPropertyId");
    var VInsightName = document.getElementById("txtInsightName").value;
    var VInsightWidth = document.getElementById("txtInsightWidth").value;
    var VInsightLength = document.getElementById("txtInsightLength").value;
    var VInsightHeight = document.getElementById("txtInsightHeight").value;

    if (PropertyInsightList.length == 0) {
    
      const formData = new FormData();
      let IsPropertyInsightsInsert = 1;
      if (VInsightName == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VInsightWidth == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VInsightLength == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VInsightHeight == "") {
        IsPropertyInsightsInsert = 0;
      } else if (InsightMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }
      if (IsPropertyInsightsInsert == 1) {
     
        let PICount=1;
        var LRNo = PropertyInsightList.length + 1;
        formData.append("PropertyId", VPropertyId);
        formData.append("PropertyLandareaInsightsId", UpdatePropertyId);
        formData.append("PropertyInsightCount", LRNo);
        let ISCN = "InsightStatus" + PICount;
        formData.append(ISCN, true);
        let INCN = "InsightName" + LRNo;
        formData.append(INCN, VInsightName);

        let IWCN = "InsightWidth" + LRNo;
        formData.append(IWCN, VInsightWidth);

        let ILCN = "InsightLength" + LRNo;
        formData.append(ILCN, VInsightLength);

        let IHCN = "InsightHeight" + LRNo;
        formData.append(IHCN, VInsightHeight);

        let IMCN = "InsightMedia" + LRNo;
        formData.append(IMCN, InsightMedia1);
    
        for (var pair of formData.entries()) {
          console.log(pair[0] + ":" + pair[1]);
        }
        axios
          .post(global.APIURL + "/EditPropertyDetail", formData)
          .then(res => {
          
            addToast("Property Insight Detail Update Successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            UpdatePropertyDetails();
            
          });
      } else {
     
        UpdatePropertyDetails();
            
      }
    } else {
      let Rcount = 0;
      let IsPropertyInsightsInsert = 1;
      if (VInsightName == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VInsightWidth == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VInsightLength == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VInsightHeight == "") {
        IsPropertyInsightsInsert = 0;
      } else if (InsightMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {
        var LRNo = PropertyInsightList.length + 1;
        
        const Pitem = {
          Id: LRNo,
          InsightName: VInsightName,
          InsightWidth: VInsightWidth,
          InsightLength: VInsightLength,
          InsightHeight: VInsightHeight,
          InsightMediaName: InsightMediaName,
          InsightMedia: InsightMedia1,
          InsightStatus: true,
          InsightNameId: "txtInsightName" + LRNo,
          InsightWidthId: "txtInsightWidth" + LRNo,
          InsightLengthId: "txtInsightLength" + LRNo,
          InsightHeightId: "txtInsightHeight" + LRNo,
          InsightMediaNameId: "txtInsightMediaName" + LRNo,
          RecordType: "N",
        };

        PropertyInsightList.push(Pitem);
      }

      if (PropertyInsightList.length > 0) {
       
        let ProInsCount = PropertyInsightList.length;
        const formData1 = new FormData();

        for (let pi = 0; pi < PropertyInsightList.length; pi++) {
          let PICount = pi + 1;
          let VRecordType = PropertyInsightList[pi].RecordType;
          let VInsightStatus = PropertyInsightList[pi].InsightStatus;
          formData1.append("PropertyId", VPropertyId);
          formData1.append("PropertyLandareaInsightsId", UpdatePropertyId);
          formData1.append("PropertyInsightCount", ProInsCount);
          let ISCN = "InsightStatus" + PICount;
          formData1.append(ISCN, VInsightStatus);

          let INCN = "InsightName" + PICount;
          let INId = "txtInsightName" + PICount;

          if (VInsightStatus == true) {
            if (VRecordType == "O") {
              let INValue = document.getElementById(INId).value;
              formData1.append(INCN, INValue);
            } else if (VRecordType == "U") {
              let INValue = document.getElementById(INId).value;
              formData1.append(INCN, INValue);
            } else if (VRecordType == "D") {
              let INValue = document.getElementById(INId).value;
              formData1.append(INCN, INValue);
            } else {
              formData1.append(INCN, PropertyInsightList[pi].InsightName);
            }
          } else {
            formData1.append(INCN, PropertyInsightList[pi].InsightName);
          }

          let IWCN = "InsightWidth" + PICount;
          let IWId = "txtInsightWidth" + PICount;

          if (VInsightStatus == true) {
            if (VRecordType == "O") {
              let IWValue = document.getElementById(IWId).value;
              formData1.append(IWCN, IWValue);
            } else if (VRecordType == "U") {
              let IWValue = document.getElementById(IWId).value;
              formData1.append(IWCN, IWValue);
            } else if (VRecordType == "D") {
              let IWValue = document.getElementById(IWId).value;
              formData1.append(IWCN, IWValue);
            } else {
              formData1.append(IWCN, PropertyInsightList[pi].InsightWidth);
            }
          } else {
            formData1.append(IWCN, PropertyInsightList[pi].InsightWidth);
          }

          let ILCN = "InsightLength" + PICount;
          let ILId = "txtInsightLength" + PICount;

          if (VInsightStatus == true) {
            if (VRecordType == "O") {
              let ILValue = document.getElementById(ILId).value;
              formData1.append(ILCN, ILValue);
            } else if (VRecordType == "U") {
              let ILValue = document.getElementById(ILId).value;
              formData1.append(ILCN, ILValue);
            } else if (VRecordType == "D") {
              let ILValue = document.getElementById(ILId).value;
              formData1.append(ILCN, ILValue);
            } else {
              formData1.append(ILCN, PropertyInsightList[pi].InsightLength);
            }
          } else {
            formData1.append(ILCN, PropertyInsightList[pi].InsightLength);
          }

          let IHCN = "InsightHeight" + PICount;
          let IHId = "txtInsightHeight" + PICount;

          if (VInsightStatus == true) {
            if (VRecordType == "O") {
              let IHValue = document.getElementById(IHId).value;
              formData1.append(IHCN, IHValue);
            } else if (VRecordType == "U") {
              let IHValue = document.getElementById(IHId).value;
              formData1.append(IHCN, IHValue);
            } else if (VRecordType == "D") {
              let IHValue = document.getElementById(IHId).value;
              formData1.append(IHCN, IHValue);
            } else {
              formData1.append(IHCN, PropertyInsightList[pi].InsightHeight);
            }
          } else {
            formData1.append(IHCN, PropertyInsightList[pi].InsightHeight);
          }

          let IMCN = "InsightMedia" + PICount;
          if (VInsightStatus == true) {
            if (VRecordType == "N") {
              formData1.append(IMCN, PropertyInsightList[pi].InsightMedia);
            } else if (VRecordType == "U") {
              formData1.append(IMCN, PropertyInsightList[pi].InsightMedia);
            }
          }

       
          axios
            .post(global.APIURL + "/EditPropertyDetail", formData1)
            .then(res => {
             
              if (pi == PropertyInsightList.length - 1) {
               
              
                addToast("Property Insight Detail Updated Successfully", {
                  appearance: "success",
                  autoDismiss: true,
                });
                UpdatePropertyDetails();
             // window.location.href = "/edit-property3";                            
              }
            });
        }
      }
    }
    
  };

  const UpdatePropertyDetails = () => {
     
    let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
    let VPropertyId = localStorage.getItem("DetailPropertyId");

    const PropListData = {
      RegisterId: VRegisterId,
      PropertyId: VPropertyId,
    };

    axios.post(global.APIURL + "/propertyDetail", PropListData).then(res => {
    
           if (res.data.Result != "") {        
        let PUpdateId = res.data.Result[0].UpdateId;
        const formData5 = new FormData();
        formData5.append("RegisterId", VRegisterId);
        formData5.append("PropertyId", VPropertyId);
        formData5.append("PropertyLandareaInsightsId", PUpdateId);
        formData5.append("LandArea", document.getElementById('txtLandArea').value);
        formData5.append("LandAreaIn", document.getElementById('ddlLandAreaIn').value);
        formData5.append("SuperBuiltUP", document.getElementById('txtSuperBuiltUP').value);
        formData5.append("SuperBuiltUpIn", document.getElementById('ddlSuperBuiltUPIn').value);
   

        axios
          .post(global.APIURL + "/PropertyEditLandArea", formData5)
          .then(res => {                
            UpdatePropertyFurnishedDetails();      
          });
      }
    });
  };

  const getPropertyInsightsArray=()=>{


    let VInsightName1=document.getElementById("txtInsightName").value;
  
  if(VInsightName1!=""){
   var PE=PropInsightDdl.filter(x=> x.InsightName==VInsightName1);
    if(PE.length==0)
    {
      let PArray={   
        Id:0,
        InsightName:VInsightName1,
      }  
      PropInsightDdl.push(PArray);
    }}
  }
  
  const UpdatePropertyFurnishedDetails = () => {
   // alert("hi")
    let VPropertyId = localStorage.getItem("DetailPropertyId");
    var VFurnishedDetails = document.getElementById(
      "txtFurnishedDetails"
    ).value;
    var VFurnishedIn = document.getElementById("ddlfurniture").value;
    if (PropertyFurnishedList.length == 0) {    
      const formData4 = new FormData();
      let IsPropertyInsightsInsert = 1;
      if (VFurnishedDetails == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VFurnishedIn == "") {
        IsPropertyInsightsInsert = 0;
      } else if (FurnishedMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }

      if (IsPropertyInsightsInsert == 1) {
      
      
        var LRNo = PropertyFurnishedList.length + 1;

        formData4.append("PropertyId", VPropertyId);
        formData4.append("PropertyLandareaInsightsId", UpdatePropertyId);
        formData4.append("FurnishedCount", LRNo);

        let FCCN = "FurnishedStatus" + LRNo;
        formData4.append(FCCN, true);

        let INCN = "PropertyFurnishedDetail" + LRNo;
        let INValue = document.getElementById("txtFurnishedDetails").value;
        formData4.append(INCN, INValue);

        let IWCN = "PropertyFurnishedIn" + LRNo;
        let IWValue = document.getElementById("ddlfurniture").value;
        formData4.append(IWCN, IWValue);

        let IMCN = "FurnishedMedia" + LRNo;
        formData4.append(IMCN, FurnishedMedia1);

        for (var pair of formData4.entries()) {
          console.log(pair[0] + ":" + pair[1]);
        }

        axios
          .post(global.APIURL + "/FurnishedEditData", formData4)
          .then(res => {
            if (res.data.Status == true) {
              addToast("Property Furnished Detail Updated Successfully", {
                appearance: "success",
                autoDismiss: true,
              });
              window.location.href = "/edit-property3";
            } else {
              addToast(res.data.StatusMessage, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          });
      } else {
        // addToast("Please Enter Property Furnished Detail", {
        //   appearance: "error",
        //   autoDismiss: true,
        // });
        window.location.href = "/edit-property3";   


      }
    } else {
    
      let IsPropertyInsightsInsert = 1;
      if (VFurnishedDetails == "") {
        IsPropertyInsightsInsert = 0;
      } else if (VFurnishedIn == "") {
        IsPropertyInsightsInsert = 0;
      } else if (FurnishedMediaName == "") {
        IsPropertyInsightsInsert = 0;
      }
      if (IsPropertyInsightsInsert == 1) {
      
        var LRNo = PropertyFurnishedList.length + 1;
        const Pitem = {
          FurnishedId: LRNo,
          FurnishedDetails: VFurnishedDetails,
          PropertyFurnishedIn: VFurnishedIn,
          FurnishedMedia: FurnishedMedia1,
          FurnishedDetailsId: "txtFurnishedDetails" + LRNo,
          FurnishedInId: "ddlFurnishedIn" + LRNo,
          FurnishedStatus: true,
          RecordType: "N",
        };
        PropertyFurnishedList.push(Pitem);
      }

      for (let pi = 0; pi < PropertyFurnishedList.length; pi++) {
     
        let PICount = pi + 1;
        let VRecordType = PropertyFurnishedList[pi].RecordType;
        let VFurnishedStatus = PropertyFurnishedList[pi].FurnishedStatus;
        const formData3 = new FormData();
        formData3.append("PropertyId", VPropertyId);
        formData3.append("PropertyLandareaInsightsId", UpdatePropertyId);
        if (VRecordType == "N") {
          formData3.append("FurnishedCount", PICount);
        }
        else {
          formData3.append("FurnishedCount", PropertyFurnishedList.length);
        }

        let FCCN = "FurnishedStatus" + PICount;
        formData3.append(FCCN, VFurnishedStatus);

        let INCN = "PropertyFurnishedDetail" + PICount;
        if (VFurnishedStatus == true) {
          if (VRecordType == "O") {
            let INId = "txtFurnishedDetails" + PICount;
            let INValue = document.getElementById(INId).value;
            formData3.append(INCN, INValue);
          } else if (VRecordType == "U") {
            let INId = "txtFurnishedDetails" + PICount;
            let INValue = document.getElementById(INId).value;
            formData3.append(INCN, INValue);
          } else if (VRecordType == "D") {
            let INId = "txtFurnishedDetails" + PICount;
            let INValue = document.getElementById(INId).value;
            formData3.append(INCN, INValue);
          } else if (VRecordType == "N") {
            formData3.append(INCN, PropertyFurnishedList[pi].FurnishedDetails);
          }
        } else {
          formData3.append(INCN, PropertyFurnishedList[pi].FurnishedDetails);
        }

        let FICN = "PropertyFurnishedIn" + PICount;
        if (VFurnishedStatus == true) {
          if (VRecordType == "O") {
            let FIId = "ddlFurnishedIn" + PICount;
            let FIValue = document.getElementById(FIId).value;
            formData3.append(FICN, FIValue);
          } else if (VRecordType == "U") {
            let FIId = "ddlFurnishedIn" + PICount;
            let FIValue = document.getElementById(FIId).value;
            formData3.append(FICN, FIValue);
          } else if (VRecordType == "D") {
            let FIId = "ddlFurnishedIn" + PICount;
            let FIValue = document.getElementById(FIId).value;
            formData3.append(FICN, FIValue);
          } else if (VRecordType == "N") {
            formData3.append(
              FICN,
              PropertyFurnishedList[pi].PropertyFurnishedIn
            );
          }
        } else {
          formData3.append(FICN, PropertyFurnishedList[pi].PropertyFurnishedIn);
        }

        let IMCN = "FurnishedMedia" + PICount;
        if (VFurnishedStatus == true) {
          if (VRecordType == "N") {
            formData3.append(IMCN, PropertyFurnishedList[pi].FurnishedMedia);
          } else if (VRecordType == "U") {
            formData3.append(IMCN, PropertyFurnishedList[pi].FurnishedMedia);
          }
        }
        for (var pair of formData3.entries()) {
          console.log(pair[0] + ":" + pair[1]);
        }
   
        axios
          .post(global.APIURL + "/FurnishedEditData", formData3)
          .then(res => {
            if (PropertyFurnishedList.length - 1 == pi) {
          
              addToast("Property Furnished Detail Updated Successfully", {
                appearance: "success",
                autoDismiss: true,
              });
            window.location.href = "/edit-property3";
            }
          });
      }
    }
  };

  const PropertyInsightAddRow = () => {
    var LRNo = parseInt(
      PropertyInsightList.length == 0
        ? 1
        : PropertyInsightList[PropertyInsightList.length - 1].Id + 1
    );
    var VInsightName1 = document.getElementById("txtInsightName").value;
    var VInsightWidth1 = document.getElementById("txtInsightWidth").value;
    var VInsightLength1 = document.getElementById("txtInsightLength").value;
    var VInsightHeight1 = document.getElementById("txtInsightHeight").value;
    let IsInsert = 1;
    if (VInsightName1 == "") {
      IsInsert = 0;
      addToast("Please Enter Property Insight Title", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (VInsightWidth1 == "") {
      IsInsert = 0;
      addToast("Please Enter Property Insight Width", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (VInsightLength1 == "") {
      IsInsert = 0;
      addToast("Please Enter Property Insight Length", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (VInsightHeight1 == "") {
      IsInsert = 0;
      addToast("Please Enter Property Insight Height", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (InsightMediaName == "") {
      IsInsert = 0;
      addToast("Please Enter Property Insight Media File", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (IsInsert == 1) {
      const Pitem = {
        Id: LRNo,
        InsightName: VInsightName1,
        InsightWidth: VInsightWidth1,
        InsightLength: VInsightLength1,
        InsightHeight: VInsightHeight1,
        InsightMediaName: InsightMedia1.name,
        InsightMedia: InsightMedia1,
        InsightStatus: true,
        InsightNameId: "txtInsightName" + LRNo,
        InsightWidthId: "txtInsightWidth" + LRNo,
        InsightLengthId: "txtInsightLength" + LRNo,
        InsightHeightId: "txtInsightHeight" + LRNo,
        InsightMediaNameId: "txtInsightMediaName" + LRNo,
        InsightDivId: "divPropertyInsight" + LRNo,
        RecordType: "N",
      };
  
      setPropertyInsightList([...PropertyInsightList, Pitem]);
      document.getElementById("txtInsightName").value = "";
      document.getElementById("txtInsightWidth").value = "";
      document.getElementById("txtInsightLength").value = "";
      document.getElementById("txtInsightHeight").value = "";
      setInsightMedia1([]);
      setInsightMediaName("");
    }
  };

  const insightsHandleAddRowdelete = (Id,VName) => {


    
    for (let pil = 0; pil < PropertyInsightList.length; pil++) {
     
      const PIId = PropertyInsightList[pil].Id;
      const VInsightDivId = "#" + PropertyInsightList[pil].InsightDivId;
      if (PIId == Id) {
      
        PropertyInsightList[pil]["InsightStatus"] = false;
        $(VInsightDivId).hide();
      }
    }

    for (let pil = 0; pil < PropertyFurnishedList.length; pil++) {

      const PFIn = PropertyFurnishedList[pil].PropertyFurnishedIn;


      const VFurnishedDivId = "#" + PropertyFurnishedList[pil].FurnishedDivId;

      if (PFIn == VName) {

        PropertyFurnishedList[pil]["FurnishedStatus"] = false;
        $(VFurnishedDivId).hide();

      }
    }    

    // setPropInsightDdl(
    //   PropInsightDdl.filter(item => item.InsightName == VName)
    // );
  
  };




  const PropertyFurnishedAddRow = () => {
    var LRNo =
      PropertyFurnishedList.length == 0
        ? 1
        : PropertyFurnishedList[PropertyFurnishedList.length - 1].FurnishedId +
        1;
    var VFurnishedDetails = document.getElementById(
      "txtFurnishedDetails"
    ).value;
    var VFurnishedIn = FurnishedIn;
    let IsInsert = 1;
    if (VFurnishedDetails == "") {
      IsInsert = 0;
      addToast("Please Enter Property Furniture", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (VFurnishedIn == "") {
      IsInsert = 0;
      addToast("Please Enter Property Furnished", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (FurnishedMediaName == "") {
      IsInsert = 0;
      addToast("Please Enter Property Furnished Media", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (IsInsert == 1) {
      const Pitem = {
        FurnishedId: LRNo,
        FurnishedDetails: VFurnishedDetails,
        PropertyFurnishedIn: VFurnishedIn,
        FurnishedMedia: FurnishedMedia1,
        FurnishedMediaName: FurnishedMediaName,
        FurnishedDetailsId: "txtFurnishedDetails" + LRNo,
        FurnishedInId: "ddlFurnishedIn" + LRNo,
        FurnishedStatus: true,

        RecordType: "N",
      };
      setPropertyFurnishedList([...PropertyFurnishedList, Pitem]);
      document.getElementById("txtFurnishedDetails").value = "";
      setFurnishedIn([]);
      setFurnishedMedia1([]);
      setFurnishedMediaName("");
    }
  };

  const PropertyFurnishedRowdelete = FurnishedId => {
    for (let pil = 0; pil < PropertyFurnishedList.length; pil++) {
      const PFId = PropertyFurnishedList[pil].FurnishedId;
      const VFurnishedDivId = "#" + PropertyFurnishedList[pil].FurnishedDivId;
      if (PFId == FurnishedId) {
        PropertyFurnishedList[pil]["FurnishedStatus"] = false;
        $(VFurnishedDivId).hide();
      }
    }
  };

  const handleInsightDd=()=>{

    setInsightMedia1([]);
    setInsightMediaName("");                   
    setInsightMediaFlag(0);


  }
  console.log(IsPropertyFurnished);

  return (
    <React.Fragment>
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow">
            <div className="row mainitalpropertylist">
              <div className="col-md-12">
                <p className="addservicetextform">Property Details</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mainpropertylistbox">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row ">
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12">
                                <p className="maintitlereuestservicenee">
                                  Land Area <span className="spanred"></span>
                                </p>
                              </div>
                              <div className="col-md-6 mb-3 mb-md-0">
                                <div className="registerinputnewedirr form-controlsustomeditnew">
                                  <input
                                    type="number"
                                    className="form-control formcontrollplaceholder border-0"
                                    id="txtLandArea"
                                    onChange={e => setLandArea(e.target.value)}
                                    placeholder="Enter Land Area"
                                    defaultValue={LandArea}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3 mb-md-0">
                                <select
                                  className="form-select form-selectnewwr form-select-lg mb-0 border-0"
                                  aria-label=".form-select-lg example"
                                  id="ddlLandAreaIn"
                                  onChange={e => setLandAreaIn(e.target.value)}
                                >
                                  <option
                                    value="Square feet"
                                    selected={"Square feet" === LandAreaIn}
                                  >
                                    Square feet
                                  </option>
                                  <option
                                    value="CENTS"
                                    selected={"CENTS" === LandAreaIn}
                                  >
                                    CENTS
                                  </option>
                                  <option
                                    value="Square meter"
                                    selected={"Square meter" === LandAreaIn}
                                  >
                                    Square meter
                                  </option>
                                  <option
                                    value="Hectar"
                                    selected={"Hectar" === LandAreaIn}
                                  >
                                    Hectare
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12">
                                <p className="maintitlereuestservicenee">
                                  Super Built Up <span className="spanred"></span>
                                </p>
                              </div>
                              <div className="col-md-6 mb-3 mb-md-0">
                                <div className="registerinputnewedirr form-controlsustomeditnew">
                                  <input
                                    type="number"
                                    className="form-control formcontrollplaceholder border-0"
                                    id="txtSuperBuiltUP"
                                    placeholder="Enter"
                                    onChange={e =>
                                      setSuperBuiltUP(e.target.value)
                                    }
                                    defaultValue={SuperBuiltUP}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3 mb-md-0">
                                <select
                                  className="form-select form-selectnewwr form-select-lg mb-0 border-0"
                                  aria-label=".form-select-lg example"
                                  onChange={e =>
                                    setSuperBuiltUpIn(e.target.value)
                                  }
                                  id="ddlSuperBuiltUPIn"
                                >
                                  <option
                                    value="Square feet"
                                    selected={"Square feet" === SuperBuiltUpIn}
                                  >
                                    Square feet
                                  </option>
                                  <option
                                    value="CENTS"
                                    selected={"CENTS" === SuperBuiltUpIn}
                                  >
                                    CENTS
                                  </option>
                                  <option
                                    value="Square meter"
                                    selected={"Square meter" === SuperBuiltUpIn}
                                  >
                                    Square meter
                                  </option>
                                  <option
                                    value="Hectar"
                                    selected={"Hectar" === SuperBuiltUpIn}
                                  >
                                    Hectare
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="maindivederaddpropertyinsight"></div>
                      </div>
                      <div className="col-md-12">
                        <div className=" mb-0">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="addservicetextform mainmarginpdtitle">
                                Property Insight (Optional) <span className="spanred"></span>
                              </p>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0 text-end">
                              <a onClick={()=>{PropertyInsightAddRow(); setInsightMediaFlag(0)}}
                              >
                                {" "}
                                <p className="addnewpropertytextneww">
                                  {" "}
                                  <i className="las la-plus-circle"></i>Add New
                                </p>
                              </a>
                            </div>
                          </div>
                          {PropertyInsightList.filter(
                            x => x.InsightStatus == true
                          ).map((item, idx) => (
                            <div
                              className="row mainaddnewinsightalldiv"
                              key={idx}
                              id={item.InsightDivId}
                            >
                              <div className="col-md-10">
                                <div className="row align-items-center ">
                            
                                  <div className="col-md-3 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                      <input
                                        type="text"
                                        className="form-control formcontrollplaceholder border-0"
                                        id={item.InsightNameId}
                                        placeholder="write here"
                                        defaultValue={item.InsightName}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                      <input
                                        type="number"
                                        className="form-control formcontrollplaceholder border-0"
                                        id={item.InsightWidthId}
                                        defaultValue={item.InsightWidth}
                                      />
                                      <div className="maininputdefulttext">
                                        <p>W</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                      <input
                                        type="number"
                                        className="form-control formcontrollplaceholder border-0"
                                        id={item.InsightLengthId}
                                        defaultValue={item.InsightLength}
                                      />
                                      <div className="maininputdefulttext">
                                        <p>L</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                      <input
                                        type="number"
                                        className="form-control formcontrollplaceholder border-0"
                                        id={item.InsightHeightId}
                                        defaultValue={item.InsightHeight}
                                      />
                                      <div className="maininputdefulttext">
                                        <p>H</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3 mb-3 mb-md-0">
                                    <div className="">
                                      <div className="mainfileuploadimgwith">
                                        <input
                                          type="text"
                                          name="file-name"
                                          id={item.InsightMediaNameId}
                                          className="file-name border-0"
                                          readOnly="readonly"
                                          defaultValue={item.InsightMediaName}
                                        />

                                        <div className="filnamewithiconmain">
                                          <i className="las la-file"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div className="col-md-2 mb-3 mb-md-0 text-end">
                                <button
                                  className="btn removerowbtnnew "
                                  onClick={e =>
                                    insightsHandleAddRowdelete(item.Id,item.InsightName)
                                  }
                                >
                                  <img
                                    src={deletedash}
                                    className="deletedashimg"
                                  ></img>
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}

                          <div className="row mainaddnewinsightalldiv">
                            <div className="col-md-10">
                              <div className="row align-items-center ">
                                {/* <div className="col-md-1"></div> */}
                                <div className="col-md-3 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                    <input
                                      type="text"
                                      className="form-control formcontrollplaceholder border-0"
                                      id="txtInsightName"
                                      placeholder="write here"
                                      onChange={handleInsightDd}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-2 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control formcontrollplaceholder border-0"
                                      id="txtInsightWidth"
                                    />
                                    <div className="maininputdefulttext">
                                      <p>W</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control formcontrollplaceholder border-0"
                                      id="txtInsightLength"
                                    />
                                    <div className="maininputdefulttext">
                                      <p>L</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control formcontrollplaceholder border-0"
                                      id="txtInsightHeight"
                                    />
                                    <div className="maininputdefulttext">
                                      <p>H</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                  <div className="">
                                    <input
                                      type="file"
                                      name="file"
                                      id="file"
                                      className="file" />

                                    <div className="mainfileuploadimgwith">
                                      <input
                                        type="text"
                                        name="file-name"
                                        id="file-name"
                                        className="file-name border-0"
                                        defaultValue={InsightMediaName}
                                        readOnly="readonly"
                                      />
                                      <a
                                        onClick={e => {
                                          setInsightMedia1([]);
                                          setInsightMediaName("");
                                          setInsightMediaFlag(0);
                                        }}
                                      >
                                        <div className="maindivdeletprpertyaddnewsmall">
                                          {InsightMediaFlag==1? <div className="deletprpertyaddnewsmall">
                                            <i className="las la-trash-alt"></i>
                                          </div>:""}
                                        </div>
                                      </a>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 mb-3 mb-md-0 text-end">
                              <button
                                type="button"
                                className="btn uploadbtnmainaddproperty"
                                id="upfile1"
                                onClick={getPropertyInsightsArray}
                               
                              >
                                <i className="las la-cloud-upload-alt"></i>
                                Upload Files{" "}
                              </button>
                              <input
                                type="file"
                                id="file1"
                                onChange={e => {
                                  getInsightMedia(e);
                              
                                }}
                                className="d-none"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="maindivederaddpropertyinsight"></div>
                            </div>
                            <div className="col-md-10 mb-2">
                              <div>
                                <form>
                                  {/* <div className="form-group newwform-group">
                                    <input
                                      type="checkbox"
                                      id="checkboxeditnew"
                                      onChange={e => handleFurnishedCheckbox(e)}
                                      checked={IsPropertyFurnished}
                                    />
                                
                                    <label htmlFor="html">
                                      is Property Furnished ?
                                    </label>
                                  </div> */}                                 
                                  <div className="form-group newwform-group">
                              <input
                                type="checkbox"
                                id="checkbox1"
                                onChange={e => handleFurnishedCheckbox(e)}
                                defaultChecked={IsPropertyFurnished}                             
                              />
                              <label htmlFor="checkbox1">
                                is Property Furnished ?
                              </label>
                            </div>
                                </form>
                              </div>
                            </div>
                            <div className="col-md-2 text-end" id="addnewbutton">
                              <a onClick={()=>{PropertyFurnishedAddRow();setFurnishedMediaFlag(0)}}                              
                              >
                                {" "}
                                <p className="addnewpropertytextneww">
                                  {" "}
                                  <i className="las la-plus-circle"></i>Add New{" "}
                                </p>
                              </a>
                            </div>
                          </div>
                          {PropertyFurnishedList.filter(
                            x => x.FurnishedStatus == true
                          ).map((item, idx) => (
                            <div
                              className="row mainaddnewinsightalldiv align-items-center "
                              id={item.FurnishedDivId}
                              key={idx}
                            >
                              <div className="col-md-12">
                                <div className="row align-items-center ">                             
                                  <div className="col-md-3 mb-3 mb-md-0">
                                    <select
                                      id={item.FurnishedInId}
                                      className="form-select border-0 form-selectnewwr form-select-lg mb-0"
                                      aria-label=".form-select-lg example"
                                      required>
                                      <option selected>
                                        Property Furnished In
                                      </option>
                                      {PropInsightDdl.map(PItems => (
                                        <option
                                          key={PItems.InsightName}
                                          value={PItems.InsightName}
                                          selected={
                                            PItems.InsightName ===
                                            item.PropertyFurnishedIn}>
                                          {" "}
                                          {PItems.InsightName}{" "}
                                        </option>
                                      ))}
                                    </select>
                                    {/* <input
                                      type="text"
                                      className="form-control formcontrollplaceholder border-0"
                                      id={item.FurnishedInId}
                                      placeholder="write here property insight"
                                      defaultValue={item.PropertyFurnishedIn}
                                    />  */}
                                  </div>
                                  <div className="col-md-4 mb-3 mb-md-0">
                                    <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                      <input
                                        type="text"
                                        className="form-control formcontrollplaceholder border-0"
                                        id={item.FurnishedDetailsId}
                                        placeholder="write here furnished detail"
                                        defaultValue={item.FurnishedDetails}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-3 mb-3 mb-md-0">
                                    <div className="">
                                      <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        className="file"
                                      />
                                      <div className="mainfileuploadimgwith">
                                        <input
                                          type="text"
                                          name="file-name"
                                          id="file-name"
                                          className="file-name border-0"
                                          readOnly="readonly"
                                          defaultValue={item.FurnishedMediaName}
                                        />
                                     
                                        <div className="filnamewithiconmain">
                                          <i className="las la-file"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                               
                                  <div className="col-md-2 text-end">
                                    <button
                                      className="btn removerowbtnnew "
                                      onClick={e =>
                                        PropertyFurnishedRowdelete(
                                          item.FurnishedId
                                        )
                                      }
                                    >
                                      <img
                                        src={deletedash}
                                        className="deletedashimg"
                                      ></img>
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="row mainaddnewinsightalldiv align-items-center "  id="autoUpdate">
                            <div className="col-md-12">
                              <div className="row align-items-center ">

                                <div className="col-md-3 mb-3 mb-md-0">
                                  <select
                                    id="ddlfurniture"
                                    className="form-select border-0 form-selectnewwr form-select-lg mb-0"
                                    aria-label=".form-select-lg example"
                                    onChange={e =>
                                      setFurnishedIn(e.target.value)
                                    }
                                    required
                                  >
                                    <option selected>
                                      Property Furnished In
                                    </option>
                                    {PropInsightDdl.map(PItems => (
                                      <option
                                        key={PItems.InsightName}
                                        value={PItems.InsightName}
                                      >
                                        {" "}
                                        {PItems.InsightName}{" "}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-4 mb-3 mb-md-0">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mainpostionrelativeinput">
                                    <input
                                      type="text"
                                      className="form-control formcontrollplaceholder border-0"
                                      id="txtFurnishedDetails"
                                      placeholder="write here furnished detail"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                  <div className="">
                                    <input
                                      type="file"
                                      name="file"
                                      id="file"
                                      className="file"
                                    />
                                    <div className="mainfileuploadimgwith">
                                      <input
                                        type="text"
                                        name="file-name"
                                        id="file-name"
                                        className="file-name border-0"
                                        defaultValue={FurnishedMediaName}
                                        readOnly="readonly"
                                      />
                                      <div className="maindivdeletprpertyaddnewsmall">
                                        {FurnishedMediaFlag==1?<div className="deletprpertyaddnewsmall">
                                          <a
                                            onClick={e => {
                                              setFurnishedMedia1([]);
                                              setFurnishedMediaName("");
                                            }}
                                          >
                                            <i className="las la-trash-alt"></i>
                                          </a>
                                        </div>:""}
                                      </div>
                                      <div className="filnamewithiconmain">
                                        <i className="las la-file"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 mb-3 mb-md-0 text-end">
                                  <button
                                    type="button"
                                    className="btn uploadbtnmainaddproperty"
                                    id="upfile2"
                                  >
                                    <i className="las la-cloud-upload-alt"></i>
                                    Upload Files{" "}
                                  </button>
                                  <input
                                    type="file"
                                    id="file2"
                                    onChange={e => {
                                      setFurnishedMedia1(e.target.files[0]);
                                      setFurnishedMediaName(
                                        e.target.files[0].name
                                      );
                                      setFurnishedMediaFlag(1);
                                    }}
                                    className="d-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12"></div>
              <div className="col-md-12">
                <a
                  className="btn btn-primary btnnextedit"
                  onClick={UpdatePropertyDetail}
                >
                  Save{" "}
                </a>
                <a
                  className="btn btn-primary btnnexteditline"
                  href="/property-list"
                >
                  {" "}
                  Cancel{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footeradmin />
    </React.Fragment>
  );
};

export default EditProperty2;

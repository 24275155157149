import React, { Component, useState, useEffect } from "react"
import mianheaderimg from "../../assets/images/frontend-img/logo-new1.png";
import loginmainimg from "../../assets/images/frontend-img/loginmainimg.png";
import profileimg from "../../assets/images/frontend-img/userdefaultimage.jpg";
import toprightshap from "../../assets/images/frontend-img/toprightshap.png";
import topshapleft from "../../assets/images/frontend-img/topshapleft.png";
import bottomrightshap from "../../assets/images/frontend-img/bottomrightshap.png";
import bottomleftshap from "../../assets/images/frontend-img/bottomleftshap.png";
import moment from 'moment';
import otpicon from "../../assets/images/frontend-img/otpicon.png";
import axios from "axios";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { useRef } from "react";

const RegisterYourProfile = () => {

  const { addToast } = useToasts();
  const [EmailVerify, setEmailVerify] = useState(0);
  const [MobileVerify, setMobileVerify] = useState(0);
  const [EmailVerifySuccess, setEmailVerifySuccess] = useState(0);
  const [MobileVerifySuccess, setMobileVerifySuccess] = useState(0);
  const [ProfilePic, setProfilePic] = useState();
  const [FullName, setFullName] = useState('');
  const [Gender, setGender] = useState('');
  const [Password, setPassword] = useState('');
  const [DateOfBirth, setDateOfBirth] = useState('');
  const [EmailAddress, setEmailAddress] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [EmailOTP, setEmailOTP] = useState('');
  const [MobileOTP, setMobileOTP] = useState('');
  const [ProfilepicUrl, setProfilepicUrl] = useState([]);

  const [OTP1, setOTP1] = useState([]);
  const [OTP2, setOTP2] = useState([]);
  const [OTP3, setOTP3] = useState([]);
  const [OTP4, setOTP4] = useState([]);

  const [OTP5, setOTP5] = useState([]);
  const [OTP6, setOTP6] = useState([]);
  const [OTP7, setOTP7] = useState([]);
  const [OTP8, setOTP8] = useState([]);

  const num1 = useRef(null);
  const num2 = useRef();
  const num3 = useRef();
  const num4 = useRef();

  const num5 = useRef(null);
  const num6 = useRef();
  const num7 = useRef();
  const num8 = useRef();

  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  useEffect(() => {
   
  }, []);


  const sendEmailOTP = async () => {
   if (EmailVerifySuccess == 0) {
      if (EmailAddress != "") {
        if (regex.test(EmailAddress) === false) {
          addToast("Please Enter Valid Email Address", { appearance: 'error', autoDismiss: true });
          return;
        }
        const formData = new FormData();

        formData.append('MobileNumber', "");
        formData.append('Email', EmailAddress);
        formData.append('OTPType', "Email");
        formData.append('DeviceInfo', "");

        axios.post(global.APIURL + '/SendOTP', formData)
          .then(res => {      
            if (res.data.Status == true) {
              addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
              setEmailVerify(1);
              $("#btnESend").html('Resend');
              $("#btnESend").removeClass('btnsendedit');
              $("#btnESend").addClass('brnresendnewbtn');
            }
            else {
              addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
              $("#btnESend").html('Verify');
              $("#btnESend").removeClass('brnresendnewbtn');
              $("#btnESend").addClass('btnsendedit');
              setEmailVerify(0);
            }
          });
      }
      else {
        addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      }
    }
  }

  const verifyEmailOTP = async () => {
    const formData = new FormData();
    formData.append('MobileNumber', "");
    formData.append('Email', EmailAddress);
    formData.append('OTPType', "Email");
    formData.append('OTP', EmailOTP);
    axios.post(global.APIURL + '/OtpVerify', formData)
      .then(res => {
        if (res.data.Status == true) {
          setEmailVerify(0);
          setEmailVerifySuccess(1);
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          $("#btnESend").html('Verified');
          $("#btnESend").removeClass('brnresendnewbtn');
          $("#btnESend").addClass('btnsendedit');
        }
        else {
          setEmailVerify(1);
          setEmailVerifySuccess(0);
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          $("#btnESend").html('Resend');
          $("#btnESend").removeClass('btnsendedit');
          $("#btnESend").addClass('brnresendnewbtn');
        }
      });
  }

  const sendMobileOTP = async () => {
    if (MobileVerifySuccess == 0) {
      if (MobileNo != "") {
        if (MobileNo.length < 10) {
          addToast("Please Enter Valid Mobile Number.", { appearance: 'error', autoDismiss: true });
          return;
        }
        const formData = new FormData();
        formData.append('MobileNumber', MobileNo);
        formData.append('Email', "");
        formData.append('OTPType', "Mobile");
        formData.append('DeviceInfo', "");

        axios.post(global.APIURL + '/SendOTP', formData)
          .then(res => {

            console.log("Mobile OTP", res.data.OTP);
            if (res.data.Status == true) {
              setMobileVerify(1);
              addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
              $("#btnMobileSend").html('Resend');
              $("#btnMobileSend").removeClass('btnsendedit');
              $("#btnMobileSend").addClass('brnresendnewbtn');
            }
            else {
              setMobileVerify(0);
              addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
              $("#btnMobileSend").html('Verify');
              $("#btnMobileSend").removeClass('brnresendnewbtn');
              $("#btnMobileSend").addClass('btnsendedit');
            }
          });
      }
      else {
        addToast("Please Enter Mobile Number", { appearance: 'error', autoDismiss: true });
        //alert("Please Entre Mobile Number.");
      }
    }
  }

  const verifyMobileOTP = async () => {

    const formData = new FormData();
    formData.append('MobileNumber', MobileNo);
    formData.append('Email', "");
    formData.append('OTPType', "MobileNumber");
    formData.append('OTP', MobileOTP);

    axios.post(global.APIURL + '/OtpVerify', formData)
      .then(res => {

        if (res.data.Status == true) {
          setMobileVerify(0);
          setMobileVerifySuccess(1);
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          $("#btnMobileSend").html('Verified');
          $("#btnMobileSend").removeClass('brnresendnewbtn');
          $("#btnMobileSend").addClass('btnsendedit');
        }
        else {
          setMobileVerify(1);
          setMobileVerifySuccess(0);
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          $("#btnMobileSend").html('Resend');
          $("#btnMobileSend").removeClass('btnsendedit');
          $("#btnMobileSend").addClass('brnresendnewbtn');
        }
      });
  }

  const CheckRegister = async () => {

    var chkTermsandconditionsval = document.getElementById("chkTermsandconditions");
    let IsRegister = 1;
    if (FullName == '') {
      IsRegister = 0;
      addToast("Please Enter Name", { appearance: 'error', autoDismiss: true });
      return;
    }
    if (EmailAddress == '') {
      IsRegister = 0;
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      return;
    }
    if (EmailAddress != "") {
      if (regex.test(EmailAddress) === false) {
        IsRegister = 0;
        addToast("Please Enter Valid Email Address", { appearance: 'error', autoDismiss: true });
        return;
      }
    }

    if (MobileNo == '') {
      IsRegister = 0;
      addToast("Please Enter Mobile Number", { appearance: 'error', autoDismiss: true });
      return;
    }

    if (MobileNo.length <= 10) {
      IsRegister = 0;
      addToast("Please Enter Valid Mobile Number along with Country Code", { appearance: 'error', autoDismiss: true });
      return;
    }
    if (DateOfBirth == '') {
      IsRegister = 0;
      addToast("Select Date Of Birth", { appearance: 'error', autoDismiss: true });
      return;
    }
    if (Password == '') {
      IsRegister = 0;
      addToast("Please Enter Password", { appearance: 'error', autoDismiss: true });
      return;
    }
    if (Gender == '') {
      IsRegister = 0;
      addToast("Select Gender", { appearance: 'error', autoDismiss: true });
      return;
    }
    else if (chkTermsandconditionsval.checked == false) {
      IsRegister = 0;
      addToast("You must accept the terms and condition to register on account.", { appearance: 'error', autoDismiss: true });
      return;
    }

    if (IsRegister == 1) {
      if (MobileVerifySuccess == 1 && EmailVerifySuccess == 1) {
        RegisterProfile();
      }
      else if (MobileVerifySuccess == 0 && EmailVerifySuccess == 1) {
        sendMobileOTP();
        $('#exampleModal1').modal('show');
      }
      else if (MobileVerifySuccess == 1 && EmailVerifySuccess == 0) {
        sendEmailOTP();
        $('#exampleModal2').modal('show');
      }
      else if (MobileVerifySuccess == 0 && EmailVerifySuccess == 0) {
        sendEmailOTP()
        sendMobileOTP()
        $('#exampleModal3').modal('show');
      }
    }
  }

  const CancelRegister = async () => {
    window.location.href = "/home";
  }

  const RegisterProfile = async () => {

    let IsRegister = 1;
    if (FullName == '') {
      IsRegister = 0;
      addToast("Please Enter Name", { appearance: 'error', autoDismiss: true });
    }
    else if (EmailAddress == '') {
      IsRegister = 0;
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });     
    }
    else if (MobileNo == '') {
      IsRegister = 0;
      addToast("Please Enter Mobile Number", { appearance: 'error', autoDismiss: true });      
    }
    else if (DateOfBirth == '') {
      IsRegister = 0;
      addToast("Select Date Of Birth", { appearance: 'error', autoDismiss: true });   
    }
    else if (Password == '') {
      IsRegister = 0;
      addToast("Please Enter Password", { appearance: 'error', autoDismiss: true });    
    }
    else if (Gender == '') {
      IsRegister = 0;
      addToast("Select Gender", { appearance: 'error', autoDismiss: true });
    }

    if (IsRegister == 1) {
      const formData = new FormData();
      formData.append('Name', FullName);
      formData.append('MobileNumber', MobileNo);
      formData.append('Email', EmailAddress);
      formData.append('OTPType', "Email");
      formData.append('DeviceInfo', "");
      formData.append('Gender', Gender);
      formData.append('ProfilePhoto', ProfilePic);
      formData.append('DOB', DateOfBirth);
      formData.append('Password', Password);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ":" + pair[1]);
      }


      axios.post(global.APIURL + '/Register', formData)
        .then(res => {
          if (res.data.status == true) {
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            localStorage.setItem("RegisterId", JSON.stringify(res.data.Result.RegisterId));
            localStorage.setItem("Name", JSON.stringify(res.data.Result.Name));
            localStorage.setItem("Email", JSON.stringify(res.data.Result.Email));
            localStorage.setItem("MobileNo", JSON.stringify(res.data.Result.MobileNo));
            localStorage.setItem("EmailVerified", JSON.stringify(res.data.Result.EmailVerified));
            localStorage.setItem("MobileVerified", JSON.stringify(res.data.Result.MobileVerified));
            window.location.href = "/dashboard";
          }
          if (res.data.Status == false) {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          }
        });
    }
  }

  const handleOtp1Change = (value) => {

    if (event.target.value != "") {
      num2.current.focus();
    }
    setOTP1(value);
  }
  const handleOtp2Change = (value) => {

    num3.current.focus();
    setOTP2(value);
    if (event.target.value == "") {
      num1.current.focus();
    }

  }
  const handleOtp3Change = (value) => {

    num4.current.focus();
    setOTP3(value);
    if (event.target.value == "") {
      num2.current.focus();
    }

  }
  const handleOtp4Change = (value) => {
    num5.current.focus();
    setOTP4(value);
    if (event.target.value == "") {
      num3.current.focus();
    }

  }
  const handleOtp5Change = (value) => {

    num6.current.focus();
    setOTP5(value);
    if (event.target.value == "") {
      num4.current.focus();
    }

  }
  const handleOtp6Change = (value) => {

    num7.current.focus();
    setOTP6(value);
    if (event.target.value == "") {
      num5.current.focus();
    }

  }
  const handleOtp7Change = (value) => {

    num8.current.focus();
    setOTP7(value);
    if (event.target.value == "") {
      num6.current.focus();
    }

  }
  const handleOtp8Change = (value) => {

    setOTP8(value);
    if (event.target.value == "") {
      num7.current.focus();
    }

  }

  const verfication = () => {

    if (MobileVerifySuccess == 0 && EmailVerifySuccess == 1) {
      const OTP = OTP1 + OTP2 + OTP3 + OTP4;
      const formData = new FormData();
      formData.append('MobileNumber', MobileNo);
      formData.append('Email', "");
      formData.append('OTPType', "Mobile");
      formData.append('OTP', OTP);

      axios.post(global.APIURL + '/OtpVerify', formData)
        .then(res => {
          if (res.data.Status == true) {
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });         
            RegisterProfile();
          }
          else {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });   
          }
        });
    }
    else if (MobileVerifySuccess == 1 && EmailVerifySuccess == 0) {

      const formData = new FormData();
      const OTP = OTP5 + OTP6 + OTP7 + OTP8;
      formData.append('MobileNumber', "");
      formData.append('Email', EmailAddress);
      formData.append('OTPType', "Email");
      formData.append('OTP', OTP);

      axios.post(global.APIURL + '/OtpVerify', formData)
        .then(res => {
          if (res.data.Status == true) {
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            RegisterProfile();
          }
          else {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          }
        });
    }
    else if (MobileVerifySuccess == 0 && EmailVerifySuccess == 0) {

      const OTP = OTP1 + OTP2 + OTP3 + OTP4;
      const formData = new FormData();
      formData.append('MobileNumber', MobileNo);
      formData.append('Email', "");
      formData.append('OTPType', "Mobile");
      formData.append('OTP', OTP);

      axios.post(global.APIURL + '/OtpVerify', formData)
        .then(res => {
          if (res.data.Status == true) {
            RegisterProfile();
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          }
          else {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          }
        });

      const formData1 = new FormData();

      const OTP2 = OTP5 + OTP6 + OTP7 + OTP8;
      formData1.append('MobileNumber', "");
      formData1.append('Email', EmailAddress);
      formData1.append('OTPType', "Email");
      formData1.append('OTP', OTP2);

      axios.post(global.APIURL + '/OtpVerify', formData1)
        .then(res => {
          if (res.data.Status == true) {
            RegisterProfile();
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });     
          }
          else {
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });     
          }
        });
    }
  }

  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  return (
    <React.Fragment>
      <img src={topshapleft} className="topshapleft"></img>
      <img src={toprightshap} className="toprightshap"></img>
      <img src={bottomrightshap} className="bottomrightshap"></img>
      <img src={bottomleftshap} className="bottomleftshap"></img>
   
      {MobileVerifySuccess == 0 && EmailVerifySuccess == 0 ?
        <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" autoFocus={false}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <p className="verificationeditext">
                  Verification
                </p>
                <button type="button" className="skipbtn" data-bs-dismiss="modal" aria-label="Close" onClick={RegisterProfile} >Skip</button>
              </div>
              <div className="modal-body" >
                <div className="text-center">
                  <img src={otpicon} ></img>
                </div>
                <div>
                  {MobileVerifySuccess == 0 ? <form action="" className="formotp">
                    <div className="d-none">
                      <p className="otpverificationtitlenew">Mobile Number Verification</p>
                    </div>
                    <input autoFocus="autoFocus" className="otp mr1vw d-none" type="text" maxLength='1' id="OTP1" ref={num1} onChange={(e) => handleOtp1Change(e.target.value)} />
                    <input className="otp mr1vw d-none" type="text" on maxLength='1' id="OTP2" ref={num2} onChange={(e) => handleOtp2Change(e.target.value)} />
                    <input className="otp mr1vw d-none" type="text" maxLength='1' id="OTP3" ref={num3} onChange={(e) => handleOtp3Change(e.target.value)} />
                    <input className="otp d-none" type="text" maxLength='1' id="OTP4" ref={num4} onChange={(e) => handleOtp4Change(e.target.value)} />

                  </form> : ""}
                  {EmailVerifySuccess == 0 ? <form action="" className="formotp">
                    <div>
                      <p className="otpverificationtitlenew">Email Address Verification</p>
                    </div>
                    <input className="otp mr1vw" type="text" maxLength='1' id="OTP5" ref={num5} onChange={(e) => handleOtp5Change(e.target.value)} />
                    <input className="otp mr1vw" type="text" on maxLength='1' id="OTP6" ref={num6} onChange={(e) => handleOtp6Change(e.target.value)} />
                    <input className="otp mr1vw" type="text" maxLength='1' id="OTP7" ref={num7} onChange={(e) => handleOtp7Change(e.target.value)} />
                    <input className="otp" type="text" maxLength='1' id="OTP8" ref={num8} onChange={(e) => handleOtp8Change(e.target.value)} />

                  </form> : ""}
                  <div className="text-center">
                    <button type="button" className="btn btn-primary btnnextedit " onClick={verfication}>Continue <i className="fas fa-long-arrow-alt-right"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : ""}
      {MobileVerifySuccess == 0 && EmailVerifySuccess == 1 ?
        <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <p className="verificationeditext">
                  Verification
                </p>
                <button type="button" className="skipbtn" data-bs-dismiss="modal" aria-label="Close" onClick={RegisterProfile}>Skip</button>
              </div>
              <div className="modal-body">
                <div className="text-center">
                  <img src={otpicon} ></img>
                </div>
                <div>
                  {MobileVerifySuccess == 0 ? <form action="" className="formotp">
                    <div className="d-none">
                      <p className="otpverificationtitlenew">Mobile Number Verification</p>
                    </div>
                    <input autoFocus="autoFocus" className="otp mr1vw d-none" type="text" maxLength='1' id="OTP1" ref={num1} onChange={(e) => handleOtp1Change(e.target.value)} />
                    <input className="otp mr1vw d-none" type="text" on maxLength='1' id="OTP2" ref={num2} onChange={(e) => handleOtp2Change(e.target.value)} />
                    <input className="otp mr1vw d-none" type="text" maxLength='1' id="OTP3" ref={num3} onChange={(e) => handleOtp3Change(e.target.value)} />
                    <input className="otp d-none" type="text" maxLength='1' id="OTP4" ref={num4} onChange={(e) => handleOtp4Change(e.target.value)} />
                    <div className="text-center">
                      <button type="button" className="btn btn-primary btnnextedit " onClick={verfication}>Continue <i className="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                  </form>
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div> : ""}
      {MobileVerifySuccess == 1 && EmailVerifySuccess == 0 ?
        <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <p className="verificationeditext">
                  Verification
                </p>
                <button type="button" className="skipbtn" data-bs-dismiss="modal" aria-label="Close" onClick={RegisterProfile}>Skip</button>
              </div>
              <div className="modal-body">
                <div className="text-center">
                  <img src={otpicon} ></img>
                </div>
                <div>
                  {EmailVerifySuccess == 0 ? <form action="" className="formotp">
                    <div>
                      <p className="otpverificationtitlenew">Email Address Verification</p>
                    </div>
                    <input className="otp mr1vw" type="text" maxLength='1' id="OTP5" ref={num5} onChange={(e) => handleOtp5Change(e.target.value)} />
                    <input className="otp mr1vw" type="text" on maxLength='1' id="OTP6" ref={num6} onChange={(e) => handleOtp6Change(e.target.value)} />
                    <input className="otp mr1vw" type="text" maxLength='1' id="OTP7" ref={num7} onChange={(e) => handleOtp7Change(e.target.value)} />
                    <input className="otp" type="text" maxLength='1' id="OTP8" ref={num8} onChange={(e) => handleOtp8Change(e.target.value)} />
                    <div className="text-center">
                      <button type="button" className="btn btn-primary btnnextedit " onClick={verfication}>Continue<i className="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                  </form>
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div> : ""}
      <nav className="navbar navbar-expand-md navbar-light fixed-top bg-dark registerheader">
        <div className="container">
          <a href="home"><img className="loginlogo3 mb-0" src={mianheaderimg}></img></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <a className="nav-link" href="/home">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/login">Login</a>
              </li>        
            </ul>
          </div>
        </div>
      </nav>
      <div className="modal fade newzindexrdit" id="termsandconditionmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Terms & Conditions</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row miandivpricingbox newmaindivpricingcustomadd">             
              <div name="termly-embed" data-id="8f1e65bc-f9c1-4d67-9060-da2884dbcb6a" data-type="iframe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade newzindexrdit" id="privacypolicemodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">privacy policy</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row miandivpricingbox newmaindivpricingcustomadd">
              <div name="termly-embed" data-id="3ff5f924-f50d-42f1-a687-cae728b3476c" data-type="iframe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="mainboxshadow mb-0">
            <div className="row gx-4">
              <div className="col-md-12">
                <p className="registertitleedit">
                  Register Your Profile
                </p>
                <p className="registerdescription">
                  Create account to start using CoLand Services Private Limited
                </p>
              </div>
              <div className="col-md-10 order-2 order-md-1">
                <div className="row gx-4">
                  <div className="col-md-4">
                    <div className="registerinput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Name <span className="spanred">*</span></label>
                      <input type="email" className="form-control" id="txtName" placeholder="Enter Your Name" onChange={(e) => setFullName(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="registerinput">
                      <div>
                        <label htmlFor="exampleFormControlInput1" className="form-label">Email Address  <span className="spanred">*</span></label>
                      </div>
                      <div className="input-group mb1vw">
                        <input type="text" className="form-control" placeholder="Enter Your Email Address" id="txtEmail" onChange={(e) => setEmailAddress(e.target.value)} aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <button className="btn btn-outline-secondary btnsendedit" type="button" id="btnESend" onClick={sendEmailOTP}>Verify</button>
                      </div>
                      {EmailVerify == 1 ?
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="Enter otp" aria-label="Recipient's username" onChange={(e) => setEmailOTP(e.target.value)} id="txtEmailOTP" aria-describedby="button-addon2" />
                          <button className="btn btn-outline-secondary btnsendedit" type="button" id="btnEVerify" onClick={verifyEmailOTP}>Verify</button>
                        </div> : ""}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="registerinput">
                      <div>
                        <label htmlFor="exampleFormControlInput1" className="form-label">Mobile Number <span className="spanred">*</span></label>
                      </div>
                      <div className="input-group mb1vw">
                        <input type="number" className="form-control" id="txtMobileNo" maxLength={15} onKeyPress={inputHandler} onChange={(e) => setMobileNo(e.target.value)} placeholder="Enter Your Mobile Number with Country Code" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <button className="btn btn-outline-secondary btnsendedit d-none" type="button" id="btnMobileSend" onClick={sendMobileOTP}>Verify</button>
                      </div>
                      {MobileVerify == 1 ?
                        <div className="input-group">
                          <input type="number" className="form-control" placeholder="Enter otp" onChange={(e) => setMobileOTP(e.target.value)} aria-label="Recipient's username" id="txtMobileOTP" aria-describedby="button-addon2" />
                          <button className="btn btn-outline-secondary btnsendedit" type="button" id="btnMobileVerify" onClick={verifyMobileOTP}>Verify</button>                     
                        </div> : ""}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="registerinput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">DOB <span className="spanred">*</span></label>
                      <input type="date" className="form-control" id="txtDOB" max={moment().subtract(18, 'years').format('YYYY-MM-DD')} placeholder="Select Your Birthdate" onChange={(e) => setDateOfBirth(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="registerinput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Password <span className="spanred">*</span></label>
                      <div className="d-flex">
                        <input type="Password" className="form-control mainloginpassnew" id="neww" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} />
                        <button className="btn btn-light mainloginpassnewbtn"  ><i toggle="#neww" className="fa fa-fw toggle-passwordlogin2 fa-eye-slash"></i></button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="registerinput">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Gender <span className="spanred">*</span></label>
                      <div className="d-flex">
                        <select
                          id="ddlCountry"
                          className="form-select  form-selectnewwr form-select-lg mb-0"
                          aria-label=".form-select-lg example"
                          required
                          onChange={(e) => setGender(e.target.value)}>
                          <option selected>Select Gender</option>
                          <option value={'Male'}>Male</option>
                          <option value={'Female'}>Female</option>
                          <option value={'Transgender'}>Transgender</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-check form-check-inline registerinput">
                      <div className="d-flex align-items-md-center">
                        <div className="mainnewsetredasa">
                          <input className="form-check-input mt-md-0 mt-1 " type="checkbox" id="chkTermsandconditions" value="option1" />
                        </div>
                        <div>
                        <label htmlFor="chkTermsandconditions" className="mb-0">By ticking, you are confirming that you have read, understood and agree to the <a className="mainlineatagee" data-bs-toggle="modal" data-bs-target="#termsandconditionmodal">Terms and conditions</a>  and <a className="mainlineatagee" data-bs-toggle="modal" data-bs-target="#privacypolicemodal">privacy policy</a> </label>              
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {/* {MobileVerifySuccess == 1 && EmailVerifySuccess == 1 ?
                      <button type="button" className="btn btn-primary btnnextedit" onClick={RegisterProfile}>Register<i className="fas fa-long-arrow-alt-right"></i></button> : ""}
                    {MobileVerifySuccess == 0 && EmailVerifySuccess == 1 ?
                      <button type="button" className="btn btn-primary btnnextedit " data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={sendMobileOTP} >Next(NMV)(1)<i className="fas fa-long-arrow-alt-right"></i></button> : ""}
                    {MobileVerifySuccess == 1 && EmailVerifySuccess == 0 ?
                      <button type="button" className="btn btn-primary btnnextedit " data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={sendEmailOTP}>Next (NEV)(2)<i className="fas fa-long-arrow-alt-right"></i></button> : ""}
                    {MobileVerifySuccess == 0 && EmailVerifySuccess == 0 ?
                      <button type="button" className="btn btn-primary btnnextedit " data-bs-toggle="modal" data-bs-target="#exampleModal3" onClick={() => { sendEmailOTP(), sendMobileOTP() }} >Next (3)<i className="fas fa-long-arrow-alt-right"></i></button> : ""} */}
                    <button type="button" className="btn btn-primary btnnextedit" onClick={CheckRegister}>Register<i className="fas fa-long-arrow-alt-right"></i></button>
                    <button type="button" className="btn btn-primary btnnexteditline" onClick={CancelRegister}>Cancel </button>
                    {/* <button type="button" className="btn btn-primary btnnexteditline" onClick={MailForOTP}>Mail </button> */}

                  </div>
                </div>
              </div>
              <div className="col-md-2 order-1 order-md-2">
                <div className="registerinput">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Profile</label>
                  <div>
                    {ProfilepicUrl == "" ? <img src={profileimg} className="imgprofileedit"></img> : <img src={ProfilepicUrl} className="imgprofileedit"></img>}
                    {/* <img src={ProfilepicUrl} className="imgprofileedit"></img> */}
                  </div>
                </div>
                <div >
                  <input type="file" name="file-input"
                    id="file1" accept="image/*" onChange={(e) => { setProfilePic(e.target.files[0]); setProfilepicUrl(URL.createObjectURL(e.target.files[0])) }} className="d-none" />
                  <a href="#" id="upfile1">  <label htmlFor="file-input" className="w-100 btn btn-primary btnuploadnew">Upload</label></a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-md-flex mb-3 mb-md-0 justify-content-between">
            <div>
              <p className="footercopytextedit">COPYRIGHT © ALL RIGHTS RESERVED BY CoLand Services Private Limited</p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default RegisterYourProfile



import React from "react";
import { Redirect } from "react-router-dom";

import home from "../pages/frontend/home";
import login from "../pages/frontend/login";
import RegisterYourProfile from "../pages/frontend/register-your-profile";
import dashboard from "../pages/frontend/dashboard";
import RequestNewService from "../pages/frontend/request-new-service";
import RequestNewService2 from "../pages/frontend/request-new-service2";
import AddNewProperty from "../pages/frontend/add-new-property";
import AddNewProperty2 from "../pages/frontend/add-new-property2";
import AddNewProperty3 from "../pages/frontend/add-new-property3";
import PropertyList from "../pages/frontend/property-list";
import PropertyDetails from "../pages/frontend/property-details";
import PropertyDetailsUpdate from "../pages/frontend/property-details-update";
import ManageService from "../pages/frontend/manage-service";
import EditRequestNewService from "../pages/frontend/edit-request-new-service";
import EditRequestNewService2 from "../pages/frontend/edit-request-new-service2";
import EditProperty from "../pages/frontend/edit-property";
import EditProperty2 from "../pages/frontend/edit-property2";
import EditProperty3 from "../pages/frontend/edit-property3";
import EditProfile from "../pages/frontend/edit-profile"
import ReferralsList from "../pages/frontend/referrals-list"
import ServiceDetail from "../pages/frontend/service-details"
import manageprofile from "../pages/frontend/manage-profile"
import TransactionHistory from "../pages/frontend/transaction-history"
import Termsofuser from "../pages/frontend/terms-of-user"
import CookiePolicy from "../pages/frontend/cookie-policy"
import EULA from "../pages/frontend/EULA"
import Disclaimer from "../pages/frontend/disclaimer"
import Privacy from "../pages/frontend/privacy"
import Refund from "../pages/frontend/refund"
import AcceptUsePolicy from "../pages/frontend/accept-use-policy"
import Pricing from "../pages/frontend/pricing"
import HowItWorks from "../pages/frontend/how-it-works";
import aboutus from '../pages/frontend/aboutus';
import ForgotPassword from "../pages/frontend/forgot-password";


const authProtectedRoutes = [
  { path: "/", exact: true, component: () => <Redirect to="/home" /> }
];

const publicRoutes = [
  { path: "/home", component: home },
  { path: "/login", component: login },
  { path: "/register-your-profile", component: RegisterYourProfile },
  { path: "/dashboard", component: dashboard },
  { path: "/request-new-service", component: RequestNewService },
  { path: "/request-new-service2", component: RequestNewService2 },
  { path: "/add-new-property", component: AddNewProperty },
  { path: "/add-new-property2", component: AddNewProperty2 },
  { path: "/add-new-property3", component: AddNewProperty3 },
  { path: "/property-list", component: PropertyList },
  { path: "/property-details", component: PropertyDetails },
  { path: "/property-details-update", component: PropertyDetailsUpdate },
  { path: "/manage-service", component: ManageService },
  { path: "/edit-request-new-service", component: EditRequestNewService },
  { path: "/edit-request-new-service2", component: EditRequestNewService2 },
  { path: "/edit-property", component: EditProperty },
  { path: "/edit-property2", component: EditProperty2 },
  { path: "/edit-property3", component: EditProperty3 },
  { path: "/edit-profile", component: EditProfile },
  { path: "/referrals-list", component: ReferralsList },
  { path: "/service-details", component: ServiceDetail },
  { path: "/manage-profile", component: manageprofile },
  { path: "/transaction-history", component: TransactionHistory },
  { path: "/terms-of-user", component: Termsofuser },
  { path: "/cookie-policy", component: CookiePolicy },
  { path: "/EULA", component: EULA },
  { path: "/privacy", component: Privacy },
  { path: "/disclaimer", component: Disclaimer },  
  { path: "/refund", component: Refund },
  { path: "/accept-use-policy", component: AcceptUsePolicy },
  { path: "/pricing", component: Pricing },
  { path: "/aboutus", component: aboutus },
  { path: "/how-it-works", component: HowItWorks },
  { path: "/forgot-password", component: ForgotPassword },


];

export { authProtectedRoutes, publicRoutes };
import React, { Component, useEffect, useState } from "react"
import mianheaderimg from "../../assets/images/frontend-img/logo-new1.png";
import hdtw2 from "../../assets/images/frontend-img/hdtw2.png";
import shaphw1 from "../../assets/images/frontend-img/shaphw1.png";
import shaphw2 from "../../assets/images/frontend-img/shaphw2.png";
import shaphw3 from "../../assets/images/frontend-img/shaphw3.png";
import icon1 from "../../assets/images/frontend-img/Mask Groupnew 11.png";
import icon2 from "../../assets/images/frontend-img/Mask Group 12.png";
import icon3 from "../../assets/images/frontend-img/Mask Group 13.png";
import icon4 from "../../assets/images/frontend-img/Mask Group 14.png";
import icon5 from "../../assets/images/frontend-img/Mask Group 15.png";
import icon6 from "../../assets/images/frontend-img/Mask Group 16.png";
import icon7 from "../../assets/images/frontend-img/Mask Group 17.png";
import axios from "axios";
import Commfooter from "../frontend/footer";

const howitworks = () => {

    const [CompanyEmail, setCompanyEmail] = useState('');
    const [CompanyEmailMailto, setCompanyEmailMailto] = useState('');
    const [CompanyContact, setCompanyContact] = useState('');
    const [CompanyContacttel, setCompanyContacttel] = useState('');
    const [facebookLink, setfacebookLink] = useState('');
    const [InstagramLink, setInstagramLink] = useState('');
    const [LinkedinLink, setLinkedinLink] = useState('');
    const [TwitterLink, setTwitterLink] = useState('');

    useEffect(() => {
        getCompanyDetail();
    }, []);

    const getCompanyDetail = async () => {
        axios.get(global.APIURL + "/CompanyDetail")
            .then(res => {
                setCompanyEmail(res.data.Result[0].CompanyEmail);
                setCompanyEmailMailto('mailto:' + res.data.Result[0].CompanyEmail);
                setCompanyContact(res.data.Result[0].CompanyContact);
                setCompanyContacttel('tel:' + res.data.Result[0].CompanyContact);
                setfacebookLink(res.data.Result[0].facebookLink);
                setInstagramLink(res.data.Result[0].InstagramLink);
                setLinkedinLink(res.data.Result[0].LinkedinLink);
                setTwitterLink(res.data.Result[0].TwitterLink);
            });
    };



    return (
        <React.Fragment>
            <div>
                <section className="maintopbar">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="text-white topbarsocialicon">
                                <a href={facebookLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                <a href={InstagramLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                <a href={LinkedinLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                <a href={TwitterLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                            </div>
                            <div className="text-white topbarcontact">
                                <a className="mt-0" href={CompanyContacttel}><i className="fas fa-phone me-md-3 me-2"></i>Call us : {CompanyContact}</a>

                                <a href={CompanyEmailMailto} className="me-0"><i className="fas fa-envelope me-md-3 me-2"></i>Mail us : {CompanyEmail}</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="">
                        <div className="">
                            <nav
                                className="navbar navbar-expand-lg navbar-light hedermain nav-container p-0  subpageheader"
                                aria-label="Eighth navbar example"
                            >
                                <div className="container">
                                    <a className="navbar-brand" href="/home">
                                        <img src={mianheaderimg} className="headerlogo" alt="" />
                                    </a>

                                    <div>
                                        <button
                                            className="navbar-toggler"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#navbarsExample07"
                                            aria-controls="navbarsExample07"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                        >
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                    </div>

                                    <div
                                        className="collapse navbar-collapse navbar-collapseedit"
                                        id="navbarsExample07"
                                    >
                                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                                            <li className="nav-item navbar-linksedit">
                                                <a
                                                    className="nav-link "
                                                    aria-current="page"
                                                    href="/home"
                                                >Home
                                                </a>
                                            </li>
                                            <li className="nav-item navbar-linksedit">
                                                <a className="nav-link" href="/aboutus">About us</a>
                                            </li>
                                            <li className="nav-item navbar-linksedit">
                                                <a className="nav-link" aria-current="page" href="/how-it-works"
                                                >How it Works?</a
                                                >
                                            </li>
                                            <li className="nav-item navbar-linksedit">
                                                <a className="nav-link" href="/pricing">Pricing</a>
                                            </li>

                                            <li className="nav-item navbar-linksedit">
                                                <a
                                                    className="nav-link"
                                                    aria-current="page"
                                                    href="/login"
                                                >Login</a
                                                >
                                            </li>
                                            <li className="nav-item navbar-linksedit">
                                                <a
                                                    className="nav-link me-0 btneditgetstarted"
                                                    aria-current="page"
                                                    href="/register-your-profile"
                                                >
                                                    <button type="button" className="btn btn-outline-light btngetstarted">Get Started</button></a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </nav>


                        </div>
                    </div>
                </section>


                <section id="howitworkid">
        <div className="container">
          <div className="mainsectiontitle">
            <div className="row">
              <div className="col-md-12 text-center">
                <p className="maincommonsmalltext">
                  Our WorkFlow
                </p>
                <p className="commonmainbigtitle">
                  How Does This Work?
                </p>
              </div>
            </div>

            <div className="row mainboxesdivhowitwork">


              <div className="col-md-2 col-6">
                <div className="mainboxhowitwork">

                  <img src={icon1} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Become a member
                  </p>
                  <p className="mainrboxtextsub"></p>
                  <img src={shaphw1} className="shaphowit1" alt="" />
                </div>
              </div>
              <div className="col-md-2 col-6">
                <div className="mainboxhowitwork2">

                  <img src={icon2} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Add your properties
                  </p>
                  <img src={shaphw2} className="shaphowit2" alt="" />
                </div>
              </div>
              <div className="col-md-2 col-6">
                <div className="mainboxhowitwork">

                  <img src={icon3} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Select/Book services
                  </p>
                  <img src={shaphw3} className="shaphowit3" alt="" />
                </div>
              </div>
              <div className="col-md-2 col-6">
                <div className="mainboxhowitwork2">

                  <img src={icon4} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Get Quote
                  </p>
                  <img src={shaphw2} className="shaphowit2" alt="" />
                </div>

              </div>
              <div className="col-md-2 col-6 d-md-block d-none">
                <div className="mainboxhowitwork mt-2 mt-md-0">
                  <img src={icon5} className="howitworkboximg" alt="" />
             
                  <p className="howitboxtitle">
                    Approve Quote
                  </p>
                  <img src={shaphw3} className="shaphowit3" alt="" />
                </div>
              </div>
              <div className="col-md-2 col-6 d-md-none d-block mt-md-0">
                <div className="mainboxhowitwork">

                  <img src={icon6} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Review
                  </p>
                </div>
              </div>
              <div className="col-md-2 col-6">
                <div className="mainboxhowitwork2">
                  <img src={icon6} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Track the progress
                  </p>
                  <img src={shaphw2} className="shaphowit2" alt="" />
                </div>
              </div>
              <div className="col-md-2 col-6 d-md-block d-none">
                <div className="mainboxhowitwork">
                  <img src={icon7} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Review
                  </p>
                </div>
              </div>
              <div className="col-md-2 col-6 d-md-none d-block ">
                <div className="mainboxhowitwork  mt-md-0">
                  <img src={hdtw2} className="howitworkboximg" alt="" />
             
                  <p className="howitboxtitle">
                    Approve Quote
                  </p>
                  <img src={shaphw3} className="shaphowit3" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
          <Commfooter/>
            </div>
        </React.Fragment>
    )
}

export default howitworks

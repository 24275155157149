import React, { Component, useEffect, useState } from "react"
import addanp from "../../assets/images/frontend-img/addanp.png";
import Commheader from "./header";
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import { set } from "lodash";
import Footeradmin from "./footeradmin";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import mianheaderimg from "../../assets/images/frontend-img/logo-new.png";

const EditProperty = () => {
  const { addToast } = useToasts();
  const [OtherPropertyType, setOtherPropertyType] = useState('');
  const [DisplayTextbox, setDisplayTextbox] = useState("");
  const [PropertyTitle, setPropertyTitle] = useState('');
  const [OtherPropertyTypeTitle, setOtherPropertyTypeTitle] = useState('');
  const [PropertyTypeId, setPropertyTypeId] = useState('0');
  const [PropertyType, setPropertyType] = useState('0');
  const [PropertyTypeList, setPropertyTypeList] = useState([]);
  const [PropertyMediaList, setPropertyMediaList] = useState([]);
  const [PropertyNumber, setPropertyNumber] = useState([]);
  const [NearByPlace, setNearByPlace] = useState('');
  const [Street, setStreet] = useState('');
  const [Pincode, setPincode] = useState('');
  const [CountryList, setCountryList] = useState([]);
  const [StateFilterList, setStateFilterList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityFilterList, setCityFilterList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [CountryId, setCountryId] = useState(0);
  const [StateId, setStateId] = useState(0);
  const [CityId, setCityId] = useState(0);
  const [FullAddress, setFullAddress] = useState([]);
  const [PropertyDetailsList,setPropertyDetailsList]=useState([]);
  const [PropertyInsightDetailsList,setPropertyInsightDetailsList]=useState([]);
  const [PropertyFurnishedDetailList,setPropertyFurnishedDetailList]=useState([])
  const [PropertyDocumentDetailList,setPropertyDocumentDetailList]=useState([]);
  const [CustomerDetails, setCustomerDetails] = useState([]);
  const [UpdatePropertyId, setUpdatePropertyId] = useState('0');

  useEffect(() => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    if (VRegisterId == null) {
      window.location.href = "/home";
    }
    else if (VRegisterId == "") {
      window.location.href = "/home";
    }
    else if (parseInt(VRegisterId) == 0) {
      window.location.href = "/home";
    } else {
      getCountryList();
      getPriorityDetail();
      getProfileData();     
    }
  
  }, []);

  const getPropertyType = async () => {
    axios.get(global.APIURL + '/PropertyType')
      .then(res => {
        setPropertyTypeList(res.data.Result);
      });
  }

  function handleChange(e) {
    let lastMedId = PropertyMediaList.length == 0 ? 0 : PropertyMediaList[PropertyMediaList.length - 1].PId;
    const PropertyMediaArr = {
      PId: lastMedId + 1,
      MediaFile: e.target.files[0],
      MediaFileUrl: URL.createObjectURL(e.target.files[0]),
      MediaStatus:true,
      DivMediaId:'divMediaId'+ lastMedId + 1
    }
    setPropertyMediaList([...PropertyMediaList, PropertyMediaArr]);
  }

  const getPriorityDetail = async () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let VPropertyId = localStorage.getItem('DetailPropertyId');
    const PropListData = {
      RegisterId: VRegisterId,
      PropertyId: VPropertyId
    }
    axios.post(global.APIURL + '/propertyDetail', PropListData)
      .then(res => {
    
        if (res.data.Result != '') {
          setPropertyDetailsList(res.data.Result);
          setPropertyInsightDetailsList(res.data.Result[0].PropertyInsight);
          setPropertyFurnishedDetailList(res.data.Result[0].PropertyFurnished);      
          setPropertyDocumentDetailList(res.data.Result[0].Document);
          setPropertyTitle(res.data.Result[0].PropertyTitle);
          setPropertyTypeId(res.data.Result[0].PropertyTypeID);
          setPropertyType(res.data.Result[0].PropertyTypeName);
          getPropertyType();
          let pt = res.data.Result[0].PropertyTypeName;
          if (pt == null) {
            setDisplayTextbox('Other');
            document.getElementById("rbOther").checked = true;
            setOtherPropertyType(res.data.Result[0].OtherPropertyType == null ? "" : res.data.Result[0].OtherPropertyType);
          }
          else if (pt == '') {
            setDisplayTextbox('Other');
            document.getElementById("rbOther").checked = true;
            setOtherPropertyType(res.data.Result[0].OtherPropertyType == null ? "" : res.data.Result[0].OtherPropertyType);
          }
          else {
            setDisplayTextbox('');
            document.getElementById("rbOther").checked = false;
            setOtherPropertyType('');
          }
          setPropertyNumber(res.data.Result[0].PropertyNumber == null ? '' : res.data.Result[0].PropertyNumber);
          setNearByPlace(res.data.Result[0].NearByPlace == null ? '' : res.data.Result[0].NearByPlace);
          setStreet(res.data.Result[0].Street == null ? '' : res.data.Result[0].Street);
          setPincode(res.data.Result[0].Pincode == null ? '' : res.data.Result[0].Pincode);
          setFullAddress(res.data.Result[0].FullAddress == null ? '' : res.data.Result[0].FullAddress);
          setCountryId(res.data.Result[0].Country == null ? 0 : res.data.Result[0].Country == '' ? 0 : res.data.Result[0].Country);
          var CountryId = res.data.Result[0].Country == null ? 0 : res.data.Result[0].Country == '' ? 0 : res.data.Result[0].Country;
          var VStateName = res.data.Result[0].StateName == null ? '' : res.data.Result[0].StateName;
          var SF = StateFilterList.filter(x => x.StateName == VStateName);
          var SId = SF.length == 0 ? 0 : SF[0].StateId == null ? 0 : SF[0].StateId == '' ? 0 : SF[0].StateId;
          setStateId(SId);
          setCityId(res.data.Result[0].City == null ? 0 : res.data.Result[0].City == '' ? 0 : res.data.Result[0].City);
          StateList.length = 0;
          for (let sd = 0; sd < StateFilterList.length; sd++) {
            if (StateFilterList[sd].CountryId == CountryId) {
              const Stateitem = {
                CountryId: StateFilterList[sd].CountryId,
                StateId: StateFilterList[sd].StateId,
                StateName: StateFilterList[sd].StateName
              };
              StateList.push(Stateitem);
            }
          }
          for (let sd = 0; sd < CityFilterList.length; sd++) {
            if (CityFilterList[sd].StateId == SId) {
              const Cityitem = {
                StateId: CityFilterList[sd].StateId,
                CityId: CityFilterList[sd].CityId,
                CityName: CityFilterList[sd].CityName
              };
              CityList.push(Cityitem);
            }
          }
          setUpdatePropertyId(res.data.Result[0].UpdateId);
          const ProMediaArr = res.data.Result[0].Media;
          const pra = [];
          for (let pm = 0; pm < ProMediaArr.length; pm++) {
            const VMediaFileUrl = ProMediaArr[pm].Url;
            const PropertyMediaArr = {
              PId: pm + 1,
              MediaFile: '',
              MediaFileUrl: VMediaFileUrl,
              MediaStatus:ProMediaArr[pm].Imagestatus=="true"?true:false,
              DivMediaId:'divMediaId'+pm+1
            }
            pra.push(PropertyMediaArr);
            //setPropertyMediaList([...PropertyMediaList, PropertyMediaArr]);
          }
          setPropertyMediaList(pra);
       
      
      
      
      
     
        }
      });
  }

  
  const getCountryList = () => {
    CountryList.length = 0;
    StateFilterList.length = 0;
    CityFilterList.length = 0;
    var AUrl = global.APIURL + '/CountryStateCity';
    axios.get(AUrl)
      .then(res => {
        const cl = res.data.Result[0].Country;
        var CLA = [];
        var SLA = [];
        var CTA = [];
        for (let cd = 0; cd < cl.length; cd++) {
          const Countyitem = {
            CountryId: cl[cd].Id,
            CountryName: cl[cd].CountryName
          };
          CountryList.push(Countyitem);
          const Sl = cl[cd].State;

          for (let sd = 0; sd < Sl.length; sd++) {
            const StateId = Sl[sd].Id;

            const Stateitem = {
              CountryId: cl[cd].Id,
              StateId: Sl[sd].Id,
              StateName: Sl[sd].StateName
            };
            StateFilterList.push(Stateitem);

            const CityList = Sl[sd].City;
            for (let ctd = 0; ctd < CityList.length; ctd++) {
              const Cityitem = {
                StateId: StateId,
                CityId: CityList[ctd].Id,
                CityName: CityList[ctd].CityName
              };
              CityFilterList.push(Cityitem);
            }
          }
        }
      });
  }

  const getCountryChange = (CountryId) => {
    var SF = StateFilterList.filter(x => x.CountryId == CountryId);
    setStateList(SF);
  }

  const getStateChange = (StateId) => {
    var CF = CityFilterList.filter(x => x.StateId == StateId);
    setCityList(CF);
  }

  const RbOtherChange = () => {

    for (let pt = 0; pt < PropertyTypeList.length; pt++) {
      const PropertyTypeId = PropertyTypeList[pt].Id;
      let PropertyTypeCId = 'RB' + PropertyTypeId;
      document.getElementById(PropertyTypeCId).checked = false;
    }
  }

  const RbPropertyTypeChange = () => {
    document.getElementById("rbOther").checked = false;
  }

  const MediaRowdelete = MediaId => {
    for (let pil = 0; pil < PropertyMediaList.length; pil++) {

      const PFId = PropertyMediaList[pil].PId;

      const VMediaDivId = "#" + PropertyMediaList[pil].DivMediaId;

      if (PFId == MediaId) {
        PropertyMediaList[pil]["MediaStatus"] = false;
        $(VMediaDivId).hide();
      }
    }
  };


  const UpdatePropertyDetail = () => {
    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    let VPropertyId = localStorage.getItem('DetailPropertyId');
    let latitude = document.getElementById("lblatitude").innerHTML;
    let Longitude = document.getElementById("lblongitude").innerHTML;
    let FullAddress = document.getElementById("autocomplete").value;

    let IsInsert = 1;
    if (PropertyTitle == "") {
      addToast("Add Property Title", {
        appearance: "error",
        autoDismiss: true,
      });
      IsInsert = 0;
      return;
    }

    if (DisplayTextbox == "Other") {
      if (OtherPropertyType == "") {
        addToast("Add Other Property Type", {
          appearance: "error",
          autoDismiss: true,
        });
        IsInsert = 0;
        return;
      }
    } else {
      if (PropertyTypeId == "") {
        addToast("Select Property Type", {
          appearance: "error",
          autoDismiss: true,
        });
        IsInsert = 0;
        return;
      }
      if (PropertyTypeId == "0") {
        addToast("Select Property Type", {
          appearance: "error",
          autoDismiss: true,
        });
        IsInsert = 0;
        return;
      }
    }

    // if (PropertyNumber == "") {
    //   addToast("Add Property Number", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    //   IsInsert = 0;
    //   return;
    // }
    // if (NearByPlace == "") {
    //   addToast("Add Near By Place", { appearance: "error", autoDismiss: true });
    //   IsInsert = 0;
    //   return;
    // }
    // if (Street == "") {
    //   addToast("Add Street Name", { appearance: "error", autoDismiss: true });
    //   IsInsert = 0;
    //   return;
    // }
    // if (Pincode == "") {
    //   addToast("Add Pincode", { appearance: "error", autoDismiss: true });
    //   IsInsert = 0;
    //   return;
    // }
    // if (Pincode.length < 6) {
    //   addToast("Add Valid Pincode", { appearance: "error", autoDismiss: true });
    //   IsInsert = 0;
    //   return;
    // }
    // if (CountryId == "") {
    //   setCountryId(0);
    //   // addToast("Select Country", { appearance: "error", autoDismiss: true });
    //   // IsInsert = 0;
   
    // }
    // if (StateId == "") {
    //   setStateId(0);
    //   // addToast("Select State", { appearance: "error", autoDismiss: true });
    //   // IsInsert = 0;
   
    // }
    // if (CityId == "") {
    //   setCityId(0);
    //   // addToast("Select City", { appearance: "error", autoDismiss: true });
    //   // IsInsert = 0;
    
    // }
    if (FullAddress == "") {
      addToast("Select Address", { appearance: "error", autoDismiss: true });
      IsInsert = 0;
      return;
    }

    if (IsInsert == 1) {
      const formData = new FormData();
      formData.append('RegisterId', VRegisterId);
      formData.append('Action', "Edit");
      formData.append('PropertyId', VPropertyId);
      formData.append('PropertyTypeID', PropertyTypeId);
      formData.append('OtherPropertyType', OtherPropertyType);
      formData.append('PropertyTitle', PropertyTitle);
      formData.append('PropertyNumber', PropertyNumber);
      formData.append('NearByPlace', NearByPlace);
      formData.append('Street', Street);
      formData.append('Pincode', Pincode);
      formData.append('City', CityId);
      formData.append('State', StateId);
      formData.append('Country', CountryId);
      formData.append('FullAddress', FullAddress);
      formData.append('Latitude', latitude);
      formData.append('Longitude', Longitude);
      let TMCount = 0
      for (let di = 0; di < PropertyMediaList.length; di++) {

        let PMF = PropertyMediaList[di].MediaFile;
        // if (PMF != null) {
        //   if (PMF != '') {
            let RCount = TMCount + 1;
            let VMedia = PropertyMediaList[di].MediaFile;
            let VMediaStatus=PropertyMediaList[di].MediaStatus;
            let CM = 'Media' + RCount;
            let MS="Imagestatus" + RCount;
            formData.append(CM, VMedia); 
            formData.append(MS,VMediaStatus);           
            TMCount++;
        //   }
        // }
      }


      if (TMCount > 0) {
        formData.append('TotalMediaCount', PropertyMediaList.length);
      }


      
      for (var pair of formData.entries()) {
        console.log(pair[0] + ":" + pair[1]);
      }
      axios.post(global.APIURL + '/PropertyAndLocationAdd', formData)
        .then(res => {
          if (res.data.Status == true) {
            addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
            window.location.href = "/edit-property2";
          }
          else {
            setShowLoader(0);
            addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
          }
        });
    }

  }

  const PropertUpdateDelete = (PId) => {
    const RId = PId;
    setPropertyMediaList(PropertyMediaList.filter(item => item.PId !== RId));
  }


  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.addImage(mianheaderimg, 'JPEG', 465, -20, 250, 150);
   
   // doc.setFont(undefined, 'bold');

    var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth();
    var curr_year = today.getFullYear();
    var m_names = new Array("January", "February", "March", 
                            "April", "May", "June", "July", 
                            "August", "September", 
                            "October", "November", "December");
    today = m_names[curr_month] + " " + curr_date + ", " + curr_year;
    var newdat = today;
    doc.text(1010, 105, newdat);

   const headers5 = [[{ content: 'User Details', colSpan: 6, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Name", "Email","MobileNo","Gender","DOB"]];

    const data5 = CustomerDetails.map((item, index) =>
      [index + 1, item.Name, item.Email,item.MobileNo,item.Gender,item.DateOfBirth]);

    let content5 = {

      startY: 120,
      head: headers5,
      body: data5,
      styles: {
        fontSize: 14,
        overflow: 'linebreak',
       // font: 'Amiri',
      },
      headStyles: { fillColor: ['#12284C'] },
      styles: {fontSize: 14 , fontFamily:"Hebrew",fontStyle:"bold"},
      //alternateRowStyles: {fillColor : [209,225,249]}
    };
    const headers = [[{ content: 'Property Details', colSpan: 3, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Property Name", "Type"]];

    const data = PropertyDetailsList.map((item, index) =>
      [index + 1, item.PropertyTitle, item.PropertyTypeName]);

    let content = {

      startY: 220,
      head: headers,
      body: data,
      styles: {
        fontSize: 14,
        overflow: 'linebreak',
        font: 'Amiri',
      },
      headStyles: { fillColor: ['#12284C'] },
      styles: {fontSize: 14 , fontFamily:"Hebrew",fontStyle:"bold"}
      //alternateRowStyles: {fillColor : [209,225,249]}
    };

    const headers4 = [[{ content: 'Location Information', colSpan: 11, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black'} }], ["#",  "Property No.", "NearByPlace", "Street", "Pincode", "Country", "State", "City", "Full Address"]];

    const data4 = PropertyDetailsList.map((item, index) =>
      [index + 1,item.PropertyNumber, item.NearByPlace, item.Street, item.Pincode, item.CountryName, item.StateName, item.CityName, item.FullAddress]);

    let content4 = {
      startY: 320,
      head: headers4,
      body: data4,
      headStyles: { fillColor: ['#12284C'] },
   
      styles: {fontSize: 14 , fontFamily:"Hebrew",fontStyle:"bold"},

    };


    const headers1 = [[{ content: 'Property Insights', colSpan: 5, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Insight Name", "Width", "Length", "Height"]];

    const data1 = PropertyInsightDetailsList.filter(x=>x.InsightStatus==="true").map((item, index) =>
      [index + 1, item.InsightName, item.InsightWidth, item.InsightLength, item.InsightHeight]);

    let content1 = {
      startY: 455,
      head: headers1,
      body: data1,
      headStyles: { fillColor: ['#12284C'] },
   
      styles: {fontSize: 14 , fontFamily:"Hebrew",fontStyle:"bold"},
      margin: { right: 600 },

    };

    const headers2 = [[{ content: 'Property Furnished In', colSpan: 3, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Furnished In", "Furniture"]];

 
    const data2 = PropertyFurnishedDetailList.filter(x=>x.FurnishedStatus==="true").map((item, index) =>
    [index + 1, item.PropertyFurnishedIn, item.FurnishedDetails]);
 
  

    let content2 = {
      startY: 455,
      head: headers2,
      body: data2,
      headStyles: { fillColor: ['#12284C'] },
   
      styles: {fontSize: 14 , fontFamily:"Hebrew",fontStyle:"bold"},
      margin: { left: 600 },

    };

    const headers3 = [[{ content: 'Documents', colSpan: 2, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Documents"]];

    const data3 = PropertyDocumentDetailList.map((item, index) =>
      [index + 1, item.DocumentName]);

    let content3 = {
      //startY: 550,
      head: headers3,
      body: data3,
      headStyles: { fillColor: ['#12284C'] },
 
      styles: {fontSize: 14 , fontFamily:"Hebrew",fontStyle:"bold"},

    };
   
    doc.autoTable(content5);
    doc.autoTable(content);
    doc.autoTable(content4);
    doc.autoTable(content1);
    doc.autoTable(content2);
    doc.autoTable(content3);
   
    doc.save("PropertyDetails.pdf");
  }

  const getProfileData = () => {

    let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
    const formData = new FormData();
    formData.append('RegisterId', VRegisterId);

    axios.post(global.APIURL + '/MyProfileData', formData)
      .then(res => {
        console.log("profile", res);
        setCustomerDetails(res.data.Result);
      });

  }

  return (
    <React.Fragment>
      <Commheader />
      <section>
        <div className="container">
          <div className="mainboxshadow manageserviceaddcustom">
            <div className="row mainitalpropertylist">
              <div className="col-md-10">
                <p className="addservicetextform">Property Details</p>
              </div>

              <div className="col-md-2 text-end">
            <button type="button" className="btn btn-warning btnpaymentdownload newpropertypdf" onClick={exportPDF}><i className="las la-download"></i></button>
        

              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mainpropertylistbox">
                  <div className="">

                    <div className="row">
                      <div className="col-md-12">
                        <div className="">
                          <div className="row ">
                            <div className="col-md-7">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Property Title <span className="spanred">*</span> </p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Property Title" defaultValue={PropertyTitle} onChange={(e) => setPropertyTitle(e.target.value)} />
                                  </div>

                                </div>

                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="mainredioboxeditap">
                                    <p className="maintitlereuestservicenee">Property Type <span className="spanred">*</span></p>
                                    <div className="d-flex align-items-center">
                                      <div>
                                        {PropertyTypeList.map((propItems) =>
                                          <div className="form-check form-check-inline" key={propItems.Id}>
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id={'RB' + propItems.Id} defaultChecked={propItems.PropertyType === PropertyType} value={propItems.Id} onChange={(e) => { RbPropertyTypeChange(e.target.value); setPropertyTypeId(e.target.value); setPropertyType(e.target.value); setDisplayTextbox(''); setOtherPropertyType(""); }} />
                                            <label className="form-check-label commonblackcolor" htmlFor={'RB' + propItems.Id}>{propItems.PropertyType}</label>
                                          </div>
                                        )}
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="radio" name="rbOther" id="rbOther" value="option4" onChange={(e) => { RbOtherChange(); setPropertyTypeId(""); setDisplayTextbox('Other'); setPropertyType('Other') }} />
                                          <label className="form-check-label commonblackcolor" htmlFor="rbOther">Other</label>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="registerinputnewedirr form-controlsustomeditnew">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {DisplayTextbox == 'Other' ?
                                <div className="row mt-2">
                                  <div className="col-md-6">
                                    <div className="registerinputnewedirr form-controlsustomeditnew">
                                      <p className="maintitlereuestservicenee">Other Property Type </p>
                                      <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" onChange={(e) => setOtherPropertyType(e.target.value)} placeholder="Write Here" defaultValue={OtherPropertyType} />
                                    </div>
                                  </div>
                                </div>
                                : ''}
                            </div>
                            <div className="col-md-5">
                              <div className="row mainsideanpimgdiv">

                                {PropertyMediaList.filter(x=>x.MediaStatus==true).map((item, idx) => (
                                  <div className="col-6 col-md-3" key={idx} Id={item.DivMediaId}>
                                    <div className="mainanpimg">
                                      <img src={item.MediaFileUrl}></img>
                                      <div className="maindivdeletprpertyaddnewsmall2pd">
                                        <div className="deletprpertyaddnewsmall2pd">
                                          <a onClick={e => MediaRowdelete(item.PId)}><i className="las la-trash-alt"></i></a>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                ))}

                                {PropertyMediaList.filter(x=>x.MediaStatus==true).length < 6 ?
                                  <div className="col-6 col-md-3">
                                    <div className="mainanpimg">
                                      <a id="btnUploadPropertyDetailImage">  <img src={addanp}></img></a>
                                      <input type="file" accept="image/*" id="fuUploadPropertyDetailImage" onChange={handleChange} className="d-none" />
                                    </div>

                                  </div>
                                  : ''}

                              </div>

                            </div>

                          </div>

                        </div>

                      </div>
                      <div className="col-md-12">
                        <div className="maindivederaddpropertyinsight"></div>

                      </div>
                      <div className="col-md-12">
                        <div className=" mb-0">
                          <div className="row">
                            <div className="col-md-12">
                              <p className="addservicetextform mainmarginpdtitle">Location Information <span className="spanred"></span></p>
                            </div>
                            <div className="col-md-7">

                              <div className="row mainlocationinfodivv">

                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Property Number <span className="spanred"></span></p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter House / Property Number" onChange={(e) => setPropertyNumber(e.target.value)} defaultValue={PropertyNumber} />
                                  </div>

                                </div>
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Near By Address <span className="spanred"></span></p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Near by Address" defaultValue={NearByPlace} onChange={(e) => setNearByPlace(e.target.value)} />
                                  </div>

                                </div>
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Street <span className="spanred"></span></p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Street Name" defaultValue={Street} onChange={(e) => setStreet(e.target.value)} />
                                  </div>

                                </div>
                                <div className="col-md-6">
                                  <div className="registerinputnewedirr form-controlsustomeditnew">
                                    <p className="maintitlereuestservicenee">Pin Code <span className="spanred"></span></p>
                                    <input type="text" className="form-control formcontrollplaceholder border-0" id="exampleFormControlInput1" placeholder="Enter Pin Code" defaultValue={Pincode} onChange={(e) => setPincode(e.target.value)} />
                                  </div>

                                </div>
                                <div className="col-md-4">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                    <p className="maintitlereuestservicenee">Country <span className="spanred"></span></p>
                                    <select id="ddlCountry" className="form-select form-selectnewwr border-0 form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => { getCountryChange(e.target.value); setCountryId(e.target.value) }}>
                                      <option selected>Select Country</option>
                                      {CountryList.map((CountryListItems) => (
                                        <option key={CountryListItems.CountryId} value={CountryListItems.CountryId} selected={CountryListItems.CountryId === CountryId}> {CountryListItems.CountryName} </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                    <p className="maintitlereuestservicenee">State <span className="spanred"></span></p>
                                    <select id="ddlState" className="form-select border-0 form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => { getStateChange(e.target.value); setStateId(e.target.value) }}>
                                      <option selected>Select State</option>
                                      {StateList.map((StateItems) => (
                                        <option key={StateItems.StateId} value={StateItems.StateId} selected={StateItems.StateId === StateId}> {StateItems.StateName} </option>
                                      ))}
                                    </select>
                                  </div>

                                </div>
                                <div className="col-md-4">
                                  <div className="registerinputnewedirr form-controlsustomeditnew mb-0">
                                    <p className="maintitlereuestservicenee">City <span className="spanred"></span></p>
                                    <select id="ddlCity" className="form-select border-0 form-selectnewwr form-select-lg mb-0" aria-label=".form-select-lg example" onChange={(e) => setCityId(e.target.value)}>
                                      <option selected>Select City</option>
                                      {CityList.map((CityItems) => (
                                        <option key={CityItems.CityId} value={CityItems.CityId} selected={CityItems.CityId === CityId}> {CityItems.CityName} </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-5">
                              <p className="maintitlereuestservicenee">Location  <span className="spanred">*</span></p>
                              <div
                                id="map_canvas"
                                style={{ width: "530px", height: "250px" }}
                              ></div>

                              <div className="registerinputnewedirr form-controlsustomeditnew">
                                <input
                                  type="text"
                                  className="form-control formcontrollplaceholder border-0"
                                  id="autocomplete"
                                  placeholder="Enter Your Address"
                                  defaultValue={FullAddress}
                             
                                />

                                <label id="lblResult" hidden />
                                <label id="lblatitude" hidden />
                                <br />
                                <label id="lblongitude" hidden />
                              </div>

                            </div>

                          </div>

                        </div>

                      </div>


                    </div>

                  </div>
                </div>

              </div>

              <div className="col-md-12">
                <a className="btn btn-primary btnnextedit" onClick={UpdatePropertyDetail} >Save </a>
                <a className="btn btn-primary btnnexteditline" href="/property-list" > Cancel </a>
              </div>


            </div>
          </div>


        </div>
      </section>
      <Footeradmin />
    </React.Fragment >
  )
}

export default EditProperty

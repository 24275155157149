import React, { Component, useEffect, useState } from "react"
import mianheaderimg from "../../assets/images/frontend-img/logo-new1.png";
import profileimg from "../../assets/images/frontend-img/userdefaultimage.jpg";
import msi from "../../assets/images/frontend-img/msi.png";
import mpi from "../../assets/images/frontend-img/mpi.png";
import vpi from "../../assets/images/frontend-img/vpi.png";
import thi from "../../assets/images/frontend-img/thi.png";
import loi from "../../assets/images/frontend-img/loi.png";
import toprightshap from "../../assets/images/frontend-img/toprightshap.png";
import topshapleft from "../../assets/images/frontend-img/topshapleft.png";
import bottomrightshap from "../../assets/images/frontend-img/bottomrightshap.png";
import bottomleftshap from "../../assets/images/frontend-img/bottomleftshap.png";
import rfi from "../../assets/images/frontend-img/rfi.png";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import axios from "axios";

const header = () => {
    const { addToast } = useToasts();
    const [ProfileFileUrl, setProfileFileUrl] = useState(profileimg);
    const [Name, setName] = useState('');
    useEffect(() => {
        let VRegisterId = JSON.parse(localStorage.getItem("RegisterId"));
        if (VRegisterId == null) {
            window.location.href = "/home";
        } else if (VRegisterId == "") {
            window.location.href = "/home";
        } else if (parseInt(VRegisterId) == 0) {
            window.location.href = "/home";
        } else {
            getProfileData();
        }
    }, []);

    const logOut = async () => {
        localStorage.setItem("RegisterId", JSON.stringify(''));
        localStorage.setItem("Name", JSON.stringify(''));
        localStorage.setItem("Email", JSON.stringify(''));
        localStorage.setItem("Phone", JSON.stringify(''));
        localStorage.setItem("EmailVerified", JSON.stringify(''));
        localStorage.setItem("MobileVerified", JSON.stringify(''));
        window.location.href = "/home";
    }

    const getProfileData = async () => {

        let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
        const formData = new FormData();
        formData.append('RegisterId', VRegisterId);
        axios.post(global.APIURL + '/MyProfileData', formData)
            .then(res => {
                if (res.data.Result.length > 0) {
                    setProfileFileUrl(res.data.Result[0].ProfilePic);
                    setName(res.data.Result[0].Name);
                }
            });
    }

    const CheckProperty = async () => {
        let VRegisterId = JSON.parse(localStorage.getItem('RegisterId'));
        const PropListData = {
            RegisterId: VRegisterId,
            Pagination: 1
        }
        axios.post(global.APIURL + '/PropertyList', PropListData)
            .then(res => {
                var PropertyListCount = res.data.Result.length;
                if (PropertyListCount == 0) {
                    addToast("Oops, Please Complete the Add property Section First", { appearance: 'error', autoDismiss: true });
                }
                else {
                    window.location.href = "/request-new-service";
                }
            });
    }



    return (
        <React.Fragment>
            <img src={topshapleft} className="topshapleft"></img>
            <img src={toprightshap} className="toprightshap"></img>
            <img src={bottomrightshap} className="bottomrightshap"></img>
            <img src={bottomleftshap} className="bottomleftshap"></img>
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark registerheader">
                <div className="container">
                    <a href="/dashboard"><img className="loginlogo mb-0" src={mianheaderimg}></img></a>

                    <div className="d-flex align-items-center">
                        <li className="nav-item newsidebarmenuadd profilemobile">
                            <a className="nav-link mt-0 dropdown-toggle profilenameedittrxt" href="#" data-bs-toggle="dropdown" aria-expanded="false">

                                {ProfileFileUrl == "" ? <img className="mainprofiledashboard" src={ProfileFileUrl} /> :
                                    <img className="mainprofiledashboard" src={profileimg} />}

                            </a>
                            <ul className="dropdown-menu dropdown-menunewww">
                                <li><a className="dropdown-item" href="/manage-service"> <img src={msi}></img>Manage Service</a></li>
                                <li><a className="dropdown-item" href="/property-list"><img src={vpi}></img>View Properties</a></li>
                                <li><a className="dropdown-item" href="/transaction-history"><img src={thi}></img>Transaction History</a></li>
                                <li><a className="dropdown-item" href="/manage-profile"><img src={mpi}></img>Manage Profile</a></li>
                                <li><a className="dropdown-item" href="/referrals-list"><img src={rfi}></img>Refer a Friend</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" onClick={logOut}><img src={loi}></img>Log Out</a></li>
                            </ul>
                        </li>
                        <div className="mainmenuopenbtn">
                            <button className="navbar-toggler" id="openNavnew" type="button" >
                                <i className="fas fa-chevron-left"></i>
                            </button>
                        </div>
                    </div>
                    <div id="mySidenav" className="sidenav ">
                        <div className="maincontentsideneww">
                            <div className="innerboxedit">
                                <div className="closebtn" id="closeNav">
                                    <div className="mainsidemenucloseedit">
                                        <div>
                                            <i className="fas fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                                <a className="nav-link active headeractivenav" aria-current="page" href="/dashboard">Dashboard</a>
                                <a className="nav-link header-nav" onClick={CheckProperty} >Request New Service</a>
                                <a className="nav-link header-nav" href="/referrals-list">Refer a Friend</a>
                            </div>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav align-items-md-center ms-md-auto mb-2 mb-md-0">
                            <li className="nav-item">
                                <a className="nav-link " href="/dashboard">Dashboard</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " onClick={CheckProperty} >Request New Service</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="/referrals-list" >Refer a Friend</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">Support</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link dropdown-toggle profilenameedittrxt" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                    {ProfileFileUrl == "" ? <img className="mainprofiledashboard" src={profileimg} /> :
                                        <img className="mainprofiledashboard" src={ProfileFileUrl} />}
                                    {Name}</a>
                                <ul className="dropdown-menu dropdown-menunewww">
                                    <li><a className="dropdown-item" href="/manage-service"> <img src={msi}></img>Manage Service</a></li>
                                    <li><a className="dropdown-item" href="/property-list"><img src={vpi}></img>View Properties</a></li>
                                    <li><a className="dropdown-item" href="/transaction-history"><img src={thi}></img>Transaction History</a></li>
                                    <li><a className="dropdown-item" href="/manage-profile"><img src={mpi}></img>Manage Profile</a></li>
                                    <li><a className="dropdown-item" href="/referrals-list"><img src={rfi}></img>Refer a Friend</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" onClick={logOut}><img src={loi}></img>Log Out</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )

}

export default header

import React, { Component, useEffect, useState } from "react"
import mianheaderimg from "../../assets/images/frontend-img/logo-new1.png";
import axios from "axios";
import Commfooter from "../frontend/footer";

const privacy = () => {

  const [CompanyEmail, setCompanyEmail] = useState('');
  const [CompanyEmailMailto, setCompanyEmailMailto] = useState('');
  const [CompanyContact, setCompanyContact] = useState('');
  const [CompanyContacttel, setCompanyContacttel] = useState('');
  const [facebookLink, setfacebookLink] = useState('');
  const [InstagramLink, setInstagramLink] = useState('');
  const [LinkedinLink, setLinkedinLink] = useState('');
  const [TwitterLink, setTwitterLink] = useState('');
 

  useEffect(() => {
    getCompanyDetail();
  
  }, []);

  const getCompanyDetail = async () => {
    axios.get(global.APIURL + "/CompanyDetail")
      .then(res => {
        setCompanyEmail(res.data.Result[0].CompanyEmail);
        setCompanyEmailMailto('mailto:' + res.data.Result[0].CompanyEmail);
        setCompanyContact(res.data.Result[0].CompanyContact);
        setCompanyContacttel('tel:' + res.data.Result[0].CompanyContact);
        setfacebookLink(res.data.Result[0].facebookLink);
        setInstagramLink(res.data.Result[0].InstagramLink);
        setLinkedinLink(res.data.Result[0].LinkedinLink);
        setTwitterLink(res.data.Result[0].TwitterLink);
      });
  };

  return (
    <React.Fragment>
      <div>
        <section className="maintopbar">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-white topbarsocialicon">
                <a href={facebookLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                <a href={InstagramLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                <a href={LinkedinLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                <a href={TwitterLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
              </div>
              <div className="text-white topbarcontact">
                <a className="mt-0" href={CompanyContacttel}><i className="fas fa-phone me-md-3 me-2"></i>Call us : {CompanyContact}</a>
                <a href={CompanyEmailMailto} className="me-0"><i className="fas fa-envelope me-md-3 me-2"></i>Mail us : {CompanyEmail}</a>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="">
            <div className="">
              <nav
                className="navbar navbar-expand-lg navbar-light hedermain nav-container p-0  subpageheader"
                aria-label="Eighth navbar example"
              >
                <div className="container">
                  <a className="navbar-brand" href="/home">
                    <img src={mianheaderimg} className="headerlogo headerlogonew" alt="" />
                  </a>
                  <div>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarsExample07"
                      aria-controls="navbarsExample07"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                  </div>
                  <div
                    className="collapse navbar-collapse navbar-collapseedit"
                    id="navbarsExample07"
                  >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                      <li className="nav-item navbar-linksedit">
                        <a
                          className="nav-link "
                          aria-current="page"
                          href="/home"
                        >Home
                        </a>
                      </li>
                      <li className="nav-item navbar-linksedit">
                        <a className="nav-link" href="/aboutus">About us</a>
                      </li>
                      <li className="nav-item navbar-linksedit">
                        <a className="nav-link" aria-current="page" href="/how-it-works">How it Works?</a>
                      </li>
                      <li className="nav-item navbar-linksedit">
                        <a className="nav-link" href="/pricing">Pricing</a>
                      </li>
                      <li className="nav-item navbar-linksedit">
                        <a className="nav-link"
                          aria-current="page"
                          href="/login">Login</a>
                      </li>
                      <li className="nav-item navbar-linksedit">
                        <a className="nav-link me-0 btneditgetstarted"
                          aria-current="page"
                          href="/register-your-profile">
                          <button type="button" className="btn btn-outline-light btngetstarted">Get Started</button></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="bestdealboxmain bestdealboxmainnewprice">        
              <div className="row miandivpricingbox newmaindivpricingcustomadd">
                <div name="termly-embed" data-id="3ff5f924-f50d-42f1-a687-cae728b3476c" data-type="iframe"></div>
              </div>
            </div>
          </div>
        </section>
     <Commfooter/>
      </div>
    </React.Fragment>)

}

export default privacy
